define("oi/components/oi-radio-button-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    activeButtonClass: 'btn-primary',
    inactiveButtonClass: 'btn-default',
    tagName: 'span',
    classNames: 'btn-group',
    'aria-hidden': false,
    'data-toggle': 'buttons',
    role: 'group',
    attributeBindings: ['role', 'data-toggle', 'aria-hidden', 'aria-Label'],
    actions: {
      internalSet: function internalSet(value) {
        var onClickAction = this.onclick;

        if (onClickAction) {
          return onClickAction(value);
        }

        return this.set('groupValue', value);
      }
    }
  });

  _exports.default = _default;
});