define("oi/components/date-with-time-tooltip", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var TIMEZONE_SPLITTER = /(.+?)([+-]\d\d:?\d\d)/; // splits of 4-digit time
  // zones but not two-digit time zones also allowed by ISO 8601 (when adapting,
  // be careful not to split of day of timeless dates, e.g. 01 of '2019-03-01')

  var DateComponent = Ember.Component.extend({
    tagName: 'span',
    attributeBindings: ['formattedDateTime:aria-label'],
    classNameBindings: ['hintClass'],
    hintClass: Ember.computed('datetime', function () {
      return this.datetime ? 'hint--top' : '';
    }),
    fallback: '--',
    formattedDateTime: Ember.computed('datetime', 'location.locode', function () {
      var hopefullyIsoDatetime = this.datetime;

      var _ref = TIMEZONE_SPLITTER.exec(hopefullyIsoDatetime) || [],
          _ref2 = (0, _slicedToArray2.default)(_ref, 2),
          datetime
      /* , timezone */
      = _ref2[1];

      if (!datetime) {
        return hopefullyIsoDatetime;
        /* CHECK: log this case?! */
      }

      var locode = this.get('location.locode');
      var description = this.get('description');
      return "".concat(datetime.replace('T', ' '), " ").concat(locode ? "@".concat(locode) : '(local)').concat(description ? " (".concat(description, ")") : '');
    })
  });
  DateComponent.reopenClass({
    positionalParams: ['datetime']
  });
  var _default = DateComponent;
  _exports.default = _default;
});