define("oi/constants/subscription", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.VALID_ARCHIVE_TRANSITIONS = _exports.VALID_CANCEL_TRANSITIONS = void 0;
  var VALID_CANCEL_TRANSITIONS = [0, 1, 2]; // new, active, completed

  _exports.VALID_CANCEL_TRANSITIONS = VALID_CANCEL_TRANSITIONS;
  var VALID_ARCHIVE_TRANSITIONS = [2]; // completed

  _exports.VALID_ARCHIVE_TRANSITIONS = VALID_ARCHIVE_TRANSITIONS;
});