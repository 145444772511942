define("oi/helpers/oi-mapbox-coords", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.oiMapboxCoords = oiMapboxCoords;
  _exports.default = void 0;

  function oiMapboxCoords(params
  /* , hash */
  ) {
    return [params[0], params[1]];
  }

  var _default = Ember.Helper.helper(oiMapboxCoords);

  _exports.default = _default;
});