define("oi/templates/-filter-apply-buttons", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3Qz3MHQK",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[7,\"button\",true],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"applyNewFilterValues\"],null]],[11,\"disabled\",[22,\"newFiltersUnchanged\"]],[10,\"class\",\"btn btn-success\"],[10,\"type\",\"button\"],[8],[0,\"\\n  Filter now\\n\"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"newFiltersUnchanged\"]]],null,{\"statements\":[[0,\"  \"],[7,\"button\",true],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"resetFiltersToDefaults\"],null]],[11,\"disabled\",[22,\"newFiltersAreDefault\"]],[11,\"class\",[29,[\"btn \",[28,\"if\",[[24,[\"noData\"]],\"btn-warning\",\"btn-link\"],null]]]],[10,\"type\",\"button\"],[8],[0,\"\\n    Reset filters\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"button\",true],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"resetNewFiltersToCurrent\"],null]],[11,\"disabled\",[22,\"newFiltersAreDefault\"]],[10,\"class\",\"btn btn-link\"],[10,\"type\",\"button\"],[8],[0,\"\\n    Cancel\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "oi/templates/-filter-apply-buttons.hbs"
    }
  });

  _exports.default = _default;
});