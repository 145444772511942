define("oi/components/checks-and-crosses", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var CCComponent = Ember.Component.extend({
    classNameBindings: ['allSomeNoneClass'],
    allSomeNoneClass: Ember.computed('allSomeNoneBaseName', 'supportItems.[]', function () {
      var allSomeNoneBaseName = this.allSomeNoneBaseName,
          supportItems = this.supportItems;
      return allSomeNoneBaseName + (
      /* eslint-disable no-nested-ternary */
      !supportItems || !supportItems.length ? '' : supportItems.every(function (x) {
        return x;
      }) ? 'all' : supportItems.some(function (x) {
        return x;
      }) ? 'some' : 'none');
    })
  });
  CCComponent.reopenClass({
    positionalParams: ['supportItems']
  });
  var _default = CCComponent;
  _exports.default = _default;
});