define("oi/models/service", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    carrier: _emberData.default.belongsTo('carrier', {
      inverse: null
    }),
    code: _emberData.default.attr('string'),
    direction: _emberData.default.attr('string'),
    codeAndDirection: Ember.computed('code', 'direction', function () {
      var dirSuffix = ['EB', 'WB', 'NB', 'SB'].includes(this.direction) ? " [".concat(this.direction[0], "]") : '';
      return "".concat(this.code).concat(dirSuffix);
    }),
    nameCodeAndDirection: Ember.computed('name', 'code', 'direction', function () {
      var dirSuffix = ['EB', 'WB', 'NB', 'SB'].includes(this.direction) ? " [".concat(this.direction[0], "]") : '';
      return "".concat(this.name, " (").concat(this.code, ")").concat(dirSuffix);
    })
  });

  _exports.default = _default;
});