define("oi/serializers/shipment", ["exports", "ember-data", "oi/mixins/pagination-aware-serializer", "oi/serializers/drf"], function (_exports, _emberData, _paginationAwareSerializer, _drf) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.addIdsToLocsAndMilestones = addIdsToLocsAndMilestones;
  _exports.default = void 0;

  function addIdsToLocsAndMilestones(shipment) {
    var shipmentId = shipment.id;
    shipment.locations.forEach(function (loc) {
      loc.id = "".concat(shipmentId).concat(loc.key);
      loc.milestones.forEach(function (ms) {
        ms.location = loc.id;
        ms.id = "".concat(shipmentId, "-").concat(ms.key);
      });
    });
  }

  var _default = _drf.default.extend(_emberData.default.EmbeddedRecordsMixin, _paginationAwareSerializer.default, {
    attrs: {
      tags: {
        embedded: 'always'
      },
      // no longer true, actually, but does not seem to hurt
      carrier: {
        deserialize: 'records'
      },
      // only for shared shipments, but does not seem to hurt
      shipmentsubscription_tags: {
        embedded: 'always'
      },
      locations: {
        deserialize: 'records'
      },
      events: {
        deserialize: 'records'
      },

      /* usually not present and queried separately,      only embedded with shared shipments */
      pod_timeofarrival_warning: {
        deserialize: 'records'
      }
    },
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      var results = Ember.isArray(payload) ? payload : payload.results || [payload];
      results.forEach(function (result) {
        if (!result.shipmentsubscription_id) {
          // TODO? replace usages by subscription.id
          result.shipmentsubscription_id = result.subscription;
        }

        return addIdsToLocsAndMilestones(result);
      });
      return this._super(store, primaryModelClass, payload, id, requestType);
    }
  });

  _exports.default = _default;
});