define("oi/models/shipment/taw", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var RISKTYPE_FA_ICONS = {
    0: 'fa-check-circle',
    1: 'fa-exclamation-circle',
    2: 'fa-exclamation-triangle'
  };
  var RISKTYPE_CLASS = {
    0: 'success',
    1: 'warning',
    2: 'danger'
  };

  var _default = _emberData.default.Model.extend({
    risk: _emberData.default.attr('number'),
    risk_verbose: _emberData.default.attr('string'),
    risk_description: _emberData.default.attr('string'),
    conclusion: _emberData.default.attr('string'),
    shipment: _emberData.default.belongsTo('shipment', {
      async: false
    }),
    pod: Ember.computed.alias('shipment.locationsDict.pod'),
    predicted_time_of_arrival: _emberData.default.belongsTo('shipment/prediction'),
    prediction: Ember.computed.alias('predicted_time_of_arrival'),
    description: Ember.computed('isArrived', 'risk', 'risk_verbose', 'risk_description', 'prediction', function () {
      var conclusion = this.get('conclusion');

      if (this.get('shipment.isArrived')) {
        return 'Shipment arrived at POD';
      }

      return "".concat(conclusion ? "".concat(conclusion) : '');
    }),
    icon: Ember.computed('risk', 'isArrived', function () {
      var icon = RISKTYPE_FA_ICONS[this.get('risk')];

      if (icon) {
        return icon;
      }

      return 'fa-question-circle';
    }),
    severityStyle: Ember.computed('risk', function () {
      var risk = this.get('risk');
      var riskClass = RISKTYPE_CLASS[risk];

      if (risk >= 0 && riskClass) {
        return riskClass;
      }

      return 'secondary';
    })
  });

  _exports.default = _default;
});