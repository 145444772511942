define("oi/components/oi-icon-with-hint", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /** Component wrapping an icon (fontawesome, bootstrap glyphicon or arbitrary
      block content) in a <span> with hint. Parameters:
  
      iconName (or 1st positional) [String]: classes to attach; if first part includes a '-',
                                             the part before the hyphen will be repeated, e.g.
                                             'fa-ship' will become 'fa fa-ship'
      label (or 'aria-label' or 2nd positional) [String]: hint content
      hintPosition [String]: class name part to attach to outer span ('top' if omitted)
  
  */
  var IconComponent = Ember.Component.extend({
    tagName: 'span',
    attributeBindings: ['aria-label'],
    'aria-label': Ember.computed.alias('label'),
    classNameBindings: ['hintClass'],
    hintPosition: 'top',
    hintClass: Ember.computed('hintPosition', function () {
      return "hint--".concat(this.hintPosition);
    }),
    iconType: Ember.computed('iconName', function () {
      var split = (this.iconName || '').split('-');
      return split.length > 1 && !split[0].includes(' ') ? split[0] : '';
    })
  });
  IconComponent.reopenClass({
    positionalParams: ['iconName', 'label']
  });
  var _default = IconComponent;
  _exports.default = _default;
});