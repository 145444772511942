define("oi/components/tasks/editfield", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    editingActive: false,
    valueToEdit: undefined,
    value: undefined,
    callbackHandler: undefined,
    actions: {
      toggleEditingState: function toggleEditingState() {
        var isEditingActive = this.get('editingActive');
        this.set('valueToEdit', this.value);
        this.set('editingActive', !isEditingActive);
      },
      apply: function apply(value) {
        if (typeof this.callbackHandler === 'function') {
          this.callbackHandler(value);
        }

        this.set('editingActive', false);
      }
    }
  });

  _exports.default = _default;
});