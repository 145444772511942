define("oi/serializers/subscription", ["exports", "ember-data", "oi/mixins/pagination-aware-serializer", "oi/serializers/drf", "oi/serializers/shipment"], function (_exports, _emberData, _paginationAwareSerializer, _drf, _shipment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _drf.default.extend(_emberData.default.EmbeddedRecordsMixin, _paginationAwareSerializer.default, {
    attrs: {
      containershipments: {
        deserialize: 'records'
      },
      documents: {
        embedded: 'always'
      },
      tags: {
        embedded: 'always'
      }
    },
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      var results = Ember.isArray(payload) ? payload : payload.results || [payload];
      results.forEach(function (result) {
        if (result.containershipments) {
          result.containershipments.forEach(function (cs) {
            return (0, _shipment.addIdsToLocsAndMilestones)(cs);
          });
        }
      });
      return this._super(store, primaryModelClass, payload, id, requestType);
    }
  });

  _exports.default = _default;
});