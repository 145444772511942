define("oi/models/comment", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;

  var _default = Model.extend({
    task: _emberData.default.belongsTo('task', {
      async: true
    }),
    comment: _emberData.default.attr('string'),
    created_by: _emberData.default.attr('string'),
    created_at: _emberData.default.attr('momentdate'),
    updated_by: _emberData.default.attr('string'),
    updated_at: _emberData.default.attr('momentdate')
  });

  _exports.default = _default;
});