define("oi/serializers/bic-code", ["exports", "oi/serializers/drf"], function (_exports, _drf) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var OLD_API = 'https://capi.ocean-insights.com';

  var _default = _drf.default.extend({
    applicationService: Ember.inject.service(),
    primaryKey: 'bic',
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      if (payload && payload.operator) {
        if (payload.operator.startsWith(OLD_API)) {
          payload.operator = payload.operator.replace(OLD_API, this.applicationService.apiHost);
        }
      }

      return this._super(store, primaryModelClass, payload, id, requestType);
    }
  });

  _exports.default = _default;
});