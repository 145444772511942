define("oi/routes/fourohfour", ["exports", "@babel/runtime/helpers/esm/toArray", "@babel/runtime/helpers/esm/slicedToArray", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _toArray2, _slicedToArray2, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    titleToken: function titleToken() {
      return '404';
    },
    beforeModel: function beforeModel(transition) {
      var _transition$intent$ur = transition.intent.url.split('?unknown='),
          _transition$intent$ur2 = (0, _slicedToArray2.default)(_transition$intent$ur, 2),
          currentTarget = _transition$intent$ur2[0],
          unknownSubRoute = _transition$intent$ur2[1];

      if (unknownSubRoute) {
        this.set('unknownRoute', "".concat(currentTarget, "/").concat(unknownSubRoute));
      } else {
        var _currentTarget$split$ = currentTarget.split('/').reverse(),
            _currentTarget$split$2 = (0, _toArray2.default)(_currentTarget$split$),
            sub = _currentTarget$split$2[0],
            othersReversed = _currentTarget$split$2.slice(1);

        var possibleTarget = othersReversed.reverse().join('/'); // eslint-disable-next-line no-console

        console.log("".concat(transition.intent.url, " not found, trying ").concat(possibleTarget));
        this.transitionTo(possibleTarget && "".concat(possibleTarget, "?unknown=").concat(sub));
      }
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set('unknownRoute', this.unknownRoute);
    }
  });

  _exports.default = _default;
});