define("oi/controllers/upcoming-arrivals", ["exports", "@babel/runtime/helpers/esm/toConsumableArray", "@babel/runtime/helpers/esm/slicedToArray", "oi/constants/countries"], function (_exports, _toConsumableArray2, _slicedToArray2, _countries) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function filterSubset(voyages, carrierNamesFilter, tagFilter, store) {
    var carrierNamesFilterSet = new Set(carrierNamesFilter);

    var _ref = (tagFilter || '').match(/(ANY|ALL)\(([\d_)]*)\)/) || [],
        _ref2 = (0, _slicedToArray2.default)(_ref, 3),
        modeStr = _ref2[1],
        idStr = _ref2[2];

    var tagIdToFilter = idStr ? idStr.split('_') : [];
    var tagFilterMethod = modeStr === 'ALL' ? 'every' : 'some';
    return voyages.map(function (voyage) {
      var subscriptions = voyage.subscriptions;

      if (carrierNamesFilterSet.size) {
        subscriptions = subscriptions.filter(function (sub) {
          return carrierNamesFilterSet.has(sub.request_carrier_name);
        });
      }

      if (tagIdToFilter.length) {
        subscriptions = subscriptions.filter(function (sub) {
          var subscriptionTagIds = new Set(sub.tags.mapBy('id'));
          return tagIdToFilter[tagFilterMethod](function (filterTagId) {
            return subscriptionTagIds.has(filterTagId);
          });
        });
      }

      if (subscriptions.length === 0) {
        return null;
      }

      if (subscriptions.length === voyage.subscriptions.length) {
        return voyage;
      }

      var shipments = subscriptions.flatMap(function (sub) {
        return sub.containershipments.toArray();
      });
      return store.createRecord('container-voyage', {
        arrivalInD: voyage.arrivalInD,
        podLocode: voyage.podLocode,
        podName: voyage.podName,
        vesselName: voyage.vesselName,
        voyage: voyage.voyage,
        subscriptions: subscriptions,
        shipments: shipments,
        numShipments: shipments.length
      });
    }).filter(Boolean);
  }

  var _default = Ember.Controller.extend({
    queryParams: ['maxDays', 'referenceDates'],
    maxDays: 3,
    presentCountries: Ember.computed('voyages.@each.podLocode', function () {
      var presentIsoCodes = new Set(this.voyages.mapBy('podCountry'));
      return _countries.default.filter(function (country) {
        return presentIsoCodes.has(country.abbr);
      });
    }),
    presentPods: Ember.computed('voyages.@each.podLocode', function () {
      return (0, _toConsumableArray2.default)(new Set(this.voyages.map(function (voyage) {
        return voyage.podName && "".concat(voyage.podName, "|||").concat(voyage.podLocode);
      }))).sort();
    }),
    presentCarriers: Ember.computed('voyages.@each.carrierNames', function () {
      return (0, _toConsumableArray2.default)(new Set(this.voyages.mapBy('carrierNames').flat())).filter(Boolean).sort();
    }),
    presentTags: Ember.computed('voyages.@each.subscriptionTags', function () {
      return (0, _toConsumableArray2.default)(new Set(this.voyages.map(function (voyage) {
        return (0, _toConsumableArray2.default)(voyage.subscriptionTags);
      }).flat())).sort();
    }),
    destinations: Ember.computed('presentCountries', 'presentPods', function () {
      var presentCountryCodes = this.presentCountries.map(function (_ref3) {
        var abbr = _ref3.abbr;
        return abbr;
      });
      var presentContinents = Object.entries(_countries.CONTINENTS_TO_COUNTRIES).filter(function (_ref4) {
        var _ref5 = (0, _slicedToArray2.default)(_ref4, 2),
            countries = _ref5[1];

        return presentCountryCodes.find(function (c) {
          return countries.has(c);
        });
      }).map(function (_ref6) {
        var _ref7 = (0, _slicedToArray2.default)(_ref6, 1),
            continentName = _ref7[0];

        return {
          id: continentName,
          title: continentName
        };
      }); // for countries as imported above, "title" already includes the abbr in parentheses

      return [{
        groupName: 'Continents',
        options: presentContinents
      }, {
        groupName: 'Countries',
        options: this.presentCountries.map(function (_ref8) {
          var title = _ref8.title,
              abbr = _ref8.abbr;
          return {
            id: abbr,
            title: title
          };
        })
      }, {
        groupName: 'Ports',
        options: this.presentPods.map(function (nameAndLocode) {
          if (!nameAndLocode) {
            return {
              id: null,
              title: '[unknown]'
            };
          }

          var _nameAndLocode$split = nameAndLocode.split('|||'),
              _nameAndLocode$split2 = (0, _slicedToArray2.default)(_nameAndLocode$split, 2),
              name = _nameAndLocode$split2[0],
              locode = _nameAndLocode$split2[1];

          return {
            id: locode,
            title: "".concat(name, " (").concat(locode, ")")
          };
        })
      }];
    }),
    isCarrierAndTagInfoPending: Ember.computed('voyages.@each.anyRelationPending', function () {
      return !!this.voyages.find(function (voyage) {
        return voyage.anyRelationPending;
      });
    }),
    store: Ember.inject.service(),
    filteredVoyages: Ember.computed('voyages.[]', 'selectedDestinations', 'selectedCarriers', 'selectedTags', function () {
      var voyagesForDestination = this.voyages;

      if (this.selectedDestinations && this.selectedDestinations.length) {
        var countries = new Set();
        var pods = new Set();
        this.selectedDestinations.forEach(function (_ref9) {
          var id = _ref9.id;

          if (id === null || id.length === 5) {
            pods.add(id);
          } else if (id.length === 2) {
            countries.add(id);
          } else if (_countries.CONTINENTS_TO_COUNTRIES[id]) {
            _countries.CONTINENTS_TO_COUNTRIES[id].forEach(function (c) {
              return countries.add(c);
            });
          } // no continent for country "unknown"

        });
        voyagesForDestination = this.voyages.filter(function (voyage) {
          return pods.has(voyage.podLocode) || countries.has(voyage.podCountry);
        });
      }

      if (!this.selectedTags && (!this.selectedCarriers || this.selectedCarriers.length === 0)) {
        return voyagesForDestination;
      } // Voyages are already grouped by destination, so destination filtering just removes some records.
      // Filtering by carrier or tags, however, may change the composition of individual records.


      return filterSubset(voyagesForDestination, this.selectedCarriers, this.selectedTags, this.store);
    })
  });

  _exports.default = _default;
});