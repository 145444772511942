define("oi/components/tasks/tasks", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    notifications: Ember.inject.service('notify'),
    tasks: undefined,
    selectedTask: undefined,
    type: undefined,
    appearence: undefined,
    actions: {
      edit: function edit(task) {
        this.set('selectedTask', task);
      },
      close: function close() {
        this.set('selectedTask', undefined);
      },
      delete: function _delete(task) {
        var _this = this;

        task.destroyRecord().then(function (t) {
          _this.set('taskToDelete', null);

          _this.notifications.success("Task ".concat(t.id, " was successfully deleted"), 'Task deleted');
        }).catch(function (ex) {
          _this.notifications.error(ex, 'Error deleting task');
        });
      }
    }
  });

  _exports.default = _default;
});