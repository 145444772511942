define("oi/templates/components/oi-tag-edit-line", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qtal2LDH",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[28,\"and\",[[24,[\"isEditing\"]],[24,[\"update\"]]],null]],null,{\"statements\":[[0,\"  \"],[7,\"td\",true],[8],[1,[28,\"input\",null,[[\"class\",\"value\"],[\"editDesc form-control\",[24,[\"tag\",\"value\"]]]]],false],[9],[0,\"\\n  \"],[7,\"td\",true],[10,\"align\",\"right\"],[8],[0,\"\\n\"],[0,\"    \"],[7,\"button\",false],[12,\"class\",\"btn btn-xs btn-default\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],\"update\"]],[8],[0,\"\\n      Save \"],[7,\"i\",true],[10,\"class\",\"fa fa-save\"],[10,\"aria-hidden\",\"true\"],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"button\",false],[12,\"class\",\"btn btn-xs btn-default\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"isEditing\"]]],null],false]],[8],[0,\"\\n      Cancel ✖\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"td\",true],[8],[0,\"\\n    \"],[1,[28,\"oi-tag\",null,[[\"tag\",\"classNames\"],[[24,[\"tag\"]],\"oi-tag-without-hash\"]]],false],[0,\"\\n\"],[0,\"  \"],[9],[0,\"\\n  \"],[7,\"td\",true],[10,\"align\",\"right\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"tag\",\"isSaving\"]]],null,{\"statements\":[[0,\"      \"],[1,[28,\"spinkit-spinner\",null,[[\"class\"],[\"nomargin\"]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"requestDelete\"]]],null,{\"statements\":[[0,\"      \"],[7,\"button\",false],[12,\"class\",\"btn btn-xs btn-default\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],[24,[\"requestDelete\"]],[24,[\"tag\"]]]],[8],[0,\"\\n        Delete \"],[7,\"i\",true],[10,\"class\",\"fa fa-trash\"],[10,\"aria-hidden\",\"true\"],[8],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "oi/templates/components/oi-tag-edit-line.hbs"
    }
  });

  _exports.default = _default;
});