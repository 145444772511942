define("oi/templates/components/requesttagdelete-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IX9Gf5Zl",
    "block": "{\"symbols\":[],\"statements\":[[4,\"confirm-modal\",null,[[\"title\",\"context\",\"ok\",\"close\",\"actionIsImmediate\"],[\"Deleting tag\",[24,[\"model\"]],\"deleteTag\",\"removeModal\",false]],{\"statements\":[[0,\"  Really want to delete the tag \"],[7,\"em\",true],[8],[1,[24,[\"model\",\"value\"]],false],[9],[0,\"? This cannot be undone.\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "oi/templates/components/requesttagdelete-modal.hbs"
    }
  });

  _exports.default = _default;
});