define("oi/helpers/join", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function (_ref, _ref2) {
    var _ref3 = (0, _slicedToArray2.default)(_ref, 2),
        array = _ref3[0],
        char = _ref3[1];

    var on = _ref2.on;
    return array.join(char || on);
  });

  _exports.default = _default;
});