define("oi/models/exception", ["exports", "ember-data", "oi/routes/index/exceptions"], function (_exports, _emberData, _exceptions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.FIXED_EXCEPTIONS = _exports.DESCRIPTIONS = void 0;
  var DESCRIPTIONS = {
    'Currently in POL': 'Containers that are currently in the POL terminal for > #DAYS#.',
    'Currently in T/S': 'Containers that are currently in a transshipment terminal for > #DAYS#.',
    'Currently in POD': 'Containers that are currently in the POD terminal for > #DAYS#.',
    'Currently in LIF': 'Containers that are currently in the LIF terminal for > #DAYS#.',
    'Behind schedule': 'Containers that are currently exceeding their latest known ETA for more than #DAYS#.',
    'POD arrival warning': 'Containers whose time-of-arrival at POD is predicted to deviate by > #DAYS# from the carrier plan or may be affected by rollover.',
    'ETD changed': 'Containers whose carrier ETD at POL has changed from the initial value. This includes all active containers, including those that have already reached POL.',
    'ETA changed': 'Containers whose carrier ETA at POD or DLV has changed from the initial value. This includes all active containers, including those that have already reached POD.',
    'Not released': 'Containers currently held by customs and/or carrier.'
  };
  _exports.DESCRIPTIONS = DESCRIPTIONS;
  var FIXED_EXCEPTIONS = ['ETD changed', 'ETA changed', 'Not released'];
  _exports.FIXED_EXCEPTIONS = FIXED_EXCEPTIONS;

  var _default = _emberData.default.Model.extend({
    count: _emberData.default.attr('number'),
    title: _emberData.default.attr('string'),
    query_params: _emberData.default.attr(),
    description: Ember.computed('query_params', function () {
      var description = DESCRIPTIONS[this.get('title')];

      var days = this.get('query_params').exception_days || _exceptions.DEFAULT_EXCEPTION_DAYS;
      /* eslint-disable-next-line eqeqeq */
      // == just in case days === '1' (i.e. string)


      return description.replace('#DAYS#', "".concat(days, " ").concat(days == 1 ? 'day' : 'days'));
    })
  });

  _exports.default = _default;
});