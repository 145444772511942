define("oi/helpers/local-datetime", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "moment"], function (_exports, _slicedToArray2, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.localDatetime = localDatetime;
  _exports.default = void 0;

  function localDatetime(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 1),
        obj = _ref2[0];

    return "".concat((0, _moment.default)(obj).format('YYYY-MM-DD HH:mm'), " ").concat((0, _moment.default)().tz(_moment.default.tz.guess()).format('z'));
  }

  var _default = Ember.Helper.helper(localDatetime);

  _exports.default = _default;
});