define("oi/components/chart-fetcher", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "oi/config/environment", "jquery"], function (_exports, _slicedToArray2, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* global Highcharts */
  var _default = Ember.Component.extend({
    applicationService: Ember.inject.service(),
    tagName: '',
    // properties from component's template
    dashboardName: null,
    chartName: null,
    desiredHeight: null,
    desiredWidth: 300,
    router: Ember.inject.service(),
    user: Ember.inject.service(),
    fetch: function fetch(dashboardName, chartName, filterQuery) {
      var url = "".concat(this.applicationService.apiBase, "/dashboards/").concat(dashboardName, "/data/?chart_name=").concat(chartName).concat(filterQuery ? "&".concat(filterQuery) : '');
      return _jquery.default.ajax({
        url: url,
        type: 'GET',
        headers: this.get('user.fullHeaders'),
        crossDomain: true,
        xhrFields: {
          withCredentials: _environment.default.APP.USE_AJAX_CALL_WITH_CREDENTIALS
        }
      });
    },
    overrideAndPassOnZoomType: function overrideAndPassOnZoomType(chart) {
      if (!chart.zoomType) {
        return;
      }

      var zoomType = ['area', 'bar', 'boxplot'].includes(chart.type) ? 'x' : chart.zoomType;
      chart.zoomType = zoomType;
      var zoomDirection = chart.type === 'bar' ? 'y' : chart.zoomType; // bar charts are transposed:
      // labels on the left with bars extending horizontally. Highcharts still condiers the labels
      // the "x-axis", but for the user, the zoom area has to be selected differently

      this.set('zoomDirection', zoomDirection); // bound to a variable passed in that will
      // be used elsewhere to render a "you can zoom in here" indicator in the chart header
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      var emberComponent = this;
      Highcharts.setOptions({
        lang: {
          drillUpText: '< Back'
        }
      });
      this.fetch(this.dashboardName, this.chartName, this.filterQuery).then(function (dataAndOptions) {
        if (_this.isDestroyed) {
          // user lost patience and navigated to a different route...
          return; // ...so at least prevent console error "calling set on destroyed object"
        } // got data - talk to component's template TODO: handle error


        var content = dataAndOptions.series;
        delete dataAndOptions.series;
        var options = dataAndOptions;
        var desiredHeight = _this.desiredHeight; // store & set the passed height

        dataAndOptions.chart.height = desiredHeight;
        dataAndOptions.desiredHeight = desiredHeight;

        _this.overrideAndPassOnZoomType(dataAndOptions.chart);

        if (options.drilldown.series.length > 0) {
          options.chart.events.drilldown = function (ev) {
            var _this2 = this;

            var newHeight = Math.max(200, ev.seriesOptions.data.length * 25);
            Ember.run.later(function () {
              return _this2.setSize(358, newHeight, false);
            }, 300);
            (0, _jquery.default)("#".concat(options.chart.renderTo)).height(newHeight); // this.containerHeight = newHeight;
            // this.reflow();
            //            this.redraw();
          }; // set the drillup handler to the chart's options


          options.chart.events.drillup = function () {
            var _this3 = this;

            Ember.run.later(function () {
              return _this3.setSize(358, options.chart.height, false);
            }, 300);
            (0, _jquery.default)("#".concat(options.chart.renderTo)).height(emberComponent.get('desiredHeight'));
          };
        }

        if (options.plotOptions.series.events) {
          options.plotOptions.series.events.click = function (ev) {
            if (!(this.data[0] && this.data[0].drilldown)) {
              // in drilldown case, the series is undefined ...
              var theCat = ev.point.category;

              if (typeof theCat === 'number' && theCat % 1 === 0) {
                theCat = ev.point.name;
              }

              var theSID = this.options.id.split('.');
              theSID = theSID[1] || theSID[0];
              var queryParams = {};
              emberComponent.get('filterQuery').split('&').forEach(function (pair) {
                var _pair$split = pair.split('='),
                    _pair$split2 = (0, _slicedToArray2.default)(_pair$split, 2),
                    key = _pair$split2[0],
                    value = _pair$split2[1];

                queryParams[key] = value;
              });
              queryParams.macnorris = "".concat(emberComponent.get('dashboardName'), "__").concat(emberComponent.get('chartName'), "__").concat(theCat, "__").concat(theSID);

              if (emberComponent.get('dashboardName') === 'podapproach') {
                // since we do aggregate archived subs in dashboards, we need to include them in directory as well
                queryParams.subscription_status = 1;
                queryParams.monitoring_status = 1;
              } else {
                // podapproach dashboard explicitly excludes archived subs
                queryParams.subscription_status = 2;
                queryParams.monitoring_status = 2;
              }

              emberComponent.get('router').transitionTo('shipments', {
                queryParams: queryParams
              });
            }
          };
        }

        _this.setProperties({
          content: content,
          options: options
        });
      });
    }
  });

  _exports.default = _default;
});