define("oi/templates/components/oi-meta-badges", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0FBk+GrD",
    "block": "{\"symbols\":[\"av\",\"date\",\"loc\",\"prop\",\"release\"],\"statements\":[[4,\"each\",[[24,[\"releaseProps\"]]],null,{\"statements\":[[4,\"with\",[[28,\"get\",[[24,[\"shipment\"]],[23,4,[\"key\"]]],null]],null,{\"statements\":[[0,\"    \"],[7,\"span\",true],[11,\"class\",[29,[\"badge bg-\",[28,\"if\",[[23,5,[\"state\"]],\"green\",\"red\"],null]]]],[8],[0,\"\\n      \"],[1,[23,4,[\"name\"]],false],[0,\" \"],[1,[28,\"if\",[[23,5,[\"state\"]],\"release\",\"hold\"],null],false],[0,\"\\n\"],[4,\"if\",[[28,\"and\",[[23,5,[\"state\"]],[23,5,[\"date\"]]],null]],null,{\"statements\":[[0,\"        \"],[1,[28,\"moment-format\",[[23,5,[\"date\"]],\"YYYY-MM-DD\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n\"]],\"parameters\":[5]},null]],\"parameters\":[4]},null],[0,\"\\n\"],[4,\"with\",[[24,[\"shipment\",\"availability\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\",true],[10,\"class\",\"badge\"],[8],[0,\"\\n    Available:\\n\"],[4,\"with\",[[23,1,[\"loc\"]]],null,{\"statements\":[[0,\"      \"],[7,\"span\",true],[11,\"aria-label\",[23,3,[\"name\"]]],[10,\"class\",\"hint--top-right\"],[8],[0,\"\\n        \"],[1,[23,1,[\"loc\",\"locode\"]],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[3]},null],[4,\"with\",[[23,1,[\"date\"]]],null,{\"statements\":[[0,\"      \"],[1,[28,\"moment-format\",[[23,2,[]],\"YYYY-MM-DD\"],null],false],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "oi/templates/components/oi-meta-badges.hbs"
    }
  });

  _exports.default = _default;
});