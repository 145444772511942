define("oi/routes/subscription", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "jquery", "ember-simple-auth/mixins/authenticated-route-mixin", "oi/mixins/route-error-logger", "oi/helpers/oi-change-customization-style"], function (_exports, _slicedToArray2, _jquery, _authenticatedRouteMixin, _routeErrorLogger, _oiChangeCustomizationStyle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _routeErrorLogger.default, {
    titleToken: function titleToken(model) {
      return "Subscription ".concat(Ember.get(model, 'subscription.request_key'));
    },
    session: Ember.inject.service(),
    beforeModel: function beforeModel(transition) {
      if (!this.get('session.isAuthenticated') && transition.to.queryParams.share) {
        return null; // skip ember-simple-auth's AuthenticatedRoute logic
      }

      return this._super(transition); // use ember-simple-auth's AuthenticatedRoute logic
    },
    notify: Ember.inject.service(),
    raven: Ember.inject.service(),
    queryParams: {
      page: {
        refreshModel: true
      },
      page_size: {
        refreshModel: true
      },
      status: {
        refreshModel: true
      },
      type: {
        refreshModel: true
      },
      appearence: {
        refreshModel: true
      }
    },
    actions: {
      cancel: function cancel(params) {
        this.controller.send('cancel', params);
        return true;
      },
      archive: function archive(params) {
        this.controller.send('archive', params);
        return true;
      },
      error: function error(_error, transition) {
        return this.handleError(_error, transition);
      },
      refreshTasks: function refreshTasks() {
        this.refresh();
      },
      willTransition: function willTransition(transition) {
        if (!transition.to.queryParams.share) {
          (0, _oiChangeCustomizationStyle.oiChangeCustomerStyle)({
            primary_color: '#1992D4',
            secondary_color: '#fff'
          });
        }
      }
    },
    // ---------------------------------------------------------------------------
    // Model hooks
    // ---------------------------------------------------------------------------
    model: function model(param, transition) {
      var _$split = "".concat(param.subscription_id).split('-'),
          _$split2 = (0, _slicedToArray2.default)(_$split, 2),
          actualId = _$split2[0],
          suffix = _$split2[1];
      /* For roughly 2018-08-21 to -26, daily digest mails were sent with links like
       * /subscription/1234567-100 due to an attempt to fix CWA-726 in the django
       * back-end without considering futher mis-uses of subscription-ids-as-event-ids
       * which caused that bug. This is a workaround to make these link work. */


      if (suffix !== undefined) {
        // suffix may be '0'
        this.raven.captureMessage("Subscription id with suffix! ".concat(actualId, "-").concat(suffix), {
          level: 'info',
          fingerprint: ['Bad subscription id'],
          extra: {
            actualId: actualId,
            suffix: suffix,
            transitionTo: transition.to,
            transitionFrom: transition.from
          }
        });
      }

      return Ember.RSVP.hash({
        subscription: transition.to.queryParams.share ? this.store.queryRecord('subscription', {
          id: param.subscription_id,
          share: transition.to.queryParams.share
        }) : this.store.find('subscription', actualId),
        sharedLink: transition.to.queryParams.share ? transition.to.queryParams.share : false,
        allAvailableTags: this.store.findAll('tag', {
          backgroundReload: false
        }) // tasks: this.store.query('task', {
        //   page: param.page,
        //   page_size: param.page_size,
        //   status: param.status,
        //   type: param.type,
        //   appearence: param.appearence,
        //   link__object_id: param.subscription_id,
        //   link__content_type__model: 'containershipmentsubscription',
        // }),

      });
    },
    afterModel: function afterModel(model, transition) {
      if (model.subscription.customization) {
        if (model.subscription.customization && transition.to.queryParams.share) {
          (0, _oiChangeCustomizationStyle.oiChangeCustomerStyle)(model.subscription.customization); // sanitize url

          var web = model.subscription.customization.web;

          if (web && !web.startsWith('http://') && !web.startsWith('https://')) {
            var url = web.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '').split('/')[0];
            model.subscription.customization.web = "https://www.".concat(url);
          }
        }
      }
    },
    // ---------------------------------------------------------------------------
    // Init controller
    // ---------------------------------------------------------------------------
    setupController: function setupController(controller, model) {
      controller.set('data', model.subscription); //

      controller.set('allAvailableTags', model.allAvailableTags);
      controller.set('sharedLink', model.sharedLink);
      controller.set('errorMessage', null);
      controller.set('tasks', model.tasks); // assign the bs tooltip magic to the late initialized object

      Ember.run.later(this, function () {
        (0, _jquery.default)('a[data-toggle="tooltip"]').tooltip();
      }, 2000);
    }
  });

  _exports.default = _default;
});