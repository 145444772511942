define("oi/components/formatted-error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    outerClass: 'row',
    classNameBindings: ['outerClass'],
    adapterOperationFailedClass: 'lead',
    alertSeverity: 'danger',
    transition: Ember.computed.alias('error.transition'),
    actions: {
      retryTransition: function retryTransition() {
        return this.get('transition').retry();
      }
    }
  });

  _exports.default = _default;
});