define("oi/components/oi-tags", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    saved: null,
    actions: {
      cancel: function cancel() {
        this.set('selectedTags', this.saved);
      },
      edit: function edit() {
        this.set('saved', this.selectedTags.slice());
      }
    }
  });

  _exports.default = _default;
});