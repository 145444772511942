define("oi/helpers/oi-shorten-text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.oiShortenText = oiShortenText;
  _exports.default = void 0;

  function oiShortenText(params) {
    if (params.length !== 2) {
      return 'Wrong args!';
    }

    var texxxt = params[0];
    var length = params[1];
    return texxxt.length > length ? "".concat(texxxt.substring(0, length), "...") : texxxt;
  }

  var _default = Ember.Helper.helper(oiShortenText);

  _exports.default = _default;
});