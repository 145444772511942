define("oi/adapters/todo", ["exports", "oi/adapters/application", "oi/config/environment"], function (_exports, _application, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var API_BASE = "".concat(_environment.default.APP.API_HOST, "/").concat(_environment.default.APP.API_NAMESPACE);

  var _default = _application.default.extend({
    buildURL: function buildURL(type, id, snapshot, requestType) {
      if (requestType === 'createRecord') {
        return "".concat(API_BASE, "/task-management/tasks/").concat(snapshot.record.get('task.id'), "/todos/");
      }

      return "".concat(API_BASE, "/task-management/tasks/").concat(snapshot.record.get('task.id'), "/todos/").concat(id, "/");
    }
  });

  _exports.default = _default;
});