define("oi/helpers/oi-subscription-type", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.oiSubscriptionType = oiSubscriptionType;
  _exports.default = void 0;

  function oiSubscriptionType(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 1),
        value = _ref2[0];

    var retValue = null;

    switch (value) {
      case 'c_id':
        retValue = 'Container number';
        break;

      case 'b_id':
        retValue = 'Booking number';
        break;

      case 'm_bl':
        retValue = 'MBL/BL number';
        break;

      default:
        retValue = 'Unknown';
    }

    return retValue;
  }

  var _default = Ember.Helper.helper(oiSubscriptionType);

  _exports.default = _default;
});