define("oi/components/oi-travel-progress", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'section',
    classNames: ['oi-travel-progress'],
    session: Ember.inject.service(),
    numberOfTransshipments: Ember.computed.alias('shipment.ts_count'),
    tsLocations: Ember.computed.alias('shipment.transshipmentLocations'),

    /**
     * Map status to the gfx - this is probably a very bad idea but - hey - it works!
     */
    statusPattern: Ember.computed('status', function () {
      switch (this.status) {
        case 10:
          // waiting for pickup
          return [{
            class: 'is-active'
          }, {
            class: 'is-next-active'
          }];

        case 11:
          // land transport to pol
          return [{
            class: 'is-ready is-active'
          }, {
            class: 'is-next-active'
          }];

        case 12: // in POL terminal

        case 13:
          // waiting for departure from POL
          return [{
            class: 'is-ready'
          }, {
            class: 'is-active'
          }, {
            class: 'is-next-active'
          }];

        case 14: // ocean transport from POL

        case 15: // in transshipment

        case 16:
          // ocean transport to POD
          return [{
            class: 'is-ready'
          }, {
            class: 'is-ready'
          }, {
            class: 'is-active'
          }, {
            class: 'is-next-active'
          }];

        case 17: // waiting for discharge at POD

        case 18:
          // in POD terminal
          return [{
            class: 'is-ready'
          }, {
            class: 'is-ready'
          }, {
            class: 'is-ready'
          }, {
            class: 'is-active'
          }, {
            class: 'is-next-active'
          }];

        case 19: // land transport to place of delivery

        case 20:
          // delivered; returning container
          return [{
            class: 'is-ready'
          }, {
            class: 'is-ready'
          }, {
            class: 'is-ready'
          }, {
            class: 'is-ready'
          }, {
            class: 'is-active'
          }];

        case 21:
          // completed
          return [{
            class: 'is-ready'
          }, {
            class: 'is-ready'
          }, {
            class: 'is-ready'
          }, {
            class: 'is-ready'
          }, {
            class: 'is-ready'
          }];

        case 0: // unknown

        default:
          return [];
      }
    })
  });

  _exports.default = _default;
});