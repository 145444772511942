define("oi/routes/index/exceptions", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.DEFAULT_EVENT_HOURS = _exports.DEFAULT_EXCEPTION_DAYS = void 0;
  var DEFAULT_EXCEPTION_DAYS = 3;
  _exports.DEFAULT_EXCEPTION_DAYS = DEFAULT_EXCEPTION_DAYS;
  var DEFAULT_EVENT_HOURS = 24;
  _exports.DEFAULT_EVENT_HOURS = DEFAULT_EVENT_HOURS;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    session: Ember.inject.service(),
    accounts: Ember.computed('session.data.prefAccountIds', function () {
      return this.get('session.data.prefAccountIds').join();
    }),
    isMasterAccount: Ember.computed('session.data.isMasterAccount', function () {
      return this.get('session.data.isMasterAccount');
    }),
    model: function model(params) {
      /* No `hash` here: controller shall display right away and deal with value being a promise explicitly. */
      return {
        eventsummaries: this.loadEvents(params.hours)
      };
    },
    loadExceptionDefinitions: function loadExceptionDefinitions() {
      return this.store.query('exceptionmanagement/definition', {});
    },
    loadEvents: function loadEvents() {
      var hours = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : DEFAULT_EVENT_HOURS;
      return this.store.query('eventsummary', {
        hours: hours
      });
    },
    setupController: function setupController(controller, model) {
      controller.setProperties(model);
    },
    resetController: function resetController(controller
    /* , isExiting, transition */
    ) {
      controller.setProperties({
        days: DEFAULT_EXCEPTION_DAYS,
        hours: DEFAULT_EVENT_HOURS
      });
    },
    actions: {
      error: function error() {
        return true;
      },
      queryParamsDidChange: function queryParamsDidChange(changed, totalPresent, removed) {
        if (!this.get('session.isAuthenticated')) {
          return null; // Action is triggered also on initial page loading if
          // query params present, before session is properly restored. Data will
          // be loaded via regular model hook call after session is restored.
        }

        var controller = this.controllerFor('index/exceptions');

        if (changed.hours || removed.hours) {
          controller.set('eventsummaries', this.loadEvents(changed.hours));
        }

        return true;
      },
      refreshExceptionDefinitions: function refreshExceptionDefinitions() {
        var controller = this.controllerFor('index/exceptions');
        controller.set('exception_definitions', this.loadExceptionDefinitions());
      }
    }
  });

  _exports.default = _default;
});