define("oi/models/shipment/milestone", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import { OUTGOING_MILESTONES_DESC } from 'oi/models/shipment/location';
  var TYPE_VERBALIZATION = {
    CL: 'loading',
    VD: 'departure',
    VA: 'arrival',
    CD: 'discharge'
  }; // CHECK: prefix by "container" or "vessel", respectively?

  var OUTBOUND_MILESTONE_KEYS = ['CEP', 'CPS', 'VDL', 'CLL', 'VDT', 'CLT', 'CGO', 'COF', 'CDC', 'CER']; // new Set(Object.values(OUTGOING_MILESTONES_DESC).flat()); // TODO: polyfill for IE11!

  var _default = _emberData.default.Model.extend({
    key: _emberData.default.attr('string'),
    location: _emberData.default.belongsTo('shipment/location'),
    planned_initial: _emberData.default.attr('string'),
    planned_last: _emberData.default.attr('string'),
    actual: _emberData.default.attr('string'),
    detected: _emberData.default.attr('string'),
    isDone: _emberData.default.attr('boolean'),
    customer: _emberData.default.attr('string'),
    doneDate: Ember.computed.or('customer', 'detected', 'actual'),
    contracted: _emberData.default.attr('string'),
    verbalization: _emberData.default.attr('string'),
    vessel: _emberData.default.attr(),
    prediction: _emberData.default.belongsTo('shipment/prediction'),
    type: Ember.computed('key', function () {
      return TYPE_VERBALIZATION[this.key.slice(0, 2)];
    }),
    isOutbound: Ember.computed('key', function () {
      return OUTBOUND_MILESTONE_KEYS.includes(this.key.slice(0, 3));
    }),
    plannedInitial: Ember.computed('{isOutbound,location.inDatePlannedInitial,location.outDatePlannedInitial}', function () {
      if (this.get('prediction.milestone.isOutbound')) {
        return this.get('prediction.milestone.location.outDatePlannedInitial');
      }

      return this.get('prediction.milestone.location.inDatePlannedInitial');
    }),
    plannedLast: Ember.computed('{isOutbound,location.inDatePlanned,location.outDatePlanned}', function () {
      if (this.get('prediction.milestone.isOutbound')) {
        return this.get('prediction.milestone.location.outDatePlanned');
      }

      return this.get('prediction.milestone.location.inDatePlanned');
    })
  });

  _exports.default = _default;
});