define("oi/helpers/oi-modulo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.oiModulo = oiModulo;
  _exports.default = void 0;

  function oiModulo(params
  /* , hash */
  ) {
    var number = params[0];
    var m = params[1];
    return number % m === 0;
  }

  var _default = Ember.Helper.helper(oiModulo);

  _exports.default = _default;
});