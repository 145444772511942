define("oi/helpers/even-or-odd", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.evenOrOdd = evenOrOdd;
  _exports.default = void 0;

  function evenOrOdd(params
  /* , hash */
  ) {
    var i = params[0];
    return i % 2 === 0 ? 'even' : 'odd';
  }

  var _default = Ember.Helper.helper(evenOrOdd);

  _exports.default = _default;
});