define("oi/helpers/format-reltime", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.verbalizeReltime = verbalizeReltime;
  _exports.default = void 0;

  /** verbalize time difference relative to now (most commonly, #days relative to today) */
  function verbalizeReltime(num) {
    var unit = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : undefined;
    var unitPlural = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : unit && "".concat(unit, "s");
    if (num <= -1.5) return "".concat(-Math.floor(num), " ").concat(unitPlural, " ago");
    if (num <= -0.5) return unit === 'day' ? 'yesterday' : "1 ".concat(unit, " ago");
    if (num <= 0.5) return unit === 'day' ? 'today' : "\xB10 ".concat(unitPlural);
    if (num < 1.5) return unit === 'day' ? 'tomorrow' : "in 1 ".concat(unit);
    return "in ".concat(Math.round(num), " ").concat(unitPlural);
  }

  var _default = Ember.Helper.helper(function (_ref, _ref2) {
    var _ref3 = (0, _slicedToArray2.default)(_ref, 3),
        num = _ref3[0],
        unitPos = _ref3[1],
        unitPluralPos = _ref3[2];

    var unit = _ref2.unit,
        unitPlural = _ref2.unitPlural;
    return verbalizeReltime(num, unit || unitPos, unitPlural || unitPluralPos);
  });

  _exports.default = _default;
});