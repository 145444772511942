define("oi/models/shipment", ["exports", "ember-data", "oi/helpers/days-diff", "oi/helpers/format-reldiff"], function (_exports, _emberData, _daysDiff, _formatReldiff) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.toDate = toDate;
  _exports.default = void 0;

  function toDate(dateStr) {
    // IE11-compatible wrapper around `new Date(..)`, as IE11 only accepts
    // timezone as [+-]dd:dd (with colon), not two or four consecutive digits
    // as returned by the API, considers date "invalid" otherwise
    return new Date(dateStr.replace(/([+-]\d{2})(\d{2})$/g, '$1:$2'));
  } // not the best place to put it, but hopefully not for the ages anyway


  function sameLoc(loc1, loc2) {
    return loc1 && loc2 && Ember.get(loc1, 'longitude') === Ember.get(loc2, 'longitude') && Ember.get(loc1, 'latitude') === Ember.get(loc2, 'latitude');
  }

  function makeMarker(loc, abbr, title) {
    var trackIndex = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : undefined;

    var _Ember$getProperties = Ember.getProperties(loc, 'latitude', 'longitude', 'name'),
        latitude = _Ember$getProperties.latitude,
        longitude = _Ember$getProperties.longitude,
        name = _Ember$getProperties.name;

    if (latitude && longitude) {
      return {
        abbr: abbr,
        trackIndex: trackIndex,
        coordinates: [latitude, longitude],
        latitude: latitude,
        longitude: longitude,
        title: "".concat(title, ": ").concat(name || ''),
        iconUrl: "/images/icon_".concat(abbr, "_loc.png"),
        iconRetinaUrl: "/images/icon_".concat(abbr, "_loc@2x.png"),
        iconSize: [30, 45]
      };
    }

    return undefined;
  }

  var PRE_POL_LOCS = ['empty_pickup', 'origin'];

  var _default = _emberData.default.Model.extend({
    user: Ember.inject.service(),
    locations: _emberData.default.hasMany('shipment/location'),
    locationsReversed: Ember.computed('locations.[]', function () {
      return this.locations.toArray().reverse();
    }),
    locationsDict: Ember.computed('locations.[]', function () {
      var locationsDict = {};
      this.locations.forEach(function (loc) {
        locationsDict[loc.get('key')] = loc;
      });
      return locationsDict;
    }),
    events: _emberData.default.hasMany('shipment/event', {
      async: true
    }),
    predictions: _emberData.default.hasMany('shipment/prediction', {
      async: true
    }),
    carrier: _emberData.default.belongsTo('carrier', {
      inverse: null
    }),
    carrier_name: Ember.computed.alias('carrier.short_name'),
    current_vessel: _emberData.default.attr(),
    current_vessel_position: _emberData.default.attr(),
    current_vessel_nextport: _emberData.default.attr(),
    current_service: Ember.computed('locations.@each.{isStarted,isDone}', // The back-end originally contained logic to extract that. However, since the
    // front end now receives all vessels & services anyway, it may as well
    // determine itself which one is the current one. This would also work for
    // current_vessel, but _position and _nextport are not usually included for
    // all other vessels, so replacing current_vessel alone with a computed property
    // saves no significant amount of back-end work.
    function () {
      var lastDoneLoc = null;
      var firstNotDoneLoc = this.locations.find(function (location) {
        if (location.isDone) {
          lastDoneLoc = location;
        }

        return !location.isDone;
      });

      if (!firstNotDoneLoc || firstNotDoneLoc.isStarted) {
        // shipment is completed or currently in port
        // CHECK: return a service also for "loaded but not yet departed"
        // and "arrived but not yet discharged" shipments?!
        return null;
      }

      return lastDoneLoc && lastDoneLoc.vessel && lastDoneLoc.vessel.service;
    }),
    status_verbose: _emberData.default.attr('string'),
    checked: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    descriptive_name: _emberData.default.attr('string'),
    container_number: _emberData.default.attr('string'),
    booking_number: _emberData.default.attr('string'),
    bl_number: _emberData.default.attr('string'),
    weight: _emberData.default.attr('number'),
    container_type_str: _emberData.default.attr('string'),
    container_type_iso: _emberData.default.attr('string'),
    status: _emberData.default.attr('number'),
    lifecycle_status: _emberData.default.attr('number'),
    id_date: _emberData.default.attr('momentdate'),
    ts_count: _emberData.default.attr('number'),
    last_carrier_update: _emberData.default.attr('momentdate'),
    last_actuals_update: _emberData.default.attr('momentdate'),
    created: _emberData.default.attr('momentdate'),
    modified: _emberData.default.attr('momentdate'),
    tags: _emberData.default.hasMany('tag', {
      async: true
    }),
    // convenience to expose something from the surrounding subscription....
    shipmentsubscription_last_carrier_update: _emberData.default.attr('momentdate'),
    shipmentsubscription_request_key: _emberData.default.attr('string'),
    shipmentsubscription_tags: _emberData.default.hasMany('tag', {
      async: true
    }),
    shipmentsubscription_id: _emberData.default.attr('number'),
    // since Ember 3 update, subscription.id
    // is undefined in shipment's afterModel while subscription is a proxy; TODO: investivate
    shipmentsubscription_status: _emberData.default.attr('number'),
    shipmentsubscription_descriptive_name: _emberData.default.attr('string'),
    shipmentsubscription_account_id: _emberData.default.attr('number'),
    subscription: _emberData.default.belongsTo('subscription'),
    readOnly: Ember.computed.alias('subscription.readOnly'),
    isSharedView: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    pasttrack: _emberData.default.attr(),
    // only expected in sharedView, otherwise looked up separately
    chineseMap: _emberData.default.attr('boolean'),
    // only expected in sharedView, otherwise extracted from master account feature
    customization: _emberData.default.attr(),
    // only expected in sharedView, otherwise looked up separately
    carrier_release: _emberData.default.attr(),
    // date and state (true/false) or null
    customs_release: _emberData.default.attr(),
    availability: _emberData.default.attr(),
    // date and/or loc, or null
    tasks_count: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    pod_timeofarrival_warning: _emberData.default.belongsTo('shipment/taw', {
      inverse: 'shipment',
      async: false
    }),
    // // fake properties looked up independently and inserted then
    // operatorInfo: DS.belongsTo('operator'),
    // boxTechInfo: DS.belongsTo('boxtech-info'),
    behindSchedule: Ember.computed('status', function () {
      if (this.status >= 17
      /* waiting for discharge at POD */
      ) {
          return null;
        }

      var podLoc = this.get('locationsDict.pod');

      if (!podLoc || podLoc.get('isDone')) {
        return null;
      }

      var date = podLoc.get('inDatePlanned') || podLoc.get('outDatePlanned') || this.get('locationsDict.dlv.outDatePlanned');

      if (!date) {
        return null;
      } // returns negative numbers for still-ahead-of-schedule


      return Math.round((new Date() - toDate(date)) / 86400000);
    }),
    etaDlvAlert: Ember.computed('status', function () {
      // CHECK: to location or milestone model?!
      var dlvMilestone = this.get('locationsDict.dlv.milestones.firstObject');
      var plannedInitial = dlvMilestone.planned_initial,
          plannedLast = dlvMilestone.planned_last,
          contracted = dlvMilestone.contracted; // TODO: implementation reproduces previous behaviour, which does not
      // consider actual dates, which seems quite bad

      var lastContrDiff = (0, _daysDiff.daysDiffRounded)(plannedLast, contracted);

      if (lastContrDiff) {
        return "ETA@DLV ".concat((0, _formatReldiff.verbalizeDiff)(lastContrDiff), " contracted ETA");
      }

      var lastInitialDiff = (0, _daysDiff.daysDiffRounded)(plannedLast, plannedInitial);

      if (lastInitialDiff) {
        return "ETA@DLV ".concat((0, _formatReldiff.verbalizeDiff)(lastInitialDiff), " initial ETA");
      }

      return '';
    }),
    alertVerboseNoETAPOD: Ember.computed('locationsDict.@each.alert', 'behindSchedule', 'predictionAlert', 'etaDlvAlert', 'holdAlert', function () {
      // TODO/CHECK: original POL alert was "currently in " only when status<=12,
      // not when status == 13 (waiting for departure from POL), although comment
      // suggested it should have (and not does -- correctly?!)
      var locationAlerts = this.locations.map(function (loc) {
        var key = loc.key,
            isDone = loc.isDone,
            alert = loc.alert; // TODO/CHECK: wouldn't it be nice to show latest "was...in TS" alert?

        if (key.startsWith('tsp') && isDone) {
          return null;
        } // empty_{pickup|return} should always have null alerts since they only have 1 milestone


        return alert;
      }).filter(function (x) {
        return x;
      });
      var behindSchedule = this.behindSchedule,
          etaDlvAlert = this.etaDlvAlert,
          holdAlert = this.holdAlert;

      if (etaDlvAlert) {
        locationAlerts.push(etaDlvAlert);
      }

      if (behindSchedule >= 3) {
        locationAlerts.push("behind schedule for ".concat(behindSchedule, " days"));
      }

      if (holdAlert) {
        locationAlerts.push(holdAlert);
      }

      return locationAlerts;
    }),
    predictionAlert: Ember.computed('pod_timeofarrival_warning.prediction', function () {
      var podPrediction = this.get('pod_timeofarrival_warning.prediction');

      if (!podPrediction || podPrediction.get('severity') < 2) {
        return null;
      }

      var diff = podPrediction.get('daysDiffRounded');

      if (!diff) {
        return podPrediction.get('method') === 2 ? 'delay at TSP -- rollover predicted' : 'deviation from carrier plan predicted';
      }

      return "".concat(podPrediction.get('daysDiffWorded'), " predicted");
    }),
    holdAlert: Ember.computed('customs_release', 'carrier_release', function () {
      var customsHold = this.get('customs_release.state') === false;
      var carrierHold = this.get('carrier_release.state') === false;

      if (customsHold || carrierHold) {
        return "currently held by ".concat(!customsHold ? 'carrier' : "".concat(carrierHold ? 'carrier and ' : '', "customs"));
      }

      return null;
    }),
    transshipmentLocations: Ember.computed('locations.[]', function () {
      return this.locations.filter(function (loc) {
        return loc.get('key').startsWith('tsp');
      });
    }),

    /* undefined before empty_pickup milestone, empty_return_loc if finished
     * (unless carrier only gives DLV and we mark the container completed then) */
    lastOrCurrentLocation: Ember.computed('locationsReversed.[]', function () {
      return this.locationsReversed.findBy('isStarted');
    }),

    /** 1-based; result 0: POL not left yet, Infinity: POD reached; next leg iff currently in port */
    currentOrNextLeg: Ember.computed('lastOrCurrentLocation', function () {
      var lastLocLeg = this.get('lastOrCurrentLocation.starts_leg');

      if (lastLocLeg) {
        return lastLocLeg;
      }

      return PRE_POL_LOCS.includes(this.get('lastOrCurrentLocation.key')) ? 0 : Infinity;
    }),
    // currentTransshipmentPort: computed('lastOrCurrentLocation', function () {
    //   const loc = this.get('lastOrCurrentLocation');
    //   return loc.get('key').startsWith('tsp') ? loc : null;
    // }), // unused, for use in case we want to display only 1 ts pill in oi-travel-progress
    // ---------------------------------------------------------------------------
    markers: Ember.computed('locationsDict', 'current_vessel_position', function () {
      var _this = this;

      var markersArray = [];
      var tsCount = this.ts_count;
      var _this$locationsDict = this.locationsDict,
          origin = _this$locationsDict.origin,
          pol = _this$locationsDict.pol,
          pod = _this$locationsDict.pod,
          lif = _this$locationsDict.lif,
          dlv = _this$locationsDict.dlv;
      /*  ORI|POL|ORIPOL  */

      if (sameLoc(origin, pol)) {
        markersArray.push(makeMarker(origin, 'oripol', 'Origin/Port of loading', 0));
      } else {
        markersArray.push(makeMarker(origin, 'origin', 'Origin'));
        markersArray.push(makeMarker(pol, 'pol', 'Port of loading', 0));
      }

      [1, 2, 3, 4].forEach(function (tsIndex) {
        if (tsCount >= tsIndex) {
          var tspI = "tsp".concat(tsIndex);
          markersArray.push(makeMarker(_this.get("locationsDict.".concat(tspI)), tspI, "Transshipment port ".concat(tsIndex), tsIndex));
        }
      });
      /*  POD|DLV|PODDLV  */

      if (sameLoc(pod, dlv)) {
        markersArray.push(makeMarker(pod, 'poddlv', 'Port of discharge/Delivery', tsCount + 1));
      } else {
        markersArray.push(makeMarker(pod, 'pod', 'Port of discharge', tsCount + 1));

        if (lif && !sameLoc(pod, lif) && !sameLoc(dlv, lif)) {
          markersArray.push(makeMarker(lif, 'lif', 'Last inland facility'));
        }

        markersArray.push(makeMarker(dlv, 'dlv', 'Delivery'));
      }

      return markersArray.filter(function (x) {
        return x !== undefined;
      });
    }),
    incidents: Ember.computed('incident', 'shipment.milestones', function () {
      var _this2 = this;

      return this.store.peekAll('incident').filter(function (incident) {
        return _this2.incidentIsMatching(incident);
      });
    }),
    incidentIsMatching: function incidentIsMatching(incident) {
      if (incident.carriers.length > 0) {
        if (!incident.carriers.mapBy('id').includes(this.carrier.get('id'))) {
          return false;
        }
      }

      var futureLocations = this.locations.filter(function (loc) {
        return !loc.isDone;
      });

      if (incident.ports.length > 0) {
        if (futureLocations.find(function (item) {
          return incident.ports.includes(item.locode);
        }) === undefined) {
          return false;
        }
      }

      if (incident.vessels.length > 0) {
        if (this.current_vessel && incident.vessels.includes(this.current_vessel.id)) {
          return true;
        }

        if (futureLocations.find(function (item) {
          return item.vessel && incident.vessels.includes(item.vessel.id);
        }) === undefined) {
          return false;
        }
      }

      return true;
    },
    isArrived: Ember.computed('status', function () {
      return this.get('status') >= 17;
    })
  });

  _exports.default = _default;
});