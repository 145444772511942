define("oi/adapters/exceptionmanagement/template", ["exports", "oi/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    applicationService: Ember.inject.service(),
    buildURL: function buildURL() {
      return "".concat(this.applicationService.apiBase, "/exception-management/templates/");
    }
  });

  _exports.default = _default;
});