define("oi/components/exceptions-chart", ["exports", "ember-highcharts/components/high-charts"], function (_exports, _highCharts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _highCharts.default.extend({
    /**
     * Workaround as described in here:
     * https://www.npmjs.com/package/ember-highcharts/v/0.7.0
     */
    contentDidChange: Ember.observer('chartOptions', function () {
      // eslint-disable-line
      var chartData = this.get('chartOptions');
      var chart = this.get('chart');

      if (chart && chartData && chartData.title && chartData.title.text) {
        chart.setTitle({
          text: chartData.title.text
        });
      }
    })
  });

  _exports.default = _default;
});