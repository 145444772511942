define("oi/routes/login", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "ember-simple-auth/mixins/unauthenticated-route-mixin", "fetch"], function (_exports, _slicedToArray2, _unauthenticatedRouteMixin, _fetch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
    routeIfAlreadyAuthenticated: 'index',
    session: Ember.inject.service(),
    queryParams: {
      exchange_token: {
        refreshModel: true
      }
    },
    titleToken: function titleToken() {
      return 'Login';
    },
    model: function model(params) {
      if (params.exchange_token && !this.session.isAuthenticated) {
        this.controllerFor('login').send('authenticate', {
          access_token: params.exchange_token // eslint-disable-line camelcase

        });
        this.transitionTo('index');
      }
    },
    setupController: function setupController(controller) {
      var url = 'https://hs6sh7rskdsn.statuspage.io/api/v2/summary.json';
      var incidentsUrl = 'https://hs6sh7rskdsn.statuspage.io/api/v2/incidents.json';
      Promise.all([(0, _fetch.default)(url).then(function (response) {
        return response.json();
      }), (0, _fetch.default)(incidentsUrl).then(function (response) {
        return response.json();
      })]).then(function (_ref) {
        var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
            componentSummary = _ref2[0],
            incidentSummary = _ref2[1];

        return controller.setProperties({
          componentSummary: componentSummary,
          incidentSummary: incidentSummary
        });
      });
    },
    actions: {
      willTransition: function willTransition() {
        // reset a possible error message after failed login
        this.controller.set('errorMessage', '');
      }
    }
  });

  _exports.default = _default;
});