define("oi/components/task-detail-page", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    task: undefined,
    assignee: Ember.computed('task.assigned_to', function () {
      var task = this.get('task');
      return {
        id: task.assignee,
        name: task.assigned_to
      };
    }),
    actions: {
      patch: function patch(field, value) {
        var task = this.get('task');

        if (task) {
          task.set(field, value);

          if (!task.isNew) {
            task.save();
          }
        }
      },
      save: function save() {
        var task = this.get('task');

        if (task) {
          task.save();
        }
      },
      searchAssignee: function searchAssignee(term) {
        return this.get('task').store.query('assignee', {
          search: term
        });
      },
      setAssignee: function setAssignee(assignee) {
        var a = {
          id: null,
          name: 'Unassigned'
        };

        if (assignee) {
          a.id = assignee.id;
          a.name = assignee.name;
        }

        var task = this.get('task');
        task.set('assignee', a.id);

        if (!task.isNew) {
          task.save();
        } else {
          task.set('assigned_to', a.name);
        }
      }
    }
  });

  _exports.default = _default;
});