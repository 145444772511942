define("oi/helpers/format-location", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.locationToString = locationToString;
  _exports.default = void 0;

  /**
   * location object to display string starting with name, including locode or country if known
   *
   * @param  {Object} loc location incl name & possibly locode & country
   * @returns {String}    "Rotterdam (NLRTM)" equivalent, '--' for empty input
   */
  function locationToString(loc) {
    if (!loc) {
      return '--';
    }

    var _Ember$getProperties = Ember.getProperties(loc, 'name', 'locode', 'county'),
        name = _Ember$getProperties.name,
        locode = _Ember$getProperties.locode,
        country = _Ember$getProperties.country;

    if (locode || country) {
      return "".concat(name, " (").concat(locode || country, ")");
    }

    return name;
  }

  var _default = Ember.Helper.helper(function (_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 1),
        loc = _ref2[0];

    return locationToString(loc);
  });

  _exports.default = _default;
});