define("oi/adapters/shipment/event", ["exports", "oi/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    applicationService: Ember.inject.service(),
    urlForQuery: function urlForQuery(query, modelName) {
      (false && !(modelName === 'shipment/event') && Ember.assert('events adapter must be used only for event model', modelName === 'shipment/event'));
      return "".concat(this.applicationService.apiBase, "/shipments/").concat(query.shipmentId, "/events");
    }
  });

  _exports.default = _default;
});