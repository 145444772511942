define("oi/helpers/oi-nu-account", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.oiNuAccount = oiNuAccount;
  _exports.default = void 0;

  function oiNuAccount(params
  /* , hash */
  ) {
    if (params.length !== 2) {
      return 'Missing args (1)!';
    }

    var givenId = params[0];
    var identity = params[1];

    if (identity.accounts === null || typeof identity.accounts === 'undefined') {
      return '--';
    }

    for (var i = 0, len = identity.accounts.length; i < len; i += 1) {
      if (identity.accounts[i].sub_accounts !== null && typeof identity.accounts[i].sub_accounts !== 'undefined') {
        //
        // THIS IS MASTER!
        //
        for (var j = 0, len2 = identity.accounts[i].sub_accounts.length; j < len2; j += 1) {
          if (identity.accounts[i].sub_accounts[j].id === givenId) {
            return identity.accounts[i].sub_accounts[j].title;
          }
        }
      } else //
        // THIS IS SLAVE!
        //
        if (identity.accounts[i].id === givenId) {
          return identity.accounts[i].title;
        }
    }

    return '--';
  }

  var _default = Ember.Helper.helper(oiNuAccount);

  _exports.default = _default;
});