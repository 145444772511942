define("oi/models/task", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var TASK_STATUS = {
    O: 'Open',
    C: 'Closed'
  };
  var TASK_TYPE_ICONS = {
    A: 'fa fa-bell-o',
    E: 'fa fa-exclamation-triangle',
    N: 'fa fa-info-circle',
    I: 'fa fa-exclamation-triangle',
    T: 'fa fa-tasks'
  };
  var TASK_TYPES = {
    A: 'Alert',
    E: 'Exception',
    N: 'Notification',
    I: 'Incident',
    T: 'Task'
  };
  var TASK_COLORS = {
    I: 'bg-success',
    W: 'bg-warning',
    E: 'bg-danger'
  };
  var TASK_APPEARENCE_TYPES = {
    I: 'Information',
    W: 'Warning',
    E: 'Critical'
  };

  var _default = Model.extend({
    title: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    type: _emberData.default.attr('string'),
    status: _emberData.default.attr('string'),
    context: _emberData.default.attr('string'),
    appearence: _emberData.default.attr('string'),
    public: _emberData.default.attr('boolean'),
    reporter: _emberData.default.attr('string', {
      defaultValue: 'unknown'
    }),
    assignee: _emberData.default.attr(),
    assigned_to: _emberData.default.attr('string'),
    created_at: _emberData.default.attr('momentdate'),
    icons: TASK_TYPE_ICONS,
    colors: TASK_COLORS,
    statusTexts: TASK_STATUS,
    typeTexts: TASK_TYPES,
    appearenceTexts: TASK_APPEARENCE_TYPES,
    comments: _emberData.default.hasMany('comment', {
      async: true
    }),
    todos: _emberData.default.hasMany('todo', {
      async: true
    }),
    relations: _emberData.default.hasMany('link', {
      async: true
    }),
    assignees: _emberData.default.hasMany('assignee', {
      async: true
    }),
    histories: _emberData.default.hasMany('history', {
      async: true
    })
  });

  _exports.default = _default;
});