define("oi/constants/system", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.IS_INTERNET_EXPLORER = _exports.ua = void 0;
  var ua = window.navigator.userAgent;
  _exports.ua = ua;
  var IS_INTERNET_EXPLORER = ua.indexOf('MSIE ') > -1 || ua.indexOf('Trident/') > -1;
  _exports.IS_INTERNET_EXPLORER = IS_INTERNET_EXPLORER;
});