define("oi/routes/sharedlinks", ["exports", "@babel/runtime/helpers/esm/objectWithoutProperties"], function (_exports, _objectWithoutProperties2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    titleToken: function titleToken() {
      return 'Shared Containers';
    },
    model: function model(params) {
      var includeExpired = params.includeExpired,
          queryParams = (0, _objectWithoutProperties2.default)(params, ["includeExpired"]);

      if (!includeExpired) {
        queryParams.is_expired = false;
      }

      queryParams.page_size = queryParams.page_size || 50; // default sorting by desc

      var addSortCreated = Object.keys(queryParams).every(function (p) {
        return !p.startsWith('sort_') || queryParams[p] === undefined;
      });

      if (addSortCreated) {
        queryParams.sort_created = 'desc';
      }

      return Ember.RSVP.hash({
        sharedlink: this.store.query('sharedlink', queryParams),
        queryParams: queryParams,
        addedSortCreated: addSortCreated
      });
    },
    setupController: function setupController(controller, model) {
      controller.setProperties(Object.assign(model.addedSortCreated ? {
        sort_created: 'desc'
      } : {}, {
        data: model.sharedlink,
        totalPages: model.sharedlink.get('meta.totalPages'),
        count: model.sharedlink.get('meta.count'),
        page_size: model.queryParams.page_size
      }));
    },
    actions: {
      queryParamsDidChange: function queryParamsDidChange() {
        Ember.run.once(this, this.refresh);
      }
    }
  });

  _exports.default = _default;
});