define("oi/models/exceptionmanagement/template", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    created_at: _emberData.default.attr('momentdate'),
    title: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    rule: _emberData.default.attr()
  });

  _exports.default = _default;
});