define("oi/templates/fourohfour", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Uh3VQ6Zr",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"container oi-first\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"page-header\"],[8],[0,\"\\n    \"],[7,\"h1\",true],[8],[0,\"Ooops!\"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"p\",true],[10,\"class\",\"lead\"],[8],[0,\"\\n    Sorry, no \"],[1,[28,\"unless\",[[24,[\"unknownRoute\"]],\"such\"],null],false],[0,\" page exists\"],[1,[28,\"if\",[[24,[\"unknownRoute\"]],[28,\"concat\",[\"for \",[24,[\"unknownRoute\"]]],null]],null],false],[0,\".\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "oi/templates/fourohfour.hbs"
    }
  });

  _exports.default = _default;
});