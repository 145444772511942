define("oi/mixins/route-error-logger", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
  Mixin that provides a handleError function that takes an error, a transition and
  a 'subject'. Assumes 'subject' is the name of the current route and that its plural
  (subject + 's') also is.
  */
  var _default = Ember.Mixin.create({
    notify: Ember.inject.service(),
    raven: Ember.inject.service(),
    handleError: function handleError(error, transition) {
      var subject = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : this.routeName;
      var level = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 1;
      var capitalizedSubject = subject.charAt(0).toUpperCase() + subject.slice(1);

      if ((error.message || '').includes('was aborted')) {
        error.humanReadableMessage = "".concat(capitalizedSubject, " could not be loaded. Probably an internet connection problem.");
        return true;
      }

      if (transition.to.queryParams.share) {
        var messageLower = (error.message || '').toLowerCase();

        if (messageLower.includes('token revoked')) {
          error.humanReadableMessage = 'Sorry, this sharing code is no longer active.';
        } else if (messageLower.includes('token expired')) {
          error.humanReadableMessage = "Sorry, this ".concat(subject).concat(subject === 'subscription' ? "'s containers'" : '', " arrived a while ago and can no longer be viewed here.");
        } else if (messageLower.includes('was aborted')) {
          error.humanReadableMessage = "Sorry, this ".concat(subject, " could not be loaded. There may be a problem with your internet connection.");
        } else {
          error.humanReadableMessage = "Sorry, no such ".concat(subject, " exists or you are not authorized to see it.");
        }

        return true; // in case of shared shipment/sub problem, always show error template
      }

      var id = Ember.get(transition, "routeInfos.".concat(level, ".params.").concat(subject, "_id")); // ^ CHECK: why is the routeInfos.level content not available at transition.to?!

      /* eslint-disable-next-line eqeqeq */
      // status may be string ('404')

      if (error && (error.status || ((error.errors || [])[0] || {}).status == 404)) {
        this.notify.error("".concat(capitalizedSubject, " ").concat(id, " does not exist or is not visible to you. \n      You will be redirected to the ").concat(subject, "s directory."), {
          closeAfter: 8000
        });
      } else {
        this.raven.captureMessage("".concat(capitalizedSubject, " loading problem other than 404 or no internet! ").concat(id), {
          level: 'error',
          fingerprint: ["".concat(subject, " loading problem")],
          extra: {
            error: error,
            errorString: "".concat(error),
            transitionTo: transition.to,
            transitionFrom: transition.from,
            transitionParams: Ember.get(transition, "routeInfos.".concat(level, ".params"))
          }
        });
        this.notify.error("".concat(capitalizedSubject, " ").concat(id, " could not be loaded. (Error has been logged.)"), {
          closeAfter: 6000
        });
      }

      return this.transitionTo("".concat(subject, "s"));
    }
  });

  _exports.default = _default;
});