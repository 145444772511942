define("oi/templates/components/shared-spinner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sLuuqObB",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"spinkit-spinner\",null,[[\"class\"],[\"shared-link-loading\"]]],false],[0,\"\\n\"],[7,\"h4\",true],[10,\"class\",\"shared-link-loading\"],[8],[0,\"powered by\"],[9],[0,\"\\n\"],[7,\"img\",true],[11,\"src\",[29,[[22,\"rootURL\"],\"images/logo_p44.svg\"]]],[10,\"width\",\"250px;\"],[10,\"alt\",\"spinner\"],[8],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "oi/templates/components/shared-spinner.hbs"
    }
  });

  _exports.default = _default;
});