define("oi/components/oi-multi-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    model: null,
    actions: {
      resetDelCheckboxIfContent: function resetDelCheckboxIfContent(checkboxValueKey, content) {
        if (content && this.get(checkboxValueKey)) {
          this.set(checkboxValueKey, false);
        }
      },
      deleteDescriptiveName: function deleteDescriptiveName() {
        this.set('model.descriptive_name', '');
        this.toggleProperty('model.delDesc');
      },
      deleteAllTags: function deleteAllTags() {
        this.set('model.selectedTags', []);
        this.toggleProperty('model.delTags');
      },
      deleteEtaPod: function deleteEtaPod() {
        this.set('model.contractual_eta_pod', null);
        this.toggleProperty('model.delEtaPod');
      },
      deleteEtaDlv: function deleteEtaDlv() {
        this.set('model.contractual_eta_dlv', null);
        this.toggleProperty('model.delEtaDlv');
      },
      deleteEtdPol: function deleteEtdPol() {
        this.set('model.contractual_etd_pol', null);
        this.toggleProperty('model.delEtdPol');
      }
    }
  });

  _exports.default = _default;
});