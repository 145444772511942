define("oi/routes/stats/leadtime", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    titleToken: function titleToken() {
      return 'Statistics: Lead Times';
    },
    model: function model(params) {
      this.controllerFor('application').setProperties({
        savepoldate1: params.pol_date_1,
        savepoldate0: params.pol_date_0,
        savepoddate1: params.pod_date_1,
        savepoddate0: params.pod_date_0,
        savetags: params.tags,
        savecarriers: params.carriers
      });
      return Ember.RSVP.hash({
        allTags: this.store.findAll('tag', {
          backgroundReload: true
        }),
        allCarriers: this.store.findAll('carrier', {
          backgroundReload: true
        }),
        leadtimes: this.store.query('leadtime', params),
        tags: params.tags
      });
    },
    setupController: function setupController(controller, model) {
      controller.setProperties(model);
    },
    actions: {
      error: function error()
      /* error, transition */
      {
        return true;
      },
      queryParamsDidChange: function queryParamsDidChange() {
        Ember.run.once(this, this.refresh);
      }
    }
  });

  _exports.default = _default;
});