define("oi/mixins/pagination-aware-serializer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    extractMeta: function extractMeta(store, modelClass, payload) {
      var meta = this._super(store, modelClass, payload);

      if (!meta) {
        return meta; // or return { totalPages: 1}? So far, consumers tolerate absence of this property...
      }

      if (meta.next) {
        var pageSize = payload[modelClass.modelName].length;
        meta.totalPages = Math.ceil(meta.count / pageSize);
      } else {
        // else current page is not a full page and #items-on-this-page < pageSize
        meta.totalPages = (meta.previous || 0) + 1;
      }

      return meta;
    }
  });

  _exports.default = _default;
});