define("oi/components/tasks/todos", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    task: undefined,
    todo: undefined,
    todoToAdd: undefined,
    completed: Ember.computed('task.todos.@each.done', function () {
      var task = this.get('task');
      var completedTasks = task.todos.reduce(function (n, x) {
        return n + (x.done === true);
      }, 0);
      var taskCount = task.todos.length;
      var percentage = 100 * completedTasks / taskCount;
      return Ember.String.htmlSafe("width: ".concat(percentage, "%"));
    }),
    actions: {
      toggleDone: function toggleDone(todo, event) {
        if (todo) {
          todo.set('done', event.target.checked);

          if (!this.get('task').isNew) {
            todo.save();
          }
        }
      },
      patch: function patch(todo, field, value) {
        if (todo) {
          todo.set(field, value);

          if (!this.get('task').isNew) {
            todo.save();
          }
        }
      },
      delete: function _delete(todo) {
        if (todo) {
          todo.destroyRecord();
        }
      },
      add: function add(todoToAdd) {
        var task = this.get('task');

        if (todoToAdd && task) {
          var todo = task.store.createRecord('todo', {
            todo: todoToAdd,
            task: task
          });

          if (!this.get('task').isNew) {
            todo.save();
          }

          this.set('todoToAdd', undefined);
        }
      }
    }
  });

  _exports.default = _default;
});