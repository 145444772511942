define("oi/components/oi-tag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var COLORS = ['darkred', 'darkgreen', 'darkblue', 'saddlebrown', 'darkmagenta', 'darkorange', '#AF3000'
  /* dark orange-red ... very brown-ish */
  , 'DarkOliveGreen', 'DarkSlateBlue', '#004898', 'darkcyan', 'darkgoldenrod'];
  var TagComponent = Ember.Component.extend({
    tagName: 'span',
    classNames: 'oi-tag-pill',
    classNameBindings: ['bright'],
    attributeBindings: 'style',
    style: Ember.computed('tag.id', 'bright', function () {
      return Ember.String.htmlSafe("background-color:".concat(this.get('tag.color') || COLORS[this.get('tag.id') % COLORS.length]));
    })
  });
  TagComponent.reopenClass({
    positionalParams: ['tag']
  });
  var _default = TagComponent;
  _exports.default = _default;
});