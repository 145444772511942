define("oi/controllers/stats/leadtime", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "jq-sparkline"], function (_exports, _slicedToArray2, _jqSparkline) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // eslint-disable-line no-unused-vars
  // ^ used by oi-box-plot, which is used only here;
  //  needs to be imported only once, creates global object/attaches itself to $
  var TRSTYLEFIXTURE = ['active', 'info', 'success', 'warning', 'danger'];
  var EXAMPLE_BOX_PLOT = {
    values: [10, 13, 13, 15, 15, 15, 17, 17, 19, 19, 21],
    mean: 16
  };

  var formatDuration = // eslint-disable-next-line eqeqeq
  function formatDuration(v) {
    return "<b>".concat(v.toFixed(0) == v ? v : v.toFixed(1), "</b> day").concat(v === 1 ? '' : 's');
  };

  var findValue = function findValue(fields, fieldName) {
    return (fields.filter(function (x) {
      return x.field === fieldName;
    })[0] || {}).value;
  };

  var SPARK_LINE_OPTIONS = {
    type: 'box',
    showOutliers: false,
    tooltipFormatter: function tooltipFormatter(_ref, _ref2, fields) {
      var values = _ref.values;
      var target = _ref2.target;
      var unique = values.filter(function (el, idx, self) {
        return self.indexOf(el) === idx;
      });
      var tooltipLines = [];

      if (unique.length < 4) {
        unique.sort();
        var uniqueCounts = {};
        unique.forEach(function (v) {
          uniqueCounts[v] = values.filter(function (el) {
            return el === v;
          }).length;
        });
        tooltipLines.push("<b>".concat(values.length, "</b> containers: ").concat(unique.map(function (v) {
          return "".concat(v, " days (").concat(uniqueCounts[v], "x)");
        }).join(', ')));
      } else {
        tooltipLines.push("<b>".concat(values.length, "</b> containers"));
        tooltipLines.push("low ".concat(formatDuration(findValue(fields, 'lw'))));
        tooltipLines.push(" q1 ".concat(formatDuration(findValue(fields, 'lq'))));
      }

      tooltipLines.push("median ".concat(formatDuration(findValue(fields, 'med'))));
      tooltipLines.push("avg ".concat(formatDuration(target || values.reduce(function (s, el) {
        return s + el;
      }, 0) / values.length)));

      if (unique.length >= 4) {
        tooltipLines.push(" q3 ".concat(formatDuration(findValue(fields, 'uq'))));
        tooltipLines.push("high ".concat(formatDuration(findValue(fields, 'rw'))));
      }

      return tooltipLines.join('<br>');
    } // tooltipFormatFieldlistKey: 'fields',

  };

  function selectionInLeadtime(selection, leadtimes) {
    var serviceInLeadtime = leadtimes.find(function (leadtimeService) {
      return leadtimeService && selection.includes("".concat(leadtimeService.name, " (").concat(leadtimeService.code, ")"));
    });
    return typeof serviceInLeadtime !== 'undefined';
  }

  var _default = Ember.Controller.extend({
    queryParams: ['tags', 'pol_date_0', 'pol_date_1', 'pod_date_0', 'pod_date_1'],
    excel: Ember.inject.service('excel'),
    init: function init() {
      this._super.apply(this, arguments); // eslint-disable-line prefer-rest-params


      this.setProperties({
        selectedCarriers: [],
        selectedPols: [],
        selectedPods: [],
        selectedServices: [],
        sparkLineOptions: SPARK_LINE_OPTIONS,
        sortProps: [],
        exampleBoxPlot: EXAMPLE_BOX_PLOT,
        boxPlotWarningThreshold: 100 // warn about long boxplot rendering time if more entries

      });
    },
    lastEntry: null,
    availablePols: Ember.computed('leadtimes.[]', function () {
      return this.leadtimes.mapBy('pol').uniq().sort();
    }),
    availablePods: Ember.computed('leadtimes.[]', function () {
      return this.leadtimes.mapBy('pod').uniq().sort();
    }),
    availableCarriers: Ember.computed('leadtimes.[]', function () {
      return this.leadtimes.mapBy('carrierName').uniq().sort();
    }),
    availableServices: Ember.computed('leadtimes.[]', function () {
      var serviceNames = [];
      this.leadtimes.mapBy('services').forEach(function (services) {
        services.forEach(function (leadtimeService) {
          if (leadtimeService != null) {
            serviceNames.push("".concat(leadtimeService.name, " (").concat(leadtimeService.code, ")"));
          }
        });
      });
      return serviceNames.uniq().sort();
    }),
    filteredEntries: Ember.computed('leadtimes.[]', 'selectedCarriers', 'selectedPols', 'selectedPods', 'selectedServices', function () {
      var _this = this;

      var result = this.get('leadtimes');
      [['selectedPols', 'pol'], ['selectedPods', 'pod'], ['selectedCarriers', 'carrierName'], ['selectedServices', 'services']].forEach(function (_ref3) {
        var _ref4 = (0, _slicedToArray2.default)(_ref3, 2),
            selName = _ref4[0],
            propName = _ref4[1];

        var selection = _this.get(selName);

        if (selection && selection.length) {
          if (propName !== 'services') {
            result = result.filter(function (e) {
              return selection.includes(e[propName]);
            });
          } else {
            result = result.filter(function (e) {
              return selectionInLeadtime(selection, e[propName]);
            });
          }
        }
      });

      if (this.boxPlotMode && this.previousNumberOfEntries < result.length && result.length > this.boxPlotWarningThreshold) {
        // prevent browser from seemingly freezing due to the long rendering
        // times for a large number of boxplots
        // eslint-disable-next-line ember/no-side-effects
        this.set('boxPlotMode', null); // sorry about the side effects, but an additional observer would make
        // this even less readable (and race condition prone)
      } // eslint-disable-next-line ember/no-side-effects


      this.set('previousNumberOfEntries', result.length); // eslint-disable-next-line ember/no-side-effects

      this.set('page', 1);
      return result;
    }),
    filteredSortedEntries: Ember.computed.sort('filteredEntries', 'sortProps'),
    annotatedFilteredSortedEntries: Ember.computed('filteredSortedEntries', function () {
      // note to future me: there must be a better way!
      var colorIndex = -1;
      var lastPolPod;
      return this.filteredSortedEntries.map(function (entry) {
        var thisPolPod = entry.pol + entry.pod;

        if (lastPolPod !== thisPolPod) {
          colorIndex = (colorIndex + 1) % 2;
          lastPolPod = thisPolPod;
        }
        /* eslint-disable-next-line ember/no-side-effects */
        // entry is POJO initially, ember object later


        Ember.set(entry, 'oi-colorIdx', TRSTYLEFIXTURE[colorIndex]);
        return entry;
      });
    }),
    pageSize: 200,
    page: 1,
    itemCount: Ember.computed('annotatedFilteredSortedEntries', function () {
      var entries = this.get('annotatedFilteredSortedEntries');

      if (entries) {
        return entries.length;
      }

      return 0;
    }),
    totalPages: Ember.computed('annotatedFilteredSortedEntries', 'pageSize', function () {
      var entries = this.get('annotatedFilteredSortedEntries');
      var pageSize = this.get('pageSize');

      if (entries) {
        return Math.ceil(entries.length / pageSize);
      }

      return 1;
    }),
    paginatedEntries: Ember.computed('annotatedFilteredSortedEntries', 'page', function () {
      var result = this.get('annotatedFilteredSortedEntries');
      var page = this.get('page') - 1;
      var pageSize = this.get('pageSize');
      var start = page * pageSize;
      var calculatedEnd = page * pageSize + pageSize;
      var end = calculatedEnd > result.length ? result.length : calculatedEnd;
      return result.slice(start, end);
    }),
    boxInfo: Ember.computed('filteredEntries.[]', function () {
      // ^ should actually depend on 'filteredEntries.@each.leadAllPorts',
      // but that should not change on a per-entry basis
      var entries = this.filteredEntries;
      var numShipVessel = entries.mapBy('lengthVessel').reduce(function (s, el) {
        return s + el;
      }, 0);
      var numShipPorts = entries.mapBy('lengthPorts').reduce(function (s, el) {
        return s + el;
      }, 0);
      var sum = numShipVessel + numShipPorts;
      var containerNumber = numShipPorts === numShipVessel ? numShipPorts : "\u2248".concat(sum < 10 ? (sum / 2).toFixed(0) : "".concat((sum / 20).toFixed(0), "0"));
      return {
        routes: {
          number: entries.length,
          text: [entries.length === 1 ? 'routing' : 'routings']
        },
        shipments: {
          number: containerNumber,
          numberTooltip: numShipPorts === numShipVessel ? null : "".concat(numShipVessel, " port to port, ").concat(numShipPorts, " gate-in/gate-out durations on record"),
          text: [[1, '≈1'].includes(containerNumber) ? 'container' : 'containers']
        }
      };
    }),
    boxPlotMinMax: Ember.computed('filteredEntries.[]', function () {
      var vesselMin = Infinity;
      var vesselMax = -Infinity;
      var portsMin = Infinity;
      var portsMax = -Infinity;
      this.filteredEntries.forEach(function (e) {
        if (e.lengthVessel > 1) {
          e.leadAllVessel.forEach(function (v) {
            if (v < vesselMin) {
              vesselMin = v;
            }

            if (v > vesselMax) {
              vesselMax = v;
            }
          });
        }

        if (e.lengthPorts > 1) {
          e.leadAllPorts.forEach(function (v) {
            if (v < portsMin) {
              portsMin = v;
            }

            if (v > portsMax) {
              portsMax = v;
            }
          });
        }
      });
      return {
        vessel: {
          min: vesselMin,
          max: vesselMax
        },
        ports: {
          min: portsMin,
          max: portsMax
        },
        anyAtAll: vesselMin > -Infinity || portsMin > -Infinity // avoid Number.isFinite: ...>-Inifity is number-as-string compatible

      };
    }),
    boxPlotMode: null,
    boxPlotModeButtons: Ember.computed('boxPlotMinMax', function () {
      var noneAvailable = !this.get('boxPlotMinMax.anyAtAll');
      return [{
        label: 'none',
        value: null
      }, {
        label: 'individual scale',
        value: 'absolute',
        disabled: noneAvailable,
        hintClass: 'hint--bottom-left',
        'aria-label': "Boxplots indicate the values' minimum, maximum and most common" + ' range. Boxplots in neighboring rows may not be to the same scale.'
      }, {
        label: 'common scale',
        value: 'relative',
        disabled: noneAvailable,
        hintClass: 'hint--bottom-left',
        'aria-label': "Boxplots indicate the values' range relative" + ' to all values in the respective column.'
      }];
    }),
    user: Ember.inject.service(),
    actions: {
      exportXls: function exportXls() {
        var marshalledData = this.filteredSortedEntries.map(function (entry) {
          return [entry.pol, entry.pod, entry.carrierName, entry.servicesAndLegs.map(function (legInfo, index) {
            // eslint-disable-next-line no-nested-ternary
            var serviceDescription = legInfo.service ? " (via ".concat(legInfo.service.code, "/").concat(legInfo.service.name, ")") : index < entry.servicesAndLegs.length - 1 ? ' -' : '';
            return "".concat(legInfo.startPort, "-").concat(legInfo.endPort).concat(serviceDescription);
          }).join(' '), entry.leadAvgVessel, entry.lengthVessel, entry.leadAvgPorts, entry.lengthPorts];
        });
        marshalledData.unshift(['POL', 'POD', 'Carrier', 'Legs and Services', 'Port-to-port, Ø', 'containers considered', 'Gate-in/Gate-out, Ø', 'containers considered']);
        var filtered = this.tags || this.pol_date_0 || this.pol_date_1 || this.get('filteredEntries.length') !== this.get('leadtimes.length');
        var accountNames = this.user.currentAccounts.map(function (acc) {
          return acc.title;
        });
        var accountName = accountNames.length > 1 ? accountNames.join('_') : accountNames[0];
        var fileName = "OI_CTT_Leadtimes.".concat(accountName, "-").concat(filtered ? 'filtered' : 'all', ".xlsx");
        this.excel.export(marshalledData, {
          sheetName: 'sheet1',
          fileName: fileName
        });
      },
      toggleSortAttr: function toggleSortAttr(attrToToggle) {
        var sorting = (this.sortProps || []).map(function (attrAndOrder) {
          return attrAndOrder.split(':');
        });
        var currentOrder = (sorting.find(function (_ref5) {
          var _ref6 = (0, _slicedToArray2.default)(_ref5, 1),
              attr = _ref6[0];

          return attr === attrToToggle;
        }) || [])[1];
        var newOrder = currentOrder !== 'asc' ? 'asc' : 'desc';
        var newSorting = [[attrToToggle, newOrder]].concat(sorting.filter(function (_ref7) {
          var _ref8 = (0, _slicedToArray2.default)(_ref7, 1),
              attr = _ref8[0];

          return attr !== attrToToggle;
        }));
        this.set('sortProps', newSorting.map(function (attrAndOrder) {
          return attrAndOrder.join(':');
        }));
      },
      clearFilters: function clearFilters() {
        this.set('page', 1);
        this.set('pol_date_0', undefined);
        this.set('pol_date_1', undefined);
        this.set('selectedTags', []);
      }
    }
  });

  _exports.default = _default;
});