define("oi/components/shipment/container-details-box", ["exports", "oi/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var BOX_TECH_DISPLAY = [{
    key: 'manufacturer',
    displayName: 'manufacturer'
  }, {
    key: 'manufacture_date',
    displayName: 'manufacture date'
  }, {
    key: 'manufacture_number',
    displayName: 'manufacture number'
  }, {
    key: 'max_payload_kg',
    displayName: 'max. payload [kg]'
  }, {
    key: 'tare_kg',
    displayName: 'tare [kg]'
  }, // {key: 'max_gross_mass_kg', displayName: 'max. gross mass [kg]' },
  // {key: 'stacking_kg', displayName: 'stacking [kg]' },
  {
    key: 'group_st',
    displayName: 'type code'
  }, {
    key: 'detail_st',
    displayName: 'detailed type'
  }];
  var BoxComponent = Ember.Component.extend({
    classNames: ['panel', 'panel-default'],
    applicationService: Ember.inject.service(),
    store: Ember.inject.service(),
    notify: Ember.inject.service(),
    user: Ember.inject.service(),
    session: Ember.inject.service(),
    boxTechPropertiesDisplayNames: BOX_TECH_DISPLAY,
    selectedTags: Ember.computed('shipment.tags', function () {
      if (this.shipment.tags.length === 0) return [];
      return this.shipment.tags.slice();
    }),
    tagsWithoutActs: Ember.computed('allAvailableTags', function () {
      return this.get('allAvailableTags').filterBy('isAccessControl', false);
    }),
    latestNonCERMilestoneDate: Ember.computed('shipment.locationsReversed.[]', function () {
      var lastDoneLoc = this.get('shipment.locationsReversed').find(function (loc) {
        return loc.get('key') !== 'empty_return' && (loc.get('outDateReal') || loc.get('inDateReal'));
      });

      if (!lastDoneLoc) {
        return null;
      }

      var date = lastDoneLoc.get('outDateReal') || lastDoneLoc.get('inDateReal');
      return new Date(date.substring(0, date.length - 5)); //  time is now localized to user'
      // browser; however, only the date (year month day) matters for use case
    }),
    cerMilestone: Ember.computed.alias('shipment.locationsDict.empty_return.milestones.firstObject'),
    actions: {
      // TODO: unify POST/PATCH logic & error handling!
      setTags: function setTags() {
        var _this = this;

        _jquery.default.ajax({
          url: "".concat(this.applicationService.apiBase, "/shipments/").concat(this.get('shipment.id'), "/tags/"),
          type: 'POST',
          dataType: 'json',
          contentType: 'application/json; charset=UTF-8',
          headers: this.get('user.authHeaders'),
          crossDomain: true,
          xhrFields: {
            withCredentials: _environment.default.APP.USE_AJAX_CALL_WITH_CREDENTIALS
          },
          data: JSON.stringify({
            payload: this.selectedTags.mapBy('id').map(function (id) {
              return {
                tagid: id
              };
            })
          }),
          success: function success() {
            _this.set('shipment.tags', _this.selectedTags);
          },
          error: function error(_error) {
            return (// eslint-disable-next-line no-console
              console.error(_error && _error.responseJSON && _error.responseJSON.detail || _error)
            );
          }
        });
      },
      updateCustomerCer: function updateCustomerCer(newValue) {
        var _this2 = this;

        _jquery.default.ajax({
          url: "".concat(this.applicationService.apiBase, "/shipments/").concat(this.get('shipment.id'), "/"),
          type: 'PATCH',
          headers: this.get('user.fullHeaders'),
          crossDomain: true,
          xhrFields: {
            withCredentials: _environment.default.APP.USE_AJAX_CALL_WITH_CREDENTIALS
          },
          data: {
            empty_return_customer: newValue
          },
          success: function success() {
            return _this2.cerMilestone.notifyPropertyChange();
          },
          error: function error(_error2) {
            return (// eslint-disable-next-line no-console
              console.error(_error2 && _error2.responseJSON && _error2.responseJSON.detail || _error2)
            );
          }
        });
      },
      // updates the description
      update: function update() {
        var _this3 = this;

        var shipment = this.shipment;
        return shipment.save().catch(function () {
          var error = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
          var firstError = (error.errors || [])[0];
          var message = error.message === 'The adapter operation was aborted' ? // error.message is usually quite ember-specific and not end-user-suitable
          'Could not reach server.' : firstError && firstError.detail;

          _this3.notify.error("Saving changes failed! ".concat(message || ''), {
            closeAfter: 6000
          });

          shipment.rollbackAttributes();

          _this3.raven.captureMessage('Error saving shipment update.', {
            extra: error
          });
        });
      }
    }
  });
  BoxComponent.reopenClass({
    positionalParams: ['shipment']
  });
  var _default = BoxComponent;
  _exports.default = _default;
});