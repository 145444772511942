define("oi/controllers/shipment", ["exports", "@babel/runtime/helpers/esm/toArray", "jquery", "oi/config/environment", "oi/models/shipment"], function (_exports, _toArray2, _jquery, _environment, _shipment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* global _paq */
  function dateWithDaysTolerance(dateString, daysTol) {
    var date = new Date(dateString.split('T')[0]);
    date.setDate(date.getDate() + daysTol);
    return date.toISOString().split('T')[0];
  }

  function extractTrackFromSchedule(_ref) {
    var schedule = _ref.schedule,
        origin = _ref.origin,
        destination = _ref.destination;

    if (!schedule) {
      return [];
    }

    var track = schedule.positions;
    var oriIndex = track.findIndex(function (el) {
      return el.name === origin.name;
    });

    if (oriIndex > -1) {
      track = track.slice(oriIndex);
    } // else track probably starts with a port between POL and current position


    track = track.slice(0, track.findIndex(function (el) {
      return el.name === destination.name;
    }) + 1);
    var yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    yesterday = yesterday.toISOString(); // let lastIndexWithOnlyPastDates = 0;

    var portcalls = schedule.portcalls;
    track.forEach(function (pos
    /* , index */
    ) {
      if (!pos.name) {
        return;
      }

      var calls = portcalls.filter(function (pc) {
        return pc.name === pos.name;
      });
      pos.eta = calls.map(function (c) {
        return c.eta;
      }).filter(function (time) {
        return time > yesterday;
      });
      var etds = calls.map(function (c) {
        return c.etd;
      });
      pos.etd = etds.filter(function (time) {
        return time > yesterday;
      });
      /* ^ at least filter unreasonable ETAs/ETDs based on today. Better:
       filter ETAs/ETDs where the rough dates when the respective segment is
       supposed to be covered are known. Example: container from Asia to Panama,
       from there to the Carribean -- the vessel in the Carribean may make several
       round trips while the container is still on the way from Asia, thus the
       following is insufficient: */
      // console.log(pos.name, pos.eta, pos.etd, etds, index, lastIndexWithOnlyPastDates)
      // if (etds.length && !pos.etd.length) {
      //   lastIndexWithOnlyPastDates = index;
      // }
    }); // track = track.slice(lastIndexWithOnlyPastDates);

    return track;
  }

  var _default = Ember.Controller.extend({
    applicationService: Ember.inject.service(),
    apiHost: Ember.computed(function () {
      return this.applicationService.apiHost;
    }),
    activatedEditComponent: '',
    queryParams: ['tab', 'share', 'allEvents', 'page', 'page_size', 'type', 'status', 'appearence'],
    page: 1,
    page_size: 50,
    status: 'O',
    tab: 'status',
    // default (any other value than "map" and "details"): "status"
    currentEventMilestoneTab: 'milestones',
    viewModeEventsMilestone: 'tab',
    init: function init() {
      this._super.apply(this, arguments); // eslint-disable-line prefer-rest-params


      this.setProperties({
        condensedRawOptions: [{
          label: 'condensed',
          value: true
        }, {
          label: 'raw',
          value: false
        }],
        priorityOptions: [{
          label: 'high priority',
          value: true
        }, {
          label: 'all',
          value: false
        }]
      });
    },
    user: Ember.inject.service(),
    session: Ember.inject.service(),
    featuresAndPermissions: Ember.computed.alias('session.data.features'),
    subscription: Ember.computed.alias('shipment.subscription'),
    sortedAllAvailableTags: Ember.computed('allAvailableTags', function () {
      return this.allAvailableTags.sortBy('isAccessControl');
    }),
    highPrioEventsOnly: Ember.computed('allEvents', {
      get: function get() {
        return !this.allEvents;
      },
      set: function set(key, value) {
        var newAllEvents = value ? undefined : true; // undefined so that queryParam does not show up in url if falsy

        this.set('allEvents', newAllEvents);
        return !newAllEvents;
      }
    }),
    relDate: true,
    filteredEvents: Ember.computed('shipment.events.[]', 'allEvents', function () {
      var content = this.get('shipment.events');

      if (!content || this.allEvents) {
        return content;
      }

      return content.filter(function (item) {
        return item.get('severity');
      });
    }),
    // eslint-disable-next-line ember/no-observers
    fetchContainerTrack: Ember.observer('tab', 'shipment', function () {
      var _this = this;

      var shipment = this.shipment;

      if (this.tab !== 'map' || !shipment) {
        return; // track not needed for current tab or model not loaded yet
      }

      if (this.get('containerTrack.length') && !this.containerTrackError) {
        return; // already fetched
      }

      this.set('scheduledTracks', []);
      var isShipmentDone = shipment.shipmentsubscription_status === 2;
      var embeddedTrack = shipment.get('pasttrack'); // obsolete, could now be
      // looked up separately for shared shipments, too

      var lookups = [embeddedTrack ? {
        track: embeddedTrack
      } : this.lookupPastTrack()];

      if (!isShipmentDone && (this.get('featuresAndPermissions.predictions.feature_enabled') // ^ getter used since predictions object may be absent entirely
      || this.share // this will lead to failing API requests for shared shipments of
      // master accounts that do not have the prediction/futuretrack permission, but
      // there is no way to tell from a shared shipment object when that is the case
      )) {
        var currentLegNumber = shipment.get('currentOrNextLeg');
        var legLocs = this.get('shipment.locations').filter(function (loc) {
          return loc.starts_leg || loc.key === 'pod';
        });
        var start = legLocs[0];
        legLocs.slice(1).forEach(function (destination, index) {
          if (currentLegNumber > index + 1) {
            lookups.push({}); // empty promise so that n-th leg is
            // always in n-th position, no matter how many before are completed
          } else {
            lookups.push(_this.lookupFutureTrack(start, destination));
          }

          start = destination;
        });
        this.set('vesselNames', legLocs.slice(0, -1).mapBy('vesselName'));
      }

      Ember.RSVP.Promise.all(lookups).then(function (_ref2) {
        var _ref3 = (0, _toArray2.default)(_ref2),
            _ref3$ = _ref3[0],
            track = _ref3$.track,
            error = _ref3$.error,
            schedules = _ref3.slice(1);

        _this.setProperties({
          containerTrack: track || [],
          containerTrackError: error
        });

        _this.set('scheduledTracks', schedules.map(function (s) {
          return extractTrackFromSchedule(s);
        }));
      });
    }),
    lookupPastTrack: function lookupPastTrack() {
      var _this2 = this;

      return new Ember.RSVP.Promise(function (resolve) {
        return _jquery.default.ajax({
          url: "".concat(_this2.applicationService.apiBase, "/shipments/").concat(_this2.shipment.id, "/containertrack/"),
          type: 'GET',
          headers: _this2.get('user.fullHeaders'),
          crossDomain: true,
          xhrFields: {
            withCredentials: _environment.default.APP.USE_AJAX_CALL_WITH_CREDENTIALS
          },
          success: function success(track) {
            return resolve({
              track: track
            });
          },
          error: function error(_error) {
            return resolve({
              error: _error
            });
          } // jquery will log error...

        });
      });
    },
    lookupFutureTrack: function lookupFutureTrack(origin, destination) {
      var onError = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : function () {
        return {};
      };
      var vesselId = origin.get('vessel.id');

      if (destination.isStarted || !vesselId) {
        return {};
      } // TODO?! ^ last port call for active leg


      var trackStartDateStr = origin.outDateReal || origin.inDateReal || origin.outDatePlanned || origin.inDatePlanned;
      var trackEndDateStr = destination.inDatePlanned || destination.outDatePlanned;

      if (!trackStartDateStr || !trackEndDateStr) {
        return {};
      }

      var durationDays = ((0, _shipment.toDate)(trackEndDateStr) - (0, _shipment.toDate)(trackStartDateStr)) / 86400000;
      var toleranceDays = Math.min(4, Math.max(1, durationDays / 3.0));
      var trackStartDate = dateWithDaysTolerance(trackStartDateStr, -toleranceDays);
      var trackEndDate = dateWithDaysTolerance(trackEndDateStr, +toleranceDays);
      return _jquery.default.ajax({
        url: "".concat(this.applicationService.apiBase, "/shipments/").concat(this.shipment.id, "/futuretrack/?id=").concat(vesselId
        /* TODO? now that ctt's cwaapi shadows the request,
        the vessel ids & due dates could be looked up there ... */
        ).concat(trackStartDate ? "&start=".concat(trackStartDate) : '').concat(trackEndDate ? "&end=".concat(trackEndDate) : ''),

        /* + '&pol=' + origin + '&pod=' + destination`// TODO? adapt csw API endpoint to limit track? */
        type: 'GET',
        headers: this.get('user.fullHeaders'),
        crossDomain: true,
        xhrFields: {
          withCredentials: _environment.default.APP.USE_AJAX_CALL_WITH_CREDENTIALS
        }
      }).then(function (schedule) {
        return {
          schedule: schedule,
          origin: origin,
          destination: destination
        };
      }).catch(onError);
    },
    notify: Ember.inject.service(),
    actions: {
      switchTab: function switchTab(newTab) {
        var tabName = (newTab || '').toLowerCase();

        if (!['map', 'details'].includes(tabName)) {
          tabName = 'status';
        }

        this.set('tab', tabName);

        _paq.push(['setDocumentTitle', "".concat((document.title || '').split('|')[0], ", ").concat(newTab, " tab (").concat(this.allEvents ? 'all' : 'prio', " events, ").concat(this.relDate, " milestones)")]);

        _paq.push(['trackPageView', this.url]);
      },
      createTask: function createTask() {
        var linkedObjects = [{
          app: 'ctt',
          type: 'containershipment',
          id: this.get('shipment').id,
          label: this.get('shipment').container_number
        }];
        this.send('createNewTask', linkedObjects, null);
      }
    }
  });

  _exports.default = _default;
});