define("oi/templates/components/batcharchive-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2vIbyjGu",
    "block": "{\"symbols\":[],\"statements\":[[4,\"confirm-modal\",null,[[\"title\",\"ok\",\"context\",\"close\"],[\"Batch archiving subscriptions\",\"batchArchive\",[24,[\"model\"]],\"removeModal\"]],{\"statements\":[[0,\"  Really want to archive \"],[1,[24,[\"model\",\"length\"]],false],[0,\" subscriptions?\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "oi/templates/components/batcharchive-modal.hbs"
    }
  });

  _exports.default = _default;
});