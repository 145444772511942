define("oi/mixins/multi-filter-mixin", ["exports", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/toConsumableArray", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _defineProperty2, _toConsumableArray2, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * computedValuesEqual - return computed property that gets two objects by the
   * given keys and checks whether first object's values match second object's values
   * for all of the first object's keys (does not check possible additional keys
   * in second object, considers falsy values and absent keys equal)
   *
   * @param  {string} key1          key identifying first (dictionary) object
   * @param  {string} key2          key identifying second (dictionary) object
   * @param  {function} initHandler (init) function (or name referring to this) if obj for key1 is undefined
   * @returns {computed}            boolean property (undefined if obj for key1 is undefined)
   */
  function computedValuesEqual(key1, key2, initHandler) {
    return Ember.computed(key1, key2, function () {
      var _this = this;

      var obj1 = this.get(key1);

      if (!obj1) {
        Ember.run.once(typeof initHandler === 'string' ? function () {
          return _this[initHandler]();
        } : initHandler());
        return undefined;
      }

      var obj2 = this.get(key2) || {};
      return !Object.entries(obj1).find(function (_ref) {
        var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
            key = _ref2[0],
            value1 = _ref2[1];

        return obj2[key] !== value1 && (Ember.isPresent(obj2[key]) || Ember.isPresent(value1));
      });
    });
  }
  /**
   * Mixin for classes defining a `filterParams` property (and possibly a
   * `defaultFilterValues` property). For each key (name) in `filterParams`,
   * defines a property `new${FilterParam}` (camelized) that is observed (e.g.
   * to be bound to input fields) and provides computed properties
   * `newFiltersUnchanged` (true if all new... match their respective original) and
   * `newFiltersAreDefault` and actions `applyNewFilterValues` (set filter params to
   * new values), `resetFiltersToDefaults` and `resetNewFiltersToCurrent` (undo
   * changes to new values only).
   *
   * See also `-filter-apply-buttons.hbs` - a partial for filter buttons that use
   * the computed properties and actions according to their original intention, and
   * `-filter-expand-button.hbs` - partial that uses `filtersPresent` according to
   * its original intention (but allows some customization)
   */


  var _default = Ember.Mixin.create({
    initNewFilters: function initNewFilters() {
      var _this2 = this;

      var filterNames = this.filterParams;
      this.set('filterValues', this.getProperties(filterNames));
      var newFilterNames = this.initOrResetNewFilterValues(filterNames); // ^ may init all new filters with undefined on first run

      this.initDependentProperties(filterNames, newFilterNames);
      filterNames.forEach(function (key) {
        return _this2.addObserver(key, _this2, 'reInitAfterActualFilterChange');
      });
    },
    reInitAfterActualFilterChange: function reInitAfterActualFilterChange() {
      var _this3 = this;

      Ember.run.once(function () {
        return _this3.initOrResetNewFilterValues();
      });
    },
    initOrResetNewFilterValues: function initOrResetNewFilterValues() {
      var filterNames = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.filterParams;
      var currentParams = this.getProperties(filterNames);
      var newFilterValues = {};
      filterNames.forEach(function (key) {
        var newName = Ember.String.camelize("new_".concat(key));
        newFilterValues[newName] = currentParams[key];
      });
      this.setProperties(newFilterValues);
      return Object.keys(newFilterValues);
    },
    initDependentProperties: function initDependentProperties(filterNames, newFilterNames) {
      var _Ember, _Ember2;

      Ember.defineProperty(this, 'newFilterValues', (_Ember = Ember).computed.apply(_Ember, (0, _toConsumableArray2.default)(newFilterNames.concat([function () {
        var _this4 = this;

        var newValuesWithOldKeys = {};
        filterNames.forEach(function (key) {
          var newName = Ember.String.camelize("new_".concat(key));
          newValuesWithOldKeys[key] = _this4.get(newName);
        });
        return newValuesWithOldKeys;
      }]))));
      Ember.defineProperty(this, 'filterValues', (_Ember2 = Ember).computed.apply(_Ember2, (0, _toConsumableArray2.default)(filterNames.concat([function () {
        return this.getProperties(filterNames);
      }])))); // this.set('filterValues', this.getProperties(filterNames));
      // ^^ this seemed tempting, as observers initialized furher above should call
      // this method on any change to any of the filterValues, but apparently not
      // always at the right time (we may take a wrong snapshot;
      //   CHECK: can there be loops in the computed dependencies here?)
    },
    newFiltersUnchanged: computedValuesEqual('newFilterValues', 'filterValues', 'initNewFilters'),
    newFiltersAreDefault: computedValuesEqual('newFilterValues', 'defaultFilterValues', 'initNewFilters'),
    currentFiltersAreDefault: computedValuesEqual('filterValues', 'defaultFilterValues', 'initNewFilters'),
    filtersPresent: Ember.computed.not('currentFiltersAreDefault'),
    actions: {
      applyNewFilterValues: function applyNewFilterValues() {
        // TODO: abstraction for "hidden" additional property (page) ?!
        this.setProperties(Object.assign({
          page: 1
          /* CWA-80 */
          ,
          newFilterValues: null
        }, this.newFilterValues));
      },
      resetFiltersToDefaults: function resetFiltersToDefaults() {
        var defaultValues = Object.assign.apply(Object, (0, _toConsumableArray2.default)(this.filterParams.map(function (name) {
          return (0, _defineProperty2.default)({}, name, undefined);
        })));
        Object.assign(defaultValues, this.defaultFilterValues || {});
        this.setProperties(defaultValues);
      },
      resetNewFiltersToCurrent: function resetNewFiltersToCurrent() {
        this.initOrResetNewFilterValues();
      }
    }
  });

  _exports.default = _default;
});