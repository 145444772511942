define("oi/components/sort-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var SortIconComponent = Ember.Component.extend({
    tagName: 'span',
    classNameBindings: 'icon',
    icon: Ember.computed('sortOrder', 'isAlphabetical', function () {
      var sortOrder = this.sortOrder;

      if (sortOrder === 'asc') {
        return 'fa fa-sort-asc fa-lg';
      }

      if (sortOrder === 'desc') {
        return 'fa fa-sort-desc fa-lg';
      }

      return 'fa fa-sort fa-lg';
    }),
    attributeBindings: ['title'],
    title: Ember.computed('sortOrder', function () {
      var so = this.sortOrder;

      if (so === 'asc') {
        return 'currently sorted in ascending order; click to sort descendingly';
      }

      if (so === 'desc') {
        return 'currently sorted in descending order; click to sort ascendingly';
      }

      return 'click to sort (in ascending order)';
    })
  });
  SortIconComponent.reopenClass({
    positionalParams: ['sortOrder']
  });
  var _default = SortIconComponent;
  _exports.default = _default;
});