define("oi/templates/components/tasks/editfield", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GKOx6XTj",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"editingActive\"]]],null,{\"statements\":[[7,\"div\",true],[10,\"class\",\"input-group\"],[8],[0,\"\\n    \"],[5,\"input\",[[12,\"class\",\"form-control\"],[12,\"type\",\"search\"]],[[\"@value\",\"@insert-newline\",\"@escape-press\"],[[28,\"mut\",[[24,[\"valueToEdit\"]]],null],[28,\"action\",[[23,0,[]],\"apply\",[24,[\"valueToEdit\"]]],null],[28,\"action\",[[23,0,[]],\"toggleEditingState\"],null]]]],[0,\"\\n    \"],[7,\"span\",true],[10,\"class\",\"input-group-btn\"],[8],[0,\"\\n        \"],[7,\"button\",false],[12,\"class\",\"btn btn-secondary\"],[12,\"is-area-hidden\",\"true\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],\"apply\",[24,[\"valueToEdit\"]]]],[8],[7,\"span\",true],[10,\"class\",\"glyphicon glyphicon-ok\"],[8],[9],[9],[0,\"\\n        \"],[7,\"button\",false],[12,\"class\",\"btn btn-secondary\"],[12,\"is-area-hidden\",\"true\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],\"toggleEditingState\"]],[8],[7,\"span\",true],[10,\"class\",\"glyphicon glyphicon-remove\"],[8],[9],[9],[0,\"\\n    \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[7,\"p\",false],[12,\"role\",\"button\"],[3,\"action\",[[23,0,[]],\"toggleEditingState\"]],[8],[1,[22,\"value\"],true],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "oi/templates/components/tasks/editfield.hbs"
    }
  });

  _exports.default = _default;
});