define("oi/components/oi-track-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* global moment */
  var _default = Ember.Component.extend({
    session: Ember.inject.service('session'),
    posList: Ember.computed('track', function () {
      var _this = this;

      return (this.track || []).map(function (el) {
        return {
          datetime: "".concat(moment.utc(el.pos_datetime).format('YYYY-MM-DD HH:mm'), " UTC"),
          latitude: el.latitude.toFixed(2),
          longitude: el.longitude.toFixed(2),
          pos_name: el.pos_source === 10 ? el.vessel_shipname : el.pos_name,
          pos_source: el.pos_source === 1 ? 'Carrier' : 'Vessel Tracking',
          transport_mode: _this.translateMode(el.transport_mode)
        };
      }).reverse();
    }),
    translateMode: function translateMode(num) {
      var ret = '';

      switch (num) {
        case 0:
          ret = 'Street';
          break;

        case 1:
          ret = 'Rail';
          break;

        case 2:
          ret = 'Inland water';
          break;

        case 10:
          ret = 'Ocean';
          break;

        default:
          ret = '--';
          break;
      }

      return ret;
    }
  });

  _exports.default = _default;
});