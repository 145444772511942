define("oi/constants/shipments-filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.LIFECYCLE_STATUSES = _exports.TRANSPORT_STATUSES = _exports.FIXED_EXCEPTIONS = _exports.DEFAULT_EXCEPTION_DAYS = _exports.ADAPTIVE_EXCEPTION_FILTERS = _exports.EVENTS_FILTER_PRESET = _exports.GENERIC_FILTER_PRESETS = _exports.EXCEPTIONS_FILTER_PRESETS = void 0;
  var EXCEPTIONS_FILTER_PRESETS = [{
    title: 'In POL for > DAYS',
    filter: 'INPOL',
    defaultValue: 3
  }, {
    title: 'In T/S for > DAYS',
    filter: 'INTSP',
    defaultValue: 3
  }, {
    title: 'In POD for > DAYS',
    filter: 'INPOD',
    defaultValue: 3
  }, {
    title: 'In delivery for > DAYS',
    filter: 'INDLV',
    defaultValue: 3
  }, {
    title: 'ETD changed',
    filter: 'ETDCD_NEQ'
  }, {
    title: 'ETD preponed for > DAYS',
    filter: 'ETDCD_FWD',
    defaultValue: 3
  }, {
    title: 'ETD postponed for > DAYS',
    filter: 'ETDCD',
    defaultValue: 3
  }, // ETA change alerts filter presets via URL param `...&filter_preset=ETACD_<HOWLONG>` w/ 'HOWLONG' in ['FWD', 'LT_DAYS', 'GTE_DAYS', 'GTE_6D', 'GTE_9D', 'GTE_12D']
  {
    title: 'ETA changed',
    filter: 'ETACD_NEQ'
  }, {
    title: 'ETA preponed for > DAYS',
    filter: 'ETACD_FWD',
    defaultValue: 3
  }, {
    title: 'ETA postponed for > DAYS',
    filter: 'ETACD',
    defaultValue: 3
  }, {
    title: 'POD arrival warning',
    filter: 'PRED_TA',
    condition: function condition(permissions) {
      return permissions && Ember.get(permissions, 'predictions.feature_enabled');
    }
  }, {
    title: 'Behind schedule for > DAYS',
    filter: 'BHSDL',
    defaultValue: 3
  }, {
    title: 'Currently held by customs and/or carrier',
    filter: 'HOLD_ANY'
  }, {
    title: 'In LIF for > DAYS',
    filter: 'INLIF',
    defaultValue: 3
  }];
  _exports.EXCEPTIONS_FILTER_PRESETS = EXCEPTIONS_FILTER_PRESETS;
  var GENERIC_FILTER_PRESETS = ['INPOL', 'INTSP', 'INPOD', 'INDLV', 'BHSDL', 'INLIF', 'ETDCD', 'ETDCD_FWD', 'ETACD', 'ETACD_FWD'];
  _exports.GENERIC_FILTER_PRESETS = GENERIC_FILTER_PRESETS;
  var EVENTS_FILTER_PRESET = [{
    title: 'Container gate-out at POD (<= DAYS)',
    filter: 'event_0'
  }, {
    title: 'Rollover warning (vessel change) (<= DAYS)',
    filter: 'event_4'
  }, {
    title: 'Vessel\'s ETA postponed (<= DAYS)',
    filter: 'event_8'
  }, {
    title: 'POD changed warning (<= DAYS)',
    filter: 'event_16'
  }, {
    title: 'Customs/Carrier Release (<= DAYS)',
    filter: 'event_30'
  }, {
    title: 'ETD at POL postponed (<= DAYS)',
    filter: 'event_1000'
  }, {
    title: 'ETA at POD postponed (<= DAYS)',
    filter: 'event_2000'
  }, {
    title: 'Vessel approaching POD (<= DAYS)',
    filter: 'event_3000'
  }, {
    title: 'Discrepancy between contracted and reported ETA (<= DAYS)',
    filter: 'event_4000'
  }];
  _exports.EVENTS_FILTER_PRESET = EVENTS_FILTER_PRESET;
  var ADAPTIVE_EXCEPTION_FILTERS = EXCEPTIONS_FILTER_PRESETS.concat(EVENTS_FILTER_PRESET).filter(function (item) {
    return item.title.includes(' DAYS');
  }).map(function (item) {
    return item.filter;
  });
  _exports.ADAPTIVE_EXCEPTION_FILTERS = ADAPTIVE_EXCEPTION_FILTERS;
  var DEFAULT_EXCEPTION_DAYS = 3;
  _exports.DEFAULT_EXCEPTION_DAYS = DEFAULT_EXCEPTION_DAYS;
  var FIXED_EXCEPTIONS = ['Not released', 'ETD changed', 'ETA changed'];
  _exports.FIXED_EXCEPTIONS = FIXED_EXCEPTIONS;
  var TRANSPORT_STATUSES = [{
    id: '0',
    title: 'unknown'
  }, {
    id: '10',
    title: 'waiting for pickup'
  }, {
    id: '11',
    title: 'land transport to POL'
  }, {
    id: '12',
    title: 'in POL terminal'
  }, {
    id: '13',
    title: 'waiting for departure from POL'
  }, {
    id: '14',
    title: 'ocean transport from POL'
  }, {
    id: '15',
    title: 'in transshipment'
  }, {
    id: '16',
    title: 'ocean transport to POD'
  }, {
    id: '17',
    title: 'waiting for discharge at POD'
  }, {
    id: '18',
    title: 'in POD terminal'
  }, {
    id: '19',
    title: 'land transport to Place of Delivery'
  }, {
    id: '20',
    title: 'delivered; returning container'
  }, {
    id: '21',
    title: 'completed'
  }];
  _exports.TRANSPORT_STATUSES = TRANSPORT_STATUSES;
  var LIFECYCLE_STATUSES = [{
    id: '10',
    title: ' initializing'
  }, {
    id: '0',
    title: 'active'
  }, {
    id: '1',
    title: 'lifecycle completed'
  }, {
    id: '2',
    title: 'expired'
  }, {
    id: '3',
    title: 'new journey detected'
  }, {
    id: '4',
    title: 'removed from booking'
  }];
  _exports.LIFECYCLE_STATUSES = LIFECYCLE_STATUSES;
});