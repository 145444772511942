define("oi/components/power-select-id", ["exports", "@babel/runtime/helpers/esm/toConsumableArray"], function (_exports, _toConsumableArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var VALUE_PATTERN = '((ANY|ALL){1})\\(([0-9a-zA-Z_]{1,}){1}\\){1}';

  function getRegexMatch(string, regex) {
    if (string) {
      var matchResult = string.match(regex);

      if (matchResult) {
        return matchResult;
      }
    }

    return undefined;
  }

  function extractQueryMode(envelope) {
    var regexMatch = getRegexMatch(envelope, VALUE_PATTERN);

    if (regexMatch) {
      return regexMatch[1];
    }

    return 'ALL';
  }

  function extractValueFromQueryModeEnvelope(envelope) {
    var regexMatch = getRegexMatch(envelope, VALUE_PATTERN);

    if (regexMatch) {
      return regexMatch[3];
    }

    return envelope;
  }

  function applyQueryModeEnvelope(queryMode, value) {
    return "".concat(queryMode, "(").concat(value, ")");
  }

  function deepSearch(options, idKey, values) {
    var matches = [];
    options.forEach(function (item) {
      if (item.options) {
        matches.push.apply(matches, (0, _toConsumableArray2.default)(deepSearch(item.options, idKey, values)));
      } else if (values.includes(item[idKey])) {
        matches.push(item);
      }
    });
    return matches;
  }
  /**
   * ...
   *
   * note: when searchEnabled=false and there is no placeholder, the layout (height) get messed up
   */


  var _default = Ember.Component.extend({
    init: function init() {
      this._super();

      this.setProperties({
        queryModeOptions: [{
          label: 'ALL',
          value: 'ALL'
        }, {
          label: 'ANY',
          value: 'ANY'
        }]
      });
    },
    queryMode: Ember.computed('value', {
      get: function get() {
        var value = this.value;
        return extractQueryMode(value);
      }
    }),
    useQueryModeSelector: false,
    tagName: '',
    selectedValue: Ember.computed('value', 'options.[]', {
      get: function get() {
        var options = this.options,
            value = this.value,
            selectMultiple = this.selectMultiple,
            selectMultipleCheckbox = this.selectMultipleCheckbox;

        if (!value || !options || !options.length) {
          return selectMultiple ? [] : null;
        }

        var idKey = this.idKey || 'id';

        if (selectMultiple || selectMultipleCheckbox) {
          var values = (this.useQueryModeSelector ? extractValueFromQueryModeEnvelope(value) : value).split('_');

          if (options && options[0] && options[0].groupName) {
            return deepSearch(options, idKey, values);
          }

          return options.filter(function (el) {
            return values.includes(el[idKey]);
          });
        }

        return deepSearch(options, idKey, [value])[0];
      },
      set: function set(key, selectedModelInstances) {
        var idKey = this.idKey || 'id';
        var values = Ember.isArray(selectedModelInstances) ? selectedModelInstances.mapBy(idKey).join('_') || undefined : selectedModelInstances && Ember.get(selectedModelInstances, idKey);

        if (values && this.get('useQueryModeSelector')) {
          this.set('value', applyQueryModeEnvelope(this.get('queryMode'), values));
        } else {
          this.set('value', values);
        }

        return selectedModelInstances;
      }
    }),
    actions: {
      refreshQueryMode: function refreshQueryMode(queryMode) {
        this.set('queryMode', queryMode);

        if (this.get('value')) {
          this.set('value', applyQueryModeEnvelope(queryMode, extractValueFromQueryModeEnvelope(this.get('value'))));
        }
      }
    }
  });

  _exports.default = _default;
});