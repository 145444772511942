define("oi/templates/components/checks-and-crosses", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "o8UudzEF",
    "block": "{\"symbols\":[\"item\",\"index\"],\"statements\":[[4,\"each\",[[24,[\"supportItems\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"if\",[[23,2,[]],\"|\"],null],false],[0,\"\\n  \"],[1,[28,\"if\",[[23,1,[]],\"✓\",\"☓\"],null],false],[0,\"\\n\"]],\"parameters\":[1,2]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "oi/templates/components/checks-and-crosses.hbs"
    }
  });

  _exports.default = _default;
});