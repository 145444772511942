define("oi/templates/components/oi-switch-account", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HtrqzuZ5",
    "block": "{\"symbols\":[\"subAccount\"],\"statements\":[[4,\"power-select\",null,[[\"class\",\"options\",\"selected\",\"initiallyOpened\",\"searchField\",\"placeholder\",\"onChange\"],[\"oi-userswitch\",[24,[\"writablesubAccounts\"]],[24,[\"currentValue\"]],[24,[\"initiallyOpened\"]],\"title\",\"Select an account... (type a part of its name to search)\",[28,\"action\",[[23,0,[]],[24,[\"updateAccount\"]]],null]]],{\"statements\":[[0,\"  \"],[1,[23,1,[\"title\"]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "oi/templates/components/oi-switch-account.hbs"
    }
  });

  _exports.default = _default;
});