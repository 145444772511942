define("oi/templates/components/charts-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ByLJmlF2",
    "block": "{\"symbols\":[\"theChart\",\"margin\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"panel panel-default\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"panel-heading text-center\"],[8],[0,\"\\n    \"],[7,\"h4\",true],[8],[1,[24,[\"feedCharts\",\"0\",\"title\"]],false],[0,\"\\n      \"],[1,[28,\"zoom-indicator\",null,[[\"zoomDirection\",\"pluralizeTooltip\"],[[24,[\"zoomDirection\"]],[28,\"gt\",[[24,[\"feedCharts\",\"length\"]],1],null]]]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"panel-body\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n      \"],[4,\"with\",[[24,[\"widths\",\"margin\"]]],null,{\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"col-md-\",[23,2,[]]]]],[8],[9]],\"parameters\":[2]},null],[0,\"\\n\"],[4,\"each\",[[24,[\"feedCharts\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\",true],[11,\"class\",[29,[\"col-md-\",[24,[\"widths\",\"single\"]]]]],[8],[0,\"\\n          \"],[1,[28,\"chart-fetcher\",null,[[\"dashboardName\",\"chartName\",\"desiredHeight\",\"filterQuery\",\"zoomDirection\"],[[24,[\"name\"]],[23,1,[\"name\"]],[23,1,[\"height\"]],[24,[\"filterQuery\"]],[24,[\"zoomDirection\"]]]]],false],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"      \"],[4,\"if\",[[24,[\"margin\"]]],null,{\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"col-md-\",[22,\"margin\"]]]],[8],[9]],\"parameters\":[]},null],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "oi/templates/components/charts-box.hbs"
    }
  });

  _exports.default = _default;
});