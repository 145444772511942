define("oi/authenticators/sso", ["exports", "ember-simple-auth/authenticators/base", "fetch", "oi/config/environment"], function (_exports, _base, _fetch, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    applicationService: Ember.inject.service(),
    ajax: Ember.inject.service('ajax'),
    session: Ember.inject.service('session'),
    currentUser: Ember.inject.service('user'),
    raven: Ember.inject.service('raven'),
    notify: Ember.inject.service('notify'),
    logWarning: function logWarning(warning) {
      this.raven.captureMessage("P44-SSO-Login problem -> ".concat(warning), {
        level: 'warning',
        fingerprint: ['P44-SSO-Login error'],
        extra: {
          warning: warning
        }
      });
    },
    makeRequest: function makeRequest(url, data) {
      var _this = this;

      var headers = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      var authenticator = this;
      var host = window.location.host;
      var options = {
        headers: headers,
        method: 'GET',
        credentials: 'include'
      };
      return new Ember.RSVP.Promise(function (resolve, reject) {
        return (0, _fetch.default)(url, options).then(function (response) {
          response.text().then(function (result) {
            if (response.status === 200) {
              var jsonResult = JSON.parse(result);

              if (jsonResult.valid_tokens.length > 0) {
                var token = jsonResult.valid_tokens[0];

                var principalUrl = _environment.default.APP.P44_PRINCIPAL_URLS["".concat(host, "_").concat(token)];

                (0, _fetch.default)(principalUrl, options).then(function (principalResponse) {
                  if (principalResponse.status === 200) {
                    authenticator.segmentIdentify(principalResponse.json());
                  }
                });
              }

              resolve(jsonResult);
            } else if (response.status !== 204) {
              var message = '';

              try {
                message = JSON.parse(result);
              } catch (e) {
                message = result;
              }

              _this.logWarning(message);

              _this.notify.error("SSO ".concat(response.statusText, ": ").concat(message), {
                closeAfter: 10000
              });

              reject('Token check failed');
            }

            reject('No sso token available');
          });
        });
      });
    },
    checkToken: function checkToken() {
      var _this2 = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        var url = "".concat(_this2.applicationService.apiHost, "/p44/token-check/");

        _this2.makeRequest(url).then(function (response) {
          Ember.run(null, resolve, response);
        }, function (response) {
          Ember.run(null, reject, response);
        });
      }).catch(function (e) {
        _this2.logWarning(e);

        return Ember.RSVP.reject();
      });
    },
    authenticate: function authenticate() {
      return this.checkToken();
    },
    restore: function restore() {
      return this.checkToken();
    },
    segmentIdentify: function segmentIdentify(authData) {
      var _window = window,
          analytics = _window.analytics;

      if (analytics) {
        analytics.identify(authData.username, {
          userId: authData.username,
          email: authData.username,
          tenantId: authData.tenantId,
          tenantName: authData.companyName,
          networkRole: authData.tenantCarrierNetworkRoles[0],
          displayName: "".concat(authData.firstName, " ").concat(authData.lastName)
        });
      }
    }
  });

  _exports.default = _default;
});