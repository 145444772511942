define("oi/templates/components/upcoming-arrivals-chart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/hK+JpGu",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[28,\"not\",[[24,[\"hideMomentarily\"]]],null]],null,{\"statements\":[[0,\"  \"],[1,[28,\"high-charts\",null,[[\"content\",\"chartOptions\"],[[24,[\"content\"]],[24,[\"chartOptions\"]]]]],false],[0,\"\\n  \"],[5,\"zoom-indicator\",[],[[\"@zoomDirection\",\"@hintPosition\",\"@classNames\"],[\"y\",\"top-left\",\"pull-up\"]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "oi/templates/components/upcoming-arrivals-chart.hbs"
    }
  });

  _exports.default = _default;
});