define("oi/templates/subscriptions_error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9jk7FoJi",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"container oi-first\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"page-header\"],[8],[0,\"\\n    \"],[7,\"h1\",true],[8],[0,\"Subscriptions\"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[1,[28,\"formatted-error\",null,[[\"error\"],[[24,[\"model\"]]]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "oi/templates/subscriptions_error.hbs"
    }
  });

  _exports.default = _default;
});