define("oi/controllers/shipments", ["exports", "oi/mixins/multi-filter-mixin", "oi/config/environment", "oi/constants/countries", "oi/controllers/subscriptions", "oi/constants/shipments-filter", "jquery"], function (_exports, _multiFilterMixin, _environment, _countries, _subscriptions, _shipmentsFilter, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var CONTEXT_MAP = {
    HOLD_ANY: 'not_released',
    INPOL: 'stuckinpol',
    INTSP: 'transship',
    INPOD: 'demurrage',
    INLIF: 'stuckinlif',
    BHSDL: 'schedule',
    ETDCD_NEQ: 'etdchange',
    ETACD_NEQ: 'etachange'
  };
  var FILTER_PARAMS = ['carrier', 'status', // trnsport status, actually ("wating for pickup"..."delivered")
  'lifecycle_status', 'shipmentsubscription__status', 'events', // the filter we're using to link from the events summary to the appr. shipments
  'container_number', 'cv', // current vessel
  'vesselMode', // "current" or "anytime"
  'pod_date_0', 'pod_date_1', 'origin_country', 'destination_country', 'pol_date_0', 'pol_date_1', 'origin_location', 'destination_location', 'filter_preset', 'exception_days', 'macnorris', 'tags', 'contracted', 'reference', 'exclude_inland', 'exception_definition', 'service'];

  var _default = Ember.Controller.extend(_multiFilterMixin.default, {
    queryParams: ['page', 'page_size', 'redirectSingle',
    /* -------------------------------------------------------------------------------------------*/
    'sort_status', 'sort_container_number', 'sort_id_date', 'sort_pol_loc', 'sort_pod_loc', 'sort_arrival_date', 'sort_shipmentsubscription__request_key',
    /* -------------------------------------------------------------------------------------------*/
    'search', 'exception_definition'].concat(FILTER_PARAMS),
    filterParams: FILTER_PARAMS,
    redirectSingle: false,
    page: 1,
    raven: Ember.inject.service(),
    session: Ember.inject.service(),
    user: Ember.inject.service(),
    notify: Ember.inject.service(),
    featuresAndPermissions: Ember.computed.alias('session.data.features'),
    defaultFilterValues: Object.freeze({
      vesselMode: 'current',
      exception_days: 3
    }),
    vesselMode: 'current',
    exception_days: 3,
    init: function init() {
      this._super.apply(this, arguments);

      Ember.run.schedule('afterRender', this, function () {
        (0, _jquery.default)('[data-toggle="tooltip"]').tooltip();
      });
    },
    carrierSortOrder: Object.freeze(['short_name']),
    sortedCarriers: Ember.computed.sort('carriers', 'carrierSortOrder'),
    transportStatuses: _shipmentsFilter.TRANSPORT_STATUSES,
    lifecycleStatuses: _shipmentsFilter.LIFECYCLE_STATUSES,
    subscriptionStatuses: _subscriptions.SUBSCRIPTION_STATUS_FILTERS,
    genericFilterPresets: _shipmentsFilter.ADAPTIVE_EXCEPTION_FILTERS,
    countries: _countries.default.map(function (country) {
      country.id = country.abbr;
      return country;
    }),
    exceptionDays: Ember.computed('eventsPresets', function () {
      return this.newExceptionDays || _shipmentsFilter.DEFAULT_EXCEPTION_DAYS;
    }),
    allEventsPresets: _shipmentsFilter.EVENTS_FILTER_PRESET,
    eventsPresets: Ember.computed('allEventsPresets', 'session.data.features.[]', function () {
      var permissions = this.get('session.data.features');
      var allEvents = this.changeEvent();
      var filteredEvents = allEvents.filter(function (el) {
        return !el.condition || el.condition(permissions, _environment.default.environment);
      });
      var incidents = this.store.peekAll('incident');
      var exceptionDefinition = this.store.peekAll('exceptionmanagement/definition');
      return this.getGroupedExceptionAndIncidentItems(filteredEvents, incidents, exceptionDefinition);
    }),
    canTag: Ember.computed.notEmpty('availableTags'),
    allowGrouping: _environment.default.environment === 'development',
    groupedShipments: Ember.computed('data.@each.locationsDict', function () {
      var shipments = [];
      var same = [];
      var lastShipment = null; // this could be written "more elegantly" (for some
      // t.b.d. definition of "elegant") with reduce, but probably not more readably:

      this.data.forEach(function (shipment) {
        if (!lastShipment || lastShipment.get('subscription.id') !== shipment.get('subscription.id') || (lastShipment.current_vessel || {}).id !== (shipment.current_vessel || {}).id || lastShipment.locationsDict.pod.locode !== shipment.locationsDict.pod.locode || lastShipment.locationsDict.pod.inDateReal !== shipment.locationsDict.pod.inDateReal || lastShipment.locationsDict.pod.inDatePlanned !== shipment.locationsDict.pod.inDatePlanned || lastShipment.descriptive_name !== shipment.descriptive_name) {
          shipments.push(shipment);
          same.push([]);
          lastShipment = shipment;
        } else {
          same[same.length - 1].push(shipment);
        }
      });
      return {
        shipments: shipments,
        same: same
      };
    }),
    isActionButtonDisabled: Ember.computed('data.@each.checked', function () {
      return !this.data.findBy('checked');
    }),
    changeEvent: function changeEvent() {
      var _this = this;

      var days = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : undefined;
      return this.allEventsPresets.map(function (item) {
        var replaceableString = item.title.match('DAYS') ? 'DAYS' : '[0-9]+';
        var value = days || _this.newExceptionDays || item.defaultValue;
        var title = item.title.replace(new RegExp(replaceableString, 'g'), value);
        return {
          title: title,
          filter: item.filter,
          defaultValue: item.defaultValue || undefined
        };
      });
    },
    groupedServices: Ember.computed('services.[]', function () {
      var services = this.services;

      if (!services) {
        return [];
      }

      var unknownCarrier = 'Carrier unknown';
      var groupedServices = services.reduce(function (r, s) {
        var carrierName = s.get('carrier.short_name') || unknownCarrier;
        r[carrierName] = r[carrierName] || [];
        r[carrierName].push(s);
        return r;
      }, {});
      var result = [];
      var keys = Object.keys(groupedServices).sort();

      if (keys.includes(unknownCarrier)) {
        keys.push(keys.splice(keys.indexOf(unknownCarrier), 1)[0]);
      }

      keys.forEach(function (key) {
        var keyServices = groupedServices[key];
        result.push({
          groupName: key,
          options: keyServices
        });
      });
      return result;
    }),
    downloadDisabled: Ember.computed('count', function () {
      var count = this.get('count') || 0;
      return count === 0;
    }),
    actions: {
      fetchServices: function fetchServices(_, _ref) {
        var _this2 = this;

        var relatedTarget = _ref.relatedTarget;

        // onFocus action is called twice; once for the button, once for the div receiving focus
        if (relatedTarget === null || relatedTarget.type === 'button') {
          return undefined;
        }

        return this.store.query('service', {}).then(function (services) {
          return _this2.setProperties({
            services: services
          });
        });
      },
      search: function search(newSearchTerm) {
        this.setProperties({
          page: 1,
          search: newSearchTerm
        });
      },
      sort: function sort(field) {
        var sortParams = {
          page: 1
          /* CWA-80 - Avoid uncanny valley */
          ,
          sort_id_date: undefined,
          sort_status: undefined,
          sort_pol_loc: undefined,
          sort_pod_loc: undefined,
          sort_container_number: undefined,
          sort_arrival_date: undefined,
          sort_shipmentsubscription__request_key: undefined // filters are exclusive, so reset all of them

        };
        sortParams[field] = this.get(field) === 'asc' ? 'desc' : 'asc';
        this.setProperties(sortParams);
      },
      updateEventDays: function updateEventDays(e) {
        var allEvents = this.changeEvent();
        this.set('allEventsPresets', allEvents);
        this.set('newExceptionDays', e.target.value);
      },
      createTask: function createTask() {
        var context = CONTEXT_MAP[this.get('filter_preset')];
        var checkedShipments = this.data.filterBy('checked').map(function (s) {
          return s;
        });
        var linkedObjects = [];
        checkedShipments.forEach(function (shipment) {
          linkedObjects.push({
            app: 'ctt',
            type: 'containershipment',
            id: shipment.id,
            label: shipment.container_number
          });
        });
        this.send('createNewTask', linkedObjects, context);
      },
      allcheckedChanged: function allcheckedChanged() {
        var _this3 = this;

        this.toggleProperty('allChecked');
        this.data.forEach(function (item) {
          item.set('checked', _this3.get('allChecked'));
        });
      },
      showDownloadModal: function showDownloadModal() {
        var itemsToExport = this.get('count');

        if (itemsToExport > 100000) {
          this.notify.error('The download would exceed the limit of 100000 items. Please use the filter to reduce the amount of data', {
            closeAfter: 10000
          });
        } else {
          this.send('showModal', 'components/download-modal', {
            askForUtcConversion: true,
            itemsToExport: itemsToExport
          });
        }
      }
    },
    getGroupedExceptionAndIncidentItems: function getGroupedExceptionAndIncidentItems(events, incidents, exceptionDefinitions) {
      var availableIncidents = incidents.map(function (incident) {
        return {
          title: incident.get('title'),
          filter: "incident_".concat(incident.get('id'))
        };
      });
      var definitions = exceptionDefinitions.map(function (definition) {
        return {
          title: definition.get('title'),
          filter: "definition_".concat(definition.get('id'))
        };
      });
      var permissions = this.get('session.data.features');
      var days = this.newExceptionDays || _shipmentsFilter.DEFAULT_EXCEPTION_DAYS;
      /* eslint-disable-next-line eqeqeq */
      // == just in case days === '1' (i.e. string)

      var daysStr = "".concat(days, " ").concat(days == 1 ? 'day' : 'days');

      var eventsOptions = _shipmentsFilter.EVENTS_FILTER_PRESET.map(function (_ref2) {
        var filter = _ref2.filter,
            title = _ref2.title;
        return {
          filter: filter,
          title: title.replace('DAYS', daysStr)
        };
      });

      var groupedEvents = [];

      if (definitions.length > 0) {
        groupedEvents.push({
          groupName: definitions.length > 1 ? 'My Exception Definitions' : 'My Exception Definition',
          options: definitions
        });
      } else {
        groupedEvents.push({
          groupName: 'Exceptions',
          options: _shipmentsFilter.EXCEPTIONS_FILTER_PRESETS.filter(function (_ref3) {
            var condition = _ref3.condition;
            return !condition || condition(permissions, _environment.default.environment);
          }).map(function (_ref4) {
            var filter = _ref4.filter,
                title = _ref4.title;
            return {
              filter: filter,
              title: title.replace('DAYS', daysStr)
            };
          })
        });
      }

      groupedEvents.push({
        groupName: 'Events',
        options: eventsOptions
      });

      if (availableIncidents.length > 0) {
        groupedEvents.push({
          groupName: availableIncidents.length > 1 ? 'Incidents' : 'Incident',
          options: availableIncidents
        });
      }

      return groupedEvents;
    }
  });

  _exports.default = _default;
});