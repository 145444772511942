define("oi/helpers/hexToRgb", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.hexToRgb = hexToRgb;
  _exports.default = void 0;

  function hexToRgb(hex) {
    var opacity = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
    var hexStripped = hex.replace('#', '');
    var r = parseInt(hexStripped.substring(0, 2), 16);
    var g = parseInt(hexStripped.substring(2, 4), 16);
    var b = parseInt(hexStripped.substring(4, 6), 16);
    var result = "rgba(".concat(r, ",").concat(g, ",").concat(b, ",").concat(opacity, ")");
    return result;
  }

  var _default = Ember.Helper.helper(hexToRgb);

  _exports.default = _default;
});