define("oi/templates/components/impersonate-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1h/zi4Gn",
    "block": "{\"symbols\":[],\"statements\":[[4,\"confirm-modal\",null,[[\"title\",\"ok\",\"context\",\"close\",\"actionIsImmediate\"],[\"Impersonate\",\"impersonate\",[24,[\"newUser\"]],\"removeModal\",true]],{\"statements\":[[0,\"  So, \"],[1,[24,[\"session\",\"data\",\"username\"]],false],[0,\", you would like to be someone else… who?\\n  \"],[7,\"div\",true],[10,\"style\",\"margin: 12px 12px 12px 24px;\"],[8],[0,\"\\n    \"],[1,[28,\"input\",null,[[\"value\",\"placeholder\",\"enter\"],[[24,[\"newUser\"]],\"User name or user id\",[28,\"action\",[[23,0,[]],\"impersonate\"],null]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"small\",true],[8],[0,\"\\n    \"],[7,\"ul\",true],[8],[0,\"\\n      \"],[7,\"li\",true],[8],[0,\"You (\"],[1,[24,[\"session\",\"data\",\"username\"]],false],[0,\") will be logged out.\"],[9],[0,\"\\n      \"],[7,\"li\",true],[8],[0,\"If your entered id or name does not match a user or matches one who can also impersonate others, you will stay logged out.\"],[9],[0,\"\\n      \"],[7,\"li\",true],[8],[0,\"If your entered id or name matches a regular user, you will be logged in as them.\"],[9],[0,\"\\n      \"],[7,\"li\",true],[8],[0,\"Your you-as-some-else session will last no more than 60 minutes.\"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "oi/templates/components/impersonate-modal.hbs"
    }
  });

  _exports.default = _default;
});