define("oi/models/boxtech-info", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    // number specified as pk in serializer (pk type is always string)
    last_looked_up: _emberData.default.attr('date'),
    lastLookedUpIsoDate: Ember.computed('last_looked_up', function () {
      return this.last_looked_up.toISOString().split('T')[0]; // alternatively: moment.utc(date).format('YYYY-MM-DD');
    }),
    info: _emberData.default.attr() // // CHECK: flatten object (incorporate info props into actual model) in serializer ?!
    // // info content (among other things):
    // manufacturer: DS.attr('string'),
    // manufacture_date: DS.attr('date'),
    // manufacture_number: DS.attr('string'),
    // max_payload_kg: DS.attr('number'),
    // tare_kg: DS.attr('number'),
    // max_gross_mass_kg: DS.attr('number'),
    // stacking_kg: DS.attr('number'),
    // group_st: DS.attr('string'),
    // detail_st: DS.attr('string'),

  });

  _exports.default = _default;
});