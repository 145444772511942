define("oi/components/oi-customer-cer", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments); // eslint-disable-line prefer-rest-params


      var date = this.get('cerMilestone.customer');
      this.set('customerCerNew', date && (date.toISOString ? date.toISOString() : "".concat(date)).split('T')[0]);
      var tomorrowUTC = new Date(Date.now() + 24 * 60 * 60 * 1000).toISOString(); // requirement: user in california can assign the correct return date to
      // containers just returned in australia;
      // back-end validates that date is at most tomorrow relative to server time

      this.set('tomorrowUTC', tomorrowUTC.split('T')[0]);
    },
    actions: {
      update: function update() {
        var dateString = (0, _moment.default)(this.customerCerNew).format('YYYY-MM-DD');
        this.set('cerMilestone.customer', dateString); // ^ NOT: .utc!

        return this.update(dateString);
      }
    }
  });

  _exports.default = _default;
});