define("oi/models/bic-code", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    // bic: DS.attr('string'), // specified as pk in serializer
    operator: _emberData.default.belongsTo('bic-operator'),
    otherCodesStr: Ember.computed('operator.bicCodes', function () {
      var thisCode = this.id;
      return this.get('operator.bicCodes').filter(function (c) {
        return c !== thisCode;
      }).join(', ');
    })
  });

  _exports.default = _default;
});