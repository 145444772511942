define("oi/helpers/newline-to-br", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.newlineToBr = newlineToBr;
  _exports.default = void 0;
  var BREAK_TAG = '<br>';

  function newlineToBr(texts) {
    return Ember.String.htmlSafe(texts.toString().replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, "$1".concat(BREAK_TAG, "$2")));
  }

  var _default = Ember.Helper.helper(function (text) {
    return newlineToBr(text);
  });

  _exports.default = _default;
});