define("oi/transforms/momentdate", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* global moment */
  var _default = _emberData.default.Transform.extend({
    /** **********************************************************************************************
     * We usually get three different date representation from API (or from the DRFAdapter):
     *
     * - null
     * - 2016-01-01 and
     * - 2015-11-21T18:16+0000
     *
     * Moment.js is being used to convert the third one to a string that can be parsed
     * safely by all browsers. And as moment.js does not like to be initialized with null
     * we skip those values.
     ************************************************************************************** */
    deserialize: function deserialize(serialized) {
      if (serialized !== null) {
        return serialized.length === 10 ? "".concat(serialized, "T12:00:00.000Z") : moment.utc(serialized).toISOString();
      } // null remains null


      return serialized;
    },
    serialize: function serialize(deserialized) {
      return deserialized;
    }
  });

  _exports.default = _default;
});