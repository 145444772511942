define("oi/templates/components/date-range-with-tooltip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kT0+a44W",
    "block": "{\"symbols\":[\"minDate\",\"diff\",\"maxDate\"],\"statements\":[[4,\"with\",[[28,\"format-date\",[[24,[\"min\"]]],[[\"placeholder\"],[\"\"]]]],null,{\"statements\":[[4,\"with\",[[28,\"format-date\",[[24,[\"max\"]]],[[\"placeholder\"],[\"\"]]]],null,{\"statements\":[[0,\"    \"],[7,\"span\",true],[11,\"class\",[28,\"or\",[[24,[\"hintClass\"]],\"hint--top\"],null]],[11,\"aria-label\",[22,\"formattedRange\"]],[8],[0,\"\\n      \"],[1,[23,1,[]],false],[0,\" \"],[4,\"if\",[[28,\"not-eq\",[[23,1,[]],[23,3,[]]],null]],null,{\"statements\":[[0,\" - \"],[1,[23,3,[]],false],[0,\" \"]],\"parameters\":[]},null],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[3]},null],[0,\"\\n\"],[4,\"with\",[[28,\"or\",[[28,\"days-diff\",[[24,[\"max\"]],[24,[\"compareDateMax\"]]],null],[28,\"days-diff\",[[24,[\"min\"]],[24,[\"compareDateMin\"]]],null]],null]],null,{\"statements\":[[0,\"    \"],[7,\"span\",true],[11,\"aria-label\",[29,[[28,\"format-reldiff\",[[23,2,[]]],null],\" initially planned ETA\"]]],[11,\"class\",[29,[[28,\"or\",[[24,[\"hintClass\"]],\"hint--bottom\"],null],\" oi-deviation-bubble\"]]],[8],[0,\"\\n      \"],[1,[28,\"if\",[[28,\"gt\",[[23,2,[]],0],null],\"+\"],null],false],[1,[23,2,[]],false],[0,\"d \"],[7,\"i\",true],[10,\"class\",\"fa fa-clock-o\"],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[2]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "oi/templates/components/date-range-with-tooltip.hbs"
    }
  });

  _exports.default = _default;
});