define("oi/templates/components/confirm-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YiLpStex",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"modal fade\"],[8],[0,\"\\n  \"],[7,\"div\",true],[11,\"class\",[29,[\"modal-dialog \",[22,\"modalClass\"]]]],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"modal-content\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"modal-header\"],[8],[0,\"\\n        \"],[7,\"button\",true],[10,\"class\",\"close\"],[10,\"data-dismiss\",\"modal\"],[10,\"aria-hidden\",\"true\"],[10,\"type\",\"button\"],[8],[0,\"×\"],[9],[0,\"\\n        \"],[7,\"h4\",true],[10,\"class\",\"modal-title\"],[8],[1,[22,\"title\"],false],[9],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[11,\"class\",[29,[\"modal-body \",[22,\"modalBodyClass\"]]]],[8],[0,\"\\n        \"],[14,1],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"modal-footer\"],[8],[0,\"\\n        \"],[7,\"button\",true],[10,\"class\",\"btn btn-default\"],[10,\"data-dismiss\",\"modal\"],[10,\"type\",\"button\"],[8],[0,\"\\n          \"],[1,[28,\"or\",[[24,[\"dismissOnlyText\"]],\"Cancel\"],null],false],[0,\"\\n        \"],[9],[0,\"\\n\"],[4,\"unless\",[[24,[\"dismissOnlyText\"]]],null,{\"statements\":[[0,\"          \"],[7,\"button\",false],[12,\"class\",\"btn btn-primary\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],\"ok\"]],[8],[0,\"OK\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "oi/templates/components/confirm-modal.hbs"
    }
  });

  _exports.default = _default;
});