define("oi/components/charts-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: ['col-md-12'],
    widths: Ember.computed('feedCharts.length', function () {
      var amount = this.get('feedCharts.length');
      var single = Math.floor(12 / amount);
      var margin = Math.floor((12 - amount * single) / 2);
      return {
        single: single,
        margin: margin
      };
    })
  });

  _exports.default = _default;
});