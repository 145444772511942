define("oi/models/shipment/event", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    shipment: _emberData.default.belongsTo('shipment'),
    details: _emberData.default.attr(),
    code: _emberData.default.attr('number'),
    severity: _emberData.default.attr('number'),
    walltime: _emberData.default.attr('momentdate'),
    // created: DS.attr('momentdate'), // unused, probably equal to walltime
    message: Ember.computed('details', function () {
      return this.get('details.message').replace(/(.*?)(\d\d\d\d-\d\d-\d\d)T(\d\d:\d\d)([+-]\d\d+)/, '$1$2 $3 (local/UTC$4)').replace('00)', ')').replace(/(.*?)(:\d\d)(:00)?\+00:?00/, '$1$2 UTC');
    }),
    niceWalltimeUTC: Ember.computed('walltime', function () {
      return "".concat(_moment.default.utc(this.walltime).format('YYYY-MM-DD HH:mm'), " UTC");
    })
  });

  _exports.default = _default;
});