define("oi/components/oi-sort-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    isSortedAsc: Ember.computed('propName', 'sortProp', function () {
      var sortProp = this.sortProp,
          propName = this.propName;
      return sortProp === propName || sortProp === "".concat(propName, ":asc");
    }),
    isSortedDesc: Ember.computed('propName', 'sortProp', function () {
      return this.sortProp === "".concat(this.propName, ":desc");
    })
  });

  _exports.default = _default;
});