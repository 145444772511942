define("oi/templates/components/visual-query-filter/rule-remove-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WGRMi/uz",
    "block": "{\"symbols\":[\"Modal\"],\"statements\":[[5,\"bs-modal\",[],[[\"@open\",\"@onSubmit\",\"@size\"],[[22,\"open\"],[22,\"remove\"],\"md\"]],{\"statements\":[[0,\"\\n  \"],[6,[23,1,[\"header\"]],[],[[],[]],{\"statements\":[[0,\"\\n    Remove Rule\\n  \"]],\"parameters\":[]}],[0,\"\\n  \"],[6,[23,1,[\"body\"]],[],[[],[]],{\"statements\":[[0,\"\\n    Do you really want to remove the rule definition \\\"\"],[7,\"b\",true],[8],[1,[24,[\"rule\",\"title\"]],false],[9],[0,\"\\\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\\n  \"],[6,[23,1,[\"footer\"]],[],[[],[]],{\"statements\":[[0,\"\\n    \"],[5,\"bs-button\",[],[[\"@onClick\"],[[28,\"action\",[[23,0,[]],[23,1,[\"close\"]]],null]]],{\"statements\":[[0,\"Cancel\"]],\"parameters\":[]}],[0,\"\\n    \"],[5,\"bs-button\",[],[[\"@onClick\",\"@type\"],[[28,\"action\",[[23,0,[]],[23,1,[\"submit\"]]],null],\"primary\"]],{\"statements\":[[0,\"Apply\"]],\"parameters\":[]}],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "oi/templates/components/visual-query-filter/rule-remove-modal.hbs"
    }
  });

  _exports.default = _default;
});