define("oi/helpers/string-contains", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.contains = contains;
  _exports.default = void 0;

  function contains() {
    var str = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    var subStr = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
    var ignoreCase = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

    if (ignoreCase) {
      return str.toLowerCase().includes(subStr.toLowerCase());
    }

    return str.includes(subStr);
  }
  /** Helper can be called with 1 positional arg (gives ISO date only), 2 positional
      args (date & format string) or 1 positional arg and "format="... */


  var _default = Ember.Helper.helper(function (_ref, _ref2) {
    var _ref3 = (0, _slicedToArray2.default)(_ref, 2),
        str = _ref3[0],
        subStr = _ref3[1];

    var ignoreCase = _ref2.ignoreCase;
    return contains(str, subStr, ignoreCase);
  });

  _exports.default = _default;
});