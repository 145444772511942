define("oi/templates/components/oi-mapboxgl-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nHuLLwky",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"mapboxError\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\",true],[10,\"class\",\"error-message\"],[8],[1,[22,\"mapboxError\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[28,\"oi-mapboxgl-legend\",null,[[\"map\",\"futureTracks\",\"markerIds\",\"hasLandTrack\"],[[24,[\"map\"]],[24,[\"scheduledTracks\"]],[24,[\"markerIds\"]],[28,\"get\",[[24,[\"landTracks\"]],0],null]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "oi/templates/components/oi-mapboxgl-map.hbs"
    }
  });

  _exports.default = _default;
});