define("oi/routes/index", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "oi/config/environment"], function (_exports, _authenticatedRouteMixin, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    session: Ember.inject.service(),
    beforeModel: function beforeModel(transition) {
      if (!this.get('session.isAuthenticated')) {
        return this._super(transition);
      }

      return this.transitionTo(_environment.default.APP.PROD_ENV === 'sysco' ? 'shipments' : 'index.exceptions');
    }
  });

  _exports.default = _default;
});