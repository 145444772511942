define("oi/adapters/tag", ["exports", "oi/config/environment", "oi/adapters/application"], function (_exports, _environment, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    applicationService: Ember.inject.service(),
    user: Ember.inject.service('user'),
    accountId: Ember.computed('user.currentAccounts', function () {
      var masterAccount = this.user.currentAccounts.filter(function (acc) {
        return acc.sub_accounts;
      })[0];
      return masterAccount ? masterAccount.id : null;
    }),
    urlForCreateRecord: function urlForCreateRecord()
    /* modelName */

    /* , snapshot */
    {
      this.set('addTrailingSlashes', true);
      return "".concat(this.applicationService.apiBase, "/accountsettings/").concat(this.accountId, "/tags");
    },
    urlForFindRecord: function urlForFindRecord(id
    /* , modelName,  snapshot */
    ) {
      this.set('addTrailingSlashes', false);
      return "".concat(this.applicationService.apiBase, "/accountsettings/").concat(this.accountId, "/tags/?tagid=").concat(id);
    },
    urlForFindAll: function urlForFindAll()
    /* modelName, snapshot */
    {
      // TODO: See the '---', this is somewhat bogus to meet the API's path, actual identity is taken from the request headers' 'Account-ID'!
      this.set('addTrailingSlashes', true); // return API_BASE + '/accountsettings/' + this.get('accountId') + '/tags';

      return "".concat(this.applicationService.apiBase, "/subscriptions/---/tags"); // cwaapi/v2/subscriptions/{id}/tags/
    },
    urlForUpdateRecord: function urlForUpdateRecord(id
    /* , modelName, snapshot */
    ) {
      this.set('addTrailingSlashes', false);
      return "".concat(this.applicationService.apiBase, "/accountsettings/").concat(this.accountId, "/tags/?tagid=").concat(id);
    },
    urlForDeleteRecord: function urlForDeleteRecord(id
    /* , modelName, snapshot */
    ) {
      this.set('addTrailingSlashes', false);
      return "".concat(this.applicationService.apiBase, "/accountsettings/").concat(this.accountId, "/tags/?tagid=").concat(id);
    },
    urlForQuery: function urlForQuery()
    /* query, modelName */
    {
      this.set('addTrailingSlashes', true);
      return "".concat(this.applicationService.apiBase, "/accountsettings/").concat(this.accountId, "/tags");
    },
    updateRecord: function updateRecord(store, type, snapshot) {
      var payload = {};
      var changedAttributes = snapshot.changedAttributes();

      if (Object.keys(changedAttributes).length > 0) {
        Object.keys(changedAttributes).forEach(function (attributeName) {
          var newValue = changedAttributes[attributeName][1];
          payload[attributeName] = newValue;
        });
        var id = snapshot.id;
        var url = this.buildURL(type.modelName, id, snapshot, 'updateRecord');
        return this.ajax(url, 'PUT', {
          data: payload,
          crossDomain: true,
          xhrFields: {
            withCredentials: _environment.default.APP.USE_AJAX_CALL_WITH_CREDENTIALS
          }
        });
      }

      return null;
    }
  });

  _exports.default = _default;
});