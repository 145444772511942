define("oi/controllers/chart", ["exports", "oi/routes/chart"], function (_exports, _chart) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: _chart.QUERY_PARAMS,
    contracted: false,
    reference: null,
    exclude_inland: false,
    downloadDisabled: Ember.computed('target.currentRoute.attributes.recipe', function () {
      var count = this.get('target.currentRoute.attributes.recipe.numbers.total.number') || this.get('target.currentRoute.attributes.recipe.numbers.overall.number') || 0;
      return count === 0;
    }),
    actions: {
      clearFilters: function clearFilters() {
        this.setProperties({
          selectedTags: [],
          selectedCarrier: [],
          pol_date_0: undefined,
          pol_date_1: undefined,
          contracted: false,
          reference: undefined,
          exclude_inland: false
        });
      },
      showDownloadModal: function showDownloadModal() {
        var chartDesc = this.currentChartDescriptor;
        this.send('showModal', 'components/download-modal', {
          chartName: chartDesc.name,
          askForHistorical: chartDesc.askForHistoricalDownload
        });
      }
    }
  });

  _exports.default = _default;
});