define("oi/constants/countries", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.CONTINENTS_TO_COUNTRIES = _exports.default = void 0;
  var countries = [{
    title: 'Afghanistan',
    abbr: 'AF'
  }, {
    title: 'Aland Islands',
    abbr: 'AX'
  }, {
    title: 'Albania',
    abbr: 'AL'
  }, {
    title: 'Algeria',
    abbr: 'DZ'
  }, {
    title: 'American Samoa',
    abbr: 'AS'
  }, {
    title: 'Andorra',
    abbr: 'AD'
  }, {
    title: 'Angola',
    abbr: 'AO'
  }, {
    title: 'Anguilla',
    abbr: 'AI'
  }, {
    title: 'Antarctica',
    abbr: 'AQ'
  }, {
    title: 'Antigua and Barbuda',
    abbr: 'AG'
  }, {
    title: 'Argentina',
    abbr: 'AR'
  }, {
    title: 'Armenia',
    abbr: 'AM'
  }, {
    title: 'Aruba',
    abbr: 'AW'
  }, {
    title: 'Australia',
    abbr: 'AU'
  }, {
    title: 'Austria',
    abbr: 'AT'
  }, {
    title: 'Azerbaijan',
    abbr: 'AZ'
  }, {
    title: 'Bahamas',
    abbr: 'BS'
  }, {
    title: 'Bahrain',
    abbr: 'BH'
  }, {
    title: 'Bangladesh',
    abbr: 'BD'
  }, {
    title: 'Barbados',
    abbr: 'BB'
  }, {
    title: 'Belarus',
    abbr: 'BY'
  }, {
    title: 'Belgium',
    abbr: 'BE'
  }, {
    title: 'Belize',
    abbr: 'BZ'
  }, {
    title: 'Benin',
    abbr: 'BJ'
  }, {
    title: 'Bermuda',
    abbr: 'BM'
  }, {
    title: 'Bhutan',
    abbr: 'BT'
  }, {
    title: 'Bolivia',
    abbr: 'BO'
  }, {
    title: 'Bonaire, Saint Eustatius and Saba',
    abbr: 'BQ'
  }, {
    title: 'Bosnia and Herzegovina',
    abbr: 'BA'
  }, {
    title: 'Botswana',
    abbr: 'BW'
  }, {
    title: 'Bouvet Island',
    abbr: 'BV'
  }, {
    title: 'Brazil',
    abbr: 'BR'
  }, {
    title: 'British Indian Ocean Territory',
    abbr: 'IO'
  }, {
    title: 'British Virgin Islands',
    abbr: 'VG'
  }, {
    title: 'Brunei',
    abbr: 'BN'
  }, {
    title: 'Bulgaria',
    abbr: 'BG'
  }, {
    title: 'Burkina Faso',
    abbr: 'BF'
  }, {
    title: 'Burundi',
    abbr: 'BI'
  }, {
    title: 'Cambodia',
    abbr: 'KH'
  }, {
    title: 'Cameroon',
    abbr: 'CM'
  }, {
    title: 'Canada',
    abbr: 'CA'
  }, {
    title: 'Cape Verde',
    abbr: 'CV'
  }, {
    title: 'Cayman Islands',
    abbr: 'KY'
  }, {
    title: 'Central African Republic',
    abbr: 'CF'
  }, {
    title: 'Chad',
    abbr: 'TD'
  }, {
    title: 'Chile',
    abbr: 'CL'
  }, {
    title: 'China',
    abbr: 'CN'
  }, {
    title: 'Christmas Island',
    abbr: 'CX'
  }, {
    title: 'Cocos Islands',
    abbr: 'CC'
  }, {
    title: 'Colombia',
    abbr: 'CO'
  }, {
    title: 'Comoros',
    abbr: 'KM'
  }, {
    title: 'Cook Islands',
    abbr: 'CK'
  }, {
    title: 'Costa Rica',
    abbr: 'CR'
  }, {
    title: 'Croatia',
    abbr: 'HR'
  }, {
    title: 'Cuba',
    abbr: 'CU'
  }, {
    title: 'Curacao',
    abbr: 'CW'
  }, {
    title: 'Cyprus',
    abbr: 'CY'
  }, {
    title: 'Czech Republic',
    abbr: 'CZ'
  }, {
    title: 'Democratic Republic of the Congo',
    abbr: 'CD'
  }, {
    title: 'Denmark',
    abbr: 'DK'
  }, {
    title: 'Djibouti',
    abbr: 'DJ'
  }, {
    title: 'Dominica',
    abbr: 'DM'
  }, {
    title: 'Dominican Republic',
    abbr: 'DO'
  }, {
    title: 'East Timor',
    abbr: 'TL'
  }, {
    title: 'Ecuador',
    abbr: 'EC'
  }, {
    title: 'Egypt',
    abbr: 'EG'
  }, {
    title: 'El Salvador',
    abbr: 'SV'
  }, {
    title: 'Equatorial Guinea',
    abbr: 'GQ'
  }, {
    title: 'Eritrea',
    abbr: 'ER'
  }, {
    title: 'Estonia',
    abbr: 'EE'
  }, {
    title: 'Ethiopia',
    abbr: 'ET'
  }, {
    title: 'Falkland Islands',
    abbr: 'FK'
  }, {
    title: 'Faroe Islands',
    abbr: 'FO'
  }, {
    title: 'Fiji',
    abbr: 'FJ'
  }, {
    title: 'Finland',
    abbr: 'FI'
  }, {
    title: 'France',
    abbr: 'FR'
  }, {
    title: 'French Guiana',
    abbr: 'GF'
  }, {
    title: 'French Polynesia',
    abbr: 'PF'
  }, {
    title: 'French Southern Territories',
    abbr: 'TF'
  }, {
    title: 'Gabon',
    abbr: 'GA'
  }, {
    title: 'Gambia',
    abbr: 'GM'
  }, {
    title: 'Georgia',
    abbr: 'GE'
  }, {
    title: 'Germany',
    abbr: 'DE'
  }, {
    title: 'Ghana',
    abbr: 'GH'
  }, {
    title: 'Gibraltar',
    abbr: 'GI'
  }, {
    title: 'Greece',
    abbr: 'GR'
  }, {
    title: 'Greenland',
    abbr: 'GL'
  }, {
    title: 'Grenada',
    abbr: 'GD'
  }, {
    title: 'Guadeloupe',
    abbr: 'GP'
  }, {
    title: 'Guam',
    abbr: 'GU'
  }, {
    title: 'Guatemala',
    abbr: 'GT'
  }, {
    title: 'Guernsey',
    abbr: 'GG'
  }, {
    title: 'Guinea',
    abbr: 'GN'
  }, {
    title: 'Guinea-Bissau',
    abbr: 'GW'
  }, {
    title: 'Guyana',
    abbr: 'GY'
  }, {
    title: 'Haiti',
    abbr: 'HT'
  }, {
    title: 'Heard Island and McDonald Islands',
    abbr: 'HM'
  }, {
    title: 'Honduras',
    abbr: 'HN'
  }, {
    title: 'Hong Kong',
    abbr: 'HK'
  }, {
    title: 'Hungary',
    abbr: 'HU'
  }, {
    title: 'Iceland',
    abbr: 'IS'
  }, {
    title: 'India',
    abbr: 'IN'
  }, {
    title: 'Indonesia',
    abbr: 'ID'
  }, {
    title: 'Iran',
    abbr: 'IR'
  }, {
    title: 'Iraq',
    abbr: 'IQ'
  }, {
    title: 'Ireland',
    abbr: 'IE'
  }, {
    title: 'Isle of Man',
    abbr: 'IM'
  }, {
    title: 'Israel',
    abbr: 'IL'
  }, {
    title: 'Italy',
    abbr: 'IT'
  }, {
    title: 'Ivory Coast',
    abbr: 'CI'
  }, {
    title: 'Jamaica',
    abbr: 'JM'
  }, {
    title: 'Japan',
    abbr: 'JP'
  }, {
    title: 'Jersey',
    abbr: 'JE'
  }, {
    title: 'Jordan',
    abbr: 'JO'
  }, {
    title: 'Kazakhstan',
    abbr: 'KZ'
  }, {
    title: 'Kenya',
    abbr: 'KE'
  }, {
    title: 'Kiribati',
    abbr: 'KI'
  }, {
    title: 'Kosovo',
    abbr: 'XK'
  }, {
    title: 'Kuwait',
    abbr: 'KW'
  }, {
    title: 'Kyrgyzstan',
    abbr: 'KG'
  }, {
    title: 'Laos',
    abbr: 'LA'
  }, {
    title: 'Latvia',
    abbr: 'LV'
  }, {
    title: 'Lebanon',
    abbr: 'LB'
  }, {
    title: 'Lesotho',
    abbr: 'LS'
  }, {
    title: 'Liberia',
    abbr: 'LR'
  }, {
    title: 'Libya',
    abbr: 'LY'
  }, {
    title: 'Liechtenstein',
    abbr: 'LI'
  }, {
    title: 'Lithuania',
    abbr: 'LT'
  }, {
    title: 'Luxembourg',
    abbr: 'LU'
  }, {
    title: 'Macao',
    abbr: 'MO'
  }, {
    title: 'Macedonia',
    abbr: 'MK'
  }, {
    title: 'Madagascar',
    abbr: 'MG'
  }, {
    title: 'Malawi',
    abbr: 'MW'
  }, {
    title: 'Malaysia',
    abbr: 'MY'
  }, {
    title: 'Maldives',
    abbr: 'MV'
  }, {
    title: 'Mali',
    abbr: 'ML'
  }, {
    title: 'Malta',
    abbr: 'MT'
  }, {
    title: 'Marshall Islands',
    abbr: 'MH'
  }, {
    title: 'Martinique',
    abbr: 'MQ'
  }, {
    title: 'Mauritania',
    abbr: 'MR'
  }, {
    title: 'Mauritius',
    abbr: 'MU'
  }, {
    title: 'Mayotte',
    abbr: 'YT'
  }, {
    title: 'Mexico',
    abbr: 'MX'
  }, {
    title: 'Micronesia',
    abbr: 'FM'
  }, {
    title: 'Moldova',
    abbr: 'MD'
  }, {
    title: 'Monaco',
    abbr: 'MC'
  }, {
    title: 'Mongolia',
    abbr: 'MN'
  }, {
    title: 'Montenegro',
    abbr: 'ME'
  }, {
    title: 'Montserrat',
    abbr: 'MS'
  }, {
    title: 'Morocco',
    abbr: 'MA'
  }, {
    title: 'Mozambique',
    abbr: 'MZ'
  }, {
    title: 'Myanmar',
    abbr: 'MM'
  }, {
    title: 'Namibia',
    abbr: 'NA'
  }, {
    title: 'Nauru',
    abbr: 'NR'
  }, {
    title: 'Nepal',
    abbr: 'NP'
  }, {
    title: 'Netherlands',
    abbr: 'NL'
  }, {
    title: 'Netherlands Antilles',
    abbr: 'AN'
  }, {
    title: 'New Caledonia',
    abbr: 'NC'
  }, {
    title: 'New Zealand',
    abbr: 'NZ'
  }, {
    title: 'Nicaragua',
    abbr: 'NI'
  }, {
    title: 'Niger',
    abbr: 'NE'
  }, {
    title: 'Nigeria',
    abbr: 'NG'
  }, {
    title: 'Niue',
    abbr: 'NU'
  }, {
    title: 'Norfolk Island',
    abbr: 'NF'
  }, {
    title: 'North Korea',
    abbr: 'KP'
  }, {
    title: 'Northern Mariana Islands',
    abbr: 'MP'
  }, {
    title: 'Norway',
    abbr: 'NO'
  }, {
    title: 'Oman',
    abbr: 'OM'
  }, {
    title: 'Pakistan',
    abbr: 'PK'
  }, {
    title: 'Palau',
    abbr: 'PW'
  }, {
    title: 'Palestinian Territory',
    abbr: 'PS'
  }, {
    title: 'Panama',
    abbr: 'PA'
  }, {
    title: 'Papua New Guinea',
    abbr: 'PG'
  }, {
    title: 'Paraguay',
    abbr: 'PY'
  }, {
    title: 'Peru',
    abbr: 'PE'
  }, {
    title: 'Philippines',
    abbr: 'PH'
  }, {
    title: 'Pitcairn',
    abbr: 'PN'
  }, {
    title: 'Poland',
    abbr: 'PL'
  }, {
    title: 'Portugal',
    abbr: 'PT'
  }, {
    title: 'Puerto Rico',
    abbr: 'PR'
  }, {
    title: 'Qatar',
    abbr: 'QA'
  }, {
    title: 'Republic of the Congo',
    abbr: 'CG'
  }, {
    title: 'Reunion',
    abbr: 'RE'
  }, {
    title: 'Romania',
    abbr: 'RO'
  }, {
    title: 'Russia',
    abbr: 'RU'
  }, {
    title: 'Rwanda',
    abbr: 'RW'
  }, {
    title: 'Saint Barthelemy',
    abbr: 'BL'
  }, {
    title: 'Saint Helena',
    abbr: 'SH'
  }, {
    title: 'Saint Kitts and Nevis',
    abbr: 'KN'
  }, {
    title: 'Saint Lucia',
    abbr: 'LC'
  }, {
    title: 'Saint Martin',
    abbr: 'MF'
  }, {
    title: 'Saint Pierre and Miquelon',
    abbr: 'PM'
  }, {
    title: 'Saint Vincent and the Grenadines',
    abbr: 'VC'
  }, {
    title: 'Samoa',
    abbr: 'WS'
  }, {
    title: 'San Marino',
    abbr: 'SM'
  }, {
    title: 'Sao Tome and Principe',
    abbr: 'ST'
  }, {
    title: 'Saudi Arabia',
    abbr: 'SA'
  }, {
    title: 'Senegal',
    abbr: 'SN'
  }, {
    title: 'Serbia',
    abbr: 'RS'
  }, {
    title: 'Serbia and Montenegro',
    abbr: 'CS'
  }, {
    title: 'Seychelles',
    abbr: 'SC'
  }, {
    title: 'Sierra Leone',
    abbr: 'SL'
  }, {
    title: 'Singapore',
    abbr: 'SG'
  }, {
    title: 'Sint Maarten',
    abbr: 'SX'
  }, {
    title: 'Slovakia',
    abbr: 'SK'
  }, {
    title: 'Slovenia',
    abbr: 'SI'
  }, {
    title: 'Solomon Islands',
    abbr: 'SB'
  }, {
    title: 'Somalia',
    abbr: 'SO'
  }, {
    title: 'South Africa',
    abbr: 'ZA'
  }, {
    title: 'South Georgia and the South Sandwich Islands',
    abbr: 'GS'
  }, {
    title: 'South Korea',
    abbr: 'KR'
  }, {
    title: 'South Sudan',
    abbr: 'SS'
  }, {
    title: 'Spain',
    abbr: 'ES'
  }, {
    title: 'Sri Lanka',
    abbr: 'LK'
  }, {
    title: 'Sudan',
    abbr: 'SD'
  }, {
    title: 'Suriname',
    abbr: 'SR'
  }, {
    title: 'Svalbard and Jan Mayen',
    abbr: 'SJ'
  }, {
    title: 'Swaziland',
    abbr: 'SZ'
  }, {
    title: 'Sweden',
    abbr: 'SE'
  }, {
    title: 'Switzerland',
    abbr: 'CH'
  }, {
    title: 'Syria',
    abbr: 'SY'
  }, {
    title: 'Taiwan',
    abbr: 'TW'
  }, {
    title: 'Tajikistan',
    abbr: 'TJ'
  }, {
    title: 'Tanzania',
    abbr: 'TZ'
  }, {
    title: 'Thailand',
    abbr: 'TH'
  }, {
    title: 'Togo',
    abbr: 'TG'
  }, {
    title: 'Tokelau',
    abbr: 'TK'
  }, {
    title: 'Tonga',
    abbr: 'TO'
  }, {
    title: 'Trinidad and Tobago',
    abbr: 'TT'
  }, {
    title: 'Tunisia',
    abbr: 'TN'
  }, {
    title: 'Turkey',
    abbr: 'TR'
  }, {
    title: 'Turkmenistan',
    abbr: 'TM'
  }, {
    title: 'Turks and Caicos Islands',
    abbr: 'TC'
  }, {
    title: 'Tuvalu',
    abbr: 'TV'
  }, {
    title: 'U.S. Virgin Islands',
    abbr: 'VI'
  }, {
    title: 'Uganda',
    abbr: 'UG'
  }, {
    title: 'Ukraine',
    abbr: 'UA'
  }, {
    title: 'United Arab Emirates',
    abbr: 'AE'
  }, {
    title: 'United Kingdom',
    abbr: 'GB'
  }, {
    title: 'United States',
    abbr: 'US'
  }, {
    title: 'United States Minor Outlying Islands',
    abbr: 'UM'
  }, {
    title: 'Uruguay',
    abbr: 'UY'
  }, {
    title: 'Uzbekistan',
    abbr: 'UZ'
  }, {
    title: 'Vanuatu',
    abbr: 'VU'
  }, {
    title: 'Vatican',
    abbr: 'VA'
  }, {
    title: 'Venezuela',
    abbr: 'VE'
  }, {
    title: 'Vietnam',
    abbr: 'VN'
  }, {
    title: 'Wallis and Futuna',
    abbr: 'WF'
  }, {
    title: 'Western Sahara',
    abbr: 'EH'
  }, {
    title: 'Yemen',
    abbr: 'YE'
  }, {
    title: 'Zambia',
    abbr: 'ZM'
  }, {
    title: 'Zimbabwe',
    abbr: 'ZW'
  }];

  var _default = countries.map(function (_ref) {
    var title = _ref.title,
        abbr = _ref.abbr;
    return {
      abbr: abbr,

      /* name: title, */
      title: "".concat(title, " (").concat(abbr, ")")
    };
  });

  _exports.default = _default;
  var CONTINENTS_TO_COUNTRIES = {
    // from cities_light, where Vladivostok is in Europe (because Russia is Europe) and all of Istanbul (Turkey) is in Asia
    Africa: new Set(['AO', 'BF', 'BI', 'BJ', 'BW', 'CD', 'CF', 'CG', 'CI', 'CM', 'CV', 'DJ', 'DZ', 'EG', 'EH', 'ER', 'ET', 'GA', 'GH', 'GM', 'GN', 'GQ', 'GW', 'KE', 'KM', 'LR', 'LS', 'LY', 'MA', 'MG', 'ML', 'MR', 'MU', 'MW', 'MZ', 'NA', 'NE', 'NG', 'RE', 'RW', 'SC', 'SD', 'SS', 'SH', 'SL', 'SN', 'SO', 'ST', 'SZ', 'TD', 'TG', 'TN', 'TZ', 'UG', 'YT', 'ZA', 'ZM', 'ZW']),
    Antarctica: new Set(['AQ', 'BV', 'GS', 'HM', 'TF']),
    Asia: new Set(['AE', 'AF', 'AM', 'AZ', 'BD', 'BH', 'BN', 'BT', 'CC', 'CN', 'CX', 'GE', 'HK', 'ID', 'IL', 'IN', 'IO', 'IQ', 'IR', 'JO', 'JP', 'KG', 'KH', 'KP', 'KR', 'KW', 'KZ', 'LA', 'LB', 'LK', 'MM', 'MN', 'MO', 'MV', 'MY', 'NP', 'OM', 'PH', 'PK', 'PS', 'QA', 'SA', 'SG', 'SY', 'TH', 'TJ', 'TM', 'TR', 'TW', 'UZ', 'VN', 'YE']),
    Europe: new Set(['AD', 'AL', 'AT', 'AX', 'BA', 'BE', 'BG', 'BY', 'CH', 'CY', 'CZ', 'DE', 'DK', 'EE', 'ES', 'FI', 'FO', 'FR', 'GB', 'GG', 'GI', 'GR', 'HR', 'HU', 'IE', 'IM', 'IS', 'IT', 'JE', 'XK', 'LI', 'LT', 'LU', 'LV', 'MC', 'MD', 'ME', 'MK', 'MT', 'NL', 'NO', 'PL', 'PT', 'RO', 'RS', 'RU', 'SE', 'SI', 'SJ', 'SK', 'SM', 'UA', 'VA', 'CS']),
    'North America': new Set(['AG', 'AI', 'AW', 'BB', 'BL', 'BM', 'BQ', 'BS', 'BZ', 'CA', 'CR', 'CU', 'CW', 'DM', 'DO', 'GD', 'GL', 'GP', 'GT', 'HN', 'HT', 'JM', 'KN', 'KY', 'LC', 'MF', 'MQ', 'MS', 'MX', 'NI', 'PA', 'PM', 'PR', 'SV', 'SX', 'TC', 'TT', 'US', 'VC', 'VG', 'VI', 'AN']),
    Oceania: new Set(['AS', 'AU', 'CK', 'FJ', 'FM', 'GU', 'KI', 'MH', 'MP', 'NC', 'NF', 'NR', 'NU', 'NZ', 'PF', 'PG', 'PN', 'PW', 'SB', 'TK', 'TL', 'TO', 'TV', 'UM', 'VU', 'WF', 'WS']),
    'South America': new Set(['AR', 'BO', 'BR', 'CL', 'CO', 'EC', 'FK', 'GF', 'GY', 'PE', 'PY', 'SR', 'UY', 'VE'])
  };
  _exports.CONTINENTS_TO_COUNTRIES = CONTINENTS_TO_COUNTRIES;
});