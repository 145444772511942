define("oi/helpers/vqf-option-is-selectable", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.vqfOptionIsSelectable = vqfOptionIsSelectable;
  _exports.default = void 0;

  function vqfOptionIsSelectable(selectOption, filters, rule) {
    var filterItem = filters.filter(function (item) {
      return item.id === rule;
    })[0];

    if (filterItem.operators === undefined || filterItem.operators.includes(selectOption)) {
      // every operator is permitted or operator is in whitelist
      return true;
    }

    return false;
  }

  var _default = Ember.Helper.helper(function (_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 3),
        selectOption = _ref2[0],
        filters = _ref2[1],
        operator = _ref2[2];

    return vqfOptionIsSelectable(selectOption, filters, operator);
  });

  _exports.default = _default;
});