define("oi/components/shipment/oi-taw-indicator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    statusIcon: Ember.computed('shipment.pod_timeofarrival_warning', function () {
      var taw = this.get('shipment.pod_timeofarrival_warning');

      if (taw) {
        return taw.get('icon');
      }

      return 'fa-question-circle';
    }),
    statusClass: Ember.computed('shipment.pod_timeofarrival_warning', function () {
      var taw = this.get('shipment.pod_timeofarrival_warning');

      if (taw) {
        var risk = taw.get('risk');

        switch (risk) {
          case 0:
            return 'text-success';

          case 1:
            return 'text-warning';

          case 2:
            return 'text-danger';

          default:
            return undefined;
        }
      }

      return 'text-secondary';
    }),
    description: Ember.computed('shipment.pod_timeofarrival_warning', function () {
      var taw = this.get('shipment.pod_timeofarrival_warning');

      if (taw) {
        var risk = taw.get('risk_verbose', 'Unknown Risk');

        if (taw.get('shipment.isArrived')) {
          risk = 'No risk';
        }

        var description = taw.get('description');
        return "".concat(risk, " - ").concat(description);
      }

      if (this.get('shipment.isArrived')) {
        return 'No risk - Shipment arrived at POD';
      }

      return 'Not available';
    })
  });

  _exports.default = _default;
});