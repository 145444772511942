define("oi/templates/components/shipment/oi-taw-indicator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "blmT/9fV",
    "block": "{\"symbols\":[],\"statements\":[[7,\"span\",true],[10,\"class\",\"fa-stack fa-xl hint--top-right\"],[11,\"aria-label\",[22,\"description\"]],[10,\"style\",\"margin-bottom: 2px;\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"shipment\",\"isArrived\"]]],null,{\"statements\":[[0,\"        \"],[7,\"i\",true],[10,\"class\",\"fa fa-check-circle fa-2x text-success\"],[10,\"aria-hidden\",\"true\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[7,\"i\",true],[11,\"class\",[29,[\"fa fa-clock-o fa-stack-2x \",[22,\"statusClass\"]]]],[10,\"aria-hidden\",\"true\"],[10,\"style\",\"opacity: 0.8;\"],[8],[9],[0,\"\\n        \"],[7,\"i\",true],[11,\"class\",[29,[\"fa fa-stack-1x \",[22,\"statusIcon\"],\" \",[22,\"statusClass\"]]]],[10,\"aria-hidden\",\"true\"],[10,\"style\",\"margin-top: 7px; margin-left: 11px;\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]}],[9],[0,\" \\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "oi/templates/components/shipment/oi-taw-indicator.hbs"
    }
  });

  _exports.default = _default;
});