define("oi/components/oi-preta-details", ["exports", "oi/components/date-with-time-tooltip"], function (_exports, _dateWithTimeTooltip) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _dateWithTimeTooltip.default.extend({
    datetimeFormat: 'YYYY-MM-DD HH:mm',
    location: Ember.computed.alias('prediction.milestone.location')
  });

  _exports.default = _default;
});