define("oi/templates/components/shipment/oi-milestones", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yBLAlT2a",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\" oi-milestones\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-md-12\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n      \"],[1,[28,\"oi-meta-badges\",null,[[\"shipment\",\"class\"],[[24,[\"shipment\"]],\"col-md-7\"]]],false],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"col-md-5\"],[8],[0,\"\\n        \"],[1,[28,\"oi-radio-button-group\",null,[[\"groupValue\",\"options\",\"buttonClassNames\",\"classNames\",\"aria-Label\"],[[24,[\"relDate\"]],[24,[\"condensedRawOptions\"]],\"btn btn-xs\",\"pull-right\",\"Date mode\"]]],false],[0,\"\\n      \"],[9],[0,\"\\n\"],[0,\"    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n      \"],[1,[28,\"oi-milestone-list\",null,[[\"locationsReversed\",\"predictions\",\"relDate\",\"service\",\"shipment\"],[[24,[\"shipment\",\"locationsReversed\"]],[24,[\"shipment\",\"predictions\"]],[24,[\"relDate\"]],[24,[\"shipment\",\"current_service\"]],[24,[\"shipment\"]]]]],false],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"col-md-12\"],[8],[0,\"\\n        \"],[7,\"p\",true],[10,\"class\",\"text-right small\"],[8],[0,\"\\n          Relative dates indicate difference to the \"],[7,\"em\",true],[8],[0,\"initially planned\"],[9],[0,\" date.\\n          The satellite icon indicates vessel actuals detected by (satellite)\\n          \"],[7,\"abbr\",true],[10,\"title\",\"Automatic Identification System\"],[8],[0,\"AIS\"],[9],[0,\".\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "oi/templates/components/shipment/oi-milestones.hbs"
    }
  });

  _exports.default = _default;
});