define("oi/routes/taskmanagement", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    queryParams: {
      page: {
        refreshModel: true
      },
      page_size: {
        refreshModel: true
      },
      status: {
        refreshModel: true
      },
      type: {
        refreshModel: true
      },
      appearence: {
        refreshModel: true
      }
    },
    model: function model(params) {
      return Ember.RSVP.hash({
        tasks: this.store.query('task', {
          status: params.status,
          page: params.page,
          page_size: params.page_size,
          type: params.type,
          appearence: params.appearence
        }),
        params: params
      });
    },
    actions: {
      refreshTasks: function refreshTasks() {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});