define("oi/templates/components/date-with-time-tooltip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "J8PV0WFt",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"innerClassNames\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\",true],[11,\"class\",[22,\"innerClassNames\"]],[8],[1,[28,\"if\",[[24,[\"datetime\"]],[28,\"format-date\",[[24,[\"datetime\"]]],null],[24,[\"fallback\"]]],null],false],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[28,\"if\",[[24,[\"datetime\"]],[28,\"format-date\",[[24,[\"datetime\"]]],null],[24,[\"fallback\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "oi/templates/components/date-with-time-tooltip.hbs"
    }
  });

  _exports.default = _default;
});