define("oi/models/download-info", ["exports", "ember-data", "jquery", "oi/config/environment"], function (_exports, _emberData, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    user: Ember.inject.service(),
    applicationService: Ember.inject.service(),
    status: _emberData.default.attr('string', {
      default: 'inProgress'
    }),
    message: _emberData.default.attr('string'),
    filename: _emberData.default.attr('string'),
    link: _emberData.default.attr('string'),
    attempts: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    download: function download(authHeader) {
      var filename = this.get('filename');

      _jquery.default.ajax({
        url: "".concat(this.applicationService.apiHost, "/media/cwa-output/").concat(this.get('filename')),
        type: 'GET',
        dataType: 'blob',
        processData: false,
        headers: authHeader,
        xhrFields: {
          withCredentials: _environment.default.APP.USE_AJAX_CALL_WITH_CREDENTIALS
        },
        success: function success(result) {
          return Ember.run.next(function () {
            /* IE11 does not support opening blob URLs portably
               * cf. https://stackoverflow.com/questions/36984907/ */
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
              window.navigator.msSaveOrOpenBlob(result, filename);
            } else {
              var objectUrl = URL.createObjectURL(result);
              var link = document.createElement('a');
              link.href = objectUrl;
              link.download = filename;
              document.body.appendChild(link);
              link.click();
              URL.revokeObjectURL(objectUrl);
              document.body.removeChild(link);
            }
          });
        },
        error: function error(jqXHR, textStatus) {
          this.set('status', 'error');
          this.set('message', textStatus);
        }
      });
    }
  });

  _exports.default = _default;
});