define("oi/components/oi-description", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    newDesc: '',
    // for comparison ("has it changed?") ->default on shipment is empty string
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments); // eslint-disable-line prefer-rest-params


      this.set('newDesc', this.desc);
    },
    actions: {
      update: function update() {
        this.set('desc', this.newDesc);
        return this.update();
      }
    }
  });

  _exports.default = _default;
});