define("oi/serializers/exceptionmanagement/template", ["exports", "oi/serializers/drf"], function (_exports, _drf) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _drf.default.extend({
    attrs: {
      rule: {
        serialize: true
      }
    },
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      var results = Ember.isArray(payload) ? payload : payload.results || [payload];
      results.forEach(function (result) {
        result.rule = JSON.parse(result.rule);
      });
      return this._super(store, primaryModelClass, payload, id, requestType);
    },
    serialize: function serialize() {
      var json = this._super.apply(this, arguments);

      json.rule = JSON.stringify(json.rule);
      return json;
    }
  });

  _exports.default = _default;
});