define("oi/initializers/main", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;
  var SUPRESSED_WARNINGS = ['ember-polyfills.deprecate-merge', // deprecated since 2.4 or so, unsure why
  // it popped up again after one of the 2.18 -> 3.4 -> 3.8 updates
  'ember-component.send-action', // affects modals here & oi-mapbox
  'computed-property.override', // since 3.8 seems to come from a package, which is included
  'computed-property.volatile', // since 3.8 seems to come from a package, which is included
  'ember-views.curly-components.jquery-element'];

  function initialize() {
    Ember.Debug.registerDeprecationHandler(function (message, options, next) {
      if (!options || !SUPRESSED_WARNINGS.includes(options.id)) {
        next(message, options);
      }
    });
  }

  var _default = {
    initialize: initialize
  };
  _exports.default = _default;
});