define("oi/adapters/leadtime", ["exports", "oi/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    pathForType: function pathForType() {
      return 'stats/leadtime';
    }
  });

  _exports.default = _default;
});