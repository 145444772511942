define("oi/components/tasks/links", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    notifications: Ember.inject.service('notify'),
    store: Ember.inject.service('store'),
    task: undefined,
    actions: {
      delete: function _delete(link) {
        if (link) {
          link.destroyRecord();
        }
      },
      addShipmentLink: function addShipmentLink(scope, shipment) {
        scope.get('actions.addLink')(scope, shipment, shipment.container_number, 'ctt', 'containershipment');
      },
      addSubscriptionLink: function addSubscriptionLink(scope, subscription) {
        scope.get('actions.addLink')(scope, subscription, subscription.request_key, 'ctt', 'containershipmentsubscription');
      },
      addLink: function addLink(scope, object, label, app, type) {
        var task = scope.get('task');

        if (object && task) {
          var link = scope.get('store').createRecord('link', {
            object_app: app,
            object_type: type,
            object_id: object.id,
            link_type: 0,
            label: label,
            task: task
          });

          if (!task.isNew) {
            link.save().then(function (t) {
              scope.notifications.success("Link ".concat(t.id, " was successfully created."), 'Link created');
            }).catch(function (ex) {
              scope.notifications.error(ex, 'Error deleting link');
            });
          }
        }
      },
      linkShipments: function linkShipments() {
        this.set('selectItemModel', {
          title: 'Select container shipments to link',
          placeholder: 'Search specific shipment...',
          model: 'shipment',
          valueKey: 'container_number',
          valueKeyLabel: 'Container-Number',
          selectActionLabel: 'Link Shipment',
          scope: this,
          selectItemCallbackHandler: this.get('actions.addShipmentLink'),
          closeDialogAfterCallback: false
        });
      },
      linkSubscriptions: function linkSubscriptions() {
        this.set('selectItemModel', {
          title: 'Select container shipment subscriptions to link',
          placeholder: 'Search for subscription ref. # or description',
          model: 'subscription',
          valueKey: 'request_key',
          valueKeyLabel: 'Request-Key',
          selectActionLabel: 'Link Subscription',
          scope: this,
          selectItemCallbackHandler: this.get('actions.addSubscriptionLink'),
          closeDialogAfterCallback: false
        });
      }
    }
  });

  _exports.default = _default;
});