define("oi/templates/components/exception-tile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/2dtZZ9Y",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"defaultException\"]]],null,{\"statements\":[[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"oi-exbtn\"],[11,\"aria-label\",[22,\"info\"]],[8],[0,\"\\n    \"],[7,\"p\",true],[8],[1,[22,\"defaultException\"],false],[0,\" \"],[7,\"i\",true],[10,\"class\",\"fa fa-info-circle\"],[10,\"aria-hidden\",\"true\"],[8],[9],[9],[0,\"\\n      \"],[1,[28,\"spinkit-spinner\",null,[[\"class\"],[\"exception-spinner\"]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"link-to\",null,[[\"params\"],[[28,\"dynamic-params\",[\"shipments\",[24,[\"exception\",\"query_params\"]]],null]]],{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"oi-exbtn\"],[8],[0,\"\\n      \"],[7,\"p\",true],[8],[1,[24,[\"exception\",\"title\"]],false],[0,\" \"],[7,\"i\",true],[10,\"class\",\"fa fa-info-circle\"],[10,\"aria-hidden\",\"true\"],[8],[9],[9],[0,\"\\n      \"],[1,[28,\"count-up\",null,[[\"startVal\",\"endVal\",\"duration\",\"formattingFn\"],[0,[24,[\"exception\",\"count\"]],1,[28,\"action\",[[23,0,[]],\"id\"],null]]]],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "oi/templates/components/exception-tile.hbs"
    }
  });

  _exports.default = _default;
});