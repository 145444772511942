define("oi/routes/index/exceptions/eventsummary", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    beforeModel: function beforeModel() {
      // route kept only to make old bookmarks to /#/ex/ev still work
      this.transitionTo('index.exceptions');
    }
  });

  _exports.default = _default;
});