define("oi/routes/subscriptions/new", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "ember-local-storage"], function (_exports, _authenticatedRouteMixin, _emberLocalStorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    titleToken: function titleToken() {
      return 'New Subscription';
    },
    prefs: (0, _emberLocalStorage.storageFor)('prefs'),
    model: function model() {
      return Ember.RSVP.hash({
        carriers: this.store.findAll('carrier', {
          backgroundReload: true
        }),
        tags: this.store.findAll('tag', {
          backgroundReload: true
        })
      });
    },
    // ---------------------------------------------------------------------------
    // Pass the model to the controller in order to make it accessible within
    // the template.
    // ---------------------------------------------------------------------------
    setupController: function setupController(controller, model) {
      controller.set('data', model);
      controller.set('errorMessage', null); // pre-fill the carrier_scac & the request_type in the controller according to
      // user's preferences; do not pre-fill if null

      controller.set('carrierScac', this.get('prefs.prefCarrierScac'));
      controller.set('requestType', this.get('prefs.prefRequestType'));
      controller.set('subAccountId', this.get('prefs.sub_account_id'));
    }
  });

  _exports.default = _default;
});