define("oi/templates/components/oi-podapproach-deprecated", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+4tDVxxf",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"alert alert-info\"],[10,\"role\",\"alert\"],[8],[0,\"\\n  For containers approaching the POD, please also check out our new \"],[5,\"link-to\",[],[[\"@route\"],[\"upcoming-arrivals\"]],{\"statements\":[[0,\"Upcoming Arrivals dashboard.\"]],\"parameters\":[]}],[0,\" It presents the same information in a more approachable fashion, allowing you to quickly identify the upcoming arrivals by port, country or continent for a period of your choice.\\n\"],[7,\"br\",true],[8],[9],[0,\"Which representation of containers approaching the POD do you prefer? Let us know at \"],[7,\"a\",true],[10,\"href\",\"mailto:support@ocean-insights.com\"],[8],[0,\"support@ocean-insights.com\"],[9],[0,\"!\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "oi/templates/components/oi-podapproach-deprecated.hbs"
    }
  });

  _exports.default = _default;
});