define("oi/helpers/flag", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function (_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
        countryOrLocode = _ref2[0],
        _ref2$ = _ref2[1],
        tooltipPosition = _ref2$ === void 0 ? 'top' : _ref2$;

    if (!countryOrLocode) {
      return undefined;
    }

    var countryCode = countryOrLocode.slice(0, 2);
    return Ember.String.htmlSafe("<span aria-label=\"".concat(countryCode, "\" class=\"flag flag-").concat(countryCode.toLowerCase(), " hint--").concat(tooltipPosition, "\"></span>"));
  });

  _exports.default = _default;
});