define("oi/components/oi-show-tags", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ShowTagsComponent = Ember.Component.extend({
    hasUndefinedValues: Ember.computed('tags.@each.{value}', function () {
      var tags = this.get('tags');

      if (tags) {
        return tags.any(function (tag) {
          return tag.value === undefined;
        });
      }

      return false;
    })
  });
  ShowTagsComponent.reopenClass({
    positionalParams: ['tags', 'brightTags']
  });
  var _default = ShowTagsComponent;
  _exports.default = _default;
});