define("oi/components/shared-link-actions", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    router: Ember.inject.service(),
    disabled: Ember.computed.or('link.isExpired', 'link.isRevoked'),
    hintClass: 'hint--bottom',
    generateUrl: function generateUrl(link) {
      var router = this.router;

      var _ref = link.get('shipment.id') ? ['shipment', link.get('shipment.id')] : ['subscription', link.get('subscription.id')],
          _ref2 = (0, _slicedToArray2.default)(_ref, 2),
          linkType = _ref2[0],
          id = _ref2[1];

      return "".concat(router.location.location.origin, "/").concat(router.urlFor(linkType, id, {
        queryParams: {
          share: link.get('token')
        }
      }));
    },
    actions: {
      toggleIsRevoked: function toggleIsRevoked(link) {
        link.toggleProperty('isRevoked');
        return link.save();
      },
      mailSharedLink: function mailSharedLink(link) {
        var containerNumber = link.get('shipment.container_number') || '';
        var label = containerNumber ? 'container' : 'subscription';
        var key = containerNumber || link.get('subscription.request_key'); // eslint-disable-next-line max-len

        var subject = "".concat(label.charAt(0).toUpperCase() + label.slice(1), " Status for ").concat(key, " - Project44");
        var body = "Hello, %0D%0A\n%0D%0A\nI would like to share with you the status of ".concat(key ? '' : 'a ').concat(label, " ").concat(key, " via this link to the project44 Container Track %26 Trace system: ").concat(encodeURIComponent(this.generateUrl(link)), ". %0D%0A\nThis page will be updated continuously during the ").concat(label, " journey.");
        window.open("mailto:?subject=".concat(subject, "&body=").concat(body));
      },
      copySharedLink: function copySharedLink(link) {
        var el = document.createElement('textarea');
        el.value = this.generateUrl(link);
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
      }
    }
  });

  _exports.default = _default;
});