define("oi/components/visual-query-filter/rule", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var OPERATOR_OPTIONS = ['equal', 'not_equal', 'in', 'not_in', 'less', 'less_or_equal', 'greater', 'greater_or_equal', 'between', 'not_between', 'is_null', 'is_not_null'];

  var _default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);
      /* eslint no-unused-expressions: ["error", { "allowShortCircuit": true }] */


      this.rule.rules.length === 0 && this.set('open', true);
    },
    operatorOptions: OPERATOR_OPTIONS,
    open: false,
    openRemove: false
  });

  _exports.default = _default;
});