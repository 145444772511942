define("oi/components/oi-mapboxgl-map", ["exports", "@babel/runtime/helpers/esm/objectSpread2", "moment", "jquery", "oi/config/environment"], function (_exports, _objectSpread2, _moment, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.DIRECTION_NAMES = void 0;

  /* global mapboxgl */
  var DIRECTION_NAMES = ['N', 'NNE', 'NE', 'ENE', 'E', 'ESE', 'SE', 'SSE', 'S', 'SSW', 'SW', 'WSW', 'W', 'WNW', 'NW', 'NNW'];
  _exports.DIRECTION_NAMES = DIRECTION_NAMES;
  var DEGREES_PER_DIRECTION = 360.0 / DIRECTION_NAMES.length;
  var CARDINAL_DIRECTIONS = {}; // Object.fromEntries would require polyfill for IE11

  DIRECTION_NAMES.forEach(function (direction, index) {
    CARDINAL_DIRECTIONS[direction] = Math.floor(index * DEGREES_PER_DIRECTION);
  });
  var FUTURE_TRACK_COLORS = ['#FADB5F', '#DE911D', '#FADB5F', '#DE911D', '#FADB5F'];

  function degToCompass(degrees) {
    /**
     * Helper to convert a degree value to a cardinal direction as String
     */
    var index = Math.floor(degrees / DEGREES_PER_DIRECTION + 0.5);
    return DIRECTION_NAMES[index % DIRECTION_NAMES.length];
  }

  function addPopUp(map, id) {
    // When a click event occurs on a feature in the places layer, open a popup at the
    // location of the feature, with description HTML from its properties.
    map.on('click', id, function (e) {
      var coordinates = e.features[0].geometry.coordinates.slice();
      var description = e.features[0].properties.description; // Ensure that if the map is zoomed out such that multiple
      // copies of the feature are visible, the popup appears
      // over the copy being pointed to.

      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
      }

      new mapboxgl.Popup().setLngLat(coordinates).setHTML(description).addTo(map);
    }); // Change the cursor to a pointer when the mouse is over the states layer.

    map.on('mouseenter', id, function () {
      map.getCanvas().style.cursor = 'pointer';
    });
  }

  function geoJsonSource(map, id, features, sourceType, style) {
    /**
     * append geoJsonSource Object with its layer to the map
     */
    var data = {
      type: 'FeatureCollection',
      features: features
    };

    if (sourceType === 'line') {
      data = {
        type: 'Feature',
        geometry: features.geometry
      };
    }

    if (!id.toLowerCase().includes('connection')) {
      addPopUp(map, id);
    }

    return map.addLayer((0, _objectSpread2.default)({
      id: id,
      type: sourceType,
      source: {
        type: 'geojson',
        data: data
      }
    }, style));
  }

  function geoJsonLayer(item) {
    var markerType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'vessel';
    var geometryType = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'Point';

    /**
     * create geoJson Object for the layer
     */
    var description;
    var coordinates = item.longitude && item.latitude ? [item.longitude, item.latitude] : item;

    if (markerType === 'vessel') {
      description = "Received: ".concat(_moment.default.utc(item.pos_datetime).format('YYYY-MM-DD HH:mm'), " UTC <br/>\n                   Speed: ").concat(item.vessel_speed_over_ground || 0, " knots");
    } else if (markerType === 'land') {
      description = "Received: ".concat(_moment.default.utc(item.pos_datetime).format('YYYY-MM-DD HH:mm'), " UTC <br/>\n                   Land transport @ ").concat(item.pos_name);
    } else if (markerType === 'port') {
      description = item.title;
    } else if (markerType === 'futureTrackPort') {
      description = "".concat(item.vesselName, " port of call ").concat(item.name);
    }

    return {
      type: 'Feature',
      geometry: {
        type: geometryType,
        coordinates: coordinates
      },
      properties: {
        description: description
      }
    };
  }

  var _default = Ember.Component.extend({
    user: Ember.inject.service('user'),
    map: null,
    visibleOceanTrack: true,
    visibleFutureTrack: true,
    visibleLandTrack: true,
    markerIds: Ember.computed('categorizedMovingMarkers', function () {
      var ids = ['oceanMooredMarkers', 'liveMarker', 'oceanConnections'];
      var movingMarkerIds = Object.keys(this.categorizedMovingMarkers);
      var portIds = this.ports.map(function (port) {
        return port.url;
      });
      var futureTrackIds = this.futureMarkers.map(function (e, idx) {
        return "futureMarkers-".concat(idx);
      });
      var futureConnectionIds = this.futureMarkerConnections.map(function (e, idx) {
        return "futureConnections-".concat(idx);
      });
      var landMarkerIds = this.landTracks.map(function (e, idx) {
        return "landMarkers-".concat(idx);
      });
      var landConnectionIds = this.landTracks.map(function (e, idx) {
        return "landConnections-".concat(idx);
      });
      ids = ids.concat(movingMarkerIds, portIds, futureTrackIds, futureConnectionIds, landMarkerIds, landConnectionIds);
      return ids;
    }),
    bounds: Ember.computed('markers', 'trackWith180Correction', 'futureMarkerConnections', function () {
      var bounds = new mapboxgl.LngLatBounds();
      this.futureMarkerConnections.forEach(function (track) {
        return track.forEach(function (position) {
          return bounds.extend(position);
        });
      });
      var worldMove = this.futureTrackWorldMove !== null ? this.futureTrackWorldMove : this.vesselTrackWorldMove; // if vessel track contains 180° lon crossing ("world move") and future track
      // does not, the future track is on the "wrong" world. Correct for this:

      if (bounds.getNorthEast() && !this.futureTrackWorldMove && this.vesselTrackWorldMove) {
        var ne = bounds.getNorthEast();
        bounds.setNorthEast([ne.lng + worldMove, ne.lat]);
        var sw = bounds.getSouthWest();
        bounds.setSouthWest([sw.lng + worldMove, sw.lat]);
      }

      this.trackWith180Correction.forEach(function (record) {
        return bounds.extend([record.longitude, record.latitude]);
      });
      var firstLoc = this.markers[0];
      bounds.extend([firstLoc.coordinates[1], firstLoc.coordinates[0]]); // Use only first and last marker for boundaries, hoping that they will be
      // the most extreme points, or that the vessel or future track will already
      // contain the most extreme points (anything originating at or delivered to
      // Chicago (USCHI) will be a good example). A better but more complex option
      // would be to find between which markers the "world move" occurred...
      // (null vs 0 check above to cover cases where track goes across the 180° lon
      // line _and back_, although untested for lack of actual examples)

      var lastLoc = this.markers[this.markers.length - 1];
      bounds.extend([lastLoc.coordinates[1] + worldMove, lastLoc.coordinates[0]]);
      return [
      /* eslint-disable no-underscore-dangle */
      // mapboxgl's name choice, not ours
      [bounds._ne.lng + 5, bounds._ne.lat + 5], [bounds._sw.lng - 5, bounds._sw.lat - 5]];
      /* eslint-enable no-underscore-dangle */
    }),
    ports: Ember.computed('markers', function () {
      return this.markers.map(function (item) {
        return {
          layer: [geoJsonLayer(item, 'port')],
          url: item.iconUrl
        };
      });
    }),
    vesselTrack: Ember.computed('markers', 'trackWith180Correction', function () {
      var _this2 = this;

      var vesselTrack = this.trackWith180Correction.filter(function (item) {
        return item.transport_mode === 10;
      }); // append Markers from pol and to pod (only when container at least has arrived at pol)

      if (this.shipmentStatus >= 12) {
        this.markers.forEach(function (marker) {
          if (['pod', 'poddlv', 'pol', 'oripol'].includes(marker.abbr)) {
            var trackItem = {
              longitude: marker.longitude,
              latitude: marker.latitude,
              vessel_speed_over_ground: null,
              vessel_true_heading: null
            };

            if (['pol', 'oripol'].includes(marker.abbr)) {
              vesselTrack.unshift(trackItem);
            } else if (_this2.lifecycleStatus !== 0) {
              trackItem.longitude = marker.longitude + _this2.vesselTrackWorldMove;
              vesselTrack.push(trackItem);
            }
          }
        });
      }

      return vesselTrack;
    }),
    categorizedMovingMarkers: Ember.computed('vesselMarkers.moving', function () {
      var directions = {};
      var markers = this.vesselMarkers.moving;
      markers.forEach(function (item, idx) {
        // handle case when ais marker has no heading
        if (item.vessel_true_heading === null) {
          if (markers[idx + 1] !== undefined && typeof markers[idx + 1].vessel_true_heading === 'number') {
            item.vessel_true_heading = markers[idx + 1].vessel_true_heading;
          } else if (markers[idx - 1] !== undefined && typeof markers[idx - 1].vessel_true_heading === 'number') {
            item.vessel_true_heading = markers[idx - 1].vessel_true_heading;
          }
        }

        var heading = degToCompass(item.vessel_true_heading);
        var directionArray = directions[heading];

        if (directionArray) {
          directionArray.push(geoJsonLayer(item, 'vessel'));
        } else {
          directions[heading] = [geoJsonLayer(item, 'vessel')];
        }
      });
      return directions;
    }),
    mooredMarkers: Ember.computed('vesselMarkers.moored', function () {
      var mooredMarkers = [];
      this.vesselMarkers.moored.forEach(function (item) {
        mooredMarkers.push(geoJsonLayer(item, 'vessel'));
      });
      return mooredMarkers;
    }),
    vesselTrackWorldMove: null,
    // -360 when we crossed the 180° line from America to Asia,
    // +360 when we crossed the 180° line from Asia (or Australia) to America,
    // null when we didn't, 0 when we crossed an even number of times ;-)
    // (note that null behaves like 0 in addition & subtraction)
    _checkWorldMove: function _checkWorldMove(previousPosition, nextPosition) {
      var longitudeDiff = !previousPosition ? 0 : nextPosition.longitude - previousPosition.longitude;

      if (longitudeDiff > 180) {
        this.vesselTrackWorldMove -= 360;
      } else if (longitudeDiff < -180) {
        this.vesselTrackWorldMove += 360;
      }
    },
    trackWith180Correction: Ember.computed('track.[]', 'markers.[]', function () {
      var _this3 = this;

      this.vesselTrackWorldMove = null;
      var previousPosition = this.markers.reverse().find(function (marker) {
        return ['pol', 'oripol', 'ori'].includes(marker.abbr);
      });
      var correctedTrack = this.track.map(function (trackPosition) {
        _this3._checkWorldMove(previousPosition, trackPosition);

        previousPosition = trackPosition;
        return !_this3.vesselTrackWorldMove ? trackPosition : Ember.assign({}, trackPosition, {
          longitude: trackPosition.longitude + _this3.vesselTrackWorldMove
        });
      });
      var podOrDlv = this.markers.find(function (marker) {
        return ['pod', 'poddlv', 'dlv'].includes(marker.abbr);
      });

      if (podOrDlv) {
        this._checkWorldMove(previousPosition, podOrDlv);
      }

      return correctedTrack;
    }),
    oceanMarkerConnections: Ember.computed('vesselTrack', function () {
      return this.vesselTrack.map(function (position) {
        return [position.longitude, position.latitude];
      });
    }),
    vesselMarkers: Ember.computed('vesselTrack', function () {
      var movingMarkers = [];
      var mooredMarkers = [];
      var oceanTrack = this.vesselTrack.filter(function (track) {
        return track.pos_source === 10 && track.vessel_speed_over_ground !== null;
      });
      oceanTrack.forEach(function (item, index) {
        if (item.vessel_speed_over_ground >= 0.5) {
          if (item.vessel_true_heading === null) {
            if (oceanTrack[item - 1]) {
              item.vessel_true_heading = oceanTrack[index - 1].vessel_true_heading;
            } else if (oceanTrack[index + 1]) {
              item.vessel_true_heading = oceanTrack[index + 1].vessel_true_heading;
            }
          }

          movingMarkers.push(item);
        } else if (item.vessel_speed_over_ground < 0.5) mooredMarkers.push(item);
      });
      return {
        moored: mooredMarkers,
        moving: movingMarkers
      };
    }),
    currentPostion: Ember.computed('trackWith180Correction', function () {
      if (this.lifecycleStatus === 0 && this.trackWith180Correction.length > 0) {
        var position = this.trackWith180Correction[this.trackWith180Correction.length - 1];
        return [geoJsonLayer(position, 'vessel')];
      }

      return null;
    }),
    futureMarkers: Ember.computed('scheduledTracks', function () {
      return this.scheduledTracks.map(function (leg) {
        return leg.filter(function (item) {
          return item.portid !== null;
        });
      });
    }),
    futureTrackWorldMove: null,
    // cf. vesselTrackWorldMove above
    futureMarkerConnections: Ember.computed('scheduledTracks', function () {
      var _this4 = this;

      return this.scheduledTracks.map(function (leg) {
        if (leg.length === 0) {
          return [];
        }

        var worldMoveBefore = _this4.futureTrackWorldMove; // There may be a side effect in a function passed to .map. Sorry about that. :-/

        var endLon = leg[leg.length - 1].longitude;

        if (endLon < -180) {
          _this4.futureTrackWorldMove -= 360;
        } else if (endLon > 180) {
          _this4.futureTrackWorldMove += 360;
        }

        return leg.map(function (pos) {
          return [pos.longitude + worldMoveBefore, pos.latitude];
        });
      });
    }),
    landTracks: Ember.computed('trackWith180Correction', function () {
      var landTracks = [];
      var currentLandTrack = [];
      this.trackWith180Correction.forEach(function (position) {
        if (position.transport_mode !== 10) {
          // transport_mode 0->street, 1->rail, 2->inland water, 10->ocean
          currentLandTrack.push(geoJsonLayer(position, 'land'));
        } else if (currentLandTrack.length) {
          // ocean leg after land transport => use new array for subsequent land
          // transport so initial and final land segments are not connected
          landTracks.push(currentLandTrack);
          currentLandTrack = [];
        }
      });

      if (currentLandTrack.length) {
        landTracks.push(currentLandTrack);
      }

      return landTracks;
    }),
    landMarkerConnections: Ember.computed('landTracks', function () {
      return this.landTracks.map(function (landTrack) {
        return landTrack.map(function (position) {
          return position.geometry.coordinates;
        });
      });
    }),
    isChineseClient: function isChineseClient() {
      if (this.chineseMap) {
        return true;
      }

      var features = this.user.session.data.features; // check if the active master account has chineseFeatureEnabled or if the navigator language is set to chinese

      if (features && features.chinese_map) {
        var chineseFeatureEnabled = features.chinese_map.feature_enabled;
        return chineseFeatureEnabled || navigator.languages.some(function (language) {
          return language.includes('zh');
        });
      }

      return false;
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments); // eslint-disable-line prefer-rest-params


      mapboxgl.accessToken = _environment.default['mapbox-gl'].accessToken;
      var map;

      try {
        var style = this.isChineseClient() ? 'mapbox://styles/oceaninsights/cl4sevc9f000l16qmuwcq2us1?optimize=true' : 'mapbox://styles/oceaninsights/cjosetrc003c02rmkempfjix2?optimize=true';
        map = new mapboxgl.Map({
          container: this.divId,
          style: style,
          center: [0, 0],
          zoom: 2,
          maxZoom: 12,
          minZoom: 1
        });
      } catch (error) {
        (0, _jquery.default)('#map').css('height', '20px');
        var additionalInformation = '<a target="_blank" href="https://wevideo.zendesk.com/hc/en-us/articles/225259448-How-to-enable-WebGL">' + ' See this site <i class="fa fa-external-link"></i></a> on how to enable WebGL in your browser if it is not already.';
        this.set('mapboxError', Ember.String.htmlSafe("Sorry, you need a browser that supports WebGL to view the map.           (".concat(error, "). <br> ").concat(additionalInformation)));
        throw error;
      }
      /* eslint no-underscore-dangle: 0 */


      var _this = this;

      map.on('load', function () {
        _this.mapLoaded(this);
      });
    },
    movingVesselMarkers: function movingVesselMarkers() {
      var _this5 = this;

      this.map.loadImage('images/triangle-marker.png', function (error, image) {
        if (error) throw error;

        _this5.map.addImage('icon-moving', image);

        Object.keys(_this5.categorizedMovingMarkers).forEach(function (index) {
          if (_this5.categorizedMovingMarkers[index].length > 0) {
            var layout = {
              layout: {
                'icon-size': 0.6,
                'icon-rotate': CARDINAL_DIRECTIONS[index],
                'icon-image': 'icon-moving'
              }
            };
            geoJsonSource(_this5.map, index, _this5.categorizedMovingMarkers[index], 'symbol', layout);
          }
        });
      });
    },
    mooredVesselMarkers: function mooredVesselMarkers() {
      var _this6 = this;

      this.map.loadImage('images/circle-blue.png', function (error, image) {
        if (error) throw error;
        var id = 'oceanMooredMarkers';
        var layout = {
          layout: {
            'icon-size': 0.18,
            'icon-image': id
          }
        };

        _this6.map.addImage(id, image);

        geoJsonSource(_this6.map, id, _this6.mooredMarkers, 'symbol', layout);
      });
    },
    drawOceanMarkerConnections: function drawOceanMarkerConnections() {
      var style = {
        paint: {
          'line-color': '#1992D4',
          'line-width': 3,
          'line-opacity': 0.8
        }
      };
      var connection = this.oceanMarkerConnections;
      var connectionLayer = geoJsonLayer(connection, 'connection', 'LineString');
      geoJsonSource(this.map, 'oceanConnections', connectionLayer, 'line', style);
    },
    drawPortMarkers: function drawPortMarkers() {
      var _this7 = this;

      this.ports.forEach(function (port) {
        _this7.map.loadImage(port.url, function (error, image) {
          if (error) throw error;

          _this7.map.addImage(port.url, image);

          var layout = {
            layout: {
              'icon-allow-overlap': true,
              'icon-size': 0.8,
              'icon-anchor': 'bottom',
              'icon-offset': [0, -3],
              'icon-image': port.url
            }
          };
          geoJsonSource(_this7.map, port.url, port.layer, 'symbol', layout);
        });
      });
    },
    drawPulsingDot: function drawPulsingDot() {
      var item = this.trackWith180Correction[this.trackWith180Correction.length - 1];
      var el = document.createElement('div');
      el.className = 'ring-container';
      var circle = document.createElement('div');
      circle.className = 'circle';
      var ringring = document.createElement('div');
      ringring.className = 'ringring';
      el.appendChild(circle);
      el.appendChild(ringring); // add marker to map

      new mapboxgl.Marker(el).setLngLat([item.longitude, item.latitude]).setPopup(new mapboxgl.Popup({
        offset: 0
      }).setHTML("Received: ".concat(_moment.default.utc(item.pos_datetime).format('YYYY-MM-DD HH:mm'), " UTC <br/>\n              Speed: ").concat(item.vessel_speed_over_ground || 0, " knots"))).addTo(this.map);
    },
    drawLiveMarkerIcon: function drawLiveMarkerIcon() {
      var _this8 = this;

      this.map.loadImage('images/icon-container.png', function (error, image) {
        if (error) throw error;
        var id = 'liveMarker';
        var layout = {
          layout: {
            'icon-size': 0.8,
            'icon-anchor': 'bottom',
            'icon-offset': [0, -12],
            'icon-image': id
          }
        };

        _this8.map.addImage(id, image);

        geoJsonSource(_this8.map, id, _this8.currentPostion, 'symbol', layout);
      });
    },
    drawLandMarkers: function drawLandMarkers() {
      var _this9 = this;

      this.map.loadImage('images/circle-purple.png', function (error, image) {
        if (error) throw error;
        var layout = {
          layout: {
            'icon-size': 0.18,
            'icon-image': 'landMarkers'
          }
        };

        _this9.map.addImage('landMarkers', image);

        _this9.landTracks.forEach(function (landTrack, idx) {
          var id = "landMarkers-".concat(idx);
          geoJsonSource(_this9.map, id, landTrack, 'symbol', layout);
        });
      });
    },
    drawLandMarkerConnections: function drawLandMarkerConnections() {
      var _this10 = this;

      var style = {
        paint: {
          'line-color': '#870557',
          'line-width': 3,
          'line-dasharray': [3, 1],
          'line-opacity': 0.8
        }
      };
      this.landMarkerConnections.forEach(function (connection, idx) {
        var connectionLayer = geoJsonLayer(connection, 'connection', 'LineString');
        geoJsonSource(_this10.map, "landConnections-".concat(idx), connectionLayer, 'line', style);
      });
    },
    drawFutureMarkers: function drawFutureMarkers() {
      var _this11 = this;

      this.futureMarkers.forEach(function (leg, index) {
        var id = "futureMarkers-".concat(index);
        var style = {
          paint: {
            'circle-color': FUTURE_TRACK_COLORS[index],
            'circle-radius': 5
          }
        };
        var legLayers = [];
        leg.forEach(function (port) {
          port.vesselName = _this11.vesselNames[index];
          legLayers.push(geoJsonLayer(port, 'futureTrackPort', 'Point'));
        });
        geoJsonSource(_this11.map, id, legLayers, 'circle', style);
      });
    },
    drawFutureMarkerConnections: function drawFutureMarkerConnections() {
      var _this12 = this;

      this.futureMarkerConnections.forEach(function (leg, index) {
        var id = "futureConnections-".concat(index);
        var style = {
          paint: {
            'line-color': FUTURE_TRACK_COLORS[index],
            'line-width': 3,
            'line-opacity': 0.8
          }
        };
        var connectionLayer = geoJsonLayer(leg, 'connection', 'LineString');
        geoJsonSource(_this12.map, id, connectionLayer, 'line', style);
      });
    },
    drawMap: function drawMap() {
      this.drawFutureMarkers();
      this.drawFutureMarkerConnections();
      this.drawLandMarkers();
      this.drawLandMarkerConnections();
      this.movingVesselMarkers();
      this.mooredVesselMarkers();
      this.drawOceanMarkerConnections();
      this.drawPortMarkers();

      if (this.lifecycleStatus === 0 && this.trackWith180Correction.length > 0) {
        this.drawLiveMarkerIcon();
        this.drawPulsingDot();
      }
    },
    fitBounds: function fitBounds() {
      this.map.fitBounds(this.bounds);
    },
    mapLoaded: function mapLoaded(e) {
      if (this.isDestroyed) {
        // user went elsewhere in the meantime (other tab, other route)...
        return; // ...so at least prevent console error "calling set on destroyed object"
      }

      this.set('map', e);
      e.addControl(new mapboxgl.NavigationControl({
        showCompass: false
      }));
      e.dragRotate.disable();

      if (this.trackWith180Correction.length === 0) {
        this.addObserver('trackWith180Correction', function () {
          this.drawMap();
          this.fitBounds();
        });
      } else {
        this.drawMap();
        this.fitBounds();
      }
    },
    actions: {
      popup: function popup(e) {
        var coordinates = e.features[0].geometry.coordinates.slice();
        var description = e.features[0].properties.description; // Ensure that if the map is zoomed out such that multiple
        // copies of the feature are visible, the popup appears
        // over the copy being pointed to.

        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }

        new mapboxgl.Popup().setLngLat(coordinates).setHTML(description).addTo(e.target);
      },
      mouseenter: function mouseenter(e) {
        e.target.getCanvas().style.cursor = 'pointer';
      },
      mouseleave: function mouseleave(e) {
        e.target.getCanvas().style.cursor = 'grab';
      }
    }
  });

  _exports.default = _default;
});