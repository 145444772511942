define("oi/components/oi-mapboxgl-legend", ["exports", "oi/components/oi-mapboxgl-map"], function (_exports, _oiMapboxglMap) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    oceanIsVisible: true,
    futureIsVisible: true,
    landIsVisible: true,
    futureTrackLegend: Ember.computed('futureTracks.@each.length', function () {
      var availableFutureLegs = this.futureTracks.map(function (track, index) {
        return track.length ? index + 1 : null;
      }).filter(function (x) {
        return x !== null;
      }); // leg indices as shown to user: count starts at 1

      if (availableFutureLegs.length === 0) {
        return null;
      }

      var hasOdd = availableFutureLegs.find(function (legIndex) {
        return legIndex % 2;
      });
      var hasEven = availableFutureLegs.find(function (legIndex) {
        return legIndex % 2 === 0;
      }); // if one leg is an intermediate land transport, we may have _multiple_ future
      // tracks but still all in the same color (e.g. leg 1 to PAONX, leg 3 from PABLB).

      var img = hasOdd && hasEven ? 'legend-future-multiple.png' : "legend-future-".concat(hasEven ? 0 : 1, ".png");
      var legendText = 'Typical route and port for leg';

      if (availableFutureLegs.length === 1) {
        return {
          img: img,
          text: "".concat(legendText, " ").concat(availableFutureLegs[0])
        };
      }

      return {
        img: img,
        flip: availableFutureLegs[0] % 2 === 0,
        text: "".concat(legendText, "s ").concat(availableFutureLegs.join(', '))
      };
    }),
    actions: {
      toggleVisibility: function toggleVisibility(trackType) {
        var _this = this;

        var toggleableMarkers = this.markerIds.filter(function (value) {
          return value.startsWith(trackType) || // edge case: moving markers which have id of a cardinal-direction:
          trackType === 'ocean' && _oiMapboxglMap.DIRECTION_NAMES.includes(value);
        });
        var propertyName = "".concat(trackType, "IsVisible");
        var visibility = this.get(propertyName) ? 'none' : 'visible';
        toggleableMarkers.forEach(function (value) {
          _this.map.setLayoutProperty(value, 'visibility', visibility);
        });
        this.toggleProperty(propertyName);
      }
    }
  });

  _exports.default = _default;
});