define("oi/components/confirm-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    title: 'foo',
    id: null,
    actionIsImmediate: false,
    actions: {
      ok: function ok() {
        if (this.actionIsImmediate) {
          this.$('.modal').modal('hide');
        } else {
          this.$('.modal').find('.modal-body').text('Talking to the server...'); // modal close/hide currently handled separately by caller after "ok"
          // action; TODO: check if action returns promise, handle close/hide here
        } // eslint-disable-next-line ember/closure-actions


        this.sendAction('ok', this.context);
      }
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      if (!this.noAutoClose) {
        this.$('.modal').modal().on('hidden.bs.modal', function () {
          // eslint-disable-next-line ember/closure-actions
          _this.sendAction('close');
        });
      }
    } // didRender() { // TODO: investigate why this does not seem to work
    //   const inputElement = this.get('element').querySelector('input');
    //   if (inputElement) { inputElement.focus(); }
    // },

  });

  _exports.default = _default;
});