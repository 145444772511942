define("oi/templates/components/oi-document-download", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "g/BsT7GJ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"a\",true],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"download\",[24,[\"document\"]]],null]],[10,\"role\",\"button\"],[8],[0,\"\\n  \"],[7,\"i\",true],[10,\"class\",\"fa fa-file-o\"],[10,\"aria-hidden\",\"true\"],[8],[9],[0,\"\\n  \"],[1,[24,[\"document\",\"target\"]],false],[0,\"\\n\"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"isDownloading\"]]],null,{\"statements\":[[1,[28,\"spinkit-spinner\",null,[[\"class\"],[\"small\"]]],false]],\"parameters\":[]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "oi/templates/components/oi-document-download.hbs"
    }
  });

  _exports.default = _default;
});