define("oi/components/condensed-voyage-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'table',
    classNames: 'table oi-nu-table even-odd',
    anyHasDescription: Ember.computed('voyages.@each.descriptions', function () {
      return this.voyages.find(function (voyage) {
        return voyage.get('descriptions.length');
      });
    })
  });

  _exports.default = _default;
});