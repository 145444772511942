define("oi/components/oi-accounts-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* global  _paq */
  var _default = Ember.Component.extend({
    tagName: 'li',
    session: Ember.inject.service(),
    user: Ember.inject.service(),
    store: Ember.inject.service(),
    router: Ember.inject.service('router'),
    currentRouteName: Ember.computed.alias('router.currentRouteName'),
    selectedValue: Ember.computed('value', 'options.[]', {
      get: function get() {
        var options = this.options,
            value = this.value;

        if (!value || !options || !options.length) {
          return [];
        }

        return options.filter(function (el) {
          return value.includes(el);
        });
      },
      set: function set(key, selectedModelInstances) {
        var _this = this;

        var newValues = selectedModelInstances;
        if (selectedModelInstances.length === 0) return this.selectedValue; // if user has selected a new master account, so this one will be used and all other choices will be resetted

        var newMasterAccount = selectedModelInstances.filter(function (e) {
          return !_this.selectedValue.includes(e) && e.master_account_id === undefined;
        });
        if (newMasterAccount.length > 0) newValues = newMasterAccount; // if user has selected a new sub account, which does not include current selected master account,
        // current master account will be resetted

        var conflictingSubAccount = selectedModelInstances.filter(function (e) {
          return _this.selectedValue.some(function (item) {
            return item.master_account_id !== e.master_account_id;
          });
        });
        if (conflictingSubAccount.length > 0) newValues = conflictingSubAccount;
        return newValues;
      }
    }),
    actions: {
      switchScope: function switchScope() {
        var accounts = this.selectedValue;
        var session = this.session;
        var prevMasterId = session.get('data.masterAccountId');
        session.set('data.prefAccountIds', accounts.map(function (e) {
          return e.id;
        })); // store new preferred dataset...

        var title = accounts.length > 0 ? "".concat(accounts.length, " Sub-accounts") : accounts[0].title;
        session.set('data.prefAccountTitle', title);
        var isMasterAccount = accounts.some(function (e) {
          return !e.master_account_id;
        });
        session.set('data.isMasterAccount', isMasterAccount);
        var newMasterId = isMasterAccount ? accounts[0].id : accounts[0].master_account_id;
        session.set('data.masterAccountId', newMasterId); // as switchScope() can never be called by single-account users:

        session.set('data.isMasterOrSingleUserAccount', isMasterAccount);
        var applicationRoute = Ember.getOwner(this).lookup('route:application');
        var currentRoute = Ember.getOwner(this).lookup("route:".concat(this.currentRouteName));
        this.store.unloadAll(); // carriers could be kept, theoretically, but are
        // cheap to reload; tags need to be thrown out when master changes and
        // subs&shipments even when subAccount changes

        applicationRoute.setupController(this); // after incorporating tag & carrier lookup...
        // into app route's model hook, route refresh will be enough

        if (!isMasterAccount && ['shipment.index', 'subscription.index', 'subscription.new'].includes(this.currentRouteName)) {
          // transition into or between sub-accounts & current page is single sub
          // or shipment: content cannot be seen anymore -> transition "up"
          return this.router.transitionTo("".concat(this.currentRouteName.split('.')[0], "s"));
        }

        if (prevMasterId !== newMasterId) {
          _paq.push(['setCustomDimension', 2, newMasterId]);

          if (currentRoute.controller.tags) {
            return currentRoute.transitionTo({
              queryParams: {
                tags: null
              }
            });
          }

          if (['shipment.index', 'subscription.index', 'subscription.new'].includes(this.currentRouteName)) {
            // transition into or between sub-accounts & current page is single sub
            // or shipment: content cannot be seen anymore -> transition "up"
            return this.router.transitionTo("".concat(this.currentRouteName.split('.')[0], "s"));
          } // else the refresh (below) may fail and we end up transitioning "up" as
          // well, but with a notification via the usual ship/sub route error handling

        }

        if (currentRoute.routeName.endsWith('_error')) {
          return window.location.reload();
        }

        return applicationRoute.refresh();
      }
    }
  });

  _exports.default = _default;
});