define("oi/components/oi-box-plot", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'span',
    didRender: function didRender() {
      this._super.apply(this, arguments); // eslint-disable-line prefer-rest-params


      this.$('span').sparkline(this.values, this.mergedSparkLineOptions);
    },
    _minLabel: Ember.computed('minLabel', 'values.[]', function () {
      return this.minLabel || (this.values.length === 0 ? null : this.values.reduce(function (m, v) {
        return v < m ? v : m;
      }, Infinity));
    }),
    _maxLabel: Ember.computed('maxLabel', 'values.[]', function () {
      return this.maxLabel || (this.values.length === 0 ? null : this.values.reduce(function (m, v) {
        return v > m ? v : m;
      }, -Infinity));
    }),
    mergedSparkLineOptions: Ember.computed('mean', 'sparkLineOptions', 'minMax', function () {
      var mean = this.mean,
          minMax = this.minMax,
          sparkLineOptions = this.sparkLineOptions;
      var baseOptions = {
        type: 'box',
        showOutliers: false,
        target: mean
      };

      if (minMax) {
        baseOptions.chartRangeMin = minMax.min;
        baseOptions.chartRangeMax = minMax.max;
      }

      return Ember.assign(baseOptions, sparkLineOptions);
    })
  });

  _exports.default = _default;
});