define("oi/serializers/eventsummary", ["exports", "ember-data", "oi/serializers/drf"], function (_exports, _emberData, _drf) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // app/serializers/eventsummary.js
  var _default = _drf.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: {
      subscription_events: {
        embedded: 'always'
      }
    }
  });

  _exports.default = _default;
});