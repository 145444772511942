define("oi/components/date-range-with-tooltip", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var TIMEZONE_SPLITTER = /(.+?)([+-]\d\d:?\d\d)/;
  var DateRangeComponent = Ember.Component.extend({
    // component similar to date-with-time-tooltip, but accepting two dates
    // as input that may be identical or close to each other, and taking
    // the locode to display directly (instead of location object);
    // also, show the difference marker if diff given
    tagName: '',
    formattedRange: Ember.computed('min', 'max', 'locode', function () {
      if (!this.min) {
        return '';
      }

      var _TIMEZONE_SPLITTER$ex = TIMEZONE_SPLITTER.exec(this.min),
          _TIMEZONE_SPLITTER$ex2 = (0, _slicedToArray2.default)(_TIMEZONE_SPLITTER$ex, 2),
          minDatetime = _TIMEZONE_SPLITTER$ex2[1];

      var _TIMEZONE_SPLITTER$ex3 = TIMEZONE_SPLITTER.exec(this.max),
          _TIMEZONE_SPLITTER$ex4 = (0, _slicedToArray2.default)(_TIMEZONE_SPLITTER$ex3, 2),
          maxDatetime = _TIMEZONE_SPLITTER$ex4[1];

      if (minDatetime === maxDatetime) {
        return "".concat(minDatetime.replace('T', ' ')).concat(this.locode ? "@".concat(this.locode) : ' (local)');
      }

      if (minDatetime.split('T')[0] === maxDatetime.split('T')[0]) {
        return "".concat(minDatetime.replace('T', ' '), "/").concat(maxDatetime.split('T')[1]).concat(this.locode ? "@".concat(this.locode) : ' (local)');
      }

      return "".concat(minDatetime.replace('T', ' '), "/").concat(maxDatetime.replace('T', ' ')).concat(this.locode ? "@".concat(this.locode) : ' (local)');
    })
  });
  DateRangeComponent.reopenClass({
    positionalParams: ['min', 'max', 'locode']
  });
  var _default = DateRangeComponent;
  _exports.default = _default;
});