define("oi/components/oi-document-download", ["exports", "ember-cli-file-saver/mixins/file-saver", "oi/config/environment", "jquery"], function (_exports, _fileSaver, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DocDownloadComponent = Ember.Component.extend(_fileSaver.default, {
    applicationService: Ember.inject.service(),
    user: Ember.inject.service(),
    raven: Ember.inject.service(),
    notify: Ember.inject.service(),
    download: function download(doc) {
      var _this = this;

      var documentId = doc.id;
      var filename = doc.get('target');
      return _jquery.default.ajax({
        url: "".concat(this.applicationService.apiBase, "/documents/").concat(documentId, "/"),
        type: 'GET',
        dataType: 'arraybuffer',
        // or 'blob'
        processData: false,
        headers: this.get('user.authHeaders'),
        crossDomain: true,
        xhrFields: {
          withCredentials: _environment.default.APP.USE_AJAX_CALL_WITH_CREDENTIALS
        }
      }).then(function (content) {
        return _this.saveFileAs(filename, content);
      }).catch(function () {
        var error = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

        if ((error.responseText || '').includes('No such file')) {
          _this.notify.error('Error downloading file. It may have been deleted in the meantime.');
        } else {
          _this.notify.error("Downloading file \"".concat(filename, "\" failed!"));
        }

        _this.raven.captureMessage('Error downloading file', {
          extra: {
            error: error,
            errorString: "".concat(error),
            documentId: documentId,
            filename: filename
          }
        });
      });
    },
    actions: {
      download: function download(doc) {
        var _this2 = this;

        this.set('isDownloading', true);
        this.download(doc).then(function (result) {
          _this2.set('isDownloading', false);

          return result;
        }).catch(function (error) {
          _this2.set('isDownloading', false);

          throw error;
        });
      }
    }
  });
  DocDownloadComponent.reopenClass({
    positionalParams: ['document']
  });
  var _default = DocDownloadComponent;
  _exports.default = _default;
});