define("oi/components/page-nav", ["exports", "@babel/runtime/helpers/esm/toConsumableArray"], function (_exports, _toConsumableArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PAGE_SIZE_OPTIONS = [50, 100, 200];

  var _default = Ember.Component.extend({
    showSummary: true,
    showSizeOption: true,
    currentPageSize: Ember.computed('page_size', function () {
      // make sure it's an int so template can compare it to numbers in PAGE_SIZE_OPTIONS
      return parseInt(this.page_size, 10);
    }),
    pageSizeOptions: Ember.computed('currentPageSize', function () {
      var pageSize = this.currentPageSize;

      if (PAGE_SIZE_OPTIONS.includes(pageSize)) {
        return PAGE_SIZE_OPTIONS;
      }

      return [pageSize].concat(PAGE_SIZE_OPTIONS).sort(function (a, b) {
        return a - b;
      });
    }),
    visiblePageNumbers: Ember.computed('totalPages', 'page', function () {
      var totalPages = this.totalPages,
          page = this.page;

      if (totalPages <= 8) {
        return (0, _toConsumableArray2.default)(Array(totalPages).keys()).map(function (n) {
          return n + 1;
        }); // the closest js comes to range(1,n+1)
      }

      var visiblePageNumbers = page <= 4 ? (0, _toConsumableArray2.default)(Array(page).keys()).map(function (n) {
        return n + 1;
      }) : [1, null, page - 1, page]; // so far: numbers up to page or 1 ... page-1 page; next: from page+1 to end:

      if (totalPages - page < 4) {
        for (var i = page + 1; i <= totalPages; i += 1) {
          visiblePageNumbers.push(i);
        }
      } else {
        visiblePageNumbers.push(page + 1);
        visiblePageNumbers.push(null);
        visiblePageNumbers.push(totalPages);
      }

      return visiblePageNumbers;
    }),
    firstEntry: Ember.computed('page', 'page_size', function () {
      var p = this.page;
      var s = this.page_size;
      return (p - 1) * s + 1;
    }),
    lastEntry: Ember.computed('page', 'page_size', 'count', function () {
      var p = this.page;
      var s = this.page_size;
      var c = this.count;
      return p * s <= c ? p * s : c;
    })
  });

  _exports.default = _default;
});