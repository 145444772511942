define("oi/router", ["exports", "oi/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Router = Ember.Router.extend({
    init: function init() {
      this._super.apply(this, arguments); // eslint-disable-line prefer-rest-params


      this.on('routeDidChange', function () {
        window.scrollTo(0, 0);
      });
    },
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });
  Router.map(function () {
    // eslint-disable-line array-callback-return
    this.route('login', {});
    this.route('logout', {});
    this.route('index', {
      path: '/'
    }, function () {
      this.route('exceptions', {
        path: '/ex'
      }, function () {
        this.route('eventsummary', {
          path: '/ev'
        });
      });
    });
    this.route('taskmanagement');
    this.route('shipments');
    this.route('shipment-legacy', {
      path: '/shipments/:shipment_id'
    });
    this.route('shipment', {
      path: '/shipment/:shipment_id'
    }, function () {
      this.route('events', {});
    });
    this.route('subscriptions', {
      path: '/subscriptions'
    });
    this.route('subscriptions/new', {
      path: '/subscriptions/new'
    });
    this.route('subscription', {
      path: '/subscription/:subscription_id'
    }, function () {
      this.route('documents', {});
    });
    this.route('chart', {
      path: '/chart/:chart_id'
    });
    this.route('stats', {
      path: '/stats'
    }, function () {
      this.route('leadtime', {
        path: '/leadtime'
      });
    });
    this.route('podapproach-legacy', {
      path: '/podapproach'
    });
    this.route('upcoming-arrivals');
    this.route('reset');
    this.route('preferences', {}, function () {
      this.route('tags', {});
    });
    this.route('sharedlinks', {});
    this.route('fourohfour', {
      path: '/*path'
    });
    this.route('exceptionmanagement', function () {
      this.route('definition');
    });
  });
  var _default = Router;
  _exports.default = _default;
});