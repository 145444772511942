define("oi/templates/components/batchedit-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Deyc07Ui",
    "block": "{\"symbols\":[],\"statements\":[[4,\"confirm-modal\",null,[[\"title\",\"ok\",\"context\",\"close\"],[\"Batch editing subscriptions\",\"batchEdit\",[24,[\"model\"]],\"removeModal\"]],{\"statements\":[[0,\"\\n  \"],[1,[28,\"oi-multi-edit\",null,[[\"model\",\"canEditAccessControlTags\"],[[24,[\"model\"]],[24,[\"user\",\"canEditAccessControlTags\"]]]]],false],[0,\"\\n\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "oi/templates/components/batchedit-modal.hbs"
    }
  });

  _exports.default = _default;
});