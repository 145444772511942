define("oi/components/subscription/details-box", ["exports", "oi/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var BoxComponent = Ember.Component.extend({
    classNames: ['panel', 'panel-default'],
    applicationService: Ember.inject.service(),
    notify: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    user: Ember.inject.service(),
    accessControlTags: Ember.computed('allAvailableTags', function () {
      return this.allAvailableTags.filterBy('isAccessControl', true);
    }),
    regularTags: Ember.computed('allAvailableTags', function () {
      return this.allAvailableTags.filterBy('isAccessControl', false);
    }),
    selectedRegularTags: Ember.computed('data.tags', function () {
      return this.data.tags.filterBy('isAccessControl', false);
    }),
    selectedAccessControlTags: Ember.computed('data.tags', function () {
      return this.data.tags.filterBy('isAccessControl', true);
    }),
    minContractualDate: Ember.computed('data.containershipments.@each.id_date', function () {
      var minIdDate = (this.get('data.containershipments') || []).map(function (cs) {
        return cs.id_date;
      }).filter(function (x) {
        return x;
      }).sort()[0]; // ^ sort because id_date is most likely still a date string, so Math.min() will not work.

      if (minIdDate) return new Date(minIdDate); // No containers yet (i.e. start of voyage unknown)? Allow at most 2 months back:

      return new Date(new Date(this.data.created) - 61 * 24 * 60 * 60 * 1000);
    }),
    actions: {
      updateAccount: function updateAccount(account) {
        var _this = this;

        var subscription = this.data;
        subscription.set('account', account.id);
        this.setProperties({
          isSavingAccount: true,
          activeEditComponent: null
        });
        subscription.save().then(function () {
          return _this.set('isSavingAccount', false);
        }).catch(function () {
          return _this.set('isSavingAccount', false);
        }); // TODO: reset on error? inform user?
      },
      setTags: function setTags(mode, selected) {
        var _this2 = this;

        var tags = mode === 'act' ? this.selectedRegularTags.concat(selected) : this.selectedAccessControlTags.concat(selected);

        _jquery.default.ajax({
          url: "".concat(this.applicationService.apiBase, "/subscriptions/").concat(this.get('data.id'), "/tags/"),
          type: 'POST',
          dataType: 'json',
          contentType: 'application/json; charset=UTF-8',
          headers: this.get('user.authHeaders'),
          crossDomain: true,
          xhrFields: {
            withCredentials: _environment.default.APP.USE_AJAX_CALL_WITH_CREDENTIALS
          },
          data: JSON.stringify({
            payload: tags.map(function (tag) {
              return {
                tagid: tag.id
              };
            })
          }),
          success: function success() {
            _this2.data.set('tags', tags);
          },
          error: function error(_error) {
            if (_error && _error.responseJSON) {
              _this2.notify.error(_error.responseJSON[0]);
            } else {
              // eslint-disable-next-line no-console
              console.error(_error && _error.responseJSON ? _error.responseJSON.detail : _error);
            }
          }
        });
      },
      update: function update() {
        var _this3 = this;

        return this.data.save().catch(function (error) {
          _this3.data.rollbackAttributes(); // CHECK: The following is c&p'd from container-details-box. Generalize?!


          var firstError = (error.errors || [])[0];
          var message = error.message === 'The adapter operation was aborted' ? 'Could not reach server.' : firstError && firstError.detail;

          _this3.notify.error("Saving changes failed! ".concat(message || ''), {
            closeAfter: 6000
          });
        });
      }
    }
  });
  BoxComponent.reopenClass({
    positionalParams: ['data']
  });
  var _default = BoxComponent;
  _exports.default = _default;
});