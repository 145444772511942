define("oi/components/oi-power-select-multiple", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ua = window.navigator.userAgent;
  var isInternetExplorer = ua.indexOf('MSIE ') > -1 || ua.indexOf('Trident/') > -1;

  var _default = Ember.Component.extend({
    tagName: '',
    isIE: isInternetExplorer,
    iePlaceholderClass: 'ie-placeholder',
    actions: {
      onFocus: function onFocus() {
        this.set('isInFocus', true);
        var originalAction = this.onFocus;
        return originalAction ? originalAction.apply(void 0, arguments) : undefined;
      },
      onBlur: function onBlur() {
        this.set('isInFocus', false);
        var originalAction = this.onBlur;
        return originalAction ? originalAction.apply(void 0, arguments) : undefined;
      }
    }
  });

  _exports.default = _default;
});