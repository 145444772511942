define("oi/helpers/string-replace", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.stringReplace = stringReplace;
  _exports.default = void 0;

  function stringReplace(str, subStr, newSubstr) {
    return str.split(subStr).join(newSubstr);
  }

  var _default = Ember.Helper.helper(function (_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 3),
        str = _ref2[0],
        subStr = _ref2[1],
        newSubstr = _ref2[2];

    return stringReplace(str, subStr, newSubstr);
  });

  _exports.default = _default;
});