define("oi/helpers/format-vessel", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.vesselToString = vesselToString;
  _exports.default = void 0;

  /**
   * vessel object to display string, including IMO if known
   *
   * @param  {Object} vessel vessel properties
   * @returns {String}       "OI Ship, IMO: 18055" equivalent, '--' for empty input
   */
  function vesselToString(vessel) {
    if (!vessel) {
      return '--';
    }

    return (vessel.name || '[Name not known]') + (vessel.imo ? ", IMO: ".concat(vessel.imo) : '');
  }

  var _default = Ember.Helper.helper(function (_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 1),
        vessel = _ref2[0];

    return vesselToString(vessel);
  });

  _exports.default = _default;
});