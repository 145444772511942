define("oi/templates/components/settings-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xieG74BL",
    "block": "{\"symbols\":[],\"statements\":[[4,\"confirm-modal\",null,[[\"title\",\"ok\",\"context\",\"close\",\"actionIsImmediate\"],[\"Cancel subscription\",\"cancel\",[24,[\"model\"]],\"removeModal\",true]],{\"statements\":[[0,\"  Cancelling the subscription will stop further updates of this shipment,\\n  but the shipment will remain available in your list.\\n    Would you like to proceed?\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "oi/templates/components/settings-modal.hbs"
    }
  });

  _exports.default = _default;
});