define("oi/serializers/tag", ["exports", "ember-data", "oi/serializers/drf"], function (_exports, _emberData, _drf) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // app/adapters/tag.js
  var _default = _drf.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    // ---------------------------------------------------------------------------
    // Properties
    // ---------------------------------------------------------------------------
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      var newPayload = [];

      if (requestType === 'findAll') {
        for (var i = 0; i < payload.length; i += 1) {
          var current = payload[i];
          newPayload.push(current);
        }

        return this._super(store, primaryModelClass, newPayload, id, requestType);
      }

      return this._super(store, primaryModelClass, payload, id, requestType);
    }
  });

  _exports.default = _default;
});