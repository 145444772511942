define("oi/models/exceptionmanagement/definition", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var SUPPORTED_TYPES = {
    ContainerShipment: 'Shipment',
    ContainerShipmentSubscription: 'Subscription'
  };

  var toHighchart = function toHighchart(total, exceptionalState) {
    return {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: 0,
        plotShadow: false,
        height: 100
      },
      title: {
        text: "".concat(exceptionalState),
        align: 'center',
        verticalAlign: 'bottom',
        y: 0,
        style: {
          fontFamily: 'Inter UI, Helvetica, Arial, sans-serif, sans-serif',
          fontSize: '32px',
          textAlign: 'center',
          fontWeight: '800',
          color: '#1F2933'
        }
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
      },
      accessibility: {
        point: {
          valueSuffix: '%'
        }
      },
      plotOptions: {
        pie: {
          dataLabels: {
            enabled: false
          },
          startAngle: -90,
          endAngle: 90,
          center: ['50%', '60'],
          size: '160px'
        }
      },
      series: [{
        type: 'pie',
        name: 'Shipments',
        innerSize: '70%',
        colors: ['#CF1124', '#00558b'],
        data: [['All active shipments affected', exceptionalState], ['All active shipments not affected', total - exceptionalState]]
      }]
    };
  };

  var _default = _emberData.default.Model.extend({
    store: Ember.inject.service(),
    created_at: _emberData.default.attr('momentdate'),
    title: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    rule: _emberData.default.attr(),
    for_type: _emberData.default.attr('string', {
      defaultValue: 'ctt.ContainerShipment'
    }),
    sort_order_index: _emberData.default.attr('number'),
    total_count: _emberData.default.attr('number'),
    exception_count: _emberData.default.attr('number'),
    template_id: _emberData.default.attr('number'),
    supportedTypes: SUPPORTED_TYPES,
    queryParams: Ember.computed('id', function () {
      return {
        filter_preset: "definition_".concat(this.get('id')),
        shipmentsubscription__status: 1
      };
    }),
    chartObject: Ember.computed('exception_count', 'total_count', function () {
      var shipmentsInExceptionalState = this.get('exception_count');
      var total = this.get('total_count');

      if (shipmentsInExceptionalState > total) {
        total = shipmentsInExceptionalState;
      }

      return toHighchart(total, shipmentsInExceptionalState);
    }),
    refresh: function refresh() {
      // Perform a save as this includes updating the figures
      this.save();
    },
    reloadExceptions: function reloadExceptions() {
      var exceptionPromise = this.get('exceptions').invoke('reload');
      return Ember.RSVP.hash({
        exceptions: exceptionPromise
      });
    }
  });

  _exports.default = _default;
});