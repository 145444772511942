define("oi/templates/-filter-expand-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fxq440uI",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[7,\"button\",true],[11,\"class\",[29,[\"btn is-item \",[28,\"if\",[[24,[\"isFilterExpanded\"]],\"active\"],null],\" btn-primary\"]]],[11,\"onclick\",[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"isFilterExpanded\"]]],null],[28,\"not\",[[24,[\"isFilterExpanded\"]]],null]],null]],[10,\"type\",\"button\"],[8],[0,\"\\n  \"],[7,\"i\",true],[10,\"class\",\"fa fa-filter\"],[10,\"aria-hidden\",\"true\"],[8],[9],[0,\" Filter\\n\"],[4,\"if\",[[28,\"eq\",[[24,[\"filtersPresent\"]],true],null]],null,{\"statements\":[[0,\"    \"],[1,[28,\"oi-icon-with-hint\",[\"fa-check\",\"Filters and/or non-default options currently apply.\"],[[\"hintPosition\",\"class\"],[\"right\",\"label label-success\"]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"filtersPresent\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"oi-icon-with-hint\",[[24,[\"filtersPresent\",\"icon\"]],[28,\"concat\",[\"A \",[24,[\"filtersPresent\",\"subject\"]],\" filter currently applies!\"],null]],[[\"hintPosition\",\"class\"],[\"right\",\"label label-warning\"]]],false],[0,\"\\n  \"]],\"parameters\":[]},null]],\"parameters\":[]}],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "oi/templates/-filter-expand-button.hbs"
    }
  });

  _exports.default = _default;
});