define("oi/controllers/reset", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ajaxBase = {
    type: 'POST',
    dataType: 'json',
    contentType: 'application/json'
  };

  var _default = Ember.Controller.extend({
    applicationService: Ember.inject.service(),
    session: Ember.inject.service(),
    isAuthenticated: Ember.computed.alias('session.isAuthenticated'),
    init: function init() {
      this._super.apply(this, arguments);

      if (!this.isAuthenticated) {
        // username might still be in session store and would be displayed
        // after requesting a reset link as "password reset for user ...",
        // even when the entered email address belonged to a different user
        return this.get('session.store').clear();
      }

      return undefined;
    },
    queryParams: ['uid', 'token', 'accountname', 'email', 'username', 'invite'],
    uid: null,
    token: null,
    accountname: null,
    email: null,
    username: null,
    account: Ember.computed.or('username', 'email', 'accountname', 'session.data.username'),
    hasCredentialsAndAccount: Ember.computed.and('uid', 'token', 'account'),
    isChangeOrReset: Ember.computed.or('hasCredentialsAndAccount', 'isAuthenticated'),
    emailForResetLink: Ember.computed.or('email', ''),
    errorMessage: '',
    isAjaxOpPending: false,
    changePassword: function changePassword(resetData, email) {
      var _this = this;

      var changeUrl = "".concat(this.applicationService.apiHost, "/accounts/api/v2/password/change/");
      var doResetUrl = "".concat(this.applicationService.apiHost, "/accounts/api/v2/password/reset/confirm/");
      this.set('errorMessage', '');
      this.set('isAjaxOpPending', true);
      var accessToken = this.get('session.data.authenticated.access_token'); // assert !!accessToken === !!resetData.old_password === !resetData.token

      var finalDoResetUrl = this.model.isInvite ? "".concat(doResetUrl, "?invite=1") : doResetUrl;

      _jquery.default.ajax(Object.assign({
        url: accessToken ? changeUrl : finalDoResetUrl,
        data: JSON.stringify(resetData),
        headers: accessToken ? {
          Authorization: "Bearer ".concat(accessToken)
        } : {}
      }, ajaxBase)).then(function (response) {
        Ember.run.next(function () {
          return _this.set('isAjaxOpPending', false);
        });

        if (response.success || response.detail) {
          _this.notifySuccess('passwordSet');
        } else {
          _this.set('errorMessage', 'Answer from server is garbled. You may have to try again.');
        }
      }).catch(function (err) {
        Ember.run.next(function () {
          return _this.set('isAjaxOpPending', false);
        });
        var reason = err && err.responseJSON;

        if (reason && reason.token && reason.token[0]
        /* === "Invalid value" */
        ) {
            _this.setProperties({
              errorMessage: 'The token was invalid. Please request a new password reset link.',
              token: null,
              emailForResetLink: email
            });
          } else if (reason && reason.old_password && reason.old_password[0]
        /* === "Invalid password" */
        ) {
            _this.setProperties({
              errorMessage: 'The current password was incorrect.',
              currentPassword: ''
            });

            (0, _jquery.default)('input[autocomplete="current-password"]').focus();
          } else if (reason && reason.new_password2) {
          (false && !(err.status === 400) && Ember.assert('Password validation failures should have status 400', err.status === 400));

          _this.set('errorMessage', reason.new_password2.join(' '));
        } else {
          _this.handleUnspecificResponseError(err);
        }
      });
    },
    requestPasswordResetForUser: function requestPasswordResetForUser(username) {
      var _this2 = this;

      var requestResetUrl = "".concat(this.applicationService.apiHost, "/accounts/api/v2/password/reset/");
      this.set('errorMessage', '');
      this.set('isAjaxOpPending', true);

      _jquery.default.ajax(Object.assign({
        url: this.model.isInvite ? "".concat(requestResetUrl, "?invite=1") : requestResetUrl,
        data: JSON.stringify({
          email: username,
          app: 'CWA',
          todo: 'requestReset'
        })
      }, ajaxBase)).then(function (response) {
        Ember.run.next(function () {
          return _this2.set('isAjaxOpPending', false);
        });

        if (response.success || response.detail) {
          _this2.notifySuccess('emailSent');
        } else {
          _this2.set('errorMessage', 'Answer from server is garbled. You may have to try again.');
        }
      }).catch(function (err) {
        Ember.run.next(function () {
          return _this2.set('isAjaxOpPending', false);
        });
        var reason = err && err.responseJSON;

        if (reason && reason.email && reason.email[0]
        /* === "Enter a valid email address." */
        ) {
            _this2.set('errorMessage', 'This email address does not seem to be valid.');
          } else if (err && err.responseText) {
          _this2.set('errorMessage', err.responseText);
        } else {
          _this2.handleUnspecificResponseError(err);
        }
      });
    },
    notifySuccess: function notifySuccess(successType) {
      this.setProperties({
        notificationType: successType,
        newpasswordone: '',
        newpasswordtwo: '',
        currentPassword: null
      });
      (0, _jquery.default)('#notification').hide();
      (0, _jquery.default)('#form').fadeOut('slow', function () {
        return (0, _jquery.default)('#notification').fadeIn('slow');
      });
    },
    raven: Ember.inject.service(),
    handleUnspecificResponseError: function handleUnspecificResponseError(error) {
      if (error && error.statusText === 'No Transport') {
        // seen with IE9 as emulated by IE11
        this.set('errorMessage', 'Could not make request. Please try a different browser.');
      } else if (error && error.status === 0) {
        this.set('errorMessage', 'The server did not respond. Try again later.');
      } else if (error && error.status >= 500) {
        this.set('errorMessage', 'A server side error occurred. Try again later.');
      } else {
        this.raven.captureMessage("Failed password reset: Unhandled API error ".concat(error && (error.detail || error.statusText)), {
          level: 'warning',
          fingerprint: ['Failed password reset'],
          extra: {
            error: error,
            errorString: "".concat(error)
          }
        });
        this.set('errorMessage', 'An unexpected error occurred. Try again later.');
      }
    },
    passwordsDiffer: Ember.computed('newpasswordone', 'newpasswordtwo', function () {
      return this.newpasswordone !== this.newpasswordtwo;
    }),
    actions: {
      showFormAgain: function showFormAgain() {
        (0, _jquery.default)('#form').fadeIn('slow');
        (0, _jquery.default)('#notification').fadeOut('slow');
      },
      requestReset: function requestReset(e) {
        if (e) {
          var givenUsername = this.emailForResetLink;

          if (givenUsername !== '') {
            this.requestPasswordResetForUser(givenUsername);
          } else {
            this.set('errorMessage', 'No username given!');
          }
        }
      },
      requestPasswordChange: function requestPasswordChange(e) {
        if (e) {
          var newpasswordone = this.newpasswordone,
              newpasswordtwo = this.newpasswordtwo,
              currentPassword = this.currentPassword,
              uid = this.uid,
              token = this.token,
              accountname = this.accountname,
              email = this.email;
          this.changePassword(Object.assign({
            new_password1: newpasswordone,
            new_password2: newpasswordtwo,
            app: 'CWA'
          }, token ? {
            token: token,
            uid: uid
          } : {
            old_password: currentPassword
          }), email || accountname);
        }
      }
    }
  });

  _exports.default = _default;
});