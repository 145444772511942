define("oi/templates/login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TFGBSDlw",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"oi-auth-form\",null,[[\"type\",\"canShowPasswordResetLink\",\"authenticate\",\"identification\",\"password\",\"errorMessageCredentials\",\"errorMessage\",\"statusPageSummary\",\"p44LoginLink\"],[\"login\",[24,[\"canShowPasswordResetLink\"]],[28,\"action\",[[23,0,[]],\"authenticate\"],null],[24,[\"identification\"]],[24,[\"password\"]],[24,[\"errorMessageCredentials\"]],[24,[\"errorMessage\"]],[24,[\"statusPageSummary\"]],[24,[\"p44LoginLink\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "oi/templates/login.hbs"
    }
  });

  _exports.default = _default;
});