define("oi/routes/application", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "@babel/runtime/helpers/esm/toConsumableArray", "ember-simple-auth/mixins/application-route-mixin", "ember-local-storage", "oi/config/environment", "jquery", "fetch"], function (_exports, _slicedToArray2, _toConsumableArray2, _applicationRouteMixin, _emberLocalStorage, _environment, _jquery, _fetch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.downloadPromises = downloadPromises;
  _exports.getSessionAccountData = getSessionAccountData;
  _exports.default = void 0;

  /* global _paq */
  var DEFAULT_FEATURES = {
    documents: {
      feature_enabled: false,
      read_only: true
    },
    statistics: {
      feature_enabled: true,
      read_only: true
    },
    tagging: {
      feature_enabled: true,
      read_only: false
    },
    subscriptions: {
      feature_enabled: true,
      read_only: false
    }
  };

  function downloadPromises(applicationService, headers, result) {
    if (headers.Authorization === 'Bearer undefined') {
      return _jquery.default.ajax({
        url: "".concat(applicationService.apiHost, "/p44/download-token/"),
        type: 'GET',
        headers: headers,
        xhrFields: {
          withCredentials: _environment.default.APP.USE_AJAX_CALL_WITH_CREDENTIALS
        }
      }).then(function (accessToken) {
        headers.Authorization = "Bearer ".concat(accessToken);
        return Ember.RSVP.Promise.all([Ember.RSVP.Promise.resolve(result.filename), _jquery.default.ajax({
          url: "".concat(applicationService.apiHost, "/media/cwa-output/").concat(result.filename),
          type: 'GET',
          dataType: 'blob',
          processData: false,
          headers: headers,
          xhrFields: {
            withCredentials: _environment.default.APP.USE_AJAX_CALL_WITH_CREDENTIALS
          }
        })]);
      });
    }

    return Ember.RSVP.Promise.all([Ember.RSVP.Promise.resolve(result.filename), _jquery.default.ajax({
      url: "".concat(applicationService.apiHost, "/media/cwa-output/").concat(result.filename),
      type: 'GET',
      dataType: 'blob',
      processData: false,
      headers: headers,
      xhrFields: {
        withCredentials: _environment.default.APP.USE_AJAX_CALL_WITH_CREDENTIALS
      }
    })]);
  }

  function getSessionAccountData(prefAccountIds) {
    var accounts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
    var fullname = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
    var preferredAccounts = [];

    if (preferredAccounts.length === 0) {
      // try matching username to a top-level account (IP feature request, trumps prefAccountIds)
      var lookupName = fullname.replace("'", ''); // special handling for
      // B'Elanna Torres (not really), whose apostrophe cannot be represented in account names

      var tlAccountWithSameName = accounts.find(function (acc) {
        return acc.title === lookupName;
      });

      if (tlAccountWithSameName) {
        preferredAccounts.push(tlAccountWithSameName);
      }
    }

    if (preferredAccounts.length === 0 && prefAccountIds) {
      // there's a preferred account id given! so let's check if the id is still part of the account list
      if (prefAccountIds.length === 1) {
        var preferredMaster = accounts.find(function (a) {
          return a.id === prefAccountIds[0];
        });

        if (preferredMaster) {
          preferredAccounts.push(preferredMaster);
        }
      }

      if (preferredAccounts.length === 0) {
        accounts.find(function (ma) {
          var candidates = (ma.sub_accounts || []).filter(function (sa) {
            return prefAccountIds.includes(sa.id);
          });

          if (candidates.length > 0) {
            preferredAccounts.push.apply(preferredAccounts, (0, _toConsumableArray2.default)(candidates)); // side effect in functional style. I am very sorry.

            return true;
          }

          return false;
        });
      }
    }

    if (preferredAccounts.length === 0) {
      // default if no username match and not last used accounts (preferredAccountIds)
      preferredAccounts.push(accounts[0]);
    }

    var isMasterAccount = !!preferredAccounts.find(function (a) {
      return a.sub_accounts && a.sub_accounts.length;
    });
    var isMasterOrSingleUserAccount = accounts[0].is_sua || isMasterAccount;
    return {
      accounts: accounts,
      prefAccountIds: preferredAccounts.map(function (acc) {
        return acc.id;
      }),
      prefAccountTitle: preferredAccounts.length > 1 ? "".concat(preferredAccounts.length, " items selected") : preferredAccounts[0].title,
      isMasterAccount: isMasterAccount,
      isMasterOrSingleUserAccount: isMasterOrSingleUserAccount,
      masterAccountId: isMasterOrSingleUserAccount ? preferredAccounts[0].id : preferredAccounts[0].master_account_id
    };
  }

  var _default = Ember.Route.extend(_applicationRouteMixin.default, {
    routeAfterAuthentication: 'index',
    applicationService: Ember.inject.service(),
    store: Ember.inject.service('store'),
    session: Ember.inject.service(),
    user: Ember.inject.service(),
    downloadService: Ember.inject.service(),
    router: Ember.inject.service(),
    raven: Ember.inject.service(),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments); // eslint-disable-next-line no-console


      console.log("version: ".concat(_environment.default.APP.version, " \xAF\\_(\u30C4)_/\xAF"));

      if (_environment.default.environment !== 'test') {
        this.metronome(undefined, _environment.default.environment !== 'development'); // ^ auto-reload when detecting version change on init, except in dev
      }

      this.router.on('routeDidChange', function () {
        return _this.segmentPageChanged();
      });
    },
    metronome: function metronome(knownVersion) {
      var _this2 = this;

      var isFirst = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      this.checkVersion(knownVersion, isFirst).then(function (newVersion) {
        Ember.run.later(_this2, function () {
          return _this2.metronome(newVersion);
        }, 300000); // tick all 5 minutes
      });
    },
    title: function title(tokens) {
      return tokens.filter(function (x) {
        return x;
      }).reverse().join(' | ');
    },
    titleToken: function titleToken() {
      return 'project44 Container Track & Trace';
    },
    model: function model() {
      // ToDo remove after successful deployment of CTT-71
      if (!this.session.get('data.prefAccountIds') && this.session.get('data.prefAccountId')) {
        this.session.set('data.prefAccountIds', [this.session.get('data.prefAccountId')]);
      }

      if (!this.session.isAuthenticated) {
        return null; // incidents will have to be loaded manually after profile fetching
      } // returning the fetched incidents will make all sub-routes wait for this,
      // but since other routes they process the result, waiting allows them to
      // look for incidents via peekAll and thus avoids reloading them many times


      return this.store.findAll('incident', {
        backgroundReload: true
      });
    },
    addProfileToSession: function addProfileToSession(profile) {
      var prefAccountIds = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : undefined;
      var session = this.session;
      var sessionData = Object.assign(getSessionAccountData(session.get('data.prefAccountIds'), profile.accounts, profile.fullname), Ember.getProperties(profile, 'username', 'fullname', 'email', 'joined', 'user_hash', 'id', 'user_access_control_tags'));
      sessionData.features = profile.get('features') || DEFAULT_FEATURES; // session.data may not be an ember object that we can call setProperties on,
      // hence we need a workaround (calling setProperties on the session object):

      var dataToSet = {}; // shorter with Object.entries (and possible Object.fromEntries around it), but
      // browser support for these is not universal and polyfilling seemingly broken

      Object.keys(sessionData).forEach(function (key) {
        dataToSet["data.".concat(key)] = sessionData[key];
      });

      if (prefAccountIds) {
        dataToSet['data.prefAccountIds'] = prefAccountIds;
      }

      session.setProperties(dataToSet); // for unknown reasons, session.setProperties does not persist the session
      // data in localStorage (or cookie), only 'set' or 'persist' do

      session.get('store').persist(session.get('data'));
      var username = profile.username,
          email = profile.email;

      if (_environment.default.APP.PROD_ENV === 'sysco' && session.get('data.isMasterAccount') && !(email || '').endsWith('@ocean-insights.com')) {
        this.raven.captureMessage("sysco/ifoodgroup system accessed by master account user ".concat(username), {
          level: 'warning',
          fingerprint: ['sysco/ifoodgroup system accessed by master account user'],
          extra: {
            profile: profile
          }
        });
      }
    },
    reloadTagsAndCarriers: function reloadTagsAndCarriers() {
      var _this3 = this;

      // Make sure all tags & carriers are in store so that we can look them up later
      // without reloading ({ reload: false }). Records would also be loaded individually
      // by id if not in store, so tags created and added to subs/containers by others
      // while a user runs the app will still show up (but  not immediately in filter
      // boxes). To be safe, we should still reload tags and carriers before creating
      // new subscriptions, fetching new additions if present.
      this.store.unloadAll('tag');
      this.store.unloadAll('carrier');
      Ember.run.next(function () {
        _this3.store.findAll('carrier', {
          backgroundReload: true
        }).then(function (carriers) {
          return _this3.controller.set('carriers', carriers);
        });

        _this3.store.findAll('tag', {
          backgroundReload: true
        });
      });
    },
    reloadProfile: function reloadProfile() {
      var _this4 = this;

      var prefAccountIds = this.session.data.prefAccountIds;
      this.store.unloadAll('user');
      this.session.set('data.features', null);
      Ember.run.next(function () {
        _this4.store.queryRecord('user', {}).then(function (profile) {
          if (profile) {
            _this4.addProfileToSession(profile, prefAccountIds);

            _this4.segmentIdentify(profile);
          }
        }).then(function () {
          _this4.refreshExceptionDefinitions();
        });
      });
    },
    loadExceptionDefinitions: function loadExceptionDefinitions() {
      return this.store.query('exceptionmanagement/definition', {});
    },
    refreshExceptionDefinitions: function refreshExceptionDefinitions() {
      if (this.session.data.features && this.session.data.features.iem && this.session.data.features.iem.feature_enabled) {
        var controller = this.controllerFor('index/exceptions');
        controller.set('exception_definitions', this.loadExceptionDefinitions());
      }
    },
    setupController: function setupController(controller) {
      if (this.get('session.isAuthenticated')) {
        this.reloadTagsAndCarriers();
        this.reloadProfile(); // Doing this in setupController so that rendering of the landing page
        // does not have to wait for it (not immediately needed there), which
        // however means that when directly entering routes that require these
        // models, these routes' model hook will load them again while the promises
        // here are still about to resolve...but hey, loading twice is still better
        // than loading them every time.
        // Note: called manually in sessionAuthenticated, which will mess things up once
        // we have a real model and want to use it there (-> move lookups to model).
      }

      var statusUrl = 'https://status.project44.com/api/v2/summary.json';
      (0, _fetch.default)(statusUrl).then(function (response) {
        return response.json();
      }).then(function (statusSummary) {
        return controller.setProperties({
          statusSummary: statusSummary
        });
      });
    },
    loadAndProcessProfile: function loadAndProcessProfile() {
      var _this5 = this;

      this.session.set('data.features', null);
      return this.store.queryRecord('user', {}).then(function (profile) {
        _this5.addProfileToSession(profile);

        _this5.controller.setTrackingAndLoggingData();

        _this5.refreshExceptionDefinitions();

        _this5.reloadTagsAndCarriers(); // :-( tight coupling: knowing that
        // what the method does can only be done when authenticated, we run it again
        // Also: load incidents again because loading in model hook will have failed if unauthenticated


        return _this5.store.findAll('incident').then(function () {
          return profile;
        });
      }).catch(function (error) {
        var identification = _this5.controllerFor('login').get('identification');

        if (error && error.message === 'You do not have permission to perform this action.') {
          _this5.raven.captureMessage("Login attempt by user without CWA permission: ".concat(identification), {
            level: 'info',
            fingerprint: ['missing CWA permission'],
            extra: {
              error: error,
              errorString: "".concat(error),
              identification: identification
            }
          });

          _this5.controllerFor('login').set('errorMessage', 'This user does not seem to have permission to use the CTT Web Application.'); // user cannot do anything with their "session", but calling invalidate()
          // will trigger refresh and make errorMessage disappear ...so wait a bit:


          Ember.run.later(function () {
            return _this5.session.invalidate();
          }, 6000);
        } else {
          _this5.raven.captureMessage("CTT profile processing failure for ".concat(identification), {
            level: 'warning',
            fingerprint: ['CTT profile processing failure'],
            extra: {
              error: error,
              errorString: "".concat(error),
              identification: identification
            }
          });
        }
      });
    },
    sessionAuthenticated: function sessionAuthenticated() {
      var _this6 = this;

      this.controller.loadChangelog();
      this.controller.checkForNotifications();
      var _super = this._super;
      return this.loadAndProcessProfile().then(function () {
        return _super.call(_this6);
      });
    },
    clearSessionPrefs: function clearSessionPrefs() {
      _paq.push(['resetUserId']);

      this.set('prefs.prefCarrierScac', 'ACLU');
      this.set('prefs.prefRequestType', 'c_id');
      this.set('prefs.sub_account_id', null);
      this.raven.callRaven('setUserContext', {});
    },
    prefs: (0, _emberLocalStorage.storageFor)('prefs'),
    checkVersion: function checkVersion() {
      var _this7 = this;

      var alreadyKnownVersion = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var isFirst = arguments.length > 1 ? arguments[1] : undefined;

      if (this.controller) {
        this.controller.checkForNotifications();
      } // ^ contoller not there yet on first check, will be notified on sessionAuthenticated, too


      var _config$APP$version$s = _environment.default.APP.version.split('+'),
          _config$APP$version$s2 = (0, _slicedToArray2.default)(_config$APP$version$s, 2),
          currentVersion = _config$APP$version$s2[0],
          currentHash = _config$APP$version$s2[1];

      currentVersion = alreadyKnownVersion.version || currentVersion; // if the user already clicked on "continue with current" even after
      // a new version was found, do not nag again for the same new version

      currentHash = alreadyKnownVersion.hash || currentHash;
      return _jquery.default.ajax('/version', {
        cache: false
      }).then(function (versionFileContent) {
        var env = _environment.default.environment;

        var _versionFileContent$s = versionFileContent.split('\n')[0].split('+'),
            _versionFileContent$s2 = (0, _slicedToArray2.default)(_versionFileContent$s, 2),
            latestVersion = _versionFileContent$s2[0],
            latestHash = _versionFileContent$s2[1];

        if (env === 'development') {
          // eslint-disable-next-line no-console
          console.log('version check', latestVersion, latestHash, currentVersion, currentHash, alreadyKnownVersion, isFirst);
        }

        if (!currentHash || !latestHash) {
          _this7.raven.captureMessage("The 'missing hash' bug is back! ".concat(currentHash, "|").concat(latestHash), {
            level: 'warning',
            fingerprint: ["The 'missing hash' bug is back"],
            extra: {
              currentHash: currentHash,
              currentVersion: currentVersion,
              versionFileContent: versionFileContent
            }
          });
        }

        var hashesPresentAndDifferent = currentHash && latestHash && currentHash !== latestHash;
        var versionChanged = latestVersion !== currentVersion || env !== 'development' && hashesPresentAndDifferent;
        var changeMessage = versionFileContent.split('\n')[1];

        if (versionChanged && (isFirst || changeMessage && changeMessage !== '!no')) {
          if (changeMessage === '!auto' || isFirst) {
            _this7.raven.captureMessage('CTT Reload due to update', {
              level: 'debug',
              extra: {
                env: env,
                changeMessage: changeMessage,
                latestVersion: latestVersion,
                currentVersion: currentVersion,
                latestHash: latestHash,
                currentHash: currentHash
              }
            });

            document.location.reload(true);
          }

          _this7.controller.set('showNewAppVersionModal', changeMessage || true);
        }

        return {
          version: latestVersion,
          hash: latestHash
        };
      }).catch(console.error); // eslint-disable-line no-console
    },
    sessionChanged: function sessionChanged() {
      this.clearSessionPrefs();
      return this.sessionAuthenticated();
    },
    invalidateSession: function invalidateSession(context) {
      context.clearSessionPrefs();
      context.get('session').setProperties({
        'data.email': null,
        'data.username': null,
        'data.fullname': null,
        'data.joined': null,
        'data.user_hash': null,
        'data.accounts': null,
        'data.masterAccountId': null,
        'data.features': null
      }); // Leave session.data.prefAccountIds and ...Title as they are so they are
      // selected again after re-login

      context.session.invalidate();
    },
    sessionInvalidated: function sessionInvalidated() {
      var _super = this._super,
          logoutUrls = this.logoutUrls;

      if (logoutUrls && logoutUrls.length > 0) {
        if (logoutUrls.length === 1) {
          window.location.assign(logoutUrls[0]);
        } else {
          for (var step = 1; step < logoutUrls.length; step += 1) {
            window.open(logoutUrls[step], '_blank').focus();
          }

          window.location.assign(logoutUrls[0]);
        }
      } else {
        _super.call(this);
      }
    },
    segmentPageChanged: function segmentPageChanged() {
      var _window = window,
          analytics = _window.analytics;

      if (analytics) {
        analytics.page({
          path: window.location.pathname + window.location.hash,
          referrer: window.location.referrer,
          search: window.location.search,
          title: 'Container Track & Trace',
          url: window.location.origin
        });
      }
    },
    segmentIdentify: function segmentIdentify(profile) {
      var _window2 = window,
          analytics = _window2.analytics; // The sso authenticator uses it's own identify mechanism based on principal resuls

      var authenticator = this.get('session.data.authenticated.authenticator');

      if (analytics && authenticator !== 'authenticator:sso') {
        analytics.identify(profile.username, {
          userId: profile.username,
          email: profile.email,
          tenantId: profile.accounts[0].id,
          tenantName: profile.accounts[0].title,
          networkRole: 'CTT-LEGACY-USER',
          displayName: profile.fullname
        });
      }
    },
    actions: {
      error: function error(_error, transition) {
        var _this8 = this;

        if (_error.message === 'Wrong account.') {
          var sessionData = this.get('session.data');
          var sessionDataClone = Object.assign({}, sessionData);
          delete sessionDataClone.authenticated;
          delete sessionDataClone.id;
          delete sessionDataClone.user_hash;

          if (!sessionData.authenticated.refresh_token) {
            if (sessionData.authenticated.impersonated_by) {
              Ember.run.later(function () {
                return _this8.get('session').invalidate();
              }, 2000);
            } else {
              this.raven.captureMessage('Refresh token absent (while not impersonated)!', {
                level: 'warning',
                extra: {
                  transitionTo: transition.to,
                  transitionFrom: transition.from,
                  sessionData: sessionDataClone
                }
              });
            }
          } else {
            this.raven.captureMessage(transition.isAborted ? '"wrong account" API response and transition already aborted!' : 'Re-fetching profile due to "wrong account" API response', {
              level: transition.isAborted ? 'warning' : 'info',
              extra: {
                transitionTo: transition.to,
                transitionFrom: transition.from,
                sessionData: sessionDataClone
              }
            });

            if (!transition.isAborted) {
              return this.loadAndProcessProfile().then(function () {
                // console.log(transition.isAborted); // true now (promise completes in another run loop)
                transition.retry();
              });
            } // Still here? Not sure how this could happen. Hopefully the log helps. ;-)

          }
        }

        _error.transition = transition;
        return true;
      },
      didTransition: function didTransition() {
        var pageTitle = (document.title || '').split('|')[0];

        _paq.push(['setDocumentTitle', pageTitle === this.titleToken() ? '(main page)' : pageTitle]);

        _paq.push(['trackPageView', this.url]);

        if (!this.linkTrackingEnabled) {
          // https://developer.matomo.org/guides/tracking-javascript-guide#download-and-outlink-tracking
          // > It is recommended to add this line just after the first call to trackPageView or trackEvent.
          _paq.push(['enableLinkTracking']); // This works for logged-in users reloading the app. Explixitly called after authentication, too.


          this.set('linkTrackingEnabled', true);
        }
      },
      logout: function logout() {
        this.set('logoutUrls', undefined);
        var host = window.location.host;
        var validTokens = this.get('session.data.authenticated.valid_tokens');
        /*
         * Check if the user had multiple valid tokens at the same time. In
         * this case add logout urls for each of those tokens so that the
         * session invalidation can open logout requests for each of those
         * tokens.
         */

        if (validTokens && validTokens.length > 0) {
          var logoutUrls = [];
          validTokens.forEach(function (token) {
            var baseLogoutUrl = _environment.default.APP.P44_LOGOUT_URLS["".concat(host, "_").concat(token)];

            var logoutUrl = "".concat(baseLogoutUrl, "?afterLogoutRedirectUri=https%3A%2F%2F").concat(host, "%0A");
            logoutUrls.push(logoutUrl);
          });
          this.set('logoutUrls', logoutUrls);
        }

        this.invalidateSession(this);
      },

      /**
       * download - request excel export from API (action reached via evil bubbling
       * from the evil modals) and trigger download (by inserting <a> element with
       * received link and clicking it)
       *
       * @param  {object} context modal window's context (model) or null
       * @returns {promise}       Ajax request's promise (returns nothing)
       */
      download: function download(context) {
        (0, _jquery.default)('#topDl').children().hide();
        (0, _jquery.default)('#topDl').append('<span id="loadingMessageTop" style="background-color: red; padding: 2px 6px; color: #fff; border-radius:3px;">Processing...</span>');
        (0, _jquery.default)('#bottomDl').children().hide();
        (0, _jquery.default)('#bottomDl').append('<span id="loadingMessageBottom" style="background-color: red; padding: 2px 6px; color: #fff; border-radius:3px;">Processing...</span>');
        var path = window.location.hash.substring(1).replace('s?', 's/?').replace(/chart\/(\w+)?(\??)/, "dashboards/".concat(context && context.chartName, "/download/$2")); // chrome can handle redirects from /cwaapi/subscriptions?foo=bar to
        // /cwaapi/subscriptions/?foo=bar, IE/Edge/Safari cannot ^^

        var url = ["".concat(this.applicationService.apiHost, "/").concat(_environment.default.APP.API_NAMESPACE).concat(path), 'format=download&async=true'].join(path.includes('?') ? '&' : '?'); // when visiting the app url .../subscriptions directly, default query
        // prameters do not get added to url (window.location) automatically...

        if (context && context.includeHistorical) {
          url += '&include_arrived=true';
        }

        if (context && context.exportDatesInUtcOnly) {
          url += '&all_dates_in_utc=true';
        }

        var headers = this.get('user.authHeaders');
        var downloadService = this.downloadService;
        return _jquery.default.ajax({
          url: url,
          headers: headers,
          type: 'GET',
          xhrFields: {
            withCredentials: _environment.default.APP.USE_AJAX_CALL_WITH_CREDENTIALS
          }
        }).then(function (result) {
          Ember.run.next(function () {
            downloadService.create(result.filename);
          });
        });
      },
      // -------------------------------------------------------------------------
      showModal: function showModal(name) {
        var model = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
        // name will be of the form `components/...-modal`
        // "model" must be set to empty object by default: if it is null or undefined,
        // somehow the previously shown modal's "model" is sticky, leading, for example,
        // to make the "include historical data" switch appear in download dialogs it
        // should not appear after it correctly appeared in a previous one (CWA-969)
        var pageTitle = (document.title || '').split('|')[0];

        _paq.push(['setDocumentTitle', "".concat(pageTitle === this.titleToken() ? '(main page)' : pageTitle, " with ").concat(name.split('/')[1])]);

        _paq.push(['trackPageView', this.url]);

        this.render(name, {
          into: 'application',
          outlet: 'modal',
          model: model
        });
        Ember.run.later(function () {
          var inputElement = document.querySelector('.modal-body input');

          if (inputElement) {
            inputElement.focus();
          }
        }, 200); // this does not work with next (or later(...,0)), possibly
        // because the modal appears via animation (?!)
      },
      removeModal: function removeModal() {
        this.disconnectOutlet({
          outlet: 'modal',
          parentView: 'application'
        });
      },
      createNewTask: function createNewTask(linkedObjects, context) {
        var _this9 = this;

        var task = this.store.createRecord('task', {
          status: 'O',
          type: 'T',
          appearence: 'I',
          assigned_to: 'Unassigned',
          context: context
        });

        if (linkedObjects) {
          linkedObjects.forEach(function (item) {
            _this9.store.createRecord('link', {
              object_app: item.app,
              object_type: item.type,
              object_id: item.id,
              label: item.label,
              task: task
            });
          });
        }

        this.controller.set('newTask', task);
      }
    }
  });

  _exports.default = _default;
});