define("oi/controllers/subscriptions/new", ["exports", "ember-local-storage", "moment"], function (_exports, _emberLocalStorage, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var REQUEST_TYPES = [{
    label: 'Container number',
    value: 'c_id'
  }, {
    label: 'Booking number',
    value: 'b_id'
  }, {
    label: 'MBL/BL number',
    value: 'm_bl'
  }];
  var CONTAINER_NUMBER_REGEX = /[a-zA-Z]{3}[uU]{1}[ ]{0,2}[0-9]{7}/;

  var _default = Ember.Controller.extend({
    init: function init() {
      this._super.apply(this, arguments); // eslint-disable-line prefer-rest-params


      this.setProperties({
        nameSorting: ['short_name'],
        tagSorting: ['value'],
        selectedTags: [],
        selectedAccessControlTags: []
      });

      if (this.writableSubAccountsIds.length === 1) {
        this.set('subAccountId', this.writableSubAccountsIds[0]);
        this.set('prefs.sub_account_id', this.writableSubAccountsIds[0]);
      }
    },
    notify: Ember.inject.service('notify'),
    session: Ember.inject.service('session'),
    user: Ember.inject.service('user'),
    // eslint-disable-next-line ember/no-observers
    accountChanged: Ember.observer('writableSubAccountsIds', function () {
      if (this.writableSubAccountsIds.length === 1) {
        this.set('subAccountId', this.writableSubAccountsIds[0]);
        this.set('prefs.sub_account_id', this.writableSubAccountsIds[0]);
      }
    }),
    actFeature: Ember.computed.alias('session.data.features.access_control_tags.feature_enabled'),
    carrierScac: null,
    // set from route
    supportedCarriers: Ember.computed.filterBy('data.carriers', 'hasSupportedRequestType'),
    sortedCarriers: Ember.computed.sort('supportedCarriers', 'nameSorting'),
    selectedCarrier: Ember.computed('sortedCarriers', 'carrierScac', function () {
      return this.sortedCarriers.findBy('scac', this.carrierScac);
    }),
    requestTypes: Ember.computed('selectedCarrier.request_type_support', function () {
      var supported = this.get('selectedCarrier.request_type_support');

      if (!supported) {
        return REQUEST_TYPES;
      }

      return REQUEST_TYPES.map(function (reqType) {
        return supported[reqType.value] ? reqType : {
          label: reqType.label,
          value: reqType.value,
          disabled: true,
          'aria-label': 'not supported for the selected carrier'
        };
      });
    }),
    accessControlTags: Ember.computed('data.tags', function () {
      var accessControlTags = this.data.tags.filterBy('isAccessControl', true);

      if (this.user.canEditAccessControlTags) {
        return accessControlTags;
      }

      var userACTIds = this.session.data.user_access_control_tags;
      return accessControlTags.filter(function (tag) {
        return userACTIds.includes(parseInt(tag.id, 10));
      });
    }),
    filteredAvailableTags: Ember.computed('data.tags', 'actFeature', function () {
      return this.actFeature ? this.data.tags.filterBy('isAccessControl', false) : this.data.tags;
    }),
    requestType: null,
    // set from route
    sortedTags: Ember.computed.sort('data.tags', 'tagSorting'),
    requestKey: '',
    descriptive_name: '',
    prefs: (0, _emberLocalStorage.storageFor)('prefs'),
    writableSubAccountsIds: Ember.computed('user.currentFlattenedAccounts', function () {
      var accounts = this.user.currentFlattenedAccounts.filter(function (acc) {
        return !acc.sub_accounts && !acc.read_only;
      });
      return accounts.map(function (acc) {
        return acc.id;
      });
    }),
    isAddButtonDisabled: Ember.computed('requestKey', 'subAccountId', 'user.subAccountsAvailable', 'selectedCarrier', 'requestType', 'keyLooksLikeContainerNumber', 'actFeature', 'selectedAccessControlTags', function () {
      if (this.user.subAccountsAvailable && !this.subAccountId || this.actFeature && this.selectedAccessControlTags.length === 0) {
        return true;
      }

      var requestKey = this.requestKey,
          requestType = this.requestType,
          keyLooksLikeContainerNumber = this.keyLooksLikeContainerNumber,
          selectedCarrier = this.selectedCarrier;

      if (!requestKey || !selectedCarrier || !requestType && !keyLooksLikeContainerNumber) {
        return true;
      }

      return !selectedCarrier.get('request_type_support')[requestType || 'c_id'];
    }),
    keyLooksLikeContainerNumber: Ember.computed('requestKey', function () {
      var key = this.requestKey;
      return (CONTAINER_NUMBER_REGEX.exec(key) || [])[0] === key;
    }),
    onSuccessfulSaveSingle: function onSuccessfulSaveSingle(result) {
      // set everything back to null
      this.set('requestKey', '');
      this.set('descriptive_name', '');
      this.set('errorMessage', ''); // get to overview

      this.notify.info({
        html: "Subscription <a href=\"#/subscription/".concat(result.id, "\">").concat(result.request_key, "</a> successfully created."),
        closeAfter: 8000
      });
    },
    onFailedSaveSingle: function onFailedSaveSingle(response) {
      this.set('errorMessage', response.errors ? "".concat(response.errors[0].title, " - ").concat(response.errors[0].detail) : response.message);
    },
    actions: {
      selectCarrier: function selectCarrier(carrier) {
        var scac = Ember.get(carrier, 'scac');
        this.set('carrierScac', scac);
        this.set('prefs.prefCarrierScac', scac); // store in prefs
      },
      selectRequestType: function selectRequestType(requestType) {
        this.set('requestType', requestType);
        this.set('prefs.prefRequestType', requestType); // store in prefs
      },
      selectSubAccount: function selectSubAccount(_ref) {
        var id = _ref.id;
        this.set('subAccountId', id);
        this.set('prefs.sub_account_id', id); // store in prefs
      },
      createSubscription: function createSubscription() {
        var _this = this;

        var requestKey = this.requestKey;

        if (requestKey.indexOf(',') !== -1) {
          // intercept!
          this.set('errorMessage', 'Please do not submit more than one number!');
          return;
        } // no comma in requestKey - so single subscription!


        var requestType = this.requestType;

        if (!requestType) {
          if ((/[a-zA-Z]{3}[uU]{1}[0-9]{7}/.exec(requestKey) || [])[0] === requestKey) {
            requestType = 'c_id';
          } else {
            this.set('errorMessage', 'Please select a reference number type!');
            return;
          }
        }

        var carrierScac = this.carrierScac,
            description = this.descriptive_name,
            cEtdPol = this.contractual_etd_pol,
            cEtaPod = this.contractual_eta_pod,
            cEtaDlv = this.contractual_eta_dlv;
        var tags = this.selectedTags.concat(this.selectedAccessControlTags);
        var accountId = this.subAccountId;

        if (!accountId) {
          accountId = this.isMasterAccount ? null
          /* should have been set earlier; will trigger API error */
          : this.get('session.data.prefAccountIds');
        } // create model instance...


        var subscription = this.store.createRecord('subscription', {
          request_key: requestKey,
          request_carrier_code: carrierScac,
          request_type: requestType,
          descriptive_name: description,
          account: accountId,
          tags: tags,
          contractual_etd_pol: cEtdPol && (0, _moment.default)(cEtdPol).format('YYYY-MM-DD'),
          contractual_eta_pod: cEtaPod && (0, _moment.default)(cEtaPod).format('YYYY-MM-DD'),
          contractual_eta_dlv: cEtaDlv && (0, _moment.default)(cEtaDlv).format('YYYY-MM-DD')
        });
        subscription.save().then(function (result) {
          return _this.onSuccessfulSaveSingle(result);
        }, function (error) {
          return _this.onFailedSaveSingle(error);
        });
      } // end createSubscription

    },
    // end actions
    extractCarrierScacArray: function extractCarrierScacArray() {
      var newArray = [];
      this.sortedCarriers.forEach(function (element) {
        newArray.push(element.get('scac'));
      });
      return newArray;
    }
  });

  _exports.default = _default;
});