define("oi/routes/subscriptions", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "jquery"], function (_exports, _authenticatedRouteMixin, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    titleToken: function titleToken() {
      return 'Subscriptions';
    },
    // -----------------------------------------------------------------------------------------------
    // Model hooks
    // -----------------------------------------------------------------------------------------------
    model: function model(params) {
      params.page_size = params.page_size || 50; // ^ string "1" because when given as url param, int cannot be distinguished from
      // string and it would get converted somewhere anyway => use string throughout

      var addSortCreated = !params.sort_status && !params.sort_request_key && !params.sort_created && !params.sort_request_carrier;

      if (addSortCreated) {
        params.sort_created = 'desc';
      }

      return Ember.RSVP.hash({
        carriers: this.store.findAll('carrier', {
          backgroundReload: false
        }),
        availableTags: this.store.findAll('tag', {
          backgroundReload: false
        }),
        subscriptions: this.store.query('subscription', params),
        params: params,
        addedSortCreated: addSortCreated
      });
    },
    // -----------------------------------------------------------------------------------------------
    // User triggered actions
    // -----------------------------------------------------------------------------------------------
    actions: {
      error: function error()
      /* error, transition */
      {
        return true;
      },
      batchCancel: function batchCancel(params) {
        this.controller.send('batchCancel', params);
        return true;
      },
      batchEdit: function batchEdit(params) {
        this.controller.send('batchEdit', params);
        return true;
      },
      batchArchive: function batchArchive(params) {
        this.controller.send('batchArchive', params);
        return true;
      },
      queryParamsDidChange: function queryParamsDidChange() {
        Ember.run.once(this, this.refresh);
      }
    },
    setupController: function setupController(controller, model) {
      controller.setProperties(Object.assign(model.addedSortCreated ? {
        sort_created: 'desc'
      } : {}, {
        subscriptions: model.subscriptions,
        carriers: model.carriers,
        availableTags: model.availableTags,
        totalPages: model.subscriptions.get('meta.totalPages'),
        count: model.subscriptions.get('meta.count'),
        status: model.params.status,
        // ^v might have default values filled in by model hook
        page_size: model.params.page_size,
        newSearch: model.params.search
      }));
      Ember.run.later(this, function () {
        (0, _jquery.default)('a[data-toggle="tooltip"]').tooltip();
      }, 2000);
    },
    resetController: function resetController(controller) {
      controller.setProperties({
        queryParams: null,
        page: 1,
        selectedTags: []
      });
    }
  });

  _exports.default = _default;
});