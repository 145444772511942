define("oi/components/my-picker", ["exports", "moment", "jquery", "date-range-picker"], function (_exports, _moment, _jquery, _dateRangePicker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // eslint-disable-line no-unused-vars
  // ^ for this.$('.daterangepicker-input').daterangepicker...
  var _default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments); // eslint-disable-line prefer-rest-params


      this.setProperties({
        buttonClasses: ['btn'],
        ranges: {
          Today: [(0, _moment.default)(), (0, _moment.default)()],
          Yesterday: [(0, _moment.default)().subtract(1, 'days'), (0, _moment.default)().subtract(1, 'days')],
          'Last 7 Days': [(0, _moment.default)().subtract(6, 'days'), (0, _moment.default)()],
          'Last 30 Days': [(0, _moment.default)().subtract(29, 'days'), (0, _moment.default)()],
          'Last Month': [(0, _moment.default)().subtract(1, 'month').startOf('month'), (0, _moment.default)().subtract(1, 'month').endOf('month')],
          'This Month': [(0, _moment.default)().startOf('month'), (0, _moment.default)().endOf('month')],
          'Next Month': [(0, _moment.default)().add(1, 'month').startOf('month'), (0, _moment.default)().add(1, 'month').endOf('month')]
        }
      });

      if (this.hideNextMonth) {
        delete this.ranges['Next Month'];
      }
    },
    classNames: ['form-group'],
    attributeBindings: ['start', 'end', 'serverFormat'],
    start: undefined,
    end: undefined,
    minDate: undefined,
    maxDate: undefined,
    timePicker: false,
    timePicker24Hour: false,
    timePickerSeconds: false,
    timePickerIncrement: undefined,
    showWeekNumbers: false,
    showDropdowns: false,
    linkedCalendars: false,
    format: 'YYYY-MM-DD',
    serverFormat: 'YYYY-MM-DD',
    clearBtn: true,
    parentEl: 'body',
    id: null,
    rangeText: Ember.computed('start', 'end', function () {
      var format = this.format,
          serverFormat = this.serverFormat,
          start = this.start,
          end = this.end,
          placeholder = this.placeholder;

      if (!Ember.isEmpty(start) && !Ember.isEmpty(end)) {
        return (0, _moment.default)(start, serverFormat).format(format) + this.separator + (0, _moment.default)(end, serverFormat).format(format);
      }

      return placeholder;
    }),
    opens: null,
    drops: null,
    separator: ' - ',
    singleDatePicker: false,
    placeholder: null,
    applyClass: null,
    cancelClass: null,
    removeDropdownOnDestroy: false,
    cancelLabel: 'Clear',
    applyAction: null,
    autoUpdateInput: true,
    autoApply: false,
    // init the dropdown when the component is added to the DOM
    didInsertElement: function didInsertElement() {
      var self = this;
      var momentStartDate = (0, _moment.default)(this.start, this.serverFormat);
      var momentEndDate = (0, _moment.default)(this.end, this.serverFormat);
      var startDate = momentStartDate.isValid() ? momentStartDate : undefined;
      var endDate = momentEndDate.isValid() ? momentEndDate : undefined;
      var momentMinDate = (0, _moment.default)(this.minDate, this.serverFormat);
      var momentMaxDate = (0, _moment.default)(this.maxDate, this.serverFormat);
      var minDate = momentMinDate.isValid() ? momentMinDate : undefined;
      var maxDate = momentMaxDate.isValid() ? momentMaxDate : undefined;
      var options = {
        autoUpdateInput: this.autoUpdateInput,
        autoApply: this.autoApply,
        locale: {
          cancelLabel: this.cancelLabel,
          format: this.format
        },
        startDate: startDate,
        endDate: endDate,
        minDate: minDate,
        maxDate: maxDate,
        timePicker: this.timePicker,
        buttonClasses: this.buttonClasses,
        applyClass: this.applyClass,
        cancelClass: this.cancelClass,
        separator: this.separator,
        singleDatePicker: this.singleDatePicker,
        drops: this.drops,
        opens: this.opens,
        timePicker24Hour: this.timePicker24Hour,
        timePickerSeconds: this.timePickerSeconds,
        timePickerIncrement: this.timePickerIncrement,
        showWeekNumbers: this.showWeekNumbers,
        showDropdowns: this.showDropdowns,
        linkedCalendars: this.linkedCalendars,
        id: this.id,
        parentEl: 'body'
      };

      if (!this.singleDatePicker) {
        options.ranges = this.ranges;
      }

      this.$('.daterangepicker-input').daterangepicker(options);
      this.$('.daterangepicker-input').on('apply.daterangepicker', function (ev, picker) {
        var start = picker.startDate.format(self.get('serverFormat'));
        var end = picker.endDate.format(self.get('serverFormat'));
        var applyAction = self.get('applyAction');

        if (applyAction) {
          (false && !(typeof applyAction === 'function') && Ember.assert('applyAction for date-range-picker must be a function', typeof applyAction === 'function'));
          applyAction(start, end);
        } else {
          self.setProperties({
            start: start,
            end: end
          });
        }
      });
      this.$('.daterangepicker-input').on('cancel.daterangepicker', function () {
        self.set('start', undefined);
        self.set('end', undefined);
      });
    },
    // Remove the hidden dropdown when this component is destroyed
    willDestroy: function willDestroy() {
      if (this.removeDropdownOnDestroy) {
        (0, _jquery.default)('.daterangepicker').remove();
      }
    }
  });

  _exports.default = _default;
});