define("oi/models/shipment/prediction", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var METHOD_NAMES = {
    1: 'Vessel captain\'s ETA',
    2: 'Risk of Rollovers detected',
    8: 'Statistical / AI prediction',
    16: 'Affected by incident'
  };
  var UNKNOWN_METHOD = 'unknown method';
  var VESSEL_ETA_PREDICTION = 1;
  var ROLLOVER_PREDICTION = 2;
  var AI_PREDICTION = 8;
  var INCIDENT_PREDICTION = 16;
  var SEVERITY_CSS_DEFAULT = 'unknown';
  var SEVERITY_ICON_DEFAULT = 'fa fa-question-circle';
  var SEVERITY_CSS_CLASSES = {
    0: 'success',
    1: SEVERITY_CSS_DEFAULT,
    2: 'warning',
    3: 'danger'
  };
  var SEVERITY_ICONS = {
    0: 'fa fa-check-circle',
    1: SEVERITY_ICON_DEFAULT,
    2: 'fa fa-exclamation-triangle',
    3: 'fa fa-exclamation-triangle'
  }; // Constants for SVG-Generation

  var width = 295;
  var baseLineStart = 12;
  var baseLineLength = width - 2 * baseLineStart;
  var predRangeStyle = '"stroke:#007bff;stroke-width:2"';
  var gridStyle = 'stroke:#cbd2d9;stroke-width:1';
  var planDiffStyle = 'stroke:#ffc107;stroke-width:2';
  var predRectStyle = 'fill:#3e4c59;opacity:0.05';

  var _default = _emberData.default.Model.extend({
    milestone: _emberData.default.belongsTo('shipment/milestone'),
    method: _emberData.default.attr('number'),
    // TODO? transform for enum?!
    methodName: Ember.computed('method', function () {
      return METHOD_NAMES[this.method] || UNKNOWN_METHOD;
    }),
    created: _emberData.default.attr('momentdate'),
    expectedDateWhenCreated: _emberData.default.attr('momentdate'),
    predictedDate: _emberData.default.attr('string'),
    predictedDateLower: _emberData.default.attr('string'),
    predictedDateUpper: _emberData.default.attr('string'),
    daysDiff: Ember.computed('predictedDate', 'expectedDateWhenCreated', 'severity', function () {
      if (this.severity === 0) {
        return null;
      }

      return (0, _moment.default)(this.predictedDate).diff(this.expectedDateWhenCreated) / 86400000;
    }),
    severity: _emberData.default.attr('number'),
    note: _emberData.default.attr('string'),
    daysDiffRounded: Ember.computed('daysDiff', function () {
      var daysDiff = this.daysDiff;
      return daysDiff ? Math.round(daysDiff) : null;
    }),
    daysDiffWorded: Ember.computed('daysDiffRounded', 'milestone.type', function () {
      var diff = this.daysDiffRounded;

      if (!diff) {
        return '';
      }

      var absDiff = Math.abs(diff);
      return "".concat(diff < 0 ? 'early' : 'late', " ").concat(this.get('milestone.type'), " (by ").concat(absDiff, " day").concat(absDiff > 1 ? 's' : '', ")");
    }),
    severityStyle: Ember.computed('severity', 'method', function () {
      if ([ROLLOVER_PREDICTION, INCIDENT_PREDICTION].includes(this.get('method'))) {
        return 'danger';
      }

      return Ember.getWithDefault(SEVERITY_CSS_CLASSES, this.get('severity'), SEVERITY_CSS_DEFAULT);
    }),
    icon: Ember.computed('method', 'severity', function () {
      if ([ROLLOVER_PREDICTION, INCIDENT_PREDICTION].includes(this.get('method'))) {
        return 'fa fa-exclamation-triangle';
      }

      return Ember.getWithDefault(SEVERITY_ICONS, this.get('severity'), SEVERITY_ICON_DEFAULT);
    }),
    shortDescription: Ember.computed('method', function () {
      if ([ROLLOVER_PREDICTION, INCIDENT_PREDICTION].includes(this.get('method'))) {
        return 'Risk of significant delay';
      }

      return null;
    }),
    longDescription: Ember.computed('method', 'milestone.type', function () {
      switch (this.get('method')) {
        case VESSEL_ETA_PREDICTION:
          return "The vessel captain has announced the estimated ".concat(this.get('milestone.type') ? "".concat(this.get('milestone.type'), " ") : '', "date as shown.");

        case ROLLOVER_PREDICTION:
          return 'There is a high risk for significant delays. The container\'s schedule is not plausible and vessel rollovers or re-routings should be expected. An ETA cannot be given at this point yet.';

        case AI_PREDICTION:
          return "This prediction shows the most likely ".concat(this.get('milestone.type') ? "".concat(this.get('milestone.type'), " ") : '', "date calculated using statistics and/or Artificial Intelligence.");

        case INCIDENT_PREDICTION:
          return 'There is a high risk for significant delays. The container schedule is likely affected by a vessel incident, a congested port or similar. Vessel rollovers or re-routings should be expected. An ETA cannot be given at this point yet.';

        default:
          return 'Unknown';
      }
    }),
    svg: Ember.computed({
      get: function get() {
        return Ember.String.htmlSafe(this.generateSvg(this));
      }
    }),
    generateSvg: function generateSvg(prediction) {
      var parse = function parse(dateString) {
        return dateString && _moment.default.parseZone(dateString);
      };

      var predictedDate = parse(prediction.get('predictedDate'));
      var predictionDateLower = parse(prediction.get('predictedDateLower'));
      var predictionDateUpper = parse(prediction.get('predictedDateUpper'));
      var plannedInitial = parse(prediction.get('milestone.plannedInitial'));
      var plannedLast = parse(prediction.get('milestone.plannedLast'));
      var dateArray = [predictedDate, predictionDateLower, predictionDateUpper, plannedInitial, plannedLast].filter(Boolean);

      var minDate = _moment.default.min(dateArray).clone().subtract(1, 'days').startOf('day');

      var maxDate = _moment.default.max(dateArray).clone().add(1, 'days').startOf('day');

      var spanInDays = maxDate.diff(minDate, 'days');
      var timediffInMillis = maxDate.valueOf() - minDate.valueOf();
      var spaceInPixelBetweenDays = baseLineLength / spanInDays; // Create the SVG

      var svgContent = '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink= "http://www.w3.org/1999/xlink">';
      svgContent += '<style>.group { font: 8px sans-serif; fill: #3e4c59;}</style>';
      svgContent += '<style>.label { font: 8px sans-serif; fill: #cbd2d9;}</style>';
      svgContent += '<defs><marker id="arrow" markerWidth="10" markerHeight="10" refX="10" refY="3" orient="auto" markerUnits="strokeWidth" viewBox="0 0 20 20"><path d="M0,0 L0,6 L9,3 z" fill="#f0b429" /></marker></defs>';
      svgContent += "<line x1=\"0\" y1=\"100\" x2=\"".concat(width, "\" y2=\"100\" style=\"").concat(gridStyle, "\"/>"); // Create the base grid

      for (var i = 0; i < spanInDays + 1; i += 1) {
        var x = baseLineStart + i * spaceInPixelBetweenDays;
        svgContent += "<line x1=\"".concat(x, "\" y1=\"110\" x2=\"").concat(x, "\" y2=\"0\" style=\"").concat(gridStyle, "\"/>");
        svgContent += "<circle cx=\"".concat(x, "\" cy=\"100\" r=\"2px\" fill=\"#cbd2d9\"/>");
        var yText = 120;

        if (spaceInPixelBetweenDays < 30) {
          if (!(i % 2 === 0)) {
            yText = 130;
          }
        }

        if (i < spanInDays) {
          var date = minDate.clone().add(i, 'days').format('MMM D');
          var xPos = x + spaceInPixelBetweenDays / 2 - baseLineStart;
          svgContent += "<text x=\"".concat(xPos, "\"\n                                y=\"").concat(yText, "\"\n                                class=\"label\">").concat(date, "</text>");
        }
      } // Prediction


      svgContent += "<rect x=\"0\"\n                             y=\"12\"\n                             rx=\"4\"\n                             ry=\"4\"\n                             width=\"".concat(width, "\"\n                             height=\"35\"\n                             style=\"").concat(predRectStyle, "\"/>");

      if (!(Ember.isEmpty(predictionDateLower) && Ember.isEmpty(predictionDateUpper))) {
        var xLowerTime = predictionDateLower.valueOf() - minDate.valueOf();
        var xUpperTime = predictionDateUpper.valueOf() - minDate.valueOf();
        var xLower = this.getXPosition(timediffInMillis, xLowerTime);
        var xUpper = this.getXPosition(timediffInMillis, xUpperTime);
        svgContent += "<line x1=\"".concat(xLower, "\"\n                               y1=\"30\"\n                               x2=\"").concat(xUpper, "\"\n                               y2=\"30\"\n                               style=").concat(predRangeStyle, "/>");
        svgContent += "<line x1=\"".concat(xLower, "\"\n                               y1=\"25\"\n                               x2=\"").concat(xLower, "\"\n                               y2=\"35\"\n                               style=").concat(predRangeStyle, "/>");
        svgContent += "<line x1=\"".concat(xUpper, "\"\n                               y1=\"25\"\n                               x2=\"").concat(xUpper, "\"\n                               y2=\"35\"\n                               style=").concat(predRangeStyle, "/>");
      }

      if (!Ember.isEmpty(predictedDate)) {
        svgContent += "<text x=\"".concat(width, "\" \n                              y=\"10\"\n                              class=\"group\"\n                              writing-mode=\"tb\">Prediction</text>");
        var xPredTime = predictedDate.valueOf() - minDate.valueOf();
        var xPred = this.getXPosition(timediffInMillis, xPredTime);
        svgContent += "<circle cx=\"".concat(xPred, "\" cy=\"30\" r=\"8px\" fill=\"#28a745\"/>");
      }

      if (!Ember.isEmpty(plannedInitial) && !Ember.isEmpty(plannedLast)) {
        svgContent += "<text x=\"".concat(width, "\" \n                              y=\"60\" \n                              class=\"group\" \n                              writing-mode=\"tb\">Carrier</text>");
        var initialTime = plannedInitial.valueOf() - minDate.valueOf();
        var lastTime = plannedLast.valueOf() - minDate.valueOf();
        var xInitial = this.getXPosition(timediffInMillis, initialTime);
        var xLatest = this.getXPosition(timediffInMillis, lastTime);

        if (xInitial === xLatest) {
          svgContent += "<circle cx=\"".concat(xInitial, "\" cy=\"75\" r=\"5px\" fill=\"#ffc107\"/>");
        } else {
          svgContent += "<circle cx=\"".concat(xInitial, "\" cy=\"75\" r=\"5px\" fill=\"#ffc107\"/>");
          svgContent += "<line x1=\"".concat(xInitial, "\" y1=\"75\" x2=\"").concat(xLatest, "\" y2=\"75\"\n                                 style=\"").concat(planDiffStyle, "\" marker-end=\"url(#arrow)\"/>");
          svgContent += "<circle cx=\"".concat(xLatest, "\" cy=\"75\" r=\"5px\" fill=\"#dc3545\"/>");
        }
      }

      svgContent += '</svg>';
      return svgContent;
    },
    getXPosition: function getXPosition(baseLineInMillis, dateToDrawAsMillis) {
      return baseLineStart + baseLineLength * dateToDrawAsMillis / baseLineInMillis;
    },
    hasInterval: Ember.computed.and('{predictedDateLower,predictedDateUpper}')
  });

  _exports.default = _default;
});