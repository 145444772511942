define("oi/components/oi-switch-account", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    session: Ember.inject.service(),
    user: Ember.inject.service(),
    writablesubAccounts: Ember.computed('session.data.{prefAccountIds,accounts}', function () {
      var accounts = this.get('user.flattenedAccounts');
      var prefAccountIds = this.get('session.data.prefAccountIds') || [];
      var currentAccounts = accounts.filter(function (acc) {
        return prefAccountIds.includes(acc.id);
      });
      var masterAccount = currentAccounts.find(function (acc) {
        return acc.sub_accounts && !acc.read_only;
      });

      if (masterAccount) {
        return masterAccount.sub_accounts;
      }

      return currentAccounts.filter(function (acc) {
        return !acc.read_only;
      });
    }),
    currentValue: Ember.computed('currentAccounts', 'currentAccountId', 'writablesubAccounts', function () {
      var currentAccount = this.currentAccount,
          currentAccountId = this.currentAccountId,
          writablesubAccounts = this.writablesubAccounts;

      if (currentAccount) {
        return currentAccount;
      }

      return writablesubAccounts.find(function (sa) {
        return sa.id === currentAccountId;
      });
    })
  });

  _exports.default = _default;
});