define("oi/components/upcoming-arrivals-chart", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "@babel/runtime/helpers/esm/toConsumableArray"], function (_exports, _slicedToArray2, _toConsumableArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var TARGET_CHART_BASE = {
    chart: {
      type: 'bar',
      zoomType: 'x',
      backgroundColor: 'transparent',
      height: null,
      events: {
        drilldown: null,
        drillup: null
      }
    },
    // , resetZoomButton: { position: { verticalAlign: 'middle' } }
    credits: {
      enabled: false
    },
    title: {
      text: null,
      style: {
        color: '#b9bbbb',
        fontWeight: 'bold'
      }
    },
    legend: {
      reversed: true,
      itemStyle: {
        color: '#b9bbbb'
      }
    },
    tooltip: {},
    plotOptions: {
      series: {
        stacking: 'normal',
        cursor: 'pointer',
        dataLabels: {
          enabled: false
        }
      },
      area: {
        trackByArea: true
      }
    },
    yAxis: {
      title: {
        text: null
      },
      stackLabels: {
        enabled: true,
        crop: false,
        overflow: 'allow',
        align: 'right',
        textAlign: null,
        x: 2,
        y: null,
        rotation: null,
        format: null
      },
      max: null,
      endOnTick: true
    },
    drilldown: {
      series: []
    }
  };

  function rightPad(arr, n) {
    var fill = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;

    // eslint-disable-next-line no-param-reassign
    if (!arr) {
      arr = [];
    }

    while (arr.length < n) {
      arr.push(fill);
    }

    return arr;
  }

  var SPECIAL_SERIES_NAMES = ['overdue', 'next 24 h'];

  function countsArrayToSeries(counts) {
    // matrix transposition with a catch: not all arrays in m
    // need to have the same length (0-padding is implied)
    var numSeries = Math.max.apply(Math, (0, _toConsumableArray2.default)(counts.map(function (arr) {
      return arr.length;
    })));
    return (0, _toConsumableArray2.default)(Array(numSeries).keys()).map(function (i) {
      var intensity = Math.floor(i * 50 / (numSeries - 1)); // used to produce colormap
      // map medium red->orange->bright yellow (with dark red for "overdue")

      return {
        data: counts.map(function (arr) {
          return arr[i] || 0;
        }),
        id: i,
        name: SPECIAL_SERIES_NAMES[i] || "".concat(i - 1, " d+"),
        color: "#".concat(i ? (205 + intensity).toString(16) : '600').concat(((intensity + 1) * 5).toString(16), "00")
      };
    }).filter(function (_ref) {
      var data = _ref.data;
      return data.some(function (el) {
        return el !== 0;
      });
    }).reverse();
  }

  var _default = Ember.Component.extend({
    tagName: 'div',
    countsByPort: Ember.computed('voyages.[]', function () {
      var byPorts = {};
      this.voyages.forEach(function (voyage) {
        var port = voyage.podName || '[unknown]';
        var arrivalInD = voyage.arrivalInD;
        var iBin = arrivalInD !== null && arrivalInD >= 0 ? arrivalInD + 1 : 0;
        var portValues = rightPad(byPorts[port], iBin + 1);
        portValues[iBin] += voyage.numShipments;
        byPorts[port] = portValues;
      });
      return byPorts;
    }),
    countsByPortSorted: Ember.computed('countsByPort', function () {
      return Object.entries(this.countsByPort).map(function (_ref2) {
        var _ref3 = (0, _slicedToArray2.default)(_ref2, 2),
            port = _ref3[0],
            counts = _ref3[1];

        return [port, counts, counts.reduce(function (a, b) {
          return a + b;
        })];
      }).sort(function (_ref4, _ref5) {
        var _ref6 = (0, _slicedToArray2.default)(_ref4, 3),
            total1 = _ref6[2];

        var _ref7 = (0, _slicedToArray2.default)(_ref5, 3),
            total2 = _ref7[2];

        return total2 - total1;
      });
    }),
    content: Ember.computed('countsByPortSorted', function () {
      if (this.countsByPortSorted.length === 0) {
        return [];
      }

      return countsArrayToSeries(this.countsByPortSorted.map(function (_ref8) {
        var _ref9 = (0, _slicedToArray2.default)(_ref8, 2),
            counts = _ref9[1];

        return counts;
      }));
    }),
    chartOptions: Ember.computed('countsByPortSorted', function () {
      var mergedPlotData = Object.assign({
        xAxis: {
          categories: this.countsByPortSorted.map(function (_ref10) {
            var _ref11 = (0, _slicedToArray2.default)(_ref10, 1),
                label = _ref11[0];

            return label;
          })
        }
      }, TARGET_CHART_BASE); // xAxis: {title: { text: null }, type: 'category', labels: {} }

      return mergedPlotData;
    }),

    /* eslint-disable-next-line ember/no-observers */
    // see template for explanation
    hideTickTock: Ember.observer('chartOptions', function () {
      var _this = this;

      this.set('hideMomentarily', true);
      Ember.run.next(function () {
        if (!_this.isDestroyed) {
          _this.set('hideMomentarily', false);
        }
      });
    })
  });

  _exports.default = _default;
});