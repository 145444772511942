define("oi/templates/components/download-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AiSJlrsD",
    "block": "{\"symbols\":[],\"statements\":[[4,\"confirm-modal\",null,[[\"title\",\"ok\",\"close\",\"actionIsImmediate\",\"context\"],[\"Download\",\"download\",\"removeModal\",true,[24,[\"model\"]]]],{\"statements\":[[4,\"if\",[[24,[\"model\",\"askForHistorical\"]]],null,{\"statements\":[[0,\"    Do you wish to include arrived containers (relevant only for the \\\"historical averages\\\")?\\n    \"],[7,\"label\",true],[8],[0,\"\\n      include historical data \\n      \"],[7,\"label\",true],[10,\"class\",\"switch\"],[8],[0,\"\\n        \"],[1,[28,\"input\",null,[[\"type\",\"checked\"],[\"checkbox\",[24,[\"model\",\"includeHistorical\"]]]]],false],[0,\"\\n        \"],[7,\"span\",true],[10,\"class\",\"slider\"],[8],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"br\",true],[8],[9],[0,\"\\n    \"],[7,\"br\",true],[8],[9],[0,\"\\n    \"],[7,\"small\",true],[8],[0,\"\\n      Your download will be prepared in the background. This may take a few seconds.\\n      You can continue to use the app normally. The download will start when it is ready.\\n    \"],[9],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    Your download will be prepared in the background. This may take a few seconds.\\n    \"],[7,\"br\",true],[8],[9],[0,\"\\n    You can continue to use the app normally. The download will start when it is ready.\\n    \"],[7,\"br\",true],[8],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"model\",\"askForUtcConversion\"]]],null,{\"statements\":[[0,\"      \"],[7,\"label\",true],[8],[0,\"\\n        Convert all timestamps to UTC\\n        \"],[7,\"label\",true],[10,\"class\",\"switch\"],[8],[0,\"\\n          \"],[1,[28,\"input\",null,[[\"type\",\"checked\"],[\"checkbox\",[24,[\"model\",\"exportDatesInUtcOnly\"]]]]],false],[0,\"\\n          \"],[7,\"span\",true],[10,\"class\",\"slider\"],[8],[9],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "oi/templates/components/download-modal.hbs"
    }
  });

  _exports.default = _default;
});