define("oi/components/grid-panel", ["exports", "ember-local-storage", "oi/constants/system"], function (_exports, _emberLocalStorage, _system) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    isIE: _system.IS_INTERNET_EXPLORER,
    exceptionmanagementStorage: (0, _emberLocalStorage.storageFor)('exceptionmanagement'),
    showPanelSettings: true,
    storedDarkMode: Ember.computed('darkMode', function () {
      return this.exceptionmanagementStorage.get('darkMode') || this.darkMode;
    }),
    storedColumns: Ember.computed('columns', function () {
      return this.exceptionmanagementStorage.get('columns') || this.columns;
    }),
    sortedItems: Ember.computed('items', 'storedItems', 'exceptionmanagementStorage.sortedIds', function () {
      var _this = this;

      var useServerSort = this.exceptionmanagementStorage.get('useServerSort');
      var sortedIds = this.exceptionmanagementStorage.get('sortedIds');
      var sortedItems = [];

      if (sortedIds === undefined || sortedIds.length === 0 || useServerSort) {
        return this.items;
      }

      sortedIds.forEach(function (id) {
        var item = _this.items.filter(function (i) {
          return i.id === id;
        })[0];

        if (item) {
          sortedItems.push(item);
        }
      });

      if (this.sortOrderAction && sortedIds) {
        this.sortOrderAction(sortedIds);
        this.exceptionmanagementStorage.set('useServerSort', true);
      }

      return sortedItems;
    }),
    column_class: Ember.computed('storedColumns', function () {
      return 12 / this.get('storedColumns');
    }),
    didUpdate: function didUpdate() {
      if (!_system.IS_INTERNET_EXPLORER) {
        window.dispatchEvent(new Event('resize'));
      }
    },
    actions: {
      changeColumns: function changeColumns(value) {
        this.set('exceptionmanagementStorage.columns', value);
        this.set('storedColumns', value);
      },
      changeDarkMode: function changeDarkMode(value) {
        this.set('exceptionmanagementStorage.darkMode', value);
        this.set('storedDarkMode', value);
      },
      sortEndAction: function sortEndAction() {
        var sortedIds = this.sortedItems.map(function (item) {
          return item.id;
        });

        if (sortedIds && this.sortOrderAction) {
          this.sortOrderAction(sortedIds);
        }
      }
    }
  });

  _exports.default = _default;
});