define("oi/components/admin-tags", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    user: Ember.inject.service(),
    accessControlTags: Ember.computed.filterBy('tags', 'isAccessControl', true),
    regularTags: Ember.computed('tags.[]', function () {
      // avoid filterBy to cover tags with `isAccessControl: false` and without any `isAccessControl`.
      return this.tags.filter(function (tag) {
        return !tag.isAccessControl;
      });
    }),
    canViewRegularTags: Ember.computed('user.{canEditAccessControlTags,atLeastOneAccountCanWrite}', 'accessControlTags.[]', function () {
      return true;
    }),
    canEditRegularTags: Ember.computed('user.{canEditAccessControlTags,atLeastOneAccountCanWrite}', 'accessControlTags.[]', function () {
      return this.user.atLeastOneAccountCanWrite;
    }),
    canViewAccessControlTags: Ember.computed('user.{canEditAccessControlTags,atLeastOneAccountCanWrite}', 'accessControlTags.[]', function () {
      return this.user.canEditAccessControlTags;
    }),
    canEditAccessControlTags: Ember.computed('user.{canEditAccessControlTags,atLeastOneAccountCanWrite}', 'accessControlTags.[]', function () {
      return this.user.canEditAccessControlTags;
    }),
    canSeeBoth: Ember.computed.and('canViewRegularTags', 'canViewAccessControlTags'),
    notify: Ember.inject.service(),
    notifyOnError: function notifyOnError(error, subject) {
      var firstError = (error.errors || [])[0] || error;
      var value = subject.get('value');

      if (this.tags.filterBy('value', value).length > 1) {
        this.notify.error("There is already a tag named \"".concat(value, "\"."), {
          closeAfter: 5000
        });
      } else if ("".concat(firstError.status) === '403' || error.message === 'User does not have permissions to edit account settings.') {
        // TODO: check current user's accounts' read-only flag before allowing changes!
        this.notify.error('Sorry, you do not have permission to do this.', {
          closeAfter: 5000
        });
      } else if ("".concat(firstError.status) === '500') {
        this.notify.error("Sorry, that didn't work. Server returned an error.", {
          closeAfter: 5000
        });
      } else {
        this.notify.error("Sorry, that didn't work (unexpected error).", {
          closeAfter: 5000
        });
      }

      subject.rollbackAttributes();
    },
    actions: {
      requestDelete: function requestDelete(tag) {
        this.showModal('components/requesttagdelete-modal', tag, function (record) {
          return record.destroyRecord();
        } // TODO: 3rd argument seems useless
        );
      },
      add: function add() {
        var _this = this;

        var value = this.newTagName;
        var tag = this.store.createRecord('tag', {
          value: value
        });
        this.set('newTagName', null);
        return tag.save().catch(function (error) {
          return _this.notifyOnError(error, tag);
        });
      },
      update: function update(tag) {
        var _this2 = this;

        return tag.save().catch(function (error) {
          return _this2.notifyOnError(error, tag);
        });
      }
    }
  });

  _exports.default = _default;
});