define("oi/components/exceptionmanagement/definition-stepper", ["exports", "@babel/runtime/helpers/esm/toConsumableArray", "oi/constants/visual-query-filter/filterdefinitions", "ember-local-storage"], function (_exports, _toConsumableArray2, _filterdefinitions, _emberLocalStorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var LAST_STEP = 1;

  var _default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this.selectedTemplates = this.selectedTemplates || [];
      this.templateErrors = this.templateErrors || [];
    },
    store: Ember.inject.service(),
    exceptionmanagementStorage: (0, _emberLocalStorage.storageFor)('exceptionmanagement'),
    vqfFilters: Ember.computed(function () {
      return (0, _filterdefinitions.getFilter)(this.get('store'));
    }),
    lastStep: LAST_STEP,
    definitionRule: Ember.computed('definition.rule', function () {
      // create a copy of rules without data binding.
      // Otherwise changes would be applied regardless of whether the user cancels or submits
      if (this.definition) {
        return JSON.parse(JSON.stringify(this.definition.rule));
      }

      return undefined;
    }),
    fieldErrors: undefined,
    templatesOpen: false,
    saveProgress: 0,
    isSavingTemplate: false,
    hideTemplates: true,
    filteredTemplates: Ember.computed('templatesOpen', 'hideTemplates', function () {
      var templates = this.get('templates');
      var hideTemplates = this.get('hideTemplates');

      if (hideTemplates) {
        var templatesInUse = new Set(this.get('definitions').map(function (definition) {
          return definition.template_id;
        }));
        return templates.filter(function (template) {
          return templatesInUse.has(parseInt(template.id, 10)) === false;
        });
      }

      return templates;
    }),
    willUpdate: function willUpdate() {
      if (this.fieldErrors) {
        this.checkErrors();
      }
    },
    setInitialState: function setInitialState() {
      this.setProperties({
        templateErrors: [],
        fieldErrors: undefined,
        saveProgress: 0,
        isSavingTemplate: false,
        selectedTemplates: []
      });
    },
    checkErrors: function checkErrors() {
      var _this = this;

      var errors = {};
      var fieldsToCheck = ['title', 'description'];
      fieldsToCheck.forEach(function (item) {
        if (!_this.definition || !_this.definition[item] || !_this.definition[item].replace(/\s/g, '')) {
          errors[item] = 'This field can not be empty';
        }
      });

      if (!this.definition || this.definitionRule.rules.length === 0) {
        errors.rules = 'Please define at least one rule';
      }

      if (Object.keys(errors).length === 0) {
        this.set('fieldErrors', undefined);
      } else {
        this.set('fieldErrors', errors);
      }
    },
    updateSortedIds: function updateSortedIds(id) {
      var storedIds = this.exceptionmanagementStorage.get('sortedIds') || [];

      if (storedIds.indexOf(id) === -1) {
        storedIds.pushObject(id);
        this.set('exceptionmanagementStorage.sortedIds', storedIds);
      }
    },
    saveDefinitions: function saveDefinitions(template) {
      var _this2 = this;

      var title = template.title,
          description = template.description,
          rule = template.rule,
          id = template.id;
      var definition = this.store.createRecord('exceptionmanagement/definition', {
        title: title,
        description: description,
        rule: rule,
        template_id: id
      });
      return definition.save().then(function (res) {
        _this2.updateSortedIds(res.id);
      }).catch(function (err) {
        var templateErrors = _this2.get('templateErrors');

        templateErrors.pushObject({
          title: title,
          errors: err.errors
        });

        _this2.set('templateErrors', templateErrors);
      });
    },
    actions: {
      saveItem: function saveItem(definition) {
        var _this3 = this;

        this.checkErrors();

        if (!this.fieldErrors) {
          var isNew = this.get('definition.isNew');
          this.set('definition.rule', this.definitionRule);
          definition.save().then(function (res) {
            _this3.updateSortedIds(res.id);

            _this3.setInitialState();

            if (isNew) {
              // Refresh list of all exceptions
              _this3.refreshDefinitions();
            }

            _this3.set('definition', undefined);
          }).catch(function (errors) {
            _this3.set('errors', errors);
          });
        }
      },
      cancel: function cancel(modal) {
        modal.close();
        this.setInitialState();
      },
      changeActiveStep: function changeActiveStep(step) {
        this.checkErrors();

        if (!this.fieldErrors) {
          this.set('activeStep', step);
        }
      },
      toggleTemplate: function toggleTemplate(template) {
        var arr = (0, _toConsumableArray2.default)(this.selectedTemplates);

        if (this.selectedTemplates.includes(template)) {
          arr = arr.filter(function (i) {
            return i !== template;
          });
        } else {
          arr.push(template);
        }

        this.set('selectedTemplates', arr);
      },
      selectTemplate: function selectTemplate(templates, modal) {
        var _this4 = this;

        this.toggleProperty('isSavingTemplate');
        var idx = 1;
        Promise.all(templates.map(function (object) {
          return new Promise(function (resolve, reject) {
            _this4.saveDefinitions(object).then(function () {
              resolve(_this4.set('saveProgress', (idx + 1) / templates.length * 100));
              idx += 1;
            }).catch(function (err) {
              resolve(_this4.set('saveProgress', (idx + 1) / templates.length * 100));
              idx += 1;
              var title = object.title;

              _this4.templateErrors.pushObject({
                title: title,
                errors: err.errors
              });

              reject(err);
            });
          }).catch(function () {});
        })).then(function () {
          _this4.set('isSavingTemplate', false);

          _this4.refreshDefinitions();

          if (_this4.templateErrors.length === 0) {
            modal.close();

            _this4.setInitialState();

            _this4.cancel();
          }
        });
      },
      selectAndEditTemplate: function selectAndEditTemplate(template) {
        var title = template.title,
            description = template.description,
            rule = template.rule,
            id = template.id;
        this.definition.setProperties({
          title: title,
          description: description,
          rule: rule,
          template_id: id
        });
        this.set('definition.activeStep', 1);
        this.set('templatesOpen', false);
      }
    }
  });

  _exports.default = _default;
});