define("oi/routes/upcoming-arrivals", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import { once } from '@ember/runloop';
  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    titleToken: function titleToken() {
      return 'POD Upcoming Arrivals';
    },
    queryParams: {
      maxDays: {
        refreshModel: true
      },
      referenceDates: {
        refreshModel: true
      }
    },
    model: function model(params) {
      // Do not return this.store.query directly, do not wrap it in a `hash`
      // so that the controller can deal directly with `voyages` being a
      // promise and we do not need a separate `podapproach_loading` template
      // (so the podapproach controller can show the filters already while
      // the model loads).
      return {
        voyages: this.store.query('container-voyage', {
          transport_status: 16,
          lifecycle_status: 0,
          max_days: params.maxDays,
          reference_dates: params.referenceDates
        })
      };
    },
    setupController: function setupController(controller, model) {
      controller.setProperties(model);
    }
  });

  _exports.default = _default;
});