define("oi/models/sharedlink", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    token: _emberData.default.attr('string'),
    shipment: _emberData.default.belongsTo('shipment'),
    subscription: _emberData.default.belongsTo('subscription'),
    created: _emberData.default.attr('date'),
    isExpired: _emberData.default.attr('boolean'),
    isRevoked: _emberData.default.attr('boolean'),
    creator_name: _emberData.default.attr('string'),
    humanReadableStatus: Ember.computed('isExpired', 'isRevoked', function () {
      var isExpired = this.isExpired,
          isRevoked = this.isRevoked;

      if (!isExpired && !isRevoked) {
        return 'active';
      }

      if (isExpired) {
        return 'expired';
      }

      if (isRevoked) {
        return 'revoked';
      }

      return 'unknown';
    })
  });

  _exports.default = _default;
});