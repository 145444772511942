define("oi/templates/taskmanagement", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JMW1Xi9u",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"id\",\"wrapper\"],[11,\"class\",[28,\"if\",[[24,[\"selectedTask\"]],\"toggled\"],null]],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"id\",\"page-content-wrapper\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"container\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"page-header\"],[8],[0,\"\\n        \"],[7,\"h1\",true],[8],[0,\"Tasks and Alerts\"],[9],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"p\",true],[10,\"class\",\"lead\"],[8],[0,\"The following tasks, alerts or exceptions are either created by or assigned to you by other users.\"],[9],[0,\"\\n      \"],[1,[28,\"tasks/tasks\",null,[[\"title\",\"tasks\",\"page\",\"page_size\",\"count\",\"totalPages\",\"type\",\"appearence\",\"status\",\"selectedTask\",\"createTask\"],[\"My Tasks and Alerts\",[24,[\"model\",\"tasks\"]],[24,[\"page\"]],[24,[\"page_size\"]],[24,[\"model\",\"tasks\",\"meta\",\"cound\"]],[24,[\"model\",\"tasks\",\"meta\",\"totalPages\"]],[24,[\"type\"]],[24,[\"appearence\"]],[24,[\"status\"]],[24,[\"selectedTask\"]],[28,\"action\",[[23,0,[]],[24,[\"send\"]],\"createNewTask\",null,null],null]]]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "oi/templates/taskmanagement.hbs"
    }
  });

  _exports.default = _default;
});