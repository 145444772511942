define("oi/templates/components/changelog-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "H9RkfsRL",
    "block": "{\"symbols\":[],\"statements\":[[4,\"confirm-modal\",null,[[\"title\",\"dismissOnlyText\",\"close\",\"modalClass\",\"modalBodyClass\"],[\"Recent Changes\",\"OK\",\"removeModal\",\"recent-changes-modal\",\"recent-change-modal-body\"]],{\"statements\":[[0,\"  \"],[1,[28,\"markdown-to-html\",[[24,[\"changelog\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "oi/templates/components/changelog-modal.hbs"
    }
  });

  _exports.default = _default;
});