define("oi/routes/shipments", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "ember-simple-auth/mixins/authenticated-route-mixin", "oi/constants/shipments-filter"], function (_exports, _slicedToArray2, _authenticatedRouteMixin, _shipmentsFilter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    titleToken: function titleToken() {
      return 'Shipments';
    },
    flag: null,
    // ---------------------------------------------------------------------------
    fetchShipments: function fetchShipments(params) {
      var query = {};
      var isExplicitlySorted = false;
      Object.entries(params).forEach(function (_ref) {
        var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
            key = _ref2[0],
            value = _ref2[1];

        if (!value) {
          return;
        }

        query[key] = value;

        if (key.startsWith('sort_')) {
          isExplicitlySorted = true;
        }
      });

      if (!Ember.isPresent(params.page_size)) {
        query.page_size = 50; // parseInt(this.get('settings.page_size'));
      }

      if (params.service) {
        query.service = params.service;
      }

      if (params.filter_preset && params.filter_preset.startsWith('event_')) {
        var eventCode = params.filter_preset.split('_')[1];
        var eventHours = query.exception_days * 24 || params.events.split('__')[1] || _shipmentsFilter.DEFAULT_EXCEPTION_DAYS * 24;

        if (params.exception_days !== eventHours / 24) {
          params.exception_days = eventHours / 24;
        }

        query.events = "".concat(eventCode, "__").concat(eventHours); // convert days into hours for backend
      } // no user defined exception Type


      if (params.filter_preset && _shipmentsFilter.GENERIC_FILTER_PRESETS.includes(params.filter_preset)) {
        query.exception_days = params.exception_days || _shipmentsFilter.DEFAULT_EXCEPTION_DAYS;
      }

      if (params.exception_definition) {
        query.exception_definition = params.exception_definition;
      }

      if (isExplicitlySorted) {
        // a sort is set in the URL as query param - so let 'em win & get rid of the flag
        this.set('flag', null);
      } else {
        // NO filter is set in URL, so set the sort_id_date=desc as default!
        // and query the ORM accordingly
        query.sort_id_date = 'desc';
        this.set('flag', 1);
      }

      return this.store.query('shipment', query);
    },
    extractPageSize: function extractPageSize(params) {
      if (Ember.isPresent(params.page_size)) {
        return parseInt(params.page_size, 10);
      }

      return 50; // parseInt(this.get('settings.page_size'));
    },
    model: function model(params) {
      this.set('redirectSingle', params.redirectSingle);
      delete params.redirectSingle;
      return Ember.RSVP.hash({
        carriers: this.store.findAll('carrier', {
          backgroundReload: false
        }),
        availableTags: this.store.findAll('tag', {
          backgroundReload: false
        }),
        services: params.service && !this.store.peekAll('service').length ? this.store.findAll('service') // Usually, controller will fetch all services once the user clicks into the GUI element that uses them.
        // However, when the route is first entered with filters already set (e.g. page reload), the corresponding
        // services are needed so that the filter displays them correctly before being clicked, and we might as well
        // load them all here.
        : undefined,
        shipments: this.fetchShipments(params),
        params: params
      });
    },
    redirect: function redirect(model) {
      if (this.redirectSingle && model.shipments.get('length') === 1) {
        var shipment = model.shipments.objectAt(0);
        this.transitionTo('shipment', {
          shipment: shipment,
          shipment_id: shipment.id,
          allAvailableTags: model.availableTags
        });
      }
    },
    actions: {
      error: function error()
      /* error, transition */
      {
        return true;
      },
      queryParamsDidChange: function queryParamsDidChange() {
        Ember.run.once(this, this.refresh);
      }
    },
    // ---------------------------------------------------------------------------
    // Controller hooks
    // ---------------------------------------------------------------------------
    setupController: function setupController(controller, model) {
      var controllerProps = Object.assign(this.flag ? {
        sort_id_date: 'desc'
      } : {}, {
        data: model.shipments,
        carriers: model.carriers,
        totalPages: model.shipments.get('meta.totalPages'),
        count: model.shipments.get('meta.count') || Ember.get(model, 'shipments.length'),
        // || ... part for tests with mirage fixtures
        events: model.params.events,
        availableTags: model.availableTags,
        page_size: this.extractPageSize(model.params),
        newSearch: model.params.search,
        exception_days: model.params.exception_days || _shipmentsFilter.DEFAULT_EXCEPTION_DAYS,
        exception_definition: model.params.exception_definition
      });

      if (model.services) {
        controllerProps.services = model.services;
      }

      controller.setProperties(controllerProps);
    },
    // ---------------------------------------------------------------------------
    resetController: function resetController(controller
    /* , isExiting, transition */
    ) {
      // we have to reset all query params, otherwise they stick around also when
      // clicking an exception (landing page) or macnorris link (statistics) and
      // mess up the results
      controller.setProperties({
        queryParams: undefined,
        lifecycle_status: undefined,
        events: undefined,
        filter_preset: undefined,
        exception_days: undefined,
        exception_definition: undefined,
        page: 1,
        redirectSingle: undefined,
        status: undefined,
        carrier: undefined,
        search: undefined,
        cv: undefined,
        origin_country: undefined,
        origin_location: undefined,
        destination_country: undefined,
        destination_location: undefined,
        tags: undefined,
        selectedTags: [],
        contracted: undefined,
        exclude_inland: undefined,
        reference: undefined,
        macnorris: undefined,
        service: undefined
      });
    }
  });

  _exports.default = _default;
});