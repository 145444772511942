define("oi/components/oi-container-lifemon-status", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // provides a lifecycle status + monitoring status indicators with a special "compact" mode.
  var LIFECYCLE_STATUS = [
  /* python
  LIFECYCLE_STATUS = Enum([
  (0, u'active', 'ACTIVE'),
  (1, u'lifecycle_completed', 'LIFECYCLE_COMPLETED'),
  (2, u'expired', 'EXPIRED'),
  (3, u'new_jouney_detected', 'NEW_JOURNEY_DETECTED'),
  (4, u'removed_from_booking', 'REMOVED_FROM_BOOKING'),
  ]
  */
  {
    title: 'Container is actively monitored',
    icon: 'glyphicon glyphicon-eye-open'
  }, {
    title: 'Container monitoring successfully completed',
    icon: 'glyphicon glyphicon-eye-close'
  }, {
    title: 'Container monitoring stopped; no further updates received',
    icon: 'glyphicon glyphicon-eye-close'
  }, {
    title: 'Container monitoring stopped; container is on new journey',
    icon: 'glyphicon glyphicon-eye-close'
  }, {
    title: 'Container monitoring stopped; container has been removed by carrier',
    icon: 'glyphicon glyphicon-eye-close'
  }];
  var MONITORING_STATUS = [
  /* python:
  SUBSCRIPTION_STATUS = Enum([
      (0, u'new / initializing', 'NEW'),
      (1, u'active', 'ACTIVE'),
      (2, u'completed', 'NO_FURTHER_UPDATES'),  # no further carrier updates (another journey or expired)
      (3, u'cancelled', 'CANCELLED'),
      (4, u'invalid', 'INVALID'),
      (5, u'archived', 'ARCHIVED'),
  ], sort_choices=False)
  */
  {
    title: 'Shipment monitoring is initializing.',
    icon: 'glyphicon glyphicon-wrench'
  }, {
    title: 'Shipment is actively monitored.',
    icon: 'glyphicon glyphicon-play'
  }, {
    title: 'Shipment monitoring completed.',
    icon: 'glyphicon glyphicon-check'
  }, {
    title: 'Shipment monitoring cancelled by user.',
    icon: 'glyphicon glyphicon-ban-circle'
  }, {
    title: 'Shipment reference is invalid.',
    icon: 'glyphicon glyphicon-exclamation-sign'
  }, {
    title: 'Shipment has been archived.',
    icon: 'glyphicon glyphicon-briefcase'
  }];

  var _default = Ember.Component.extend({
    tagName: 'span',
    classNames: ['oi-lifemon-status'],
    attributeBindings: ['title'],
    classNameBindings: ['icon'],
    monitoringStatus: 0,
    lifecycleStatus: 0,
    mode: 'compact',
    title: Ember.computed.alias('status.title'),
    icon: Ember.computed.alias('status.icon'),
    status: Ember.computed('monitoringStatus', 'lifecycleStatus', 'mode', function () {
      var monitoringStatus = this.monitoringStatus,
          lifecycleStatus = this.lifecycleStatus,
          mode = this.mode;

      if (mode === 'lifecycle') {
        return LIFECYCLE_STATUS[lifecycleStatus];
      }

      if (mode === 'monitoring') {
        return MONITORING_STATUS[monitoringStatus];
      } // else: mode == compact:


      if (monitoringStatus !== 2 || lifecycleStatus === 1) {
        // ms !== 2: we have issues on monitoring level
        // in all these cases, the "ms" value is more important
        // ls===2 & ls===1: everything is fine on lifecycle level as well -- show checkmark
        return MONITORING_STATUS[monitoringStatus];
      } // there is an issue on lifecycle level: show it


      return LIFECYCLE_STATUS[lifecycleStatus];
    }),
    didRender: function didRender() {
      this._super.apply(this, arguments);

      (0, _jquery.default)('.glyphicon').tooltip();
    }
  });

  _exports.default = _default;
});