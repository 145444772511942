define("oi/templates/components/batchcancel-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mt2nod2w",
    "block": "{\"symbols\":[],\"statements\":[[4,\"confirm-modal\",null,[[\"title\",\"ok\",\"context\",\"close\"],[\"Batch cancelling subscriptions\",\"batchCancel\",[24,[\"model\"]],\"removeModal\"]],{\"statements\":[[0,\"  Really want to cancel \"],[1,[24,[\"model\",\"length\"]],false],[0,\" subscriptions?\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "oi/templates/components/batchcancel-modal.hbs"
    }
  });

  _exports.default = _default;
});