define("oi/templates/components/oi-show-tags", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FYNV5DoF",
    "block": "{\"symbols\":[\"tag\",\"tag\"],\"statements\":[[4,\"unless\",[[24,[\"skipIcon\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"oi-icon-with-hint\",[\"fa-tag\"],[[\"label\",\"classNames\"],[\"Tags\",\"oi-gap1\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"hasUndefinedValues\"]]],null,{\"statements\":[[0,\"  Loading tags...\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"each\",[[24,[\"tags\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"oi-tag\",[[23,2,[]]],null],false],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"\\n\"],[4,\"each\",[[24,[\"brightTags\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"oi-tag\",[[23,1,[]]],[[\"bright\"],[true]]],false],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[0,\"    \"],[1,[28,\"unless\",[[24,[\"tags\",\"length\"]],\"--\"],null],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "oi/templates/components/oi-show-tags.hbs"
    }
  });

  _exports.default = _default;
});