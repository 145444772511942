define("oi/components/oi-contractual-eta", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    setIsStartDate: function setIsStartDate(referenceDate) {
      // We want to display the "not too early" hint when a user navigates to the
      // earliest possible month, which we can only know about via an action fired
      // by the datepicker component, not via observing any property
      this.set('isStartDateMonth', referenceDate.getMonth() === this.startDate.getMonth() && referenceDate.getFullYear() === this.startDate.getFullYear());
    },
    startDateHint: 'The contractual date cannot be much earlier than the first recorded shipment activity.',
    actions: {
      edit: function edit() {
        this.set('newEta', this.eta);

        if (this.startDate) {
          this.setIsStartDate(new Date());
        }
      },
      update: function update() {
        this.set('eta', this.newEta);
        return this.update();
      },
      monthChanged: function monthChanged(newMonthDate) {
        if (this.startDate) {
          this.setIsStartDate(newMonthDate);
        }
      }
    }
  });

  _exports.default = _default;
});