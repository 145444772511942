define("oi/custom/pollster", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend({
    schedule: function schedule(f) {
      return Ember.run.later(this, function () {
        f.apply(this);
        this.set('timer', this.schedule(f));
      }, 10000); // Time between polls (in ms)
    },
    // Stops the pollster
    stop: function stop() {
      Ember.run.cancel(this.timer);
    },
    // Starts the pollster, i.e. executes the `onPoll` function every interval.
    start: function start() {
      this.onPoll(); // make the first call by hand to avoid 10000 ms delay!

      this.set('timer', this.schedule(this.onPoll));
    },
    // will be overwritten when creating an instance!
    onPoll: function onPoll() {// Issue JSON request and add data to the store
    }
  });

  _exports.default = _default;
});