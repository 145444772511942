define("oi/routes/reset", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // can be visited both unauthenticated (with query params) or authenticated,
  // hence no mixin - controller differentiates behaviour
  var _default = Ember.Route.extend({
    titleToken: function titleToken(model) {
      return model.isInvite ? 'User Invitation' : 'Reset password';
    },
    model: function model(params) {
      var isInvite = params.invite;
      return Ember.RSVP.hash({
        isInvite: isInvite
      });
    },
    resetController: function resetController(controller) {
      controller.setProperties({
        errorMessage: '',
        notificationType: null,
        currentPassword: '',
        newpasswordone: '',
        newpasswordtwo: ''
      });
    }
  });

  _exports.default = _default;
});