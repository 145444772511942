define("oi/components/zoom-indicator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: 'pull-right',
    tooltip: Ember.computed('zoomDirection', 'pluralizeTooltip', function () {
      if (!this.zoomDirection) {
        return null;
      } // eslint-disable-next-line no-nested-ternary


      var zoomDirectionVerbal = this.zoomDirection === 'x' ? 'horizontally' : this.zoomDirection === 'y' ? 'vertically' : '';
      return "".concat(this.pluralizeTooltip ? 'These charts' : 'This chart', " supports zooming in.\n\n\n    ").concat(this.pluralizeTooltip ? 'In charts with many items, click\n   ' : 'Click', " one end of the region to zoom in to, \n    hold the mouse button and drag the \n    mouse ").concat(zoomDirectionVerbal, " to the other end of the \n    region, then release the mouse button.");
    })
  });

  _exports.default = _default;
});