define("oi/serializers/boxtech-info", ["exports", "oi/serializers/drf"], function (_exports, _drf) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _drf.default.extend({
    primaryKey: 'number'
  });

  _exports.default = _default;
});