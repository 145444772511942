define("oi/services/raven", ["exports", "ember-cli-sentry/services/raven"], function (_exports, _raven) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _raven.default.extend({
    // unhandledPromiseErrorMessage: '',
    // eslint-disable-next-line ember/avoid-leaking-state-in-ember-objects
    ignoreErrors: [// has to be modifiable, else service init fails
    'The adapter operation was aborted', // lost internet connection...we do not need to know
    /property 'tagName' of .*?null/, // happens in ember-countup when user navigates away
    // from landing page within 1s after exception data was loaded (or possibly before)
    'c.d is null', // same as above, firefox-specific message
    "null is not an object (evaluating 'c.d.tagName')", // ~, safari
    // // These following two occur when a user reopens a previously closed tab and the token
    // // from localStorage has expired in the meantime. However, there was a bug breaking
    // // token refresh in late 2020 that lead to a vastly increased number of these events,
    // // so they might still be useful (while a few a day may be ignored).
    // 'User is not authenticated.',
    // 'Unauthorized',
    'Unhandled Promise error detected', // usually preceeded by a failed token refresh after being offline for
    // a while; error message of no further use (https://sentry.oi-services.net/organizations/sentry/issues/153)
    'Aborted' // usually related to someone losing connection; or bots (?) probing nonsense routes
    ],
    captureMessage: function captureMessage(message, options) {
      if (this.isRavenUsable) {
        return this._super(message, options);
      } // default ember-cli-sentry behaviour is to throw an error in development
      // mode, but it suffices if captureException does that...there should be a
      // method to send something to raven even when the situation is recoverable.
      // eslint-disable-next-line no-console


      var method = console[(options || {}).level || 'warn'] || console.warn;
      method(message, options);
      return true;
    }
  });

  _exports.default = _default;
});