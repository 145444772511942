define("oi/authenticators/oauth2", ["exports", "ember-simple-auth/authenticators/oauth2-password-grant"], function (_exports, _oauth2PasswordGrant) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.CLIENT_ID_HEADER = void 0;
  var CLIENT_ID_HEADER = Object.freeze({
    Authorization: 'Basic M2NWWU1QMzZQejA2R0xZU1ZOTGFBaHNISXVlUmhWcmIwU0J6cUpMdDpoSHNlYXNoNjNYNnpaNHdIZlBmek1WbDJPbFVZekJmQXBheTNWYVI0NFZMRTc0SXdoVnpOa1RjQm5Bd1lOTERVSHZCVjVUem8xMVJxVHVXbURaakNqbzZzbVhYd0pIdGVLdjMzR1ZSR3ZrYnhqSjBLUk1SY3FPVFhJdkY2cmpLcw=='
  });
  _exports.CLIENT_ID_HEADER = CLIENT_ID_HEADER;
  var RETRY_REFRESH_S = 15;

  var _default = _oauth2PasswordGrant.default.extend({
    applicationService: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      this.serverTokenEndpoint = "".concat(this.applicationService.apiHost, "/o/token/");
      this.serverTokenRevocationEndpoint = "".concat(this.applicationService.apiHost, "/o/revoke-token/");
    },
    // clientId: config.APP.CLIENT_ID, // ember-simple-auth tries to encode the client
    // // using btoa(clientId.concat(':'), which gives a slighty different result than
    // // btoa(clientId.join(':')), which was used before it was embedded directly above)
    _clientIdHeader: CLIENT_ID_HEADER,
    nextRefreshIsRetry: false,
    _refreshAccessToken: function _refreshAccessToken(expiresIn, refreshToken) {
      var _this = this;

      if (this.nextRefreshIsRetry) {
        this.toggleProperty('nextRefreshIsRetry');
        return this._super(expiresIn, refreshToken);
      }

      return this._super(expiresIn, refreshToken).catch(function () {
        _this.toggleProperty('nextRefreshIsRetry'); // eslint-disable-next-line no-underscore-dangle


        _this._scheduleAccessTokenRefresh(RETRY_REFRESH_S, null, refreshToken);
      });
    }
  });

  _exports.default = _default;
});