define("oi/services/application-service", ["exports", "oi/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    apiHost: Ember.computed(function () {
      var host = _environment.default.APP.UI_API_HOSTS[window.location.hostname];

      if (host) {
        return host;
      }

      return _environment.default.APP.API_HOST;
    }),
    redirectLink: Ember.computed(function () {
      var redirectLink = _environment.default.APP.P44_REDIRECT_BACK_TO[window.location.host];

      if (redirectLink) {
        return redirectLink;
      }

      return "".concat(window.location.protocol, "//").concat(window.location.host, "/#/index");
    }),
    apiBase: Ember.computed('apiHost', function () {
      return "".concat(this.get('apiHost'), "/").concat(_environment.default.APP.API_NAMESPACE);
    }),
    linkToVoc: Ember.computed(function () {
      return _environment.default.APP.P44_LINKS_TO_VOC[window.location.hostname];
    })
  });

  _exports.default = _default;
});