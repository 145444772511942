define("oi/adapters/exceptionmanagement/definition", ["exports", "oi/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ACTIONS = ['updateRecord', 'deleteRecord', 'findRecord'];

  var _default = _application.default.extend({
    applicationService: Ember.inject.service(),
    buildURL: function buildURL(type, id, snapshot, requestType) {
      if (ACTIONS.includes(requestType)) {
        return "".concat(this.applicationService.apiBase, "/exception-management/definitions/").concat(id, "/");
      }

      return "".concat(this.applicationService.apiBase, "/exception-management/definitions/");
    }
  });

  _exports.default = _default;
});