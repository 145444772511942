define("oi/components/upcoming-arrivals-filter", ["exports", "@babel/runtime/helpers/esm/toConsumableArray", "moment"], function (_exports, _toConsumableArray2, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var PREDICTION_PERMISSION = function PREDICTION_PERMISSION(permissions) {
    return permissions && Ember.get(permissions, 'predictions.feature_enabled');
  };

  var REFERENCE_DATE_OPTIONS = [{
    id: 'planned',
    name: 'carrier ETA'
  }, {
    id: 'predicted,planned',
    name: 'prediction if available, else carrier ETA',
    condition: PREDICTION_PERMISSION
  }];
  var EXPORT_HEADER = ['POD-LOCODE', 'POD-NAME', 'CARRIER', 'SUBSCRIPTIONS', 'SUBSCRIPTION-TAGS', 'NUM-OF-SHIPMENTS', 'SHIPMENTS', 'VESSEL', 'VOYAGE', 'DESCRIPTIONS', 'ETA', 'DELAY_IN_DAYS', 'ARRIVAL_IN_DAYS'];

  var getEtaAsStringValue = function getEtaAsStringValue(eta) {
    if (eta) {
      if (eta.min === eta.max) {
        return "".concat(eta.max);
      }

      return "".concat(eta.min, " - ").concat(eta.max);
    }

    return '';
  };

  var getExportData = function getExportData(voyages, type) {
    var data = [EXPORT_HEADER];

    if (voyages) {
      voyages.forEach(function (voyage) {
        var subscriptions = '';
        voyage.get('subscriptions').forEach(function (subscription) {
          subscriptions += "".concat(subscription.get('request_key'), ", ");
        });
        var shipments = '';
        voyage.get('shipments').forEach(function (shipment) {
          shipments += "".concat(shipment.get('container_number'), ", ");
        });
        data.push([voyage.get('podLocode'), voyage.get('podName'), voyage.get('carrierNames').join(', '), subscriptions, (0, _toConsumableArray2.default)(voyage.subscriptionTags).map(function (tag) {
          return tag.value;
        }).join(', '), voyage.get('numShipments'), shipments, voyage.get('vesselName'), voyage.get('voyage'), voyage.descriptions.join(', '), getEtaAsStringValue(voyage.getEta(type)), voyage.getDelay(type), voyage.get('arrivalInD')]);
      });
    }

    return data;
  };

  var _default = Ember.Component.extend({
    excel: Ember.inject.service(),
    daysRange: Object.freeze({
      min: 0,
      max: 14
    }),
    daysPips: Object.freeze({
      mode: 'values',
      values: [0, 7, 14],
      stepped: true
    }),
    session: Ember.inject.service(),
    referenceDateOptions: Ember.computed('session.data.features', function () {
      var permissions = this.get('session.data.features');
      return REFERENCE_DATE_OPTIONS.filter(function (_ref) {
        var condition = _ref.condition;
        return !condition || condition(permissions);
      });
    }),
    referenceDateSelection: Ember.computed('referenceDates', {
      get: function get() {
        var idToFind = this.referenceDates || 'planned';
        return REFERENCE_DATE_OPTIONS.find(function (_ref2) {
          var id = _ref2.id;
          return id === idToFind;
        });
      },
      set: function set(key, newValue) {
        this.set('referenceDates', newValue.id);
        return newValue;
      }
    }),
    isSliding: Ember.computed('selectedDays', 'maxDays', function () {
      /* eslint-disable-next-line eqeqeq */
      // may be string vs int
      return this.selectedDays !== undefined && this.selectedDays != this.maxDays;
    }),
    actions: {
      download: function download(exporttype, voyages, type) {
        var date = (0, _moment.default)().format('YYYY_MM_DD_HHmm');
        var file = "upcoming_arrivals_".concat(date, ".xlsx");
        var exportService = this.get(exporttype);

        if (exportService) {
          var data = getExportData(voyages, type);
          var sheets = [{
            name: 'Upcoming Arrivals',
            data: data
          }];
          exportService.export(sheets, {
            multiSheet: true,
            fileName: file
          });
        }
      }
    }
  });

  _exports.default = _default;
});