define("oi/components/oi-milestone-date", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    hintClass: 'hint--top',
    // may only be used for final (rightmost) element(s), hint--top otherwise
    showAisSymbol: false,
    goodClassNames: 'oi-bold',
    contractedDate: Ember.computed.alias('milestone.contracted'),
    userDate: Ember.computed.alias('milestone.customer'),
    didReceiveAttrs: function didReceiveAttrs() {
      // Note: this logic relies on all set properties being constant; component will
      // not be updated accordingly on changes (hence separate def of contracted & userDate)
      var mode = this.mode,
          milestone = this.milestone;

      if (mode === 'planned') {
        this.setProperties({
          goodDate: milestone.planned_last,
          otherDate: milestone.planned_initial,
          compareDate: milestone.planned_initial,
          goodName: 'last planned date',
          compareName: 'initially planned date'
        });
      } else if (mode === 'actual') {
        this.setProperties({
          goodDate: milestone.detected,
          otherDate: milestone.actual,
          compareDate: milestone.planned_last,
          goodName: '',
          // it should be clear from context what is compared here...
          compareName: 'last planned date',
          hintClass: 'hint--top-left',
          showAisSymbol: true
        });
      }
    }
  });

  _exports.default = _default;
});