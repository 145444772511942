define("oi/models/leadtime", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend({
    pol: attr('string'),
    pod: attr('string'),
    polLocode: attr('string'),
    podLocode: attr('string'),
    polCountry: attr('string'),
    podCountry: attr('string'),
    tsLocodes: attr(),
    carrier: belongsTo('carrier'),
    carrierName: Ember.computed.alias('carrier.short_name'),
    leadAvgVessel: attr('number'),
    lengthVessel: attr('number'),
    leadAllVessel: attr(),
    leadAvgPorts: attr('number'),
    lengthPorts: attr('number'),
    leadAllPorts: attr(),
    services: attr(),
    servicesAndLegs: Ember.computed('tsLocodes.[]', 'services.[]', 'polLocode', 'podLocode', function () {
      var _this = this;

      var tsCount = (this.tsLocodes || []).length;
      return this.services.map(function (service, index) {
        return {
          service: service,
          startPort: index === 0 ? _this.polLocode : _this.tsLocodes[index - 1],
          endPort: index === tsCount ? _this.podLocode : _this.tsLocodes[index]
        };
      });
    })
  });

  _exports.default = _default;
});