define("oi/templates/components/oi-tags-select-multiple", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NpH60gp5",
    "block": "{\"symbols\":[\"tag\"],\"statements\":[[4,\"oi-power-select-multiple\",null,[[\"options\",\"searchField\",\"searchEnabled\",\"selected\",\"placeholder\",\"checkboxOptions\",\"class\",\"onChange\",\"onBlur\"],[[24,[\"options\"]],\"value\",true,[24,[\"selected\"]],[24,[\"placeholder\"]],true,[24,[\"class\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"selected\"]]],null]],null],[24,[\"onBlur\"]]]],{\"statements\":[[0,\"  \"],[1,[23,1,[\"value\"]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "oi/templates/components/oi-tags-select-multiple.hbs"
    }
  });

  _exports.default = _default;
});