define("oi/templates/reset", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wJjOlpBD",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[28,\"not\",[[24,[\"isChangeOrReset\"]]],null]],null,{\"statements\":[[0,\"  \"],[1,[28,\"oi-auth-form\",null,[[\"type\",\"isChangeOrReset\",\"submit\",\"isAjaxOpPending\",\"emailForResetLink\",\"username\",\"errorMessage\",\"notificationType\",\"isInvite\",\"token\"],[\"reset_request\",[24,[\"isChangeOrReset\"]],[28,\"action\",[[23,0,[]],\"requestReset\"],null],[24,[\"isAjaxOpPending\"]],[24,[\"emailForResetLink\"]],[24,[\"username\"]],[24,[\"errorMessage\"]],[24,[\"notificationType\"]],[24,[\"model\",\"isInvite\"]],[24,[\"token\"]]]]],false],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"or\",[[24,[\"hasCredentialsAndAccount\"]],[24,[\"isAuthenticated\"]]],null]],null,{\"statements\":[[0,\"  \"],[1,[28,\"oi-auth-form\",null,[[\"type\",\"isAuthenticated\",\"isChangeOrReset\",\"submit\",\"isAjaxOpPending\",\"emailForResetLink\",\"currentPassword\",\"newpasswordone\",\"newpasswordtwo\",\"passwordsDiffer\",\"username\",\"errorMessage\",\"notificationType\"],[\"reset_password\",[24,[\"isAuthenticated\"]],[24,[\"isChangeOrReset\"]],[28,\"action\",[[23,0,[]],\"requestPasswordChange\"],null],[24,[\"isAjaxOpPending\"]],[24,[\"emailForResetLink\"]],[24,[\"currentPassword\"]],[24,[\"newpasswordone\"]],[24,[\"newpasswordtwo\"]],[24,[\"passwordsDiffer\"]],[24,[\"username\"]],[24,[\"errorMessage\"]],[24,[\"notificationType\"]]]]],false],[0,\"\\n \"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "oi/templates/reset.hbs"
    }
  });

  _exports.default = _default;
});