define("oi/templates/components/oi-gfx-date", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "j7E9EsvJ",
    "block": "{\"symbols\":[\"plannedDate\",\"realDate\"],\"statements\":[[4,\"with\",[[28,\"or\",[[24,[\"location\",\"outDateReal\"]],[24,[\"location\",\"inDateReal\"]],[24,[\"bestGuessRealDate\"]]],null]],null,{\"statements\":[[5,\"oi-preta-details\",[],[[\"@description\",\"@datetime\",\"@prediction\",\"@diff\",\"@shipment\"],[[29,[\"Actual date for \",[24,[\"location\",\"actualDateMilestoneKey\"]]]],[23,2,[]],[24,[\"location\",\"mostRelevantPrediction\"]],[22,\"notableDiff\"],[22,\"shipment\"]]],{\"statements\":[],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[2]},{\"statements\":[[4,\"with\",[[28,\"and\",[[24,[\"isPlannedConsistentWithNeighboringRealDates\"]],[28,\"or\",[[24,[\"location\",\"outDatePlanned\"]],[24,[\"location\",\"inDatePlanned\"]],[24,[\"bestGuessPlannedDate\"]]],null]],null]],null,{\"statements\":[[0,\"  \"],[5,\"oi-preta-details\",[],[[\"@description\",\"@datetime\",\"@prediction\",\"@diff\",\"@shipment\"],[[29,[\"Planned date for \",[24,[\"location\",\"plannedDateMilestoneKey\"]]]],[23,1,[]],[24,[\"location\",\"mostRelevantPrediction\"]],[22,\"notableDiff\"],[22,\"shipment\"]]],{\"statements\":[],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[0,\"  \"],[5,\"oi-preta-details\",[],[[\"@datetime\",\"@prediction\",\"@diff\",\"@shipment\"],[\"--\",[24,[\"location\",\"mostRelevantPrediction\"]],[22,\"notableDiff\"],[22,\"shipment\"]]],{\"statements\":[],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "oi/templates/components/oi-gfx-date.hbs"
    }
  });

  _exports.default = _default;
});