define("oi/components/oi-milestone-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['col-md-12'],
    session: Ember.inject.service(),
    predictionsByKey: Ember.computed('predictions.@each.milestone_key', function () {
      var predictionsByMsKey = {};
      (this.predictions || []).forEach(function (pred) {
        predictionsByMsKey[pred.get('milestone_key')] = pred;
      });
      return predictionsByMsKey;
    }),
    hasAtLeastOneContractedDate: Ember.computed('locations.[]', 'locationsReversed.[]', function () {
      return (this.locationsReversed || this.locations).filter(function (loc) {
        return loc.get('milestones').findBy('contracted');
      });
    })
  });

  _exports.default = _default;
});