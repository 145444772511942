define("oi/services/carrier-coverage-export", ["exports", "jspdf", "jspdf-autotable", "moment"], function (_exports, _jspdf, _jspdfAutotable, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    exportToPdf: function exportToPdf(selectedTableId) {
      var element = window.document.getElementById(selectedTableId).cloneNode(true);

      if (element) {
        var tds = element.querySelectorAll('td');
        tds.forEach(function (td) {
          td.textContent = td.textContent.replaceAll('✓', 'Yes').replaceAll('☓', 'No');

          if (td.textContent.indexOf('⬤') !== -1) {
            if (td.getAttribute('class').indexOf('green') !== -1) {
              td.textContent = 'OK';
            } else td.textContent = 'WARNING';
          }

          if (td.textContent.length === 0) {
            td.textContent = '-';
          }
        });
        var doc = new _jspdf.jsPDF('l', 'mm', 'a4');
        doc.setProperties({
          title: 'Carrier Coverage Report',
          author: 'project44',
          keywords: 'carrier, coverage, report'
        });
        doc.text(15, 15, "project44 - ".concat(selectedTableId === 'dataquality' ? 'Carrier Coverage And Data Quality' : 'Carrier Milestone Support', " "));
        doc.setFontSize(10);
        doc.text(15, 20, "as of ".concat((0, _moment.default)().format('MMMM Do YYYY, h:mm:ss a')));
        doc.autoTable({
          html: element,
          startY: 25
        });
        var previousTableY = doc.autoTable.previous.finalY;
        doc.setFontSize(12);
        doc.text('Legend', 15, previousTableY + 13);
        doc.setFontSize(10);

        if (selectedTableId === 'dataquality') {
          // Add dataquylity legend
          doc.text('Yes - indicates that search by this number is supported', 15, previousTableY + 20);
          doc.text('No - indicates that search by this number is not supported', 15, previousTableY + 25);
          doc.text('Beta quality - These carriers are currently in quality review. You are invited to work with these carriers, but please be aware that data quality may vary.', 15, previousTableY + 35);
          doc.text('The more shipments we can analyze, the better the quality will be. Your shipment will automatically benefit from quality improvements during the process. Thanks!', 15, previousTableY + 40);
          doc.setFontSize(12);
          doc.text('Missing some carriers?', 15, previousTableY + 53);
          doc.setFontSize(10);
          doc.text('Are you working with carriers that are not yet supported by Project44? We\'d be happy to check integration for you - please get in touch!', 15, previousTableY + 60);
        } else {
          doc.text('Yes | No - "planned" | "actual"', 15, previousTableY + 20);
          doc.text('Yes - indicates that a carrier does support planned/actual dates for a milestone', 15, previousTableY + 25);
          doc.text('No -  indicates that a carrier does not support planned/actual dates for a milestone', 15, previousTableY + 30);
          doc.text('This table shows, which of the major milestones of a shipment\'s lifecycle are supported by each carrier with corresponding data.', 15, previousTableY + 40);
          doc.text('However, please note: Even if the milestone is generally supported by a carrier, there is no guarantee that the respective information is available for every single shipment', 15, previousTableY + 45);
          doc.text('tracked with this carrier. For best results, we generally recommend tracking based on BL or booking numbers, as some carriers only support a reduced number of milestones', 15, previousTableY + 50);
          doc.text('when tracking shipments on the base of a container number.', 15, previousTableY + 55);
          doc.text('Please also note that the supported milestones may change over time, for example in case of changes in the communication standards of the carrier. The Carrier Milestone', 15, previousTableY + 65);
          doc.text('Support table will be updated regularly.', 15, previousTableY + 70);
          var milestonelegend = document.getElementById('milestonelegend');
          doc.autoTable({
            html: milestonelegend,
            startY: previousTableY + 75
          });
        }

        this.addFooter(doc, 'Copyright project44, Rostock, Germany - Confidential');
        doc.save("ctt_".concat(selectedTableId, ".pdf"));
        return true;
      }

      return false;
    },
    addFooter: function addFooter(doc, footnote) {
      var totalPages = doc.internal.getNumberOfPages();

      for (var i = 1; i <= totalPages; i += 1) {
        doc.setPage(i);
        doc.text(15, doc.internal.pageSize.height - 10, footnote);
      }
    }
  });

  _exports.default = _default;
});