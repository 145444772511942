define("oi/helpers/is-in-array", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isInArray = isInArray;
  _exports.default = void 0;

  function isInArray(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
        i = _ref2[0],
        arr = _ref2[1];

    if (arr === null || arr === undefined) {
      return false;
    } // if arr is a RelatedSomethingManager or something other ember-internal, we may have to work on its content:


    if (!arr.some) {
      return arr.content.some(function (arrVal) {
        return i === arrVal;
      });
    }

    return arr.some(function (arrVal) {
      return i === arrVal;
    });
  }

  var _default = Ember.Helper.helper(isInArray);

  _exports.default = _default;
});