define("oi/models/container-voyage", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    arrivalInD: _emberData.default.attr('number'),
    podLocode: _emberData.default.attr('string'),
    podName: _emberData.default.attr('string'),
    podCountry: Ember.computed('podLocode', function () {
      return this.podLocode && this.podLocode.slice(0, 2);
    }),
    vesselName: _emberData.default.attr('string'),
    voyage: _emberData.default.attr('string'),
    subscriptions: _emberData.default.hasMany('subscriptions', {
      inverse: null
    }),
    shipments: _emberData.default.hasMany('shipments', {
      inverse: null
    }),
    numShipments: Ember.computed.alias('shipments.length'),
    anyRelationPending: Ember.computed.or('subscriptions.isPending', 'shipments.isPending'),
    carrierNames: Ember.computed('subscriptions.@each.request_carrier_name', function () {
      return this.subscriptions.mapBy('request_carrier_name').uniq();
    }),
    descriptions: Ember.computed('subscriptions.@each.descriptive_name', function () {
      return this.subscriptions.mapBy('descriptive_name').uniq().filter(Boolean);
    }),
    subscriptionTags: Ember.computed('subscriptions.@each.tags', function () {
      var tagUnion = new Set();
      this.subscriptions.forEach(function (subscription) {
        var tags = subscription.get('tags');
        tags.forEach(function (tag) {
          return tagUnion.add(tag);
        });
      });
      return tagUnion;
    }),
    absoluteArrivalInD: Ember.computed('arrivalInD', function () {
      return Math.abs(this.get('arrivalInD'));
    }),
    etas: Ember.computed('shipments.@each.locationsDict', function () {
      var pods = this.shipments.mapBy('locationsDict.pod').filter(function (el) {
        return el;
      }); // going directly for VAD milestones here would prevent fallback to discharge date
      // in case vslarrival planned date is missing

      if (!pods.length) return {};
      var pretas = pods.mapBy('milestones.firstObject.prediction.predictedDate').filter(function (el) {
        return el;
      }).sort(); // note that all dates are still ISO strings and all dates of the same type are given
      // with the same timezone offset by the API - otherwise .sort() would not work right

      var contractuals = pods.mapBy('contractualDate').filter(function (el) {
        return el;
      }).sort();
      var planneds = pods.mapBy('inDatePlanned').filter(function (el) {
        return el;
      }).sort();
      var plannedInitials = pods.mapBy('inDatePlannedInitial').filter(function (el) {
        return el;
      }).sort();
      return {
        minPreTA: pretas[0],
        maxPreTA: pretas[pretas.length - 1],
        minContractual: contractuals[0],
        maxContractual: contractuals[contractuals.length - 1],
        minPlanned: planneds[0],
        maxPlanned: planneds[planneds.length - 1],
        minPlannedInitial: plannedInitials[0],
        maxPlannedInitial: plannedInitials[plannedInitials.length - 1]
      };
    }),
    getEta: function getEta() {
      var type = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'planned';
      var etas = this.get('etas');

      if (type === 'planned') {
        return {
          type: 'planned',
          min: etas.minPlanned,
          max: etas.maxPlanned
        };
      }

      if (type === 'predicted') {
        return {
          type: 'predicted',
          min: etas.minPreTA,
          max: etas.maxPreTA
        };
      }

      if (type === 'predicted,planned') {
        if (etas.minPreTA && etas.maxPreTA) {
          return {
            type: 'predicted',
            min: etas.minPreTA,
            max: etas.maxPreTA
          };
        }

        return {
          type: 'planned',
          min: etas.minPlanned,
          max: etas.maxPlanned
        };
      }

      if (type === 'contractual') {
        return {
          min: etas.minContractual,
          max: etas.maxContractual
        };
      }

      if (type === 'contractual,planned') {
        if (etas.minContractual && etas.maxContractual) {
          return {
            min: etas.minContractual,
            max: etas.maxContractual
          };
        }

        return {
          min: etas.minPlanned,
          max: etas.maxPlanned
        };
      }

      return null;
    },
    getEtaType: function getEtaType(type) {
      var eta = this.getEta(type);

      if (eta) {
        return eta.type;
      }

      return null;
    },
    getMinEta: function getMinEta(type) {
      var eta = this.getEta(type);

      if (eta) {
        return eta.min;
      }

      return null;
    },
    getMaxEta: function getMaxEta(type) {
      var eta = this.getEta(type);

      if (eta) {
        return eta.max;
      }

      return null;
    },
    getDelay: function getDelay(type) {
      var eta = this.getEta(type);
      var etas = this.get('etas');

      if (eta && etas) {
        var etaMax = (0, _moment.default)(eta.max);
        var maxInnitial = (0, _moment.default)(etas.maxPlannedInitial);
        var delay = Math.floor((etaMax - maxInnitial) / (1000 * 60 * 60 * 24));

        if (delay === 0) {
          return 'as planned';
        }

        return delay < 0 ? "".concat(Math.abs(delay), " d early") : "".concat(delay, " d late");
      }

      return 0;
    }
  });

  _exports.default = _default;
});