define("oi/controllers/application", ["exports", "ember-local-storage", "oi/config/environment", "jquery", "oi/constants/system"], function (_exports, _emberLocalStorage, _environment, _jquery, _system) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* global zE, _paq */
  var RELEVANT_MILESTONES = ['CEP', 'CPS', 'CGI', 'CLL', 'VDL', 'VAD', 'CDD', 'CGO', 'CIF', 'COF', 'CDC', 'CER'];

  var _default = Ember.Controller.extend({
    queryParams: {
      hash: {
        refreshModel: true
      },
      showCarrierCoverage: {
        refreshModel: true
      }
    },
    applicationService: Ember.inject.service(),
    downloadService: Ember.inject.service(),
    raven: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    user: Ember.inject.service(),
    carrierCoverageExport: Ember.inject.service(),
    router: Ember.inject.service('router'),
    notify: Ember.inject.service(),
    notificationStorage: (0, _emberLocalStorage.storageFor)('notification'),
    hasAccessControlTags: Ember.computed.alias('session.data.user_access_control_tags.length'),
    hash: null,
    showCarrierCoverage: null,
    tasks: Ember.computed(function () {
      return this.get('store').query('task', {
        status: 'O'
      });
    }),
    carriermilestonesupport: Ember.computed('carriers', function () {
      return this.store.findAll('carriermilestonesupport', {
        backgroundReload: true
      });
    }),
    linkToVoc: Ember.computed(function () {
      return this.applicationService.linkToVoc;
    }),
    relevantMilestones: RELEVANT_MILESTONES,
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments); // eslint-disable-line prefer-rest-params
      // on app reload (F5 or close and reopen tab) this method is run before session isAuthenticated,
      // hence the check whether we have a restored previous session makes sense only later:


      Ember.run.next(function () {
        if (_this.get('session.isAuthenticated')) {
          _this.setTrackingAndLoggingData();

          _this.checkForNotifications();

          _this.loadChangelog();
        } else {
          _this.session.authenticate('authenticator:sso');
        }
      });
    },
    setTrackingAndLoggingData: function setTrackingAndLoggingData() {
      // also to be called from route on sessionAuthenticated
      var sessionData = this.get('session.data');

      var _Ember$getProperties = Ember.getProperties(sessionData, 'fullname', 'username', 'email', 'accounts', 'prefAccountIds', 'masterAccountId', 'authenticated.impersonated_by'),
          originalUsername = _Ember$getProperties.username,
          fullname = _Ember$getProperties.fullname,
          originalEmail = _Ember$getProperties.email,
          masterAccountId = _Ember$getProperties.masterAccountId,
          imposter = _Ember$getProperties['authenticated.impersonated_by'];

      var imposterSuffix = imposter ? " (".concat(imposter, ")") : '';
      var username = originalUsername + imposterSuffix;
      var name = fullname ? fullname + imposterSuffix : username;
      var email = imposter ? "".concat(imposter, " as ").concat(originalEmail) : originalEmail;

      if (username || email) {
        // not sure how any of these can ever be falsy, but leaving it in just in case...
        this.raven.callRaven('setUserContext', {
          email: email,
          username: username
        }); // set matomo/piwik user

        _paq.push(['setUserId', username]);

        _paq.push(['setCustomDimension', 1, email]);

        _paq.push(['setCustomDimension', 2, imposter ? null : masterAccountId]);

        _paq.push(['enableLinkTracking']);

        if (typeof zE !== 'undefined') {
          zE(function () {
            return zE.identify({
              name: name,
              email: email,
              source: _environment.default.APP.PROD_ENV
            });
          });
          zE(function () {
            return zE.setLocale('en');
          });
        }
      }
    },
    checkForNotifications: function checkForNotifications() {
      var _this2 = this;

      if (!this.session.isAuthenticated) {
        return;
      }

      var headers = this.get('user.authHeaders');
      headers.Accept = 'application/json';

      _jquery.default.ajax({
        url: "".concat(this.applicationService.apiHost, "/").concat(_environment.default.APP.API_NAMESPACE) + "/notifications/?version=".concat(this.currentVersion),
        type: 'GET',
        contentType: 'application/json; charset=UTF-8',
        headers: headers,
        crossDomain: true,
        xhrFields: {
          withCredentials: _environment.default.APP.USE_AJAX_CALL_WITH_CREDENTIALS
        },
        success: function success(res) {
          if (res) {
            // when broadcast cookie is set and corresponds to current broadcast message broadcast shouldn't be displayed
            var data = res.filter(function (entry) {
              return !_this2.get("notificationStorage.seenBroadcast-".concat(entry.id));
            });

            _this2.set('broadcast', data);
          } else {
            _this2.set('broadcast', null);
          }
        },
        error: function error(_error) {
          _this2.set('broadcast', null); // eslint-disable-next-line no-console


          console.error(_error && _error.responseJSON && _error.responseJSON.detail || _error);
        }
      });
    },
    loadChangelog: function loadChangelog() {
      var _this3 = this;

      _jquery.default.ajax('/changelog.md', {
        cache: false
      }).then(function (result) {
        return Ember.run.next(function () {
          _this3.set('changelog', result);

          if (_this3.hash) {
            _this3.send('showModal', 'components/changelog-modal', result);

            _this3.set('hash', null);
          }
        });
      }).catch(function () {
        return _this3.set('changelog', null);
      });
    },
    currentYear: Ember.computed(function () {
      return new Date().getFullYear();
    }),
    currentVersion: Ember.computed(function () {
      return _environment.default.APP.version.split('+')[0];
    }),

    /* eslint-disable-next-line ember/no-observers */
    // never worked without this
    checkShowChangelog: Ember.observer('hash', function () {
      if (this.hash) {
        this.loadChangelog();
      }
    }),
    hasZendeskEnabled: Ember.computed(function () {
      return typeof zE !== 'undefined';
    }),
    currentRouteName: Ember.computed.alias('router.currentRouteName'),
    isNormalAccountWithJustOneEntry: Ember.computed('session.data.accounts', function () {
      var possibleAccounts = this.get('session.data.accounts');
      return possibleAccounts.length === 1 && Ember.isEmpty(possibleAccounts[0].sub_accounts); // SUA-account object now comes with [] as sub_accounts ^^
    }),
    highlightAccount: function highlightAccount(_ref) {
      var highlighted = _ref.highlighted,
          searchText = _ref.searchText,
          results = _ref.results;

      var includesLc = function includesLc(s1, s2) {
        return s1.toLowerCase().includes(s2.toLowerCase());
      };
      /* when searching for a sub-account, the first entry in user.flattenedAccounts
       * will be the corresponding master account, so the second one should be selected */


      if (highlighted && !includesLc(highlighted.title, searchText) && // unfortunately, we do not have access to the ember-power-select matcher here,
      // so the former may result in false negatives ... double check:
      results.length > 1 && includesLc(results[1].title, searchText)) {
        return results[1];
      }

      return highlighted;
    },
    accountFilterFunction: function accountFilterFunction()
    /* , skipDisabled = false */
    {
      var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      var term = arguments.length > 1 ? arguments[1] : undefined;
      var matcher = this.optionMatcher;
      var directMatches = options.filter(function (o) {
        return matcher(o, term) >= 0;
      });
      var uniqueMasterIds = directMatches.map(function (m) {
        return m.master_account_id;
      }).filter(function (elem, pos, arr) {
        return elem !== undefined && arr.indexOf(elem) === pos;
      });

      if (!uniqueMasterIds.length) {
        return directMatches;
      }

      return options.filter(function (o) {
        return directMatches.includes(o) || uniqueMasterIds.includes(o.id);
      });
    },
    showStatsAndNewSubscriptions: Ember.computed(function () {
      return !['sysco'].includes(_environment.default.APP.PROD_ENV);
    }),
    showNewAppVersionModal: false,
    actions: {
      impersonate: function impersonate(username) {
        var _this4 = this;

        var modal = (0, _jquery.default)('.modal'); // dirty hack for direct call to action on enter pressed in input field

        if (modal) {
          modal.modal('hide');
        } // TODO: when fixing modal window logic, move there


        var session = this.session;
        var imposter = session.get('data.username');
        session.set('data.prefAccountIds', null); // shall get recalculated later

        var internalSession = session.get('session');
        var oldSessionData = Object.assign({}, internalSession.content.authenticated);
        var authenticatorName = internalSession.get('authenticator'); // eslint-disable-next-line no-underscore-dangle

        var regularAuthenticator = internalSession._lookupAuthenticator(authenticatorName);

        var appRoute = Ember.getOwner(this).lookup('route:application');
        return session.authenticate('authenticator:impersonator', username).then(function () {
          return appRoute.sessionChanged();
        }) // so that accounts are properly updated
        .catch(function (error) {
          _this4.raven.captureMessage("Failed impersonation of ".concat(username, " by ").concat(imposter, ": ").concat(JSON.stringify(error)), {
            level: 'debug',
            fingerprint: ['Failed impersonation'],
            extra: {
              username: username,
              imposter: imposter,
              error: error,
              errorString: "".concat(error)
            }
          });

          return undefined; // when session.authenticate fails (e.g. API returns 40x), ember-simple-auth
          // initiates session invalidation, so we cannot return to our old
          // session. We should at least properly revoke its tokens then...
        }).then(function () {
          regularAuthenticator.invalidate(oldSessionData);

          _this4.store.unloadAll();

          appRoute.refresh();
        });
      },
      zeActivate: function zeActivate() {
        if (typeof zE !== 'undefined') {
          zE(function () {
            zE.activate({
              hideOnClose: true
            });
          });
        }
      },
      reload: function reload() {
        document.location.reload(true);
      },
      deleteTag: function deleteTag(tag) {
        (0, _jquery.default)('.modal').modal('hide'); // const store = this.get('store');
        // store.findRecord('tag', id, {reload: true, backgroundReload: false}).then((tag) =>

        tag.destroyRecord().then(function () {
          return (0, _jquery.default)('.modal').modal('hide');
        }).catch(function (error) {
          (0, _jquery.default)('.modal').modal('hide'); // TODO: notify!

          console.error(error); // eslint-disable-line no-console
        });
      },
      hideBroadcast: function hideBroadcast(id) {
        var _this5 = this;

        this.set("notificationStorage.seenBroadcast-".concat(id), true);
        this.setProperties({
          broadcast: this.broadcast.filter(function (notification) {
            return !_this5.get("notificationStorage.seenBroadcast-".concat(notification.id));
          })
        });
      },
      showCarrierCoverageModal: function showCarrierCoverageModal() {
        this.set('showCarrierCoverage', 'dataquality');
      },
      saveTask: function saveTask(task) {
        var _this6 = this;

        task.save().then(function (t) {
          _this6.get('actions.saveReferencedObjects')(task.comments);

          _this6.get('actions.saveReferencedObjects')(task.relations);

          _this6.get('actions.saveReferencedObjects')(task.todos);

          _this6.set('newTask', null);

          _this6.notify.success("Task ".concat(t.id, " was created"), 'Task created', {
            positionClass: 'toast-bottom-right'
          });

          _this6.send('refreshTasks');
        }).catch(function (ex) {
          _this6.set('exception', ex);
        });
      },
      saveReferencedObjects: function saveReferencedObjects(objects) {
        objects.forEach(function (item) {
          if (item.isNew) {
            item.save();
          }
        });
      },
      getUrl: function getUrl() {
        return window.location.href;
      },
      exportCarrierCoveragePdf: function exportCarrierCoveragePdf(tableId) {
        if (_system.IS_INTERNET_EXPLORER) {
          this.notify.error('This feature is not available in Internet Explorer');
          return;
        }

        var TITLE = 'Export PDF';
        var result = this.carrierCoverageExport.exportToPdf(tableId);

        if (result) {
          this.notify.success('Report was generated successfully', TITLE);
        } else {
          this.notify.error('Report can\'t be created', TITLE);
        }
      }
    }
  });

  _exports.default = _default;
});