define("oi/components/download-overview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    downloadService: Ember.inject.service(),
    actions: {
      download: function download(item) {
        item.download();
      },
      clearItem: function clearItem(item) {
        this.downloadService.removeDownload(item);
      }
    }
  });

  _exports.default = _default;
});