define("oi/components/oi-gfx-date", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "oi/helpers/days-diff", "oi/helpers/format-reldiff", "oi/models/shipment"], function (_exports, _slicedToArray2, _daysDiff, _formatReldiff, _shipment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    previousRealDate: Ember.computed.or('previousLocation.{outDateReal,inDateReal}'),
    nextRealDate: Ember.computed.or('nextLocation.{inDateReal,outDateReal}'),
    isPlannedConsistentWithNeighboringRealDates: Ember.computed('location.{outDatePlanned,inDatePlanned}', 'previousRealDate', 'nextRealDate', function () {
      var plannedDate = this.location.outDatePlanned || this.location.inDatePlanned;

      if (!plannedDate) {
        return true;
      }

      if (this.previousRealDate && (0, _shipment.toDate)(this.previousRealDate) > (0, _shipment.toDate)(plannedDate)) {
        return false;
      }

      if (this.nextRealDate && (0, _shipment.toDate)(this.nextRealDate) < (0, _shipment.toDate)(plannedDate)) {
        return false;
      }

      return true;
    }),
    bestGuessRealDate: Ember.computed('previousRealDate', 'nextRealDate', function () {
      // if previous and next milestone happened on the same day, use that day
      var _split = (this.nextRealDate || '').split('T'),
          _split2 = (0, _slicedToArray2.default)(_split, 1),
          nextRealDateWithoutTime = _split2[0];

      if (nextRealDateWithoutTime === (this.previousRealDate || '').split('T')[0]) {
        return nextRealDateWithoutTime;
      }

      return null;
    }),
    bestGuessPlannedDate: Ember.computed('nextLocation.{inDatePlanned,outDatePlanned}', 'previousLocation.{inDatePlanned,outDatePlanned}', function () {
      if (!this.previousLocation || !this.nextLocation) {
        return null;
      } // if previous and next milestone will happen on the same day, use that day


      var _split3 = (this.nextLocation.inDatePlanned || this.nextLocation.outDatePlanned || '').split('T'),
          _split4 = (0, _slicedToArray2.default)(_split3, 1),
          nextPlannedDateWithoutTime = _split4[0];

      if (nextPlannedDateWithoutTime === (this.previousLocation.outDatePlanned || this.previousLocation.inDatePlanned || '').split('T')[0]) {
        return nextPlannedDateWithoutTime;
      }

      return null;
    }),
    notableDiff: Ember.computed('location.{milestones,key}', function () {
      var _this$location = this.location,
          key = _this$location.key,
          milestones = _this$location.milestones;

      if (key !== 'pod') {
        return null;
      }

      var arrivalMilestone = milestones.get('firstObject');
      var contracted = arrivalMilestone.contracted,
          doneDate = arrivalMilestone.doneDate,
          plannedInit = arrivalMilestone.planned_initial,
          plannedLast = arrivalMilestone.planned_last;
      var diffActualContr = contracted && doneDate && (0, _daysDiff.daysDiffRounded)(doneDate, contracted);

      if (diffActualContr) {
        return {
          diff: diffActualContr,
          isReal: true,
          label: "ATA@POD ".concat((0, _formatReldiff.verbalizeDiff)(diffActualContr), " contracted ETA")
        };
      }

      var diffActualPlanned = plannedInit && doneDate && (0, _daysDiff.daysDiffRounded)(doneDate, plannedInit);

      if (diffActualPlanned) {
        return {
          diff: diffActualPlanned,
          isReal: true,
          label: "ATA@POD ".concat((0, _formatReldiff.verbalizeDiff)(diffActualPlanned), " intially planned ETA")
        };
      }

      var diffPlannedContr = plannedLast && contracted && (0, _daysDiff.daysDiffRounded)(plannedLast, contracted);

      if (diffPlannedContr) {
        return {
          diff: diffPlannedContr,
          isReal: false,
          label: "ETA@POD ".concat((0, _formatReldiff.verbalizeDiff)(diffPlannedContr), " contracted ETA")
        };
      }

      var diffPlans = plannedLast && plannedInit && (0, _daysDiff.daysDiffRounded)(plannedLast, plannedInit);

      if (diffPlans) {
        return {
          diff: diffPlans,
          isReal: false,
          label: "last planned ETA@POD ".concat((0, _formatReldiff.verbalizeDiff)(diffPlans), " intially planned ETA")
        };
      }

      var dischargeMilestone = milestones.findBy('key', 'CDD');
      var pl = dischargeMilestone.plannedLast,
          pi = dischargeMilestone.plannedInit;
      var diffPlansDischarge = pl && pi && (0, _daysDiff.daysDiffRounded)(pl, pi);

      if (diffPlansDischarge) {
        return {
          diff: diffPlansDischarge,
          isReal: false,
          label: "last planned ETA@DISCHARGE ".concat((0, _formatReldiff.verbalizeDiff)(diffPlansDischarge), " intially planned DISCHARGE")
        };
      }

      return null;
    })
  });

  _exports.default = _default;
});