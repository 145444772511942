define("oi/helpers/lightenDarkenHex", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.lightenDarkenHex = lightenDarkenHex;
  _exports.default = void 0;

  /* eslint-disable no-bitwise */
  function lightenDarkenHex(color, amt) {
    var usePound = false;
    var col = color;

    if (col[0] === '#') {
      col = col.slice(1);
      usePound = true;
    }

    var num = parseInt(col, 16);
    var r = (num >> 16) + amt;

    if (r > 255) {
      r = 255;
    } else if (r < 0) {
      r = 0;
    }

    var b = (num >> 8 & 0x00FF) + amt;

    if (b > 255) {
      b = 255;
    } else if (b < 0) {
      b = 0;
    }

    var g = (num & 0x0000FF) + amt;

    if (g > 255) {
      g = 255;
    } else if (g < 0) {
      g = 0;
    }

    return (usePound ? '#' : '') + (g | b << 8 | r << 16).toString(16);
  }

  var _default = Ember.Helper.helper(lightenDarkenHex);

  _exports.default = _default;
});