define("oi/routes/preferences", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    titleToken: function titleToken() {
      return 'Administration';
    },
    model: function model()
    /* param */
    {
      return this.store.findAll('tag', {
        backgroundReload: true
      });
    },
    setupController: function setupController(controller, model) {
      controller.set('tags', model);
    },
    notify: Ember.inject.service(),
    raven: Ember.inject.service(),
    actions: {
      error: function error(reason) {
        var firstError = reason.errors && reason.errors[0] || {};

        if (firstError.detail === 'Tagging not supported without multi-user-account setup.') {
          this.notify.error('Oops. You should not have seen this link after all. ' + 'OI has been informed of the problem. Apologies for the inconvenience.', {
            closeAfter: 6000
          });
        }

        this.raven.captureMessage('Error accessing preferences.', {
          extra: reason
        });
      }
    }
  });

  _exports.default = _default;
});