define("oi/templates/components/autocompletionfilter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "00t8UtEV",
    "block": "{\"symbols\":[\"result\"],\"statements\":[[5,\"power-select\",[],[[\"@class\",\"@allowClear\",\"@searchEnabled\",\"@search\",\"@selected\",\"@placeholder\",\"@onInput\",\"@onChange\"],[[23,0,[\"class\"]],true,true,[28,\"action\",[[23,0,[]],\"search\"],null],[23,0,[\"value\"]],[23,0,[\"placeholder\"]],[28,\"fn\",[[28,\"mut\",[[23,0,[\"value\"]]],null]],null],[28,\"fn\",[[28,\"mut\",[[23,0,[\"value\"]]],null]],null]]],{\"statements\":[[0,\"\\n  \"],[1,[23,1,[]],false],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "oi/templates/components/autocompletionfilter.hbs"
    }
  });

  _exports.default = _default;
});