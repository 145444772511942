define("oi/templates/components/oi-description", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+nwwGErI",
    "block": "{\"symbols\":[\"isEditing\"],\"statements\":[[4,\"oi-editable\",null,[[\"readOnly\",\"whoIsEditing\",\"update\",\"inputInvalid\",\"inputElementClass\"],[[24,[\"readOnly\"]],[24,[\"whoIsEditing\"]],[28,\"action\",[[23,0,[]],\"update\"],null],[28,\"eq\",[[24,[\"desc\"]],[24,[\"newDesc\"]]],null],\"break-word\"]],{\"statements\":[[4,\"if\",[[23,1,[]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"input\",null,[[\"class\",\"value\"],[\"pull-left editDesc form-control\",[24,[\"newDesc\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[28,\"or\",[[24,[\"desc\"]],\"--\"],null],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "oi/templates/components/oi-description.hbs"
    }
  });

  _exports.default = _default;
});