define("oi/templates/components/shared-link-actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9ayfaKxt",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[28,\"and\",[[24,[\"canChange\"]],[28,\"not\",[[24,[\"link\",\"isExpired\"]]],null]],null]],null,{\"statements\":[[0,\"  \"],[7,\"button\",true],[10,\"class\",\"btn btn-xs btn-primary btn-sharing\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"toggleIsRevoked\",[24,[\"link\"]]],null]],[10,\"type\",\"button\"],[8],[0,\"\\n    \"],[7,\"i\",true],[11,\"class\",[29,[\"fa fa-\",[28,\"if\",[[24,[\"link\",\"isRevoked\"]],\"refresh\",\"eraser\"],null]]]],[10,\"aria-hidden\",\"true\"],[8],[9],[0,\"\\n    \"],[1,[28,\"if\",[[24,[\"link\",\"isRevoked\"]],\"Renew\",\"Revoke\"],null],false],[0,\" sharing\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[7,\"button\",true],[10,\"aria-label\",\"Mail shared link\"],[11,\"class\",[29,[\"btn btn-link \",[28,\"if\",[[28,\"not\",[[24,[\"disabled\"]]],null],[24,[\"hintClass\"]]],null]]]],[11,\"disabled\",[22,\"disabled\"]],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"mailSharedLink\",[24,[\"link\"]]],null]],[10,\"type\",\"button\"],[8],[0,\"\\n  \"],[7,\"i\",true],[10,\"class\",\"fa fa-envelope\"],[10,\"aria-hidden\",\"true\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"button\",true],[10,\"aria-label\",\"Copy shared link to clipboard\"],[11,\"class\",[29,[\"btn btn-link \",[28,\"if\",[[28,\"not\",[[24,[\"disabled\"]]],null],[24,[\"hintClass\"]]],null]]]],[11,\"disabled\",[22,\"disabled\"]],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"copySharedLink\",[24,[\"link\"]]],null]],[10,\"type\",\"button\"],[8],[0,\"\\n  \"],[7,\"i\",true],[10,\"class\",\"fa fa-clipboard\"],[10,\"aria-hidden\",\"true\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "oi/templates/components/shared-link-actions.hbs"
    }
  });

  _exports.default = _default;
});