define("oi/controllers/subscription", ["exports", "jquery", "oi/config/environment", "oi/constants/subscription"], function (_exports, _jquery, _environment, _subscription) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    applicationService: Ember.inject.service(),
    apiHost: Ember.computed(function () {
      return this.applicationService.apiHost;
    }),
    queryParams: ['page', 'page_size', 'type', 'appearence'],
    page: 1,
    page_size: 50,
    status: 'O',
    activatedEditComponent: '',
    user: Ember.inject.service(),
    session: Ember.inject.service(),
    featuresAndPermissions: Ember.computed.alias('session.data.features'),
    allowArchive: Ember.computed('data.{readOnly,status}', function () {
      return !['sysco'].includes(_environment.default.APP.PROD_ENV) && !this.data.readOnly && _subscription.VALID_ARCHIVE_TRANSITIONS.includes(this.data.status);
    }),
    allowCancel: Ember.computed('data.{readOnly,status}', function () {
      return !['sysco'].includes(_environment.default.APP.PROD_ENV) && !this.data.readOnly && _subscription.VALID_CANCEL_TRANSITIONS.includes(this.data.status);
    }),
    sortedAllAvailableTags: Ember.computed('allAvailableTags', function () {
      return this.allAvailableTags.sortBy('isAccessControl');
    }),
    onSuccessfulArchive: function onSuccessfulArchive() {
      this.transitionToRoute('subscriptions');
    },
    onFailedArchive: function onFailedArchive(error) {
      if (error.status === 404) {
        this.set('errorMessage', 'The subscription you tried to archive could not be found!', {
          Origin: _environment.default.APP.PROD_ENV
        });
      } else {
        this.set('errorMessage', 'An unhandled error occured!');
      }
    },
    onSuccessfulCancel: function onSuccessfulCancel() {
      this.transitionToRoute('subscriptions');
    },
    onFailedCancel: function onFailedCancel(error) {
      if (error.status === 404) {
        this.set('errorMessage', 'The subscription you tried to cancel could not be found!', {
          Origin: _environment.default.APP.PROD_ENV
        });
      } else {
        this.set('errorMessage', 'An unhandled error occured!');
      }
    },
    actions: {
      cancel: function cancel(param) {
        // this cancels the subscription by issuing a non-standard ajax call
        _jquery.default.ajax({
          url: "".concat(this.applicationService.apiBase, "/subscriptions/").concat(param, "/cancel/"),
          type: 'GET',
          headers: this.get('user.authHeaders'),
          crossDomain: true,
          xhrFields: {
            withCredentials: _environment.default.APP.USE_AJAX_CALL_WITH_CREDENTIALS
          }
        }).then(this.onSuccessfulCancel.bind(this), this.onFailedCancel.bind(this));
      },
      archive: function archive(param) {
        _jquery.default.ajax({
          url: "".concat(this.applicationService.apiBase, "/subscriptions/").concat(param, "/archive/"),
          type: 'GET',
          headers: this.get('user.authHeaders'),
          crossDomain: true,
          xhrFields: {
            withCredentials: _environment.default.APP.USE_AJAX_CALL_WITH_CREDENTIALS
          }
        }).then(this.onSuccessfulArchive.bind(this), this.onFailedArchive.bind(this));
      },
      del: function del(arg) {
        // ////////////////////////////////////////////////////////////////////////////////////////////////
        // INSTEAD OF DELETING THE DOCLINK DIRECTLY                                                     //
        // YOU HAVE TO GET THE  SUBSCRIPTION  MODEL                                                     //
        // AND  TO  UPDATE  THE  DOCLINKS  ARRAY!!!                                                     //
        // ////////////////////////////////////////////////////////////////////////////////////////////////
        // READ HERE: https://guides.emberjs.com/v2.8.0/models/relationships/ FOR DEEPER UNDERSTANDING //
        var subscription = this.store.peekRecord('subscription', this.get('data.id'));
        var document = this.store.peekRecord('document', arg);
        subscription.get('documents').removeObject(document);
        subscription.save();
      },
      addNew: function addNew() {
        var subscription = this.store.peekRecord('subscription', this.get('data.id'));
        var document = this.store.createRecord('document', {
          title: 'New document title',
          target: 'http://'
        });
        subscription.get('document').pushObject(document);
        document.save().then(function () {
          subscription.save();
        });
      },
      createTask: function createTask() {
        var linkedObjects = [{
          app: 'ctt',
          type: 'containershipmentsubscription',
          id: this.get('data').id,
          label: this.get('data').request_key
        }];
        this.send('createNewTask', linkedObjects, null);
      }
    }
  });

  _exports.default = _default;
});