define("oi/templates/components/download-overview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "y4lyUOJy",
    "block": "{\"symbols\":[\"download\"],\"statements\":[[4,\"each\",[[24,[\"downloadService\",\"downloads\"]]],null,{\"statements\":[[4,\"if\",[[28,\"eq\",[[23,1,[\"status\"]],\"error\"],null]],null,{\"statements\":[[0,\"        \"],[5,\"bs-alert\",[],[[\"@type\"],[\"danger\"]],{\"statements\":[[0,\"\\n            \"],[7,\"i\",true],[10,\"class\",\"fa fa-exclamation-circle\"],[10,\"aria-hidden\",\"true\"],[8],[9],[0,\"\\n            \"],[7,\"strong\",true],[8],[0,\"Download failed...\"],[9],[0,\" \"],[1,[23,1,[\"message\"]],false],[0,\"\\n        \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[5,\"bs-alert\",[],[[\"@type\",\"@onDismiss\"],[\"light\",[28,\"action\",[[23,0,[]],\"clearItem\",[23,1,[]]],null]]],{\"statements\":[[0,\"\\n            \"],[7,\"i\",true],[10,\"class\",\"fa fa-clock-o\"],[10,\"aria-hidden\",\"true\"],[8],[9],[0,\"\\n            \"],[7,\"strong\",true],[8],[0,\"Download in progress...\"],[9],[0,\" Your report is being prepared in the background and will start to download soon.\\n        \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "oi/templates/components/download-overview.hbs"
    }
  });

  _exports.default = _default;
});