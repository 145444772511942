define("oi/components/classic-exceptions", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "oi/models/exception"], function (_exports, _slicedToArray2, _exception) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    store: Ember.inject.service(),
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      // separate property for selected value in input element (so we can debounce
      // changes and do not reload on every up/down arrow click):
      this.setProperties({
        selectedDays: this.days,
        variableExceptions: null
      });
      this.store.query('exception', {
        days: this.days
      }).then(function (exceptions) {
        return _this.setProperties({
          variableExceptions: exceptions.filter(function (ex) {
            return !_exception.FIXED_EXCEPTIONS.includes(ex.title);
          }),
          fixedExceptions: exceptions.filter(function (ex) {
            return _exception.FIXED_EXCEPTIONS.includes(ex.title);
          })
        });
      });
    },
    variableExceptionPlaceholders: Object.fromEntries(Object.entries(_exception.DESCRIPTIONS).filter(function (_ref) {
      var _ref2 = (0, _slicedToArray2.default)(_ref, 1),
          key = _ref2[0];

      return !_exception.FIXED_EXCEPTIONS.includes(key);
    })),
    fixedExceptionPlaceholders: Object.fromEntries(Object.entries(_exception.DESCRIPTIONS).filter(function (_ref3) {
      var _ref4 = (0, _slicedToArray2.default)(_ref3, 1),
          key = _ref4[0];

      return _exception.FIXED_EXCEPTIONS.includes(key);
    })),
    setDays: function setDays(value) {
      this.setProperties({
        selectedDays: value,
        days: value
      });
    },
    actions: {
      setExceptionDays: function setExceptionDays(value) {
        Ember.run.debounce(this, this.setDays, value, 500);
      }
    }
  });

  _exports.default = _default;
});