define("oi/templates/components/oi-stats-summary-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VU0BgD7N",
    "block": "{\"symbols\":[\"line\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"panel\"],[10,\"style\",\"background: none; border-color: transparent; border-left: 1px solid #eee;\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"panel-heading\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"col-xs-12 text-center\"],[8],[0,\"\\n        \"],[7,\"div\",true],[11,\"class\",[29,[\"huge \",[28,\"if\",[[24,[\"info\",\"numberTooltip\"]],[28,\"or\",[[24,[\"info\",\"numberTooltipClass\"]],\"hint--top-left\"],null]],null]]]],[11,\"aria-label\",[24,[\"info\",\"numberTooltip\"]]],[8],[0,\"\\n          \"],[1,[24,[\"info\",\"number\"]],false],[0,\"\\n          \"],[7,\"small\",true],[8],[1,[24,[\"info\",\"suffix\"]],false],[9],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"col-xs-12 text-center\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"info\",\"text\"]]],null,{\"statements\":[[0,\"          \"],[7,\"div\",true],[10,\"style\",\"line-height: 165%\"],[8],[1,[23,1,[]],false],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "oi/templates/components/oi-stats-summary-box.hbs"
    }
  });

  _exports.default = _default;
});