define("oi/templates/preferences", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "J2ct9ISc",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"admin-tags\",null,[[\"tags\",\"showModal\",\"tagName\"],[[24,[\"tags\"]],[28,\"action\",[[23,0,[]],\"toggleModal\"],null],\"\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "oi/templates/preferences.hbs"
    }
  });

  _exports.default = _default;
});