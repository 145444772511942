define("oi/helpers/reversed", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.reversed = reversed;
  _exports.default = void 0;

  function reversed(_ref
  /* , hash */
  ) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 1),
        array = _ref2[0];

    if (!array) {
      return array;
    }

    if (!array.reverse && array.toArray) {
      return array.toArray().reverse();
    }

    return array.slice().reverse();
  }

  var _default = Ember.Helper.helper(reversed);

  _exports.default = _default;
});