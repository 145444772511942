define("oi/models/link", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    object_app: _emberData.default.attr('string'),
    object_type: _emberData.default.attr('string'),
    object_id: _emberData.default.attr('string'),
    label: _emberData.default.attr('string'),
    content_object: _emberData.default.attr(),
    created_by: _emberData.default.attr('string'),
    created_at: _emberData.default.attr('momentdate'),
    updated_by: _emberData.default.attr('string'),
    updated_at: _emberData.default.attr('momentdate'),
    task: _emberData.default.belongsTo('task', {
      async: true
    })
  });

  _exports.default = _default;
});