define("oi/components/oi-shipment-filter", ["exports", "oi/constants/countries"], function (_exports, _countries) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    canTag: Ember.computed.notEmpty('tags'),
    canSelectCarriers: Ember.computed.notEmpty('carriers'),
    countries: _countries.default
  });

  _exports.default = _default;
});