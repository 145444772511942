define("oi/components/oi-editable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    user: Ember.inject.service(),
    actions: {
      edit: function edit() {
        var _this = this;

        this.set('whoIsEditing', this.elementId);

        if (this.$('input')) {
          Ember.run.next(function () {
            return _this.$('input').focus();
          });
        }

        return this.edit ? this.edit() : undefined;
      },
      cancel: function cancel() {
        this.set('whoIsEditing', null);
        return this.cancel ? this.cancel() : undefined;
      },
      update: function update() {
        var _this2 = this;

        this.set('whoIsEditing', null);
        var updateResult = this.update();

        if (updateResult && updateResult.then) {
          this.toggleProperty('isSaving');
          updateResult.then(function (res) {
            _this2.toggleProperty('isSaving');

            return res;
          }).catch(function (error) {
            _this2.toggleProperty('isSaving');

            throw error;
          });
        }
      }
    }
  });

  _exports.default = _default;
});