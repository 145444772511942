define("oi/services/user", ["exports", "@babel/runtime/helpers/esm/toConsumableArray"], function (_exports, _toConsumableArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    session: Ember.inject.service(),
    authHeaders: Ember.computed('session.data.{authenticated.access_token,prefAccountIds}', function () {
      var accessToken = this.get('session.data.authenticated.access_token');
      var shareToken = (window.location.href.split('share=')[1] || '').split('&')[0];

      if (accessToken === undefined && shareToken) {
        // no token authentication => shared shipment/subscription view
        return {
          Authorization: "Share ".concat(shareToken)
        };
      }

      var headers = {
        Authorization: "Bearer ".concat(accessToken)
      };
      var accountId = this.get('session.data.prefAccountIds'); // const accountId = this.get('session.data.prefAccountId');

      if (accountId) {
        headers['Account-Id'] = accountId.join();
      }

      return headers;
    }),
    fullHeaders: Ember.computed('authHeaders', function () {
      return Object.assign({
        Accept: 'application/json, text/javascript, */*; q=0.01'
      }, this.authHeaders);
    }),
    flattenAccounts: function flattenAccounts(nestedAccounts) {
      // ^ without the fallback || [], this fails silently after logout and does
      // not get propertly recomputed after new login -> UI element missing
      var flatAccounts = [];
      var isNested = false; // we need to keep track of structure...

      nestedAccounts.forEach(function (account) {
        if (isNested && !account.sub_accounts) {
          flatAccounts.splice(0, 0, account); // show master-less sub-accounts above first master
        } else {
          flatAccounts.push(account);
          isNested = isNested || account.sub_accounts;
          flatAccounts.push.apply(flatAccounts, (0, _toConsumableArray2.default)(account.sub_accounts || []));
        }
      }); // ...to decide about sorting

      return isNested ? flatAccounts : flatAccounts.sortBy('title');
    },
    flattenedAccounts: Ember.computed('session.data.accounts', function () {
      var nestedAccounts = this.get('session.data.accounts') || [];
      return this.flattenAccounts(nestedAccounts);
    }),
    currentFlattenedAccounts: Ember.computed('session.data.accounts', function () {
      var nestedAccounts = this.currentAccounts || [];
      return this.flattenAccounts(nestedAccounts);
    }),
    currentAccounts: Ember.computed('session.data.prefAccountIds.[]', 'flattenedAccounts', function () {
      var _this = this;

      if (!this.session.data.prefAccountIds) {
        return [];
      }

      var currentAccounts = this.flattenedAccounts.filter(function (account) {
        return _this.session.data.prefAccountIds.includes(account.id);
      });
      return currentAccounts;
    }),
    atLeastOneAccountCanWrite: Ember.computed('currentAccounts.@each.read_only', function () {
      var currentAccounts = this.currentAccounts;

      if (!currentAccounts) {
        /* should only happen during tests */
        return false;
      }

      var canWrite = currentAccounts.filter(function (account) {
        return !account.read_only;
      }).length > 0;
      return canWrite;
    }),
    subAccountsAvailable: Ember.computed('currentAccounts.@each.{read_only,sub_accounts}', function () {
      return this.currentAccounts.length && (this.currentAccounts.length !== 1 || this.currentAccounts[0].sub_accounts && this.currentAccounts[0].sub_accounts.length);
    }),
    canEditAccessControlTags: Ember.computed('session.data.features.access_control_tags.{feature_enabled,read_only}', function () {
      var actFeature = this.session.get('data.features.access_control_tags') || {};
      return actFeature.feature_enabled && !actFeature.read_only;
    })
  });

  _exports.default = _default;
});