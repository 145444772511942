define("oi/controllers/subscriptions", ["exports", "oi/mixins/multi-filter-mixin", "oi/config/environment", "moment", "jquery"], function (_exports, _multiFilterMixin, _environment, _moment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.SUBSCRIPTION_STATUS_FILTERS = void 0;
  var SUBSCRIPTION_STATUS_FILTERS = [{
    id: '0',
    title: 'new'
  }, {
    id: '1',
    title: 'active'
  }, {
    id: '2',
    title: 'completed'
  }, {
    id: '3',
    title: 'cancelled '
  }, {
    id: '4',
    title: 'invalid '
  }, {
    id: '5',
    title: 'archived'
  }];
  _exports.SUBSCRIPTION_STATUS_FILTERS = SUBSCRIPTION_STATUS_FILTERS;
  var FILTER_PARAMS = ['request_carrier', 'tags', 'created_0', 'created_1', 'status'];
  var SORT_PARAMS = ['sort_status', 'sort_request_key', 'sort_created', 'sort_request_carrier'];

  var _default = Ember.Controller.extend(_multiFilterMixin.default, {
    queryParams: ['page', 'page_size', 'search'].concat(FILTER_PARAMS, SORT_PARAMS),
    filterParams: FILTER_PARAMS,
    applicationService: Ember.inject.service(),
    notify: Ember.inject.service(),
    user: Ember.inject.service(),
    session: Ember.inject.service(),
    featuresAndPermissions: Ember.computed.alias('session.data.features'),
    subscriptionStatusList: SUBSCRIPTION_STATUS_FILTERS,
    page: 1,
    carrierSortOrder: Object.freeze(['short_name']),
    sortedCarriers: Ember.computed.sort('carriers', 'carrierSortOrder'),
    isMasterAccount: Ember.computed.alias('session.data.isMasterAccount'),
    canTag: Ember.computed.notEmpty('availableTags'),
    allowBatchActions: Ember.computed('user.atLeastOneAccountCanWrite', function () {
      return !['sysco'].includes(_environment.default.APP.PROD_ENV) && this.user.atLeastOneAccountCanWrite;
    }),
    isActionButtonDisabled: Ember.computed('subscriptions.@each.checked', function () {
      return !this.subscriptions.findBy('checked');
    }),

    /* for batch-/multi-edit sub-account change */
    availableSubAccounts: Ember.computed('user.{currentAccounts.[],flattenedAccounts.[]}', function () {
      var currentAccounts = this.user.currentAccounts;

      if (currentAccounts.length === 1 && currentAccounts[0].sub_accounts) {
        // one master of a MUA setup selected: return all sub-accounts
        return currentAccounts[0].sub_accounts;
      }

      if (!currentAccounts.length || !currentAccounts[0].master_account_id) {
        // accounts still loading or SUA setup: no sub-accounts to select from
        return [];
      }

      var masterId = currentAccounts[0].master_account_id;
      return this.user.flattenedAccounts.filter(function (acc) {
        return acc.master_account_id === masterId;
      });
    }),
    downloadDisabled: Ember.computed('count', function () {
      var count = this.get('count') || 0;
      return count === 0;
    }),
    onBatchSuccess: function onBatchSuccess() {
      var _this = this;

      (0, _jquery.default)('.modal').modal('hide');
      this.subscriptions.forEach(function (item) {
        if (item.get('checked')) {
          item.set('checked', false);
        }
      });
      this.notify.info('Changes successfully applied.', {
        closeAfter: 2000
      });
      Ember.run.later(function () {
        Ember.getOwner(_this).lookup('route:subscriptions').refresh();
      }, 2000);
    },
    onBatchFail: function onBatchFail(arg) {
      (0, _jquery.default)('.modal').modal('hide');
      var message = arg.responseJSON.detail || arg.responseJSON.non_field_errors || 'An unhandled error occurred.';
      this.notify.info("Applying changes failed! ".concat(message), {
        closeAfter: 6000
      });
    },
    getAvailableTags: function getAvailableTags() {
      if (this.session.get('data.features.access_control_tags.feature_enabled')) {
        return this.availableTags.slice().filterBy('isAccessControl', false);
      }

      return this.availableTags.slice();
    },
    batchAjax: function batchAjax(payload, urlSuffix) {
      _jquery.default.ajax({
        url: "".concat(this.applicationService.apiBase, "/subscriptions/").concat(urlSuffix, "/"),
        type: 'POST',
        data: JSON.stringify(payload),
        contentType: 'application/json; charset=UTF-8',
        headers: this.get('user.authHeaders'),
        crossDomain: true,
        xhrFields: {
          withCredentials: _environment.default.APP.USE_AJAX_CALL_WITH_CREDENTIALS
        }
      }).then(this.onBatchSuccess.bind(this), this.onBatchFail.bind(this));
    },
    actions: {
      allcheckedChanged: function allcheckedChanged() {
        var _this2 = this;

        this.toggleProperty('allChecked');
        this.subscriptions.forEach(function (item) {
          if (!item.readOnly) item.set('checked', _this2.get('allChecked'));
        });
      },
      search: function search(newSearchTerm) {
        this.setProperties({
          page: 1,
          search: newSearchTerm
        });
      },
      showDownloadModal: function showDownloadModal() {
        var itemsToExport = this.get('count');

        if (itemsToExport > 100000) {
          this.notify.error('The download would exceed the limit of 100000 items. Please use the filter to reduce the amount of data', {
            closeAfter: 10000
          });
        } else {
          this.send('showModal', 'components/download-modal', {
            itemsToExport: itemsToExport
          });
        }
      },
      requestEdit: function requestEdit() {
        // collect the checked model id's and put them into an array
        var checkedSubscriptions = this.subscriptions.filterBy('checked').map(function (s) {
          return s.id;
        }); // packaging

        var model = {
          selectedSubscriptionIds: checkedSubscriptions,
          selectedTags: [],
          availableTags: this.getAvailableTags(),
          availableAccessControlTags: this.availableTags.slice().filterBy('isAccessControl', true),
          selectedAccessControlTags: this.selectedTags ? this.selectedTags.slice().filterBy('isAccessControl', true) : [],
          descriptive_name: '',
          availableSubAccounts: this.availableSubAccounts,
          selectedUser: null,
          contractual_etd_pol: null,
          contractual_eta_pod: null,
          contractual_eta_dlv: null,
          delDesc: false,
          replaceActTags: false,
          delTags: false,
          delEtdPol: false,
          delEtaPod: false,
          delEtaDlv: false
        }; // call the modal dialog

        if (checkedSubscriptions.length > 0) {
          this.send('showModal', 'components/batchedit-modal', model);
        }
      },
      requestCancel: function requestCancel() {
        // collect the checked model id's and put them into an array
        var checkedSubscriptions = this.subscriptions.filterBy('checked').map(function (s) {
          return s.id;
        }); // call the modal dialog

        if (checkedSubscriptions.length > 0) {
          this.send('showModal', 'components/batchcancel-modal', checkedSubscriptions);
        }
      },
      requestArchive: function requestArchive() {
        // collect the checked model id's and put them into an array
        var checkedSubscriptions = this.subscriptions.filterBy('checked').map(function (s) {
          return s.id;
        }); // call the modal dialog

        if (checkedSubscriptions.length > 0) {
          this.send('showModal', 'components/batcharchive-modal', checkedSubscriptions);
        }
      },
      batchEdit: function batchEdit(param) {
        var payload = {
          subscriptions: param.selectedSubscriptionIds
        };
        var actIdArray = param.selectedAccessControlTags.map(function (t) {
          return t.id;
        });
        var tagIdArray = param.selectedTags.map(function (t) {
          return t.id;
        });

        if (actIdArray.length > 0 || tagIdArray.length > 0) {
          payload.tags = actIdArray.concat(tagIdArray);
        }

        if (param.replaceActTags && actIdArray.length > 0) {
          payload.replaceActTags = true;
        }

        if (param.delTags) {
          payload.clearNormalTags = true;
        }

        if (param.descriptive_name && param.descriptive_name.length > 0) {
          payload.descriptive_name = param.descriptive_name;
        } else if (param.delDesc) {
          payload.descriptive_name = '';
        }

        if (param.selectedUser) {
          payload.account = param.selectedUser.id;
        }

        if (param.contractual_etd_pol) {
          payload.contractual_etd_pol = _moment.default.utc(param.contractual_etd_pol).format('YYYY-MM-DD');
        } else if (param.delEtdPol) {
          payload.contractual_etd_pol = null;
        }

        if (param.contractual_eta_pod) {
          payload.contractual_eta_pod = _moment.default.utc(param.contractual_eta_pod).format('YYYY-MM-DD');
        } else if (param.delEtaPod) {
          payload.contractual_eta_pod = null;
        }

        if (param.contractual_eta_dlv) {
          payload.contractual_eta_dlv = _moment.default.utc(param.contractual_eta_dlv).format('YYYY-MM-DD');
        } else if (param.delEtaDlv) {
          payload.contractual_eta_dlv = null;
        }

        var isDirty = Object.keys(payload).length > 0;

        if (isDirty) {
          this.batchAjax(payload, 'many_edit');
        } else {
          (0, _jquery.default)('.modal').modal('hide');
        }
      },
      batchCancel: function batchCancel(ids) {
        this.batchAjax({
          ids: ids
        }, 'many_cancel');
      },
      batchArchive: function batchArchive(ids) {
        this.batchAjax({
          ids: ids
        }, 'many_archive');
      },
      sort: function sort(field) {
        var sortParams = {
          page: 1
          /* CWA-80 - Avoid uncanny valley */
          ,
          sort_created: undefined,
          sort_status: undefined,
          sort_request_carrier: undefined,
          sort_request_key: undefined // filters are exclusive, so reset all of them

        };
        sortParams[field] = this.get(field) === 'asc' ? 'desc' : 'asc';
        this.setProperties(sortParams);
      },
      createTask: function createTask() {
        var checkedSubscriptions = this.subscriptions.filterBy('checked').map(function (s) {
          return s;
        });
        var linkedObjects = [];
        checkedSubscriptions.forEach(function (subscription) {
          linkedObjects.push({
            app: 'ctt',
            type: 'containershipmentsubscription',
            id: subscription.id,
            label: subscription.request_key
          });
        });
        this.send('createNewTask', linkedObjects, null);
      }
    }
  });

  _exports.default = _default;
});