define("oi/adapters/shipment", ["exports", "oi/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    urlForQueryRecord: function urlForQueryRecord(query, modelName) {
      var id = query.id,
          share = query.share;

      if (!id || !share) {
        for (var _len = arguments.length, rargs = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
          rargs[_key - 2] = arguments[_key];
        }

        return this._super.apply(this, [query, modelName].concat(rargs));
      }

      return this.buildURL(modelName, id).replace('shipment', 'sharedshipment');
    }
  });

  _exports.default = _default;
});