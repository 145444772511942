define("oi/models/bic-operator", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    address: _emberData.default.attr('string'),
    bic_codes: _emberData.default.hasMany('bic-code'),
    bicCodes: Ember.computed.mapBy('bic_codes', 'id'),
    city: _emberData.default.attr('string'),
    country: _emberData.default.attr('string'),
    fax: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    state: _emberData.default.attr('string'),
    telephone: _emberData.default.attr('string'),
    web: _emberData.default.attr('string'),
    zip_code: _emberData.default.attr('string')
  });

  _exports.default = _default;
});