define("oi/adapters/application", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "moment", "ember-simple-auth/mixins/data-adapter-mixin", "oi/config/environment", "oi/adapters/drf"], function (_exports, _slicedToArray2, _moment, _dataAdapterMixin, _environment, _drf) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PATCHABLE_OBJECTS = ['subscription', 'shipment', 'task', 'comment', 'todo'];

  var _default = _drf.default.extend(_dataAdapterMixin.default, {
    // maxURLLength: 1536, /* before increasing the wsgi buffer-size on the server:
    // long urls (default value is 2048) caused 502 bad gateway from API; if that
    // comes back, try this setting again */
    addTrailingSlashes: true,
    coalesceFindRequests: true,
    // shouldBackgroundReloadAll: () => false, // will break tags & carriers due to eager embedding
    applicationService: Ember.inject.service(),
    user: Ember.inject.service(),
    headers: Ember.computed.alias('user.authHeaders'),
    authorize: function authorize(xhr) {
      Object.entries(this.headers).forEach(function (_ref) {
        var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
            key = _ref2[0],
            value = _ref2[1];

        return xhr.setRequestHeader(key, value);
      });
    },
    host: Ember.computed(function () {
      return this.applicationService.apiHost;
    }),
    ensureResponseAuthorized: function ensureResponseAuthorized(status, headers, payload, requestData) {
      if (status === 403 && payload && payload.detail === 'User is not authenticated.') {
        // work-around for back-end returning a 403 instead of a 401 after token expires
        // at some endpoints
        this.get('session').invalidate(); // session injected in base class DataAdapterMixin

        return;
      }

      this._super(status, headers, payload, requestData);
    },
    shouldReloadAll: function shouldReloadAll()
    /* store, snapshotRecordArray  */
    {
      return false;
    },
    updateRecord: function updateRecord(store, type, snapshot) {
      if (PATCHABLE_OBJECTS.includes(snapshot.modelName)) {
        var payload = {};

        if (typeof snapshot.tags !== 'undefined' && snapshot.hasMany('tags', {
          ids: true
        }).length > 0) {
          payload.tags = snapshot.hasMany('tags', {
            ids: true
          });
        }

        var changedAttributes = snapshot.changedAttributes();
        Object.keys(changedAttributes).forEach(function (attributeName) {
          var newValue = changedAttributes[attributeName][1]; // Do something with the new value and the payload
          // This will depend on what your server expects for a PATCH request
          // payload["descriptive_name"] = newValue;

          if (newValue && (attributeName === 'contractual_etd_pol' || attributeName === 'contractual_eta_pod' || attributeName === 'contractual_eta_dlv')) {
            newValue = (0, _moment.default)(newValue).format('YYYY-MM-DD'); // NOT: utc! - moves date back a day in earlier-than-UTC timezones
          }

          payload[attributeName] = newValue;
        });
        var id = snapshot.id;
        var url = this.buildURL(type.modelName, id, snapshot, 'updateRecord');
        return this.ajax(url, 'PATCH', {
          data: payload
        });
      }

      return this._super(store, type, snapshot);
    },
    createRecord: function createRecord(store, type, snapshot) {
      if (snapshot.modelName === 'subscription') {
        var payload = snapshot.attributes(); // if (snapshot.hasMany('tags').length > 0) {
        //  payload["tags"] = snapshot.hasMany('tags', {ids: true});
        // }

        if (snapshot.hasMany('tags', {
          ids: true
        }).length > 0) {
          payload.set_tags = snapshot.hasMany('tags', {
            ids: true
          });
        } // payload["set_tags"] = [1,2];


        var id = snapshot.id;
        var url = this.buildURL(type.modelName, id, snapshot, 'createRecord');
        return this.ajax(url, 'POST', {
          data: payload
        });
      }

      return this._super(store, type, snapshot);
    },
    handleResponse: function handleResponse(status, headers, payload, requestData) {
      if (status === 403) {
        return new Error(payload.detail);
      }

      return this._super(status, headers, payload, requestData);
    },
    ajax: function ajax(url, method) {
      var hash = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      hash.crossDomain = true;
      hash.xhrFields = {
        withCredentials: _environment.default.APP.USE_AJAX_CALL_WITH_CREDENTIALS
      };
      return this._super(url, method, hash);
    }
  });

  _exports.default = _default;
});