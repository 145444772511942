define("oi/components/hold-button", ["exports", "ember-hold-button/components/hold-button"], function (_exports, _holdButton) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _holdButton.default;
    }
  });
});