define("oi/components/exception-tile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: 'col-lg-4 col-md-4 col-sm-4 col-xs-6 oi-exceptions hint--top',
    description: Ember.computed('info', 'exception.description', function () {
      if (this.exception && this.exception.description) {
        return this.exception.description;
      }

      return (this.info || '').replace('#DAYS#', '... days');
    }),
    attributeBindings: ['description:aria-label'],
    actions: {
      id: function id(arg) {
        return arg;
      } // workaround for ember-countup requiring a "formatter"

    }
  });

  _exports.default = _default;
});