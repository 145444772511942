define("oi/templates/components/my-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QGLy3hSc",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,[\"label\"]]],null,{\"statements\":[[0,\"  \"],[7,\"label\",true],[8],[1,[22,\"label\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[7,\"div\",true],[10,\"class\",\"daterangepicker-input form-control clearfix\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"pull-left oi-gray\"],[8],[0,\"\\n    \"],[7,\"i\",true],[10,\"class\",\"fa fa-calendar oi-gray\"],[8],[9],[0,\" \"],[7,\"span\",true],[8],[1,[22,\"rangeText\"],false],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"pull-right\"],[8],[0,\"\\n    \"],[7,\"b\",true],[10,\"class\",\"cross\"],[8],[9],[7,\"b\",true],[10,\"class\",\"picker-caret\"],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"hide\"],[8],[0,\"\\n    \"],[1,[28,\"input\",null,[[\"value\"],[[24,[\"start\"]]]]],false],[0,\"\\n    \"],[1,[28,\"input\",null,[[\"value\"],[[24,[\"end\"]]]]],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[14,1],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "oi/templates/components/my-picker.hbs"
    }
  });

  _exports.default = _default;
});