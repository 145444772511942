define("oi/components/oi-shared-link-pinoekel", ["exports", "@babel/runtime/helpers/esm/defineProperty", "oi/helpers/days-diff"], function (_exports, _defineProperty2, _daysDiff) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: 'share-link-buttons',
    shareHelpText: "Creates a secret link to this container that can be shared with others.\nAnybody who knows this link will be able to view this container status page without\nhaving to log in. Internal details such as description and tags will NOT be shown.",
    notify: Ember.inject.service(),
    raven: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    didReceiveAttrs: function didReceiveAttrs() {
      this.update();
    },
    update: function update() {
      var _this = this;

      this.set('isBusy', true); // TODO: replace by regular ember-data logic

      return this.store.query('sharedlink', {
        link_type: this.get('linkType'),
        id: this.get('data.id')
      }).then(function (sharedlinks) {
        return _this.set('sharedLinks', sharedlinks);
      }).catch(function (error) {
        if ((_this.get('session.data.email') || '').endsWith('@ocean-insights.com')) {
          return; // dirty hack: assume people at OI know what they are doing
        }

        _this.raven.captureMessage('Shared link lookup error -- possible account/user misconfiguration.', {
          extra: error
        });
      }).then(function () {
        return _this.set('isBusy', false);
      });
    },
    notifyCreateSuccess: function notifyCreateSuccess() {
      var label = this.linkType === 'shipment' ? 'container' : 'subscription';
      this.notify.info("The sharing link for this ".concat(label, " has been created successfully.\n      You may now send this link to anyone who shall be able to view this ").concat(label, "\u2019s status."), {
        closeAfter: 8000
      });
      return this.update();
    },
    notifyFailure: function notifyFailure(error) {
      this.raven.captureMessage('Shared link change error', {
        extra: error
      });
      this.notify.error('The sharing link could not be created. You may not have permission for this account.', {
        closeAfter: 9000
      });
      return this.update();
    },
    hasNoValidSharedLinks: Ember.computed.not('sharedLinks.length'),
    userCanChange: Ember.computed('featuresAndPermissions.sharedlinks', function () {
      return this.get('featuresAndPermissions.sharedlinks.feature_enabled') && !this.get('featuresAndPermissions.sharedlinks.read_only');
    }),
    isLinkExpired: Ember.computed('data.{lifecycle_status,last_carrier_update}', function () {
      // initializing and active shipments
      var validStatuses = [0, 10]; // eslint-disable-next-line prefer-const

      var _this$data = this.data,
          lifecycleStatus = _this$data.lifecycle_status,
          lastUpdate = _this$data.last_carrier_update;

      if (this.linkType === 'subscription') {
        // new and active subscriptions
        validStatuses = [0, 1];
        lifecycleStatus = this.data.status;
      }

      if (validStatuses.includes(lifecycleStatus)) {
        return false;
        /* INIT or ACTIVE */
      }

      return !lastUpdate || (0, _daysDiff.daysDiffRounded)(lastUpdate, 'now') <= -21;
    }),
    notEditableMessage: Ember.computed('data', function () {
      if (this.linkType === 'subscription') {
        // all invalid and cancelled subscriptions will get another message
        if ([4, 3].indexOf(this.data.status) !== -1) {
          return "The subscription can not be shared because\n        its status is ".concat(this.data.statusVerbose);
        }

        return 'All containers of this subscription have arrived, so it can no longer be shared.';
      }

      return 'This container has arrived and can no longer be shared.';
    }),
    label: Ember.computed('linkType', function () {
      return this.linkType === 'shipment' ? 'container' : 'subscription';
    }),
    actions: {
      requestSharedLink: function requestSharedLink() {
        var _this2 = this;

        this.set('isBusy', true);
        return this.store.createRecord('sharedlink', (0, _defineProperty2.default)({}, this.linkType, this.data)).save().then(function () {
          return _this2.notifyCreateSuccess();
        }).catch(function (error) {
          return _this2.notifyFailure(error);
        });
      }
    }
  });

  _exports.default = _default;
});