define("oi/templates/components/oi-contractual-eta", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tt0bHKcL",
    "block": "{\"symbols\":[\"isEditing\"],\"statements\":[[4,\"oi-editable\",null,[[\"readOnly\",\"whoIsEditing\",\"update\",\"edit\"],[[24,[\"readOnly\"]],[24,[\"whoIsEditing\"]],[28,\"action\",[[23,0,[]],\"update\"],null],[28,\"action\",[[23,0,[]],\"edit\"],null]]],{\"statements\":[[4,\"if\",[[23,1,[]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"bootstrap-datepicker\",null,[[\"value\",\"startDate\",\"changeMonth\",\"placeholder\",\"class\",\"format\",\"autoclose\",\"clearBtn\"],[[24,[\"newEta\"]],[24,[\"startDate\"]],[28,\"action\",[[23,0,[]],\"monthChanged\"],null],[28,\"if\",[[24,[\"newEta\"]],[28,\"format-date\",[[24,[\"newEta\"]]],null],\"Pick a date...\"],null],\"form-control\",\"yyyy-mm-dd\",true,true]]],false],[0,\"\\n  \"],[1,[28,\"if\",[[24,[\"isStartDateMonth\"]],[24,[\"startDateHint\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[28,\"format-date\",[[24,[\"eta\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "oi/templates/components/oi-contractual-eta.hbs"
    }
  });

  _exports.default = _default;
});