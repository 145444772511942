define("oi/components/shipment/oi-taw", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    locationIdent: 'POD',
    datetimeFormat: 'YYYY-MM-DD HH:mm',
    prediction: Ember.computed('shipment.pod_timeofarrival_warning.prediction', function () {
      return this.get('shipment.pod_timeofarrival_warning.prediction');
    }),
    severityStyle: Ember.computed('shipment', function () {
      if (this.get('shipment.isArrived')) {
        return 'success';
      }

      return this.get('shipment.pod_timeofarrival_warning.severityStyle');
    })
  });

  _exports.default = _default;
});