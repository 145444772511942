define("oi/controllers/login", ["exports", "oi/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    applicationService: Ember.inject.service(),
    statusPageSummary: Ember.computed('componentSummary.status', function () {
      if (!this.componentSummary || this.componentSummary.status.indicator === 'none') {
        return undefined;
      }

      var filteredComponents = this.componentSummary.components.filter(function (e) {
        return e.name === 'Container Track & Trace' || e.name === 'Vessel Tracking Engine';
      });
      var sortedIncidents = this.incidentSummary.incidents.sort(function (a) {
        if (a.status === 'resolved') {
          return 0;
        }

        return -1;
      });
      return {
        components: filteredComponents,
        incidents: sortedIncidents.slice(0, 3)
      };
    }),
    p44LoginLink: Ember.computed(function () {
      var redirectLink = this.applicationService.redirectLink;
      var p44AuthSystem = _environment.default.APP.P44_LOGIN_SYSTEMS[window.location.host];

      if (p44AuthSystem) {
        return "".concat(p44AuthSystem, "?redirect_uri=").concat(redirectLink);
      }

      return undefined;
    }),
    session: Ember.inject.service(),
    raven: Ember.inject.service(),
    authenticator: 'authenticator:oauth2',
    ssoAuthenticator: 'authenticator:sso',
    errorMessage: null,
    canShowPasswordResetLink: Ember.computed(function () {
      return (// currently this is only enabled on ctt production site
        window.location.href.indexOf('//ctt') !== -1
      );
    }),
    actions: {
      authenticate: function authenticate() {
        var _this = this;

        var ssoCredentials = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
        this.set('errorMessage', ''); // get the credentials and the session object

        var username = this.identification;

        if (ssoCredentials !== null && ssoCredentials.access_token) {
          return this.session.authenticate(this.get('ssoAuthenticator'), ssoCredentials).catch(function (err) {
            _this.set('errorMessage', err);
          });
        }

        return this.session.authenticate(this.authenticator, username, this.password).catch(function (errorResponse) {
          var error = errorResponse && (errorResponse.responseJSON || errorResponse);

          if (error && error.error === 'invalid_grant') {
            _this.set('errorMessageCredentials', 'Username and/or password invalid!');

            return;
          }

          if (!error) {
            _this.set('errorMessage', 'possibly a problem reaching the server<br>Note: If you are behind a firewall requests to <b>*.project44.com <u>must</u> be granted</b>!'); // fall-through for sentry logging, although if we really have a
            // case of "network problem" here, that will fail, too

          } else if (error.error === 'invalid_client') {
            _this.set('errorMessage', 'Request problem -- your network may garble requests (e.g. strip headers).');
          } else {
            _this.set('errorMessage', error.detail || 'unexpected error');

            console.error(error); // eslint-disable-line no-console
          }

          _this.raven.captureMessage("Failed CTT login for ".concat(username, ": ").concat(_this.errorMessage), {
            level: 'warning',
            fingerprint: ['Failed CTT login'],
            extra: {
              username: username,
              error: error
            }
          });
        });
      }
    }
  });

  _exports.default = _default;
});