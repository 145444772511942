define("oi/templates/components/zoom-indicator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7SXSHYG5",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"zoomDirection\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\",true],[11,\"class\",[29,[\"hint--\",[28,\"or\",[[24,[\"hintPosition\"]],\"top-left\"],null],\" hint-keeplinebreaks\"]]],[11,\"aria-label\",[22,\"tooltip\"]],[10,\"style\",\"font-size:smaller;\"],[8],[0,\"\\n  \"],[7,\"i\",true],[10,\"class\",\"fa fa-info-circle\"],[10,\"aria-hidden\",\"true\"],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "oi/templates/components/zoom-indicator.hbs"
    }
  });

  _exports.default = _default;
});