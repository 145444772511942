define("oi/templates/components/oi-accounts-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wG5IDi8M",
    "block": "{\"symbols\":[\"elem\",\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"oi-multiple-filter\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"oi-multiple-filter-selector\"],[8],[0,\"\\n\"],[4,\"oi-power-select-multiple\",null,[[\"class\",\"closeOnSelect\",\"selectMultiple\",\"selectedSummarized\",\"checkboxOptions\",\"customSummarizedTitle\",\"allowClear\",\"options\",\"defaultHighlighted\",\"selected\",\"searchField\",\"searchPlaceholder\",\"searchEnabled\",\"onChange\",\"filter\",\"horizontalPosition\",\"onSubmit\"],[[24,[\"class\"]],false,true,true,true,[28,\"concat\",[[24,[\"selectedValue\",\"length\"]],\" Sub-accounts\"],null],false,[24,[\"options\"]],[24,[\"highlightAccount\"]],[24,[\"selectedValue\"]],\"title\",\"Type to filter...\",true,[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"selectedValue\"]]],null]],null],[24,[\"accountFilterFunction\"]],\"auto-right\",[28,\"action\",[[23,0,[]],\"switchScope\"],null]]],{\"statements\":[[0,\"        \"],[14,2,[[23,1,[]]]],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "oi/templates/components/oi-accounts-select.hbs"
    }
  });

  _exports.default = _default;
});