define("oi/components/tasks/comments", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    task: undefined,
    comment: undefined,
    actions: {
      patch: function patch(comment, value) {
        var task = this.get('task');

        if (comment) {
          comment.set('comment', value);

          if (!task.isNew) {
            comment.save();
          }
        }
      },
      delete: function _delete(comment) {
        if (comment) {
          comment.destroyRecord();
        }
      },
      add: function add(commentToAdd) {
        var task = this.get('task');

        if (commentToAdd && task) {
          var createdComment = task.store.createRecord('comment', {
            comment: commentToAdd,
            task: task
          });

          if (!task.isNew) {
            createdComment.save();
          }

          this.set('commentToAdd', undefined);
        }
      }
    }
  });

  _exports.default = _default;
});