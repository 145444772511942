define("oi/models/subscription", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    user: Ember.inject.service(),
    session: Ember.inject.service(),
    request_carrier_name: _emberData.default.attr('string'),
    request_carrier_code: _emberData.default.attr('string'),
    // only for creation, i.e. POST
    request_key: _emberData.default.attr('string'),
    request_type: _emberData.default.attr('string'),
    descriptive_name: _emberData.default.attr('string'),
    containershipments_count: _emberData.default.attr('number'),
    containershipments: _emberData.default.hasMany('shipment'),
    containerFilter: _emberData.default.attr('string'),
    containerFilterCount: Ember.computed('containerFilter', function () {
      return this.containerFilter ? this.containerFilter.split(',').length : 0;
    }),
    account: _emberData.default.attr('number'),
    owner_internal_id: _emberData.default.attr('number'),
    // internal id for
    created: _emberData.default.attr('momentdate'),
    modified: _emberData.default.attr('momentdate'),
    last_carrier_update: _emberData.default.attr('momentdate'),
    last_actuals_update: _emberData.default.attr('momentdate'),
    status: _emberData.default.attr('number'),
    checked: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    documents: _emberData.default.hasMany('document'),
    documentattachments_count: _emberData.default.attr('number'),
    tags: _emberData.default.hasMany('tag', {
      async: true
    }),
    contractual_etd_pol: _emberData.default.attr('momentdate'),
    contractual_eta_pod: _emberData.default.attr('momentdate'),
    contractual_eta_dlv: _emberData.default.attr('momentdate'),
    tasks_count: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    readOnly: Ember.computed('account', 'user.currentAccounts', 'session.data.prefAccountIds', function () {
      var _this = this;

      var userAccounts = this.user.currentFlattenedAccounts; // MUA check

      var subscriptionAccount = userAccounts.filter(function (account) {
        return account.id === _this.account;
      });

      if (subscriptionAccount.length === 1) {
        return subscriptionAccount[0].read_only;
      } // SUA check


      var suaAcc = userAccounts.filter(function (acc) {
        return acc.is_sua;
      });

      if (suaAcc.length === 1) {
        return suaAcc[0].read_only;
      }

      return true;
    }),
    statusVerbose: _emberData.default.attr('string'),
    // CWA-37 --------------------------------------------------------------------
    statusIcon: Ember.computed('status', function () {
      var status = this.status;
      var vStatus = ['wrench', 'play-circle icon-green', 'check icon-green', 'ban icon-red', 'exclamation-circle icon-red', 'archive icon-red'];

      if (status > 0 && status < 6) {
        return vStatus[status];
      }

      return vStatus[0];
    }),
    customization: _emberData.default.attr() // only expected in sharedView, otherwise looked up separately

  });

  _exports.default = _default;
});