define("oi/serializers/task", ["exports", "oi/mixins/pagination-aware-serializer", "oi/serializers/drf"], function (_exports, _paginationAwareSerializer, _drf) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _drf.default.extend(_paginationAwareSerializer.default, {
    primaryKey: 'id',
    attrs: {
      reporter: {
        serialize: false
      },
      assigned_to: {
        serialize: false
      }
    }
  });

  _exports.default = _default;
});