define("oi/authenticators/impersonator", ["exports", "ember-simple-auth/authenticators/oauth2-password-grant"], function (_exports, _oauth2PasswordGrant) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _oauth2PasswordGrant.default.extend({
    applicationService: Ember.inject.service(),
    session: Ember.inject.service(),
    serverTokenEndpoint: Ember.computed(function () {
      return "".concat(this.applicationService.apiBase, "/impersonate/");
    }),
    serverTokenRevocationEndpoint: Ember.computed(function () {
      return "".concat(this.applicationService.apiHost, "/o/revoke-token/");
    }),
    authenticate: function authenticate(username, password, scope, headers) {
      var accessToken = this.get('session.data.authenticated.access_token');
      var headersWithBearerToken = Object.assign({}, headers, {
        Authorization: "Bearer ".concat(accessToken)
      });
      return this._super(username, password, scope, headersWithBearerToken);
    },
    invalidate: function invalidate(data) {
      var _this = this;

      // closely following https://github.com/simplabs/ember-simple-auth/blob/master/addon/authenticators/oauth2-password-grant.js
      // but incorporating impersonator name
      function success(resolve) {
        /* eslint-disable no-underscore-dangle */
        Ember.run.cancel(this._refreshTokenTimeout);
        delete this._refreshTokenTimeout;
        /* eslint-enable no-underscore-dangle */

        resolve();
      }

      return new Ember.RSVP.Promise(function (resolve) {
        var requests = [];
        ['access_token', 'refresh_token'].forEach(function (tokenType) {
          // imposters do not have a refresh_token in the original implementation,
          // but maybe in the future ... ;-)
          var token = data[tokenType];

          if (token) {
            requests.push(_this.makeRequest(_this.serverTokenRevocationEndpoint, {
              imposter: data.impersonated_by,
              token_type_hint: tokenType,
              token: token
            }));
          }
        });

        var succeed = function succeed() {
          success.apply(_this, [resolve]);
        };

        Ember.RSVP.all(requests).then(succeed, succeed);
      });
    }
  });

  _exports.default = _default;
});