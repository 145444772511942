define("oi/controllers/preferences", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      toggleModal: function toggleModal() {
        this.send.apply(this, ['showModal'].concat(Array.prototype.slice.call(arguments))); // eslint-disable-line prefer-rest-params
      }
    }
  });

  _exports.default = _default;
});