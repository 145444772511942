define("oi/models/shipment/location", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "ember-data", "oi/helpers/days-diff"], function (_exports, _slicedToArray2, _emberData, _daysDiff) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.OUTGOING_MILESTONES_DESC = void 0;
  var INCOMING_MILESTONES_ASC = {
    /* each ordered from earlier to later */
    pol: ['CGI'],
    tsp: ['VAT', 'CDT'],
    pod: ['VAD', 'CDD'],
    lif: ['CIF']
  };
  /* must resist urge to add dlv:[CGO] duplicate just because "days in dlv" considers
  pod_departure the starting point of dlv (addition would cause mess where inDateReal
  is used as fallback if outDateReal n/a, e.g. shipment status graphic) */

  var OUTGOING_MILESTONES_DESC = {
    /* from later to earlier, if multiple */
    empty_pickup: ['CEP'],
    // no usages known (2018Q4), but included for completeness
    origin: ['CPS'],
    // single-milestone locations included here as they had to be
    // included somewhere and this seemed more intuitive, since where dates are
    // coalesced (shipment status/timeline), outgoing dates are checked first
    pol: ['VDL', 'CLL'],
    tsp: ['VDT', 'CLT'],
    pod: ['CGO'],
    lif: ['COF'],
    // no in-out comparison or coalesced date usages known (2018Q4)
    dlv: ['CDC'],
    empty_return: ['CER']
  };
  _exports.OUTGOING_MILESTONES_DESC = OUTGOING_MILESTONES_DESC;

  function extractMilestones(msKeyLookupByLocKey, _ref) {
    var locKey = _ref.key,
        milestones = _ref.milestones;

    /** given location-key->milestone-keys mapping, extract milestones for given
     * location from given milestones list */
    var _locKey$split = locKey.split(/([_a-z]+)([0-9]*)/),
        _locKey$split2 = (0, _slicedToArray2.default)(_locKey$split, 3),
        locKeyBase = _locKey$split2[1],
        tspIndex = _locKey$split2[2];

    return (msKeyLookupByLocKey[locKeyBase] || []).map(function (msKey) {
      return milestones.findBy('key', msKey + tspIndex);
    });
  }

  function first(arrayName, dependentKey) {
    return function () {
      return this.get(arrayName).mapBy(dependentKey).find(function (el) {
        return el;
      });
    };
  }

  function firstItem(arrayName) {
    return function () {
      var array = this.get(arrayName);

      if (array) {
        return array[0];
      }

      return undefined;
    };
  }

  function firstItemDate(inDateKey, outDateKey) {
    return function () {
      if (this.get(outDateKey)) {
        return this.get('firstMilestoneOutgoing').key;
      }

      if (this.get(inDateKey)) {
        return this.get('firstMilestoneIncoming').key;
      }

      return undefined;
    };
  }

  var _default = _emberData.default.Model.extend({
    key: _emberData.default.attr('string'),
    // former flat attribute name prefix, e.g. empty_pickup, loc, tsp1
    shipment: _emberData.default.belongsTo('shipment', {
      inverse: 'locations'
    }),
    // basic loc properties:
    locode: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    latitude: _emberData.default.attr('number'),
    longitude: _emberData.default.attr('number'),
    timezone: _emberData.default.attr('string'),
    milestones: _emberData.default.hasMany('shipment/milestone'),
    starts_leg: _emberData.default.attr('number'),
    vessel: _emberData.default.attr(),
    vesselName: Ember.computed.alias('vessel.name'),
    isDone: Ember.computed.alias('milestones.lastObject.isDone'),
    // note: milestones are also reversed  (location is done if latest (1st) milestone done)
    isStarted: Ember.computed.alias('milestones.firstObject.isDone'),
    mostRelevantPrediction: Ember.computed.alias('milestones.firstObject.prediction.content'),
    // ^ TODO/CHECK: The most relevant @POL would be the last, right?!
    // ^TODO2: investigate whether we can use the proxy directly, without .content
    //  somehow (proxy is always truthy, however, even if content is undefined)
    milestonesIncoming: Ember.computed('milestones.[]', function () {
      return extractMilestones(INCOMING_MILESTONES_ASC, this.getProperties('key', 'milestones'));
    }),
    milestonesOutgoing: Ember.computed('milestones.[]', function () {
      return extractMilestones(OUTGOING_MILESTONES_DESC, this.getProperties('key', 'milestones'));
    }),
    firstMilestoneIncoming: Ember.computed('milestonesIncoming', firstItem('milestonesIncoming')),
    firstMilestoneOutgoing: Ember.computed('milestonesOutgoing', firstItem('milestonesOutgoing')),
    actualDateMilestoneKey: Ember.computed('milestones.[]', firstItemDate('inDateReal', 'outDateReal')),
    plannedDateMilestoneKey: Ember.computed('milestones.[]', firstItemDate('inDatePlanned', 'outDatePlanned')),
    inDateReal: Ember.computed('milestonesIncoming', first('milestonesIncoming', 'doneDate')),
    outDateReal: Ember.computed('milestonesOutgoing', first('milestonesOutgoing', 'doneDate')),
    inDatePlanned: Ember.computed('milestonesIncoming', first('milestonesIncoming', 'planned_last')),
    outDatePlanned: Ember.computed('milestonesOutgoing', first('milestonesOutgoing', 'planned_last')),
    inDatePlannedInitial: Ember.computed('milestonesIncoming', first('milestonesIncoming', 'planned_initial')),
    outDatePlannedInitial: Ember.computed('milestonesOutgoing', first('milestonesOutgoing', 'planned_initial')),
    contractualDate: Ember.computed('milestones.@each.contracted', first('milestones', 'contracted')),
    daysIn: Ember.computed('inDateReal', 'outDateReal', 'isDone', function () {
      // if no in-date or this loc isDone and no outdate known, returns null
      // if in-date and no-outdate, return days from in-date to today (rounded),
      // else return days from in- to out-date (rounded)
      // Note: for dlv, we use pod-outDate as fallback for dlv-inDate
      var inDateReal = this.inDateReal,
          outDateReal = this.outDateReal,
          isDone = this.isDone,
          key = this.key;
      var inDate = inDateReal || key === 'dlv'
      /* special case fallback */
      && this.get('shipment.locationsDict.pod.outDateReal');
      var outDate = outDateReal || key === 'dlv'
      /* special case fallback */
      && this.get('shipment.locationsDict.empty_return.outDateReal');

      if (!inDate || isDone && !outDate) {
        return null;
      }

      return (0, _daysDiff.daysDiffRounded)(isDone ? outDate : 'now', inDate);
    }),
    alert: Ember.computed('daysIn', 'isDone', 'isStarted', 'shipment.lifecycle_status', function () {
      var daysIn = this.daysIn,
          isDone = this.isDone,
          key = this.key;

      if (!daysIn) {
        return null;
      }

      if (isDone && daysIn > 3) {
        return "was ".concat(daysIn, " days in ").concat(key.toUpperCase());
      }

      if (!isDone && this.get('shipment.lifecycle_status') === 0) {
        return "currently ".concat(daysIn, " day").concat(daysIn > 1 ? 's' : '', " in ").concat(key.toUpperCase());
      }

      return null;
    })
  });

  _exports.default = _default;
});