define("oi/helpers/add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.add = add;
  _exports.default = void 0;

  function add(params) {
    var sum = 0;
    params.forEach(function (param) {
      sum += param;
    });
    return sum;
  }

  var _default = Ember.Helper.helper(add);

  _exports.default = _default;
});