define("oi/templates/components/archive-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sIdfVuB/",
    "block": "{\"symbols\":[],\"statements\":[[4,\"confirm-modal\",null,[[\"title\",\"ok\",\"context\",\"close\",\"actionIsImmediate\"],[\"Archive subscription\",\"archive\",[24,[\"model\"]],\"removeModal\",true]],{\"statements\":[[0,\"  Archiving the subscription will flag the subscription as archived which is very handy to put finished subscriptions out of your scope.\\n    Would you like to proceed?\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "oi/templates/components/archive-modal.hbs"
    }
  });

  _exports.default = _default;
});