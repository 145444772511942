define("oi/components/autocompletionfilter", ["exports", "fetch"], function (_exports, _fetch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    applicationService: Ember.inject.service(),
    user: Ember.inject.service(),
    notify: Ember.inject.service(),
    search: function search(term, resolve, reject) {
      var _this = this;

      var options = {
        headers: this.get('user.authHeaders'),
        method: 'GET',
        credentials: 'include'
      };
      var searchOptions = '';

      if (this.searchLocodes) {
        searchOptions += '&searchLocode=1';
      }

      if (this.searchCity) {
        searchOptions += '&searchCity=1';
      }

      if (this.searchVessel) {
        searchOptions += '&searchVessel=1';
      }

      if (this.searchMmsi) {
        searchOptions += '&searchMmsi=1';
      }

      if (this.searchImo) {
        searchOptions += '&searchImo=1';
      }

      var apiBase = this.applicationService.apiBase;
      var url = "".concat(apiBase, "/autocompletion/?search=").concat(term).concat(searchOptions);
      return (0, _fetch.default)(url, options).then(function (resp) {
        if (resp.status !== 200) {
          _this.notify.error("Autocompletion not possible. Request returned ".concat(resp.status, " - ").concat(resp.statusText, "."), {
            closeAfter: 5000
          });
        }

        return resp.json();
      }).then(function (json) {
        return resolve(json);
      }, reject);
    },
    actions: {
      search: function search(term) {
        var _this2 = this;

        return new Ember.RSVP.Promise(function (resolve, reject) {
          Ember.run.debounce(_this2, _this2.search, term, resolve, reject, 600);
        });
      }
    }
  });

  _exports.default = _default;
});