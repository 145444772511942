define("oi/components/simple-block", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Dummy component that just displays its block-content
   *
   * used for "named yields"-like behaviour of `filterable-table`, cf.
   * https://discuss.emberjs.com/t/using-yield-for-multiple-nested-components/9126/4
   */
  var _default = Ember.Component.extend({
    tagName: ''
  });

  _exports.default = _default;
});