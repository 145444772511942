define("oi/components/exceptionmanagement/exception-card", ["exports", "ember-local-storage", "oi/constants/system"], function (_exports, _emberLocalStorage, _system) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    exceptionmanagementStorage: (0, _emberLocalStorage.storageFor)('exceptionmanagement'),
    isIE: _system.IS_INTERNET_EXPLORER,
    actions: {
      formatNumber: function formatNumber(value) {
        return value;
      },
      edit: function edit(item) {
        this.controller.send('edit', item);
      },
      refresh: function refresh(item) {
        item.refresh();
      },
      clone: function clone(item) {
        this.controller.send('clone', item);
      },
      saveItem: function saveItem(item) {
        this.controller.send('saveItem', item);
      },
      askForDelete: function askForDelete(item) {
        this.controller.send('askForDelete', item);
      },
      deleteItem: function deleteItem(item) {
        this.controller.send('deleteItem', item);
      },
      cancel: function cancel(field, item) {
        this.controller.send('cancel', field, item);
      },
      closeDialog: function closeDialog(field) {
        this.controller.send('closeDialog', field);
      }
    }
  });

  _exports.default = _default;
});