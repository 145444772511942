define("oi/components/filterable-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Wrapper for any list of items that can be filtered. Yields 3 "blocks":
   *
   * header - for action buttons, direct filter fields. "Expand filters" button will
   *          be added to the end (inside same div), will be followed by page-nav.
   * filter-panel - content shown when "expand filters" button is clicked
   * data - for actual content below header & possibly filter-tablepanel
   *
   * Required arguments: everything required by page-nav:
   * page, page_size, count, totalPages
   *
   * Optional arguments:
   * noData - if true, a "no items, please reset filters"-like message will be
   *          rendered instead of the data block
   * subjectsName - plural word describing content to replace "items" in `noData`-message
   * filtersPresent - mark "expand filters" button to indicate presence of filters already:
   *                 `true` for default marker or hash of "subject" and "icon" for custom
   */
  var _default = Ember.Component.extend({
    classNames: ['container'],
    subjectsName: 'items' // usually unhelpful default value in case nothing is passed in

  });

  _exports.default = _default;
});