define("oi/components/visual-query-filter/rule-modal", ["exports", "@babel/runtime/helpers/esm/toConsumableArray", "oi/constants/countries"], function (_exports, _toConsumableArray2, _countries) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    errors: null,
    isTitleEdit: false,
    fieldErrors: undefined,
    countries: _countries.default,
    checkErrors: function checkErrors() {
      var errors = [];

      if (this.query.rules.length === 0) {
        errors.push('no-rules');
      }

      this.query.rules.forEach(function (item) {
        if (item.type !== 'boolean' && (!item.value || !item.value.replace(/\s/g, ''))) {
          errors.push(item);
        }
      });
      this.set('fieldErrors', errors);
    },
    filtersFlat: Ember.computed('filters', function () {
      var _this = this;

      var filtersFlat = [];
      Object.keys(this.filters).forEach(function (e) {
        filtersFlat = filtersFlat.concat(_this.filters[e]);
      });
      return filtersFlat;
    }),
    willUpdate: function willUpdate() {
      if (this.fieldErrors) {
        this.checkErrors();
      }
    },
    setRule: function setRule(rulePreset) {
      var rule = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : undefined;
      var newRule = {};
      newRule.field = rulePreset.id;
      newRule.input = rulePreset.input;
      newRule.type = rulePreset.type;
      newRule.id = rulePreset.id;

      if (rulePreset.options) {
        newRule.options = rulePreset.options;
      }

      newRule.operator = rulePreset.default_operator || this.operators[0];

      if (!rule) {
        newRule.value = newRule.default_value || '';
        this.set('query.rules', [].concat((0, _toConsumableArray2.default)(this.query.rules), [newRule]));
      } else {
        this.set('query.rules', this.query.rules.map(function (item) {
          if (item === rule) {
            if (newRule.options !== undefined) {
              newRule.value = newRule.options[0].id;
            } else if (newRule.type === 'boolean') {
              newRule.value = true;
            } else {
              newRule.value = rulePreset.default_value;
            }

            return newRule;
          }

          return item;
        }));
      }
    },
    actions: {
      ok: function ok() {
        this.checkErrors();

        if (!this.fieldErrors || this.fieldErrors.length === 0) {
          this.save(this.query);
          this.toggleProperty('open');
        }
      },
      removeRule: function removeRule(rule) {
        var newRules = this.query.rules.filter(function (r) {
          return r !== rule;
        });
        this.set('query.rules', newRules);
      },
      addRule: function addRule() {
        this.set('fieldErrors', undefined);
        var filterPreset = this.filtersFlat[0];
        var newRule = {
          id: filterPreset.id,
          field: filterPreset.field,
          label: filterPreset.label,
          type: filterPreset.type
        };
        this.setRule(newRule);
      },
      changeRule: function changeRule(r, event) {
        var rulePreset = this.filtersFlat.filter(function (filter) {
          return filter.id === event.target.value;
        })[0];
        this.setRule(rulePreset, r);
      },
      saveTitle: function saveTitle(title) {
        this.set('query.title', title);
        this.toggleProperty('isTitleEdit');
      }
    }
  });

  _exports.default = _default;
});