define("oi/helpers/format-date", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "moment"], function (_exports, _slicedToArray2, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatDate = formatDate;
  _exports.default = void 0;

  function formatDate(date) {
    var format = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'YYYY-MM-DD';
    var local = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
    var placeholder = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : '--';

    if (!date) {
      return placeholder;
    }

    if (date.slice && format.length === 10) {
      return date.slice(0, 10);
    } // note: no UTC! (otherwise bootrap-datepicker will seemingly behave strangely
    // in UTC+x timezones as it will always pick T00:00:00 local dates which will
    // be "converted" back to late evening the day before UTC)


    var dt = local ? _moment.default.parseZone(date) : (0, _moment.default)(date);
    return dt.format(format);
  }

  var _default = Ember.Helper.helper(function (_ref, _ref2) {
    var _ref3 = (0, _slicedToArray2.default)(_ref, 1),
        date = _ref3[0];

    var format = _ref2.format,
        local = _ref2.local,
        placeholder = _ref2.placeholder;
    return formatDate(date, format, local, placeholder);
  });

  _exports.default = _default;
});