define("oi/components/tasks/itemselector", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    selectItemModel: undefined,
    term: undefined,
    page: 1,
    size: 10,
    items: Ember.computed('term', 'page', 'size', 'selectItemModel.model', function () {
      if (this.get('selectItemModel.model')) {
        return this.get('store').query(this.get('selectItemModel.model'), {
          search: this.get('term'),
          page: this.get('page'),
          page_size: this.get('size')
        });
      }

      return [];
    }),
    actions: {
      select: function select(item) {
        var selectItemCallbackHandler = this.get('selectItemModel.selectItemCallbackHandler');

        if (typeof selectItemCallbackHandler === 'function') {
          selectItemCallbackHandler(this.get('selectItemModel.scope'), item);
        }

        if (this.get('selectItemModel.closeDialogAfterCallback')) {
          this.set('selectItemModel', undefined);
        }
      }
    }
  });

  _exports.default = _default;
});