define("oi/models/carrier", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    internal_code: _emberData.default.attr('string'),
    short_name: _emberData.default.attr('string'),
    official_name: _emberData.default.attr('string'),
    scac: _emberData.default.attr('string'),
    request_type_support: _emberData.default.attr(),
    hasSupportedRequestType: Ember.computed.or('request_type_support.{c_id,b_id,m_bl}'),
    title: Ember.computed('scac', 'short_name', function () {
      return "".concat(this.short_name, " (").concat(this.scac, ")");
    }),
    ctt_quality: _emberData.default.attr()
  });

  _exports.default = _default;
});