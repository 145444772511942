define("oi/components/visual-query-filter/vqf", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    vqfRules: Ember.computed('definitionRule.rules', function () {
      // create a copy of rules without data binding.
      // Otherwise changes would be applied regardless of whether the user cancels or submits
      if (this.definitionRule) {
        return JSON.parse(JSON.stringify(this.definitionRule.rules));
      }

      return undefined;
    }),
    condensedFilters: Ember.computed('filters', function () {
      var condensedFilters = {};
      this.filters.fields.forEach(function (item) {
        var optgroup = item.optgroup;

        if (condensedFilters[optgroup]) {
          condensedFilters[optgroup].push(item);
        } else {
          condensedFilters[optgroup] = [item];
        }
      });
      return condensedFilters;
    }),
    actions: {
      saveRules: function saveRules() {
        // filter out every invalid rule set
        var filteredRules = this.vqfRules.filter(function (item) {
          return item.rules.length > 0;
        });
        this.set('definitionRule.rules', filteredRules);
      },
      cancel: function cancel() {
        this.set('vqfRules', JSON.parse(JSON.stringify(this.definitionRule.rules)));
      },
      changeCondition: function changeCondition(e) {
        this.set('definitionRule.condition', e.target.value);
      },
      addRule: function addRule() {
        var newRule = {
          condition: 'OR',
          rules: []
        };

        if (this.vqfRules) {
          newRule.title = "Rule ".concat(this.vqfRules.length + 1);
          this.vqfRules.pushObject(newRule);
        } else {
          newRule.title = 'Rule 1';
          this.set('vqfRules', newRule);
        }
      },
      remove: function remove(rule) {
        var filteredRules = this.vqfRules.filter(function (item) {
          return item !== rule;
        });
        this.setProperties({
          'definitionRule.rules': filteredRules,
          vqfRules: filteredRules
        });
      }
    }
  });

  _exports.default = _default;
});