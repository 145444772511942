define("oi/helpers/oi-call-method", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.oiCallMethod = oiCallMethod;
  _exports.default = void 0;

  function oiCallMethod(params) {
    if (params && params.length >= 3) {
      var obj = params[0];
      var method = params[1];
      var param = params[2];
      return obj[method](param);
    }

    return null;
  }

  var _default = Ember.Helper.helper(oiCallMethod);

  _exports.default = _default;
});