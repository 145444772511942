define("oi/helpers/days-diff", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "moment"], function (_exports, _slicedToArray2, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.daysDiffRounded = daysDiffRounded;
  _exports.default = void 0;

  function daysDiffRounded(dt1, dt2) {
    if (!dt1 || !dt2) {
      return undefined;
    }

    var momentDiff = (0, _moment.default)(dt1 === 'now' ? undefined : dt1).diff(dt2 === 'now' ? undefined : dt2);
    return Math.round(momentDiff / 86400000);
  }

  var _default = Ember.Helper.helper(function (_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
        datetime1 = _ref2[0],
        datetime2 = _ref2[1];

    return daysDiffRounded(datetime1, datetime2);
  });

  _exports.default = _default;
});