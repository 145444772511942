define("oi/models/user", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    username: _emberData.default.attr('string'),
    joined: _emberData.default.attr('number'),
    accounts: _emberData.default.attr(),
    user_hash: _emberData.default.attr('string'),
    fullname: _emberData.default.attr('string'),
    features: _emberData.default.attr(),
    email: _emberData.default.attr('string'),
    prefAccountTitle: _emberData.default.attr('string'),
    prefAccountIds: _emberData.default.attr(),
    prefAccountId: _emberData.default.attr('number'),
    user_access_control_tags: _emberData.default.attr()
  });

  _exports.default = _default;
});