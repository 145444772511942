define("oi/components/oi-tag-edit-line", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'tr',
    isEditing: false,
    doubleClick: function doubleClick() {
      this.set('isEditing', true);
    },
    actions: {
      update: function update() {
        this.toggleProperty('isEditing');
        this.update();
      }
    }
  });

  _exports.default = _default;
});