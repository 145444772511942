define("oi/routes/chart", ["exports", "jquery", "oi/config/environment", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _jquery, _environment, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.QUERY_PARAMS = _exports.OTHER_SETTINGS = _exports.FILTER_PARAMS = void 0;
  var CHARTSFIXTURE = [{
    name: 'volume',
    title: 'Volume',
    desc: 'Real-time dashboard on container volumes arrived in the past as well as currently travelling.',
    add: 'Arrived containers are those that have reached the delivery location or are otherwise considered completed. Reference date is based on first known Port of Loading or Origin activity.',
    askForHistoricalDownload: true
  }, {
    name: 'performance',
    title: 'On-time Performance',
    referenceOptions: [{
      value: 'pol',
      label: 'POL'
    }, {
      value: 'pod',
      label: 'POD',
      isDefault: true
    }],
    desc: {
      default: Ember.String.htmlSafe('Real-time dashboard on on-time performance at Port of Discharge. ' + "Compares actual arrival (ATA) with either <span class='oi-highlight'>initial planning (ETA) " + 'or contractual ETA. Use the checkbox above to toggle behaviour</span>.'),
      pol: Ember.String.htmlSafe('Real-time dashboard on on-time performance at Port of Loading. ' + "Compares actual departure (ATD) with either <span class='oi-highlight'>initial planning (ETD) " + 'or contractual ETD. Use the checkbox above to toggle behaviour</span>.')
    },
    add: {
      default: 'All shipments that have reached the Port of Discharge are considered. ' + 'The shown shipping date is based on first known Port of Loading or Origin activity.',
      pol: 'All shipments that have left the Port of Loading are considered. ' + 'The shown shipping date is based on first known Port of Loading or Origin activity.'
    }
  }, {
    name: 'demurrage',
    title: 'Port of Discharge - Demurrage/Detention',
    desc: Ember.String.htmlSafe('Real-time dashboard on container waiting time at Port of Discharge and time in delivery until return to container depot. ' + "<span class='oi-highlight'>The latter may involve land transport from POD to an inland container facility, conducted by the carrier. " + 'Use the checkbox above to omit this voyage stage from the calculations where applicable</span>.'),
    add: 'Considers only containers where appropriate data is available.',
    askForHistoricalDownload: true
  }, {
    name: 'etdchange',
    title: 'Port of Loading - ETD changes',
    desc: 'Dashboard outlining ETD changes for Port of Loading. Compares last planned date for departure/load (first known, in given order) with initially planned or - if provided - contractual date. Use the checkbox above to toggle behaviour.',
    add: 'Considers only containers where appropriate data is available.',
    askForHistoricalDownload: true
  }, {
    name: 'etachange',
    title: 'Port of Discharge - ETA changes',
    desc: 'Dashboard outlining ETA changes for Port of Discharge. Compares last planned date for arrival/discharge/delivery (first known, in given order) with initially planned or - if provided - contractual date. Use the checkbox above to toggle behaviour.',
    add: 'Considers only containers where appropriate data is available.',
    askForHistoricalDownload: true
  }, {
    name: 'transship',
    title: 'Transshipment Port',
    desc: 'Real-time dashboard on container waiting time plus historical statistics about turnaround times, per affected T/S ports.',
    add: 'Considers only containers where appropriate data is available.',
    askForHistoricalDownload: true
  }, {
    altName: 'pol',
    name: 'stuckinpol',
    title: 'Port of Loading - Turnaround Times',
    desc: 'Real-time dashboard on container waiting time in port of loading (POL) plus historical statistics about turnaround times, per affected POLs.',
    add: 'Considers only containers where appropriate data is available.',
    askForHistoricalDownload: true
  }, {
    name: 'podapproach',
    title: 'Port of Discharge - Approach',
    desc: Ember.String.htmlSafe('Real-time dashboard on containers approaching their POD or delivery location ' + 'according to the latest planned date from the carrier or the contractual date. ' + "<span class='oi-highlight'>(Use the checkbox to toggle which reference date is used.)</span>"),
    add: 'Note: Unlike the other statistics pages, this dashboard refers to the future, not the past. ' + 'Considers only containers already departed from the port of loading.'
  }, {
    altName: 'lif',
    name: 'stuckinlif',
    title: 'Last Inland Facility',
    desc: 'Real-time dashboard on container waiting time in last inland facility (LIF) plus historical statistics about turnaround times, per affected LIFs.',
    add: 'Considers only containers where appropriate data is available.',
    askForHistoricalDownload: true
  }];
  var FILTER_PARAMS = ['tags', 'pol_date_0', 'pol_date_1', 'carrier', 'origin_country', 'destination_country', 'origin_location', 'destination_location', 'pod_date_0', 'pod_date_1'];
  _exports.FILTER_PARAMS = FILTER_PARAMS;
  var OTHER_SETTINGS = ['contracted', 'reference', 'exclude_inland'];
  _exports.OTHER_SETTINGS = OTHER_SETTINGS;
  var QUERY_PARAMS = [].concat(FILTER_PARAMS, OTHER_SETTINGS);
  _exports.QUERY_PARAMS = QUERY_PARAMS;

  function buildFilterQueryString(params) {
    return QUERY_PARAMS.filter(function (p) {
      return params[p];
    }).map(function (p) {
      return "".concat(p, "=").concat(params[p]);
    }).join('&');
  }

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    titleToken: function titleToken(model) {
      var title = model.meta.currentChartDescriptor.title;
      return "Statistics: ".concat(title);
    },
    applicationService: Ember.inject.service(),
    user: Ember.inject.service(),
    fetchActualStuff: function fetchActualStuff(chartName, filterQuery) {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        _jquery.default.ajax({
          url: "".concat(_this.applicationService.apiBase, "/dashboards/").concat(chartName, "/recipe/").concat(filterQuery ? "?".concat(filterQuery) : ''),
          type: 'GET',
          crossDomain: true,
          xhrFields: {
            withCredentials: _environment.default.APP.USE_AJAX_CALL_WITH_CREDENTIALS
          },
          headers: _this.get('user.fullHeaders'),
          success: function success(data) {
            return resolve(data);
          },
          error: function error(_error) {
            return reject(_error);
          }
        });
      });
    },
    // ---------------------------------------------------------------------------
    // Model hooks
    // ---------------------------------------------------------------------------
    model: function model(params) {
      var index = parseInt(params.chart_id, 10);
      var currentChartDescriptor = index < CHARTSFIXTURE.length ? CHARTSFIXTURE[index] : CHARTSFIXTURE.find(function (c) {
        return c.name === params.chart_id || c.altName && c.altName === params.chart_id;
      }) // support "stuckinpol" as "pol" alias
      || CHARTSFIXTURE[0];
      this.controllerFor('application').setProperties({
        savepoldate1: params.pol_date_1,
        savepoldate0: params.pol_date_0,
        savetags: params.tags,
        savecarrier: params.carrier
      });
      var filterQuery = buildFilterQueryString(params);
      return Ember.RSVP.hash({
        recipe: this.fetchActualStuff(currentChartDescriptor.name, filterQuery),
        availableTags: this.store.findAll('tag', {
          backgroundReload: false
        }),
        availableCarriers: this.store.findAll('carrier', {
          backgroundReload: false
        }),
        meta: {
          currentChartDescriptor: currentChartDescriptor,
          filterQuery: filterQuery
        }
      });
    },
    // ---------------------------------------------------------------------------
    // Init controller
    // ---------------------------------------------------------------------------
    setupController: function setupController(controller, model) {
      controller.set('feed', model.recipe);
      controller.set('availableTags', model.availableTags);
      controller.set('availableCarriers', model.availableCarriers);
      controller.set('filterQuery', model.meta.filterQuery);
      controller.set('currentChartDescriptor', model.meta.currentChartDescriptor);
    },
    // ---------------------------------------------------------------------------
    // Render the appr. template
    // ---------------------------------------------------------------------------
    renderTemplate: function renderTemplate(controller, model) {
      // determine the right template file...
      var templateName = "chart_".concat(model.meta.currentChartDescriptor.name); // link to the right controller (currently all charts are using the same)
      // var controller = this.controllerFor('chart');

      this.render(templateName, {
        controller: controller
      });
    },
    actions: {
      queryParamsDidChange: function queryParamsDidChange() {
        Ember.run.once(this, this.refresh);
      }
    }
  });

  _exports.default = _default;
});