define("oi/services/download-service", ["exports", "oi/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var POLLING_LIMIT = 400;
  var POLLING_SECONDS = 10;

  var _default = Ember.Service.extend({
    user: Ember.inject.service(),
    store: Ember.inject.service(),
    applicationService: Ember.inject.service(),
    downloads: Ember.A([]),
    init: function init() {
      this._super.apply(this, arguments);

      if (_environment.default.environment !== 'test') {
        this.check_downloads();
      }
    },
    registerDownload: function registerDownload(obj) {
      this.downloads.pushObject(obj);
    },
    removeDownload: function removeDownload(obj) {
      this.downloads.removeObject(obj);
    },
    activeDownloads: Ember.computed(function () {
      return this.get('downloads');
    }),
    authorize: function authorize() {
      var _this = this;

      var headers = this.get('user.authHeaders');
      return _jquery.default.ajax({
        url: "".concat(this.applicationService.apiHost, "/p44/download-token/"),
        type: 'GET',
        headers: headers,
        xhrFields: {
          withCredentials: _environment.default.APP.USE_AJAX_CALL_WITH_CREDENTIALS
        }
      }).then(function (accessToken) {
        headers.Authorization = "Bearer ".concat(accessToken);
        Ember.run.later(_this, function () {
          this.set('auth_header', headers);
        }, 500);
      });
    },
    create: function create(filename) {
      var downloadService = this;

      if (this.get('auth_header')) {
        var item = this.store.createRecord('download-info');
        item.set('filename', filename);
        this.registerDownload(item);
      } else {
        var headers = this.get('user.authHeaders');

        if (headers.Authorization === 'Bearer undefined') {
          this.authorize().then(function () {
            var item = downloadService.store.createRecord('download-info');
            item.set('filename', filename);
            downloadService.registerDownload(item);
          });
        } else {
          this.set('auth_header', headers);

          var _item = this.store.createRecord('download-info');

          _item.set('filename', filename);

          this.registerDownload(_item);
        }
      }
    },
    check_downloads: function check_downloads() {
      var _this2 = this;

      var downloadService = this;
      this.downloads.forEach(function (file) {
        var status = file.get('status');

        if (status !== 'error') {
          var url = "".concat(_this2.applicationService.apiHost, "/media/cwa-output/").concat(file.filename);

          _jquery.default.ajax({
            url: url,
            type: 'HEAD',
            xhrFields: {
              withCredentials: _environment.default.APP.USE_AJAX_CALL_WITH_CREDENTIALS
            },
            headers: _this2.get('auth_header'),
            success: function success() {
              file.set('link', url);
              file.download(downloadService.get('auth_header'));
              downloadService.removeDownload(file);
            },
            error: function error(xhr) {
              if (xhr.status === 401) {
                downloadService.authorize();
              }

              var attempts = file.get('attempts');

              if (attempts >= POLLING_LIMIT) {
                file.set('status', 'error');
                file.set('message', 'Download generation timed out');
              } else {
                file.set('attempts', attempts + 1);
              }
            }
          });
        }
      });
      Ember.run.later(this, function () {
        return _this2.check_downloads();
      }, POLLING_SECONDS * 1000);
    }
  });

  _exports.default = _default;
});