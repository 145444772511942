define("oi/templates/subscription_loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "klUcZqgl",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"container oi-first\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"page-header\"],[8],[0,\"\\n    \"],[7,\"h1\",true],[11,\"class\",[28,\"if\",[[28,\"string-contains\",[[23,0,[\"target\",\"url\"]],\"share=\"],null],\"text-center shared-link-loading\"],null]],[8],[0,\"Subscription is loading...\"],[9],[0,\"\\n  \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"loading-pane\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"loading-message\"],[8],[0,\"\\n\"],[4,\"if\",[[28,\"string-contains\",[[23,0,[\"target\",\"url\"]],\"share=\"],null]],null,{\"statements\":[[0,\"          \"],[1,[22,\"shared-spinner\"],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"          \"],[1,[22,\"spinkit-spinner\"],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "oi/templates/subscription_loading.hbs"
    }
  });

  _exports.default = _default;
});