define("oi/components/oi-stats-head", ["exports", "oi/mixins/multi-filter-mixin", "oi/routes/chart"], function (_exports, _multiFilterMixin, _chart) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_multiFilterMixin.default, {
    tagName: '',
    filterParams: _chart.FILTER_PARAMS,
    infoBoxComponent: null,
    description: Ember.computed('currentChartDescriptor.{desc,add}', 'radioValue', function () {
      var currentChartDescriptor = this.currentChartDescriptor,
          radioValue = this.radioValue;
      var desc = currentChartDescriptor.desc,
          _currentChartDescript = currentChartDescriptor.add,
          add = _currentChartDescript === void 0 ? '' : _currentChartDescript;
      return {
        main: desc[radioValue] || desc.default || desc,
        add: add[radioValue] || add.default || add
      };
    }),
    carrierSortOrder: Object.freeze(['short_name']),
    sortedCarriers: Ember.computed('availableCarriers', 'carrierSortOrder', function () {
      if (!this.availableCarriers) {
        return null;
      }

      var carriers = this.availableCarriers.sortBy('carrierSortOrder').map(function (carrier) {
        return {
          id: carrier.id,
          title: "".concat(carrier.short_name, " (").concat(carrier.scac, ")")
        };
      });
      return carriers;
    }),
    willUpdate: function willUpdate() {
      /* "performance" chart only: if "POL" is the reference */
      if (this.get('reference') === 'pol') {
        this.set('c_eta', false);
      }
    }
  });

  _exports.default = _default;
});