define("oi/helpers/format-reldiff", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.verbalizeDiff = verbalizeDiff;
  _exports.default = void 0;

  /** verbalize time difference between two dates (most commonly, in #days) */
  function verbalizeDiff(diff) {
    var unit = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'day';
    var unitPlural = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : unit && "".concat(unit, "s");

    if (diff === 0) {
      return null;
    }

    var absRoundedDiff = Math.abs(diff);
    return "".concat(absRoundedDiff, " ").concat(absRoundedDiff === 1 ? unit : unitPlural).concat(diff < 0 ? ' ahead of' : ' behind');
  }

  var _default = Ember.Helper.helper(function (_ref, _ref2) {
    var _ref3 = (0, _slicedToArray2.default)(_ref, 1),
        num = _ref3[0];

    var unit = _ref2.unit,
        unitPlural = _ref2.unitPlural;
    return verbalizeDiff(num, unit, unitPlural);
  });

  _exports.default = _default;
});