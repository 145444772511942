define("oi/controllers/index/exceptions", ["exports", "@babel/runtime/helpers/esm/toConsumableArray", "oi/routes/index/exceptions", "ember-local-storage", "oi/config/environment", "jquery"], function (_exports, _toConsumableArray2, _exceptions, _emberLocalStorage, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var EVENT_ICONS = {
    0: 'fa-sign-out',
    4: 'fa-ship',
    8: 'fa-clock-o',
    16: 'fa-exchange',
    30: 'fa-check-square',
    1000: 'fa-clock-o',
    2000: 'fa-clock-o',
    3000: 'fa-sign-in',
    4000: 'fa-handshake-o'
  };
  var HOURS_OPTIONS = [24, 48, 72];

  var _default = Ember.Controller.extend({
    user: Ember.inject.service(),
    session: Ember.inject.service(),
    applicationService: Ember.inject.service(),
    exceptionmanagementStorage: (0, _emberLocalStorage.storageFor)('exceptionmanagement'),
    itemToEdit: undefined,
    itemToDelete: undefined,
    exception_templates: Ember.computed('session.data.features', function () {
      if (this.session.get('data.features.iem.feature_enabled')) {
        return this.store.findAll('exceptionmanagement/template');
      }

      return undefined;
    }),
    queryParams: ['days', 'hours'],
    days: _exceptions.DEFAULT_EXCEPTION_DAYS,
    // URL manipulations of the "days=..." part shall be reflected in the displayed
    // input field, but manipulations there should not _immediately_ change days, hence:
    selectedDays: Ember.computed.oneWay('days'),
    hours: _exceptions.DEFAULT_EVENT_HOURS,
    selectedHours: Ember.computed.oneWay('hours'),
    hoursOptions: Ember.computed('hours', function () {
      if (HOURS_OPTIONS.includes(this.hours)) {
        return HOURS_OPTIONS;
      }

      return [this.hours].concat(HOURS_OPTIONS).sort(function (a, b) {
        return a - b;
      });
    }),
    iconFor: EVENT_ICONS,
    actions: {
      toggleModal: function toggleModal() {
        this.send.apply(this, ['showModal'].concat(Array.prototype.slice.call(arguments))); // eslint-disable-line prefer-rest-params
      },
      setHours: function setHours(e) {
        this.setProperties({
          selectedHours: e,
          hours: e
        });
      },
      createDefinition: function createDefinition() {
        var newDefinition = this.store.createRecord('exceptionmanagement/definition', {
          rule: {
            condition: 'AND',
            rules: []
          },
          activeStep: 0
        });
        this.send('edit', newDefinition);
      },
      edit: function edit(record) {
        this.set('itemToEdit', record);
      },
      refresh: function refresh(item) {
        item.reloadExceptions();
      },
      clone: function clone(item) {
        var newDefinition = item.store.createRecord('exceptionmanagement/definition', {
          title: "Copy of ".concat(item.title),
          description: item.description,
          rule: item.rule,
          template_id: item.template_id
        });
        this.set('itemToEdit', newDefinition);
      },
      saveItem: function saveItem(definition) {
        var _this = this;

        definition.save().then(function () {
          _this.send('refreshExceptionDefinitions');

          _this.send('closeDialog', 'itemToEdit');
        }).catch(function (errors) {
          _this.set('errors', errors);
        });
      },
      askForDelete: function askForDelete(item) {
        this.set('itemToDelete', item);
      },
      deleteItem: function deleteItem(item) {
        var _this2 = this;

        if (item && !this.get('deleteInProgress')) {
          this.set('deleteInProgress', true);
          item.destroyRecord().then(function (rec) {
            rec.unloadRecord();

            var storedIds = _this2.exceptionmanagementStorage.get('sortedIds');

            if (storedIds !== undefined) {
              var newIds = storedIds.filter(function (e) {
                return e !== item.id;
              });

              _this2.set('exceptionmanagementStorage.sortedIds', newIds);
            }
          }).then(function () {
            _this2.send('closeDialog', 'itemToDelete');

            _this2.send('refreshExceptionDefinitions');
          }).catch(function (errors) {
            _this2.set('deleteInProgress', false);

            _this2.set('errors', errors);
          });
        }
      },
      cancel: function cancel(field, item) {
        if (item) {
          item.rollbackAttributes();
        }

        this.send('closeDialog', field);
      },
      closeDialog: function closeDialog(field) {
        this.set(field, undefined);
        this.set('errors', undefined);
        this.set('deleteInProgress', undefined);
      },
      refreshDefinitions: function refreshDefinitions() {
        this.send('refreshExceptionDefinitions');
      },
      sortOrderChanged: function sortOrderChanged(sortedIds) {
        var _this3 = this;

        var sortedIdsInReverseOrder = (0, _toConsumableArray2.default)(sortedIds).reverse();
        sortedIdsInReverseOrder.forEach(function (definitionId, index) {
          var baseUrl = _this3.applicationService.apiBase;
          var url = "".concat(baseUrl, "/exception-management/definitions/").concat(definitionId, "/");
          return _jquery.default.ajax({
            type: 'PATCH',
            url: url,
            data: {
              sort_order_index: index + 1
            },
            headers: _this3.get('user.fullHeaders'),
            crossDomain: true,
            xhrFields: {
              withCredentials: _environment.default.APP.USE_AJAX_CALL_WITH_CREDENTIALS
            }
          });
        });
      },
      resetSortOrder: function resetSortOrder() {
        this.get('exceptionmanagementStorage').clear();

        if (window) {
          window.location.reload(true);
        }
      },
      toggleEventItem: function toggleEventItem(event) {
        if (event) {
          this.set('selectedEvents', event.subscription_events);
        }
      }
    }
  });

  _exports.default = _default;
});