define("oi/components/oi-meta-badges", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    releaseProps: Object.freeze([{
      name: 'Carrier',
      key: 'carrier_release'
    }, {
      name: 'Customs',
      key: 'customs_release'
    }])
  });

  _exports.default = _default;
});