define("oi/helpers/oi-change-customization-style", ["exports", "oi/helpers/hexToRgb", "oi/helpers/lightenDarkenHex"], function (_exports, _hexToRgb, _lightenDarkenHex) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.oiChangeCustomerStyle = oiChangeCustomerStyle;
  _exports.default = void 0;

  function oiChangeCustomerStyle(customization) {
    if (Object.keys(customization).length > 0) {
      var primary = customization.primary_color;
      var secondary = customization.secondary_color;
      var root = document.documentElement;
      root.style.setProperty('--primary-color-120', (0, _lightenDarkenHex.lightenDarkenHex)(primary, -20), 'important');
      root.style.setProperty('--primary-color-100', (0, _hexToRgb.hexToRgb)(primary));
      root.style.setProperty('--primary-color-30', (0, _hexToRgb.hexToRgb)(primary, 0.3));
      root.style.setProperty('--primary-color-20', (0, _hexToRgb.hexToRgb)(primary, 0.2));
      root.style.setProperty('--secondary-color', secondary);
      root.style.setProperty('--primary-color-100-oi', (0, _hexToRgb.hexToRgb)(primary));
      root.style.setProperty('--primary-color-30-oi', (0, _hexToRgb.hexToRgb)(primary, 0.3));
      root.style.setProperty('--primary-color-20-oi', (0, _hexToRgb.hexToRgb)(primary, 0.2));
      root.style.setProperty('--secondary-color-oi', secondary);
    }
  }

  var _default = Ember.Helper.helper(oiChangeCustomerStyle);

  _exports.default = _default;
});