define("oi/components/oi-container-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didRender: function didRender() {
      this._super.apply(this, arguments); // eslint-disable-line prefer-rest-params


      this.$('span').tooltip();
    }
  });

  _exports.default = _default;
});