define("oi/constants/visual-query-filter/filterdefinitions", ["exports", "oi/constants/countries", "oi/constants/shipments-filter"], function (_exports, _countries, _shipmentsFilter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getFilter = _exports.createLocationFilter = _exports.EVENT_TIME_RANGE_FILTERS = void 0;
  var EVENT_TIME_RANGE_FILTERS = [{
    id: '24',
    title: '24 hours'
  }, {
    id: '48',
    title: '48 hours'
  }, {
    id: '72',
    title: '72 hours'
  }];
  _exports.EVENT_TIME_RANGE_FILTERS = EVENT_TIME_RANGE_FILTERS;

  var createLocationFilter = function createLocationFilter(id, group) {
    return [{
      id: "".concat(id, "_loc__cached_country"),
      label: "".concat(group, " Country"),
      type: 'string',
      input: 'oi-country-filter',
      options: _countries.default,
      optgroup: group,
      operators: ['equal', 'not_equal'],
      description: "".concat(group, " country of the shipment. You can select                    the country out of a list of supported countries.")
    }, {
      id: "".concat(id, "_loc__cached_locode"),
      label: "".concat(group, " Locode"),
      optgroup: group,
      type: 'string',
      input: 'oi-locode-filter',
      operators: ['equal', 'not_equal', 'contains'],
      description: "".concat(group, " locode of the shipment (i.e. DEHAM)")
    }, {
      id: "".concat(id, "_loc__displayname"),
      label: "".concat(group, " Name"),
      optgroup: group,
      type: 'string',
      input: 'oi-location-filter',
      operators: ['equal', 'not_equal', 'contains'],
      description: "".concat(group, " name of the shipment (i.e. Hamburg)")
    }];
  };

  _exports.createLocationFilter = createLocationFilter;

  var getFilter = function getFilter(store) {
    var carriers = store.peekAll('carrier').map(function (carrier) {
      var result = {
        id: carrier.get('id'),
        title: "".concat(carrier.get('short_name'), " (").concat(carrier.get('scac'), ")")
      };
      return result;
    });
    var tags = store.peekAll('tag').map(function (tag) {
      var result = {
        id: tag.get('id'),
        title: tag.get('value')
      };
      return result;
    });
    var operators = ['equal', 'not_equal', 'less', 'less_or_equal', 'greater', 'greater_or_equal', 'contains', 'in'];
    return {
      operators: operators,
      fields: [{
        id: 'descriptive_name',
        label: 'Shipment Description',
        type: 'string',
        operators: ['equal', 'not_equal', 'contains'],
        optgroup: 'Shipment',
        description: 'Search for shipments with specified description.'
      }, {
        id: 'current_vessel__cached_imo',
        label: 'Current Vessel - IMO',
        type: 'integer',
        optgroup: 'Shipment',
        input: 'oi-vessel-imo-filter',
        description: 'Search for shipments where the current vessel has the specified IMO (International Maritime Organization).'
      }, {
        id: 'current_vessel__cached_mmsi',
        label: 'Current Vessel - MMSI',
        type: 'string',
        optgroup: 'Shipment',
        input: 'oi-vessel-mmsi-filter',
        description: 'Search for shipments where the current vessel has the specified MMSI (Maritime Mobile Service Identity).'
      }, {
        id: 'current_vessel__displayname',
        label: 'Current Vessel - Name',
        type: 'string',
        optgroup: 'Shipment',
        input: 'oi-vessel-name-filter',
        description: 'Search for shipments where the current vessel has the specified name.'
      }, {
        id: 'carrier',
        label: 'Carrier',
        type: 'string',
        input: 'select',
        options: carriers,
        optgroup: 'Shipment',
        operators: ['equal', 'not_equal', 'in', 'not_in'],
        description: 'Search for shipments with the specified carrier.'
      }, {
        id: 'tags',
        label: 'Shipment tags',
        type: 'integer',
        input: 'select',
        options: tags,
        optgroup: 'Shipment',
        operators: ['equal'],
        description: 'Search for shipments that are tagged with the specified tag.'
      }, {
        id: 'lifecycle_status',
        label: 'Lifecycle Status',
        type: 'string',
        input: 'select',
        options: _shipmentsFilter.LIFECYCLE_STATUSES,
        optgroup: 'Shipment',
        operators: ['equal', 'not_equal'],
        description: 'Search for shipments with the specified lifecycle status.'
      }, {
        id: 'status',
        label: 'Transport Status',
        type: 'string',
        input: 'select',
        options: _shipmentsFilter.TRANSPORT_STATUSES,
        optgroup: 'Shipment',
        operators: ['equal', 'not_equal'],
        description: 'Search for shipments with the specified transport status.'
      }, {
        id: 'shipmentsubscription__tags',
        label: 'Subscription Tags',
        type: 'integer',
        input: 'select',
        options: tags,
        optgroup: 'Subscription',
        operators: ['equal'],
        description: 'Search for shipments that subscriptions are tagged with the specified tag.'
      }, {
        id: 'shipmentsubscription__request_key',
        label: 'Subscription Ref. #',
        type: 'string',
        optgroup: 'Subscription',
        operators: ['equal'],
        description: 'Search for shipments that subscription has the defined request key.'
      }, {
        id: 'shipmentsubscription__descriptive_name',
        label: 'Subscription Description',
        type: 'string',
        optgroup: 'Subscription',
        operators: ['equal', 'not_equal', 'contains'],
        description: 'Search for shipments that subscription has the defined description.'
      }, {
        id: 'stuck_in_pol',
        label: 'Days in POL',
        type: 'integer',
        optgroup: 'Exceptional States',
        default_operator: 'greater_or_equal',
        default_value: '3',
        operators: ['equal', 'not_equal', 'greater', 'greater_or_equal', 'less', 'less_or_equal'],
        description: 'Search for shipments that are currently in port of loading for more, equal or less than x <b>days</b>.'
      }, {
        id: 'stuck_in_ts',
        label: 'Days in TS',
        type: 'integer',
        optgroup: 'Exceptional States',
        default_operator: 'greater_or_equal',
        default_value: '3',
        operators: ['equal', 'not_equal', 'greater', 'greater_or_equal', 'less', 'less_or_equal'],
        description: 'Search for shipments that are currently in transshipmment for more, equal or less than x <b>days</b>.'
      }, {
        id: 'stuck_in_pod',
        label: 'Days in POD',
        type: 'integer',
        optgroup: 'Exceptional States',
        default_operator: 'greater_or_equal',
        default_value: '3',
        operators: ['equal', 'not_equal', 'greater', 'greater_or_equal', 'less', 'less_or_equal'],
        description: 'Search for shipments that are currently in port of discharge for more, equal or less than x <b>days</b>.'
      }, {
        id: 'stuck_in_lif',
        label: 'Days in LIF',
        type: 'integer',
        optgroup: 'Exceptional States',
        default_operator: 'greater_or_equal',
        default_value: '3',
        operators: ['equal', 'not_equal', 'greater', 'greater_or_equal', 'less', 'less_or_equal'],
        description: 'Search for shipments that are currently in last inland facility for more, equal or less than x <b>days</b> (based on the chosen operator).'
      }, {
        id: 'behind_schedule',
        label: 'Days Behind Schedule',
        type: 'integer',
        optgroup: 'Exceptional States',
        default_operator: 'greater_or_equal',
        default_value: '3',
        operators: ['equal', 'not_equal', 'greater', 'greater_or_equal', 'less', 'less_or_equal'],
        description: 'Search for shipments that are currently behind schedule for more, equal or less than x <b>days</b> (based on the chosen operator).'
      }, {
        id: 'etd_changed',
        label: 'ETD changed',
        type: 'boolean',
        optgroup: 'Exceptional States',
        operators: ['equal'],
        default_value: 'true',
        input: function input() {
          return '<b>True</b>';
        },
        description: 'Search for shipments that estimated time of departure had been changed.'
      }, {
        id: 'eta_changed',
        label: 'ETA changed',
        type: 'boolean',
        optgroup: 'Exceptional States',
        operators: ['equal'],
        default_value: 'true',
        input: function input() {
          return '<b>True</b>';
        },
        description: 'Search for shipments that estimated time of arrival had been changed.'
      }, {
        id: 'pod_arrival_warning',
        label: 'POD Arrival Warning (Original)',
        type: 'integer',
        optgroup: 'Exceptional States',
        default_operator: 'greater_or_equal',
        default_value: '3',
        operators: ['equal', 'not_equal', 'greater', 'greater_or_equal', 'less', 'less_or_equal'],
        description: 'Search for shipments that are active and have generated time of arrival warnings or predictions at POD.'
      }, {
        id: 'pod_predicted_versus_contracted_eta',
        label: 'POD Predicted- vs. contracted ETA',
        type: 'integer',
        optgroup: 'Exceptional States',
        operators: ['equal', 'not_equal', 'greater', 'greater_or_equal', 'less', 'less_or_equal'],
        default_operator: 'greater_or_equal',
        default_value: '3',
        description: 'Search for shipments where contracted and predicted eta differ for more, equal or less than x <b>days</b> (based on the chosen operator).'
      }, {
        id: 'pod_predicted_versus_latest_planned_eta',
        label: 'POD Predicted- vs. latest planned ETA',
        type: 'integer',
        optgroup: 'Exceptional States',
        operators: ['equal', 'not_equal', 'greater', 'greater_or_equal', 'less', 'less_or_equal'],
        default_operator: 'greater_or_equal',
        default_value: '3',
        description: 'Search for shipments where latest planned ETA of the carrier and the predicted eta differs for more, equal or less than x <b>days</b> (based on the chosen operator).'
      }, {
        id: 'pod_latest_planned_vs_contracted_eta',
        label: 'POD latest planned vs. contracted ETA',
        type: 'integer',
        optgroup: 'Exceptional States',
        operators: ['equal', 'not_equal', 'greater', 'greater_or_equal', 'less', 'less_or_equal'],
        default_operator: 'greater_or_equal',
        default_value: '3',
        description: 'this rule is defined in order to compare the latest planned POD against the contracted ETA. In more clear words, it will compare the contractual ETA with the carrier\'s latest ETA, to see at a glance on the Exception Dashboard where the carrier "plans to" deviate from the Time of arrival they confirmed in the contract with the main user.'
      }, {
        id: 'latest_eta_vs_initial_eta',
        label: 'POD latest planned vs. initial ETA',
        type: 'integer',
        optgroup: 'Exceptional States',
        operators: ['equal', 'not_equal', 'greater', 'greater_or_equal', 'less', 'less_or_equal'],
        default_operator: 'greater_or_equal',
        default_value: '3',
        description: 'this rule compares the latest planned POD against the initial ETA. In more clear words it serves to make the user able to see on the first glance if there is a difference between the initial set date/time by the carrier and the latest planned.'
      }, {
        id: 'not_released',
        label: 'Not Released',
        type: 'boolean',
        optgroup: 'Exceptional States',
        operators: ['equal'],
        default_value: 'true',
        input: function input() {
          return '<b>True</b>';
        },
        description: 'Search for shipments that are not released (by customs or by carrier).'
      }, {
        id: 'expired_event',
        label: 'Event \'Shipment expired\' (no further updates) received within last',
        input: 'select',
        type: 'integer',
        options: EVENT_TIME_RANGE_FILTERS,
        optgroup: 'Events',
        operators: ['less_or_equal'],
        default_operator: 'less_or_equal',
        description: 'Search for shipments for that \'Shipment expired\' events have been generated within last x <b>days</b>.'
      }, {
        id: 'removed_from_booking_event',
        label: 'Event \'Container removed from booking\' received within last',
        input: 'select',
        type: 'integer',
        options: EVENT_TIME_RANGE_FILTERS,
        optgroup: 'Events',
        operators: ['less_or_equal'],
        default_operator: 'less_or_equal',
        description: 'Search for shipments for that \'Shipment expired\' events have been generated within last x <b>days</b>.'
      }, {
        id: 'schedule_changed_event',
        label: 'Event \'Schedule changed\' received within last',
        input: 'select',
        type: 'integer',
        options: EVENT_TIME_RANGE_FILTERS,
        optgroup: 'Events',
        operators: ['less_or_equal'],
        default_operator: 'less_or_equal',
        description: 'Search for shipments for that \'Schedule changed\' events have been generated within last x <b>days</b>.'
      }, {
        id: 'rollover_warning_vessel_changed',
        label: 'Event \'Rollover warning\' (vessel change) received within last',
        input: 'select',
        type: 'integer',
        options: EVENT_TIME_RANGE_FILTERS,
        optgroup: 'Events',
        operators: ['less_or_equal'],
        default_operator: 'less_or_equal',
        description: 'this rule will raise an exception if any Rollover Events happened within last x <b>hours</b>, so that the user can react and plan accordingly.'
      }, {
        id: 'status_changed_event',
        label: 'Event \'Status changed\' received within last',
        input: 'select',
        type: 'integer',
        options: EVENT_TIME_RANGE_FILTERS,
        optgroup: 'Events',
        operators: ['less_or_equal'],
        default_operator: 'less_or_equal',
        description: 'Search for shipments for that \'Status changed\' events have been generated within last x <b>days</b>.'
      }].concat(createLocationFilter('pol', 'Origin or POL')).concat(createLocationFilter('pod', 'Destination or POD')).concat(createLocationFilter('via_tsp', 'Via Transshipment Port')).concat(createLocationFilter('in_tsp', 'Currently in Transshipment Port'))
    };
  };

  _exports.getFilter = getFilter;
});