define("oi/serializers/leadtime", ["exports", "oi/serializers/drf"], function (_exports, _drf) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _drf.default.extend({
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      (false && !(Ember.isArray(payload)) && Ember.assert('leadtimes must not be queried individually', Ember.isArray(payload)));
      payload.forEach(function (leadtimeRecord) {
        leadtimeRecord.lead_all_vessel = leadtimeRecord.lead_all_vessel.map(Number);
        leadtimeRecord.lead_all_ports = leadtimeRecord.lead_all_ports.map(Number);
      });
      return this._super(store, primaryModelClass, payload, id, requestType);
    }
  });

  _exports.default = _default;
});