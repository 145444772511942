define("oi/templates/components/oi-radio-button-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Hreg87D1",
    "block": "{\"symbols\":[\"o\"],\"statements\":[[4,\"each\",[[24,[\"options\"]]],null,{\"statements\":[[0,\"  \"],[7,\"button\",true],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"internalSet\",[23,1,[\"value\"]]],null]],[11,\"disabled\",[23,1,[\"disabled\"]]],[11,\"aria-label\",[23,1,[\"aria-label\"]]],[11,\"class\",[29,[[22,\"buttonClassNames\"],\" \",[23,1,[\"classNames\"]],\" \",[28,\"if\",[[28,\"or\",[[28,\"eq\",[[24,[\"groupValue\"]],[23,1,[\"value\"]]],null],[28,\"and\",[[28,\"not\",[[24,[\"groupValue\"]]],null],[23,1,[\"isDefault\"]]],null]],null],[28,\"concat\",[[23,0,[\"activeButtonClass\"]],\" \",\"active\"],null],[23,0,[\"inactiveButtonClass\"]]],null],\" \",[28,\"if\",[[23,1,[\"aria-label\"]],[28,\"or\",[[23,1,[\"hintClass\"]],\"hint--top-right\"],null]],null]]]],[10,\"type\",\"button\"],[8],[0,\"\\n    \"],[1,[23,1,[\"label\"]],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "oi/templates/components/oi-radio-button-group.hbs"
    }
  });

  _exports.default = _default;
});