define("oi/templates/components/oi-incident", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CdUjA+nf",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"alert alert-warning alert-dismissible\"],[10,\"role\",\"alert\"],[8],[0,\"\\n    \"],[7,\"i\",true],[10,\"class\",\"fa fa-4x fa-exclamation-circle warning-icon\"],[10,\"aria-hidden\",\"true\"],[8],[9],[0,\"\\n    \"],[7,\"div\",true],[8],[0,\"\\n\"],[4,\"if\",[[28,\"and\",[[24,[\"incident\",\"start_date\"]],[24,[\"incident\",\"end_date\"]]],null]],null,{\"statements\":[[0,\"        \"],[7,\"p\",true],[10,\"class\",\"incident-date\"],[8],[0,\"Duration: \"],[7,\"b\",true],[8],[1,[28,\"local-datetime\",[[24,[\"incident\",\"start_date\"]]],null],false],[9],[0,\" to \"],[7,\"b\",true],[8],[1,[28,\"local-datetime\",[[24,[\"incident\",\"end_date\"]]],null],false],[9],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[7,\"h5\",true],[10,\"class\",\"incident-title\"],[8],[1,[24,[\"incident\",\"title\"]],false],[9],[0,\"\\n      \"],[7,\"p\",true],[10,\"class\",\"incident-description\"],[8],[1,[24,[\"incident\",\"description\"]],true],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "oi/templates/components/oi-incident.hbs"
    }
  });

  _exports.default = _default;
});