define("oi/components/spinkit-spinner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // CSS loading spinner taken from http://tobiasahlin.com/spinkit/
  var _default = Ember.Component.extend({
    classNames: ['spinner']
  });

  _exports.default = _default;
});