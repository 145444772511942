define("oi/controllers/sharedlinks", ["exports", "oi/mixins/multi-filter-mixin"], function (_exports, _multiFilterMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var FILTER_PARAMS = ['includeExpired'];
  var SORT_PARAMS = ['sort_subscription', 'sort_created', 'sort_creator__username'];

  var _default = Ember.Controller.extend(_multiFilterMixin.default, {
    queryParams: ['page', 'page_size'].concat(FILTER_PARAMS, SORT_PARAMS),
    filterParams: FILTER_PARAMS,
    includeExpired: false,
    page: 1,
    actions: {
      sort: function sort(field) {
        var sortParams = {
          page: 1
        };
        SORT_PARAMS.forEach(function (p) {
          sortParams[p] = undefined;
        }); // ^ filters are exclusive, so reset all of them

        sortParams[field] = this.get(field) === 'asc' ? 'desc' : 'asc';
        this.setProperties(sortParams);
      },
      toggleIncludeExpired: function toggleIncludeExpired() {
        if (this.includeExpired) {
          // current page number may be higher than total number afterwards: reset
          this.setProperties({
            includeExpired: false,
            page: 1
          });
        } else {
          // there will be at least as many items as before: no need to reset page
          this.set('includeExpired', true);
        }
      }
    }
  });

  _exports.default = _default;
});