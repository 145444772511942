define("oi/components/locode-with-name-tooltip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var LocComponent = Ember.Component.extend({
    tagName: 'span',
    attributeBindings: ['location.name:aria-label'],
    classNameBindings: ['hintClass'],
    hintClass: 'hint--top'
  });
  LocComponent.reopenClass({
    positionalParams: ['location']
  });
  var _default = LocComponent;
  _exports.default = _default;
});