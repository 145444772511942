define("oi/templates/components/oi-sort-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xBkgJjBs",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"isSortedAsc\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\",true],[10,\"class\",\"glyphicon glyphicon-triangle-top\"],[10,\"title\",\"sorted in ascending order; click to sort in descending order\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"isSortedDesc\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\",true],[10,\"class\",\"glyphicon glyphicon-triangle-bottom\"],[10,\"title\",\"sorted in descending order; click to remove sorting\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"i\",true],[10,\"class\",\"fa fa-sort\"],[10,\"aria-hidden\",\"true\"],[10,\"style\",\"opacity:0.5\"],[10,\"title\",\"click to sort in ascending order\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "oi/templates/components/oi-sort-icon.hbs"
    }
  });

  _exports.default = _default;
});